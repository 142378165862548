/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Menu, Drawer } from 'antd'
import { Widget } from 'react-typeform-embed'

import ButtonComponent from '../Button/index'
import GetModal from '../Modal/index'
import styles from './navigation.module.scss'
import logo2 from '../../assets/images/logo2.svg'
import userIcon from 'src/assets/images/user-light-blue-bg.svg'

import { ReactComponent as CancelIcon } from '../../assets/images/CancelIcon.svg'
import { ReactComponent as HamburgerIconBlack } from '../../assets/images/hamburger-menu-icon.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import useHandleSectionScroll from 'src/utils/Hooks/useHandleSectionScroll'

const ConsumerNavigation = ({ page, fontSize, showUserBtn }) => {
    const {
        NavLayout,
        NavHeader,
        BtnWrapper,
        NavLogoWrapper,
        NavItem,
        BarsMenu,
        BtnCancel,
        NavText,
        ConsumerNavText,
        BtnSecondary,
        ConsumerBtn,
        MobileDrawer,
        ListStyle,
        ConsumerAppHomeLinks,
        ConsumerMenuWrapper,
        ConsumerListWrapper,
        userBtn,
        ConsumerNav,
        DesktopBtns,
    } = styles
    const { largeTablet, isTablet } = useMediaQueries()
    const { handleSectionScroll } = useHandleSectionScroll()

    const [visible, setVisible] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()

    const showDrawer = () => {
        setVisible(true)
    }

    const hideDrawer = () => {
        setVisible(false)
    }

    const onClose = () => {
        setVisible(false)
    }
    const getOfferTypeForm = (
        <Widget
            id={`${process.env.REACT_APP_GET_OFFER_FORM_URL}`}
            height={700}
        />
    )

    const getStarted = () => {
        history.push(
            page === 'getStarted' ||
                page === 'home' ||
                page === 'proileListings'
                ? '/consumer/welcome'
                : '/',
        )
    }

    const handleCancel = () => {
        setShowModal(false)
    }

    const openChatWidget = () => {
        window.$zoho?.salesiq?.floatwindow.visible('show')
    }

    return (
        <Layout
            className={NavLayout}
            style={{
                position:
                    (page === 'appHome' ||
                        page === 'consumerConversationalFormActive' ||
                        page === 'ConsumerAppHome') &&
                    'relative',
            }}
        >
            <header
                className={`${NavHeader} ${ConsumerNav}`}
                style={{
                    boxShadow: '0px 0px 18.8182px rgba(0, 0, 0, 0.06)',
                }}
            >
                <div className={`${NavLogoWrapper} ${ConsumerNav}`}>
                    <Link to="/">
                        <img
                            src={logo2}
                            alt="logo"
                            style={{ height: '31px' }}
                        />
                    </Link>
                </div>
                {page === 'closedCluster' && (
                    <Menu mode="horizontal" className={ConsumerMenuWrapper}>
                        <li className={NavItem}>
                            <p onClick={openChatWidget}>Help</p>
                        </li>
                        <li className={NavItem}>
                            <a
                                href="/learn"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    fontWeight: page === 'learn' && '600',
                                }}
                            >
                                Learn
                            </a>
                        </li>
                        <li className={NavItem}>
                            <p
                                onClick={() =>
                                    handleSectionScroll('faq', 'closedCluster')
                                }
                            >
                                Questions
                            </p>
                        </li>
                    </Menu>
                )}
                {page !== 'appHome' &&
                page !== 'ConsumerAppHome' &&
                page !== 'consumerConversationalFormInactive' &&
                page !== 'consumerConversationalFormActive' &&
                page !== 'closedCluster' ? (
                    <Menu mode="horizontal" className={ConsumerMenuWrapper}>
                        <li className={NavItem}>
                            <Link
                                to="/learn"
                                style={{
                                    fontWeight: page === 'learn' && '600',
                                }}
                            >
                                Learn
                            </Link>
                        </li>
                        <li className={NavItem}>
                            <Link
                                to="/partners"
                                style={{
                                    fontWeight: page === 'partner' && '600',
                                }}
                            >
                                Become a partner
                            </Link>
                        </li>
                        <li className={NavItem}>
                            <p
                                onClick={() =>
                                    handleSectionScroll(
                                        'solar-savings-calculator',
                                    )
                                }
                            >
                                Energy Calculator
                            </p>
                        </li>
                    </Menu>
                ) : page === 'ConsumerAppHome' ? (
                    <div className={ConsumerAppHomeLinks}>
                        <li className={NavItem}>
                            <Link
                                to="/privacy-policy"
                                style={{
                                    fontSize: `${fontSize}`,
                                }}
                            >
                                Privacy Policy
                            </Link>
                        </li>
                        <li className={NavItem}>
                            <Link
                                to="/terms-of-service"
                                style={{
                                    fontSize: `${fontSize}`,
                                }}
                            >
                                Terms of Service
                            </Link>
                        </li>
                    </div>
                ) : null}
                {page !== 'appHome' &&
                page !== 'ConsumerAppHome' &&
                page !== 'consumerConversationalFormInactive' &&
                page !== 'consumerConversationalFormActive' &&
                page !== 'sseSystemsListings' &&
                page !== 'sseViewSystem' &&
                page !== 'closedCluster' ? (
                    <div className={BtnWrapper}>
                        {showUserBtn && (
                            <button className={userBtn}>
                                <img src={userIcon} alt="user sign in" />
                            </button>
                        )}{' '}
                        {/* : !isMobile ? ( */}
                        <div className={DesktopBtns}>
                            <ButtonComponent
                                btnBgColor="#fff"
                                btnTextColorOutline="#004AAD"
                                btnOutlineColor="#004AAD"
                                btnWidth={isTablet ? '120px' : '160px'}
                                btnHeight={isTablet ? '45px' : '53px'}
                                handleClick={() => getStarted()}
                                fontSize={isTablet ? '12px' : '14px'}
                                type="outline"
                            >
                                {page === 'getStarted' ||
                                page === 'home' ||
                                page === 'proileListings'
                                    ? 'Sign in'
                                    : 'Get Started'}
                            </ButtonComponent>
                        </div>
                        {/* ) : null} */}
                    </div>
                ) : null}

                {largeTablet && (
                    <button className={BarsMenu} onClick={showDrawer}>
                        <span>
                            <HamburgerIconBlack role={'presentation'} />
                        </span>
                    </button>
                )}

                {largeTablet && (
                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        visible={visible}
                        width="100%"
                    >
                        <div className={MobileDrawer}>
                            <button className={BtnCancel} onClick={hideDrawer}>
                                <span>
                                    <CancelIcon />
                                </span>
                            </button>
                        </div>
                        {page === 'closedCluster' ? (
                            <div className={ConsumerListWrapper}>
                                <li className={ListStyle}>
                                    <p
                                        className={`${NavText} ${ConsumerNavText}`}
                                        onClick={openChatWidget}
                                    >
                                        Help
                                    </p>
                                </li>
                                <li style={{ marginBottom: '30px' }}>
                                    <Link
                                        to="/learn"
                                        className={`${NavText} ${ConsumerNavText}`}
                                        style={{
                                            fontWeight:
                                                page === 'learn' && '600',
                                        }}
                                    >
                                        Learn
                                    </Link>
                                </li>
                                <li style={{ marginBottom: '50px' }}>
                                    <p
                                        onClick={() => {
                                            handleSectionScroll(
                                                'faq',
                                                'closedCluster',
                                            )
                                            onClose()
                                        }}
                                        className={`${NavText} ${ConsumerNavText}`}
                                    >
                                        Questions
                                    </p>
                                </li>
                            </div>
                        ) : (
                            <div className={ConsumerListWrapper}>
                                <li className={ListStyle}>
                                    <Link
                                        to="/learn"
                                        className={`${NavText} ${ConsumerNavText}`}
                                        style={{
                                            fontWeight:
                                                page === 'learn' && '600',
                                        }}
                                    >
                                        Learn
                                    </Link>
                                </li>
                                <li style={{ marginBottom: '30px' }}>
                                    <Link
                                        to="/partners"
                                        className={`${NavText} ${ConsumerNavText}`}
                                        style={{
                                            fontWeight:
                                                page === 'partner' && '600',
                                        }}
                                    >
                                        Become a partner
                                    </Link>
                                </li>
                                <li style={{ marginBottom: '50px' }}>
                                    <p
                                        onClick={() => {
                                            handleSectionScroll(
                                                'solar-savings-calculator',
                                            )
                                            onClose()
                                        }}
                                        className={`${NavText} ${ConsumerNavText}`}
                                    >
                                        Energy Calculator
                                    </p>
                                </li>
                                {page !== 'sseViewSystem' &&
                                    page !== 'sseSystemsListings' &&
                                    page !== 'closedCluster' && (
                                        <>
                                            <button
                                                className={`${BtnSecondary} ${ConsumerBtn}`}
                                                onClick={() => getStarted()}
                                            >
                                                {page === 'getStarted' ||
                                                page === 'home' ||
                                                page === 'proileListings'
                                                    ? 'Sign in'
                                                    : 'Get Started'}
                                            </button>
                                            <div
                                                style={{ marginBottom: '27px' }}
                                            ></div>
                                        </>
                                    )}
                            </div>
                        )}
                    </Drawer>
                )}
            </header>
            <GetModal
                showModal={showModal}
                onCancel={handleCancel}
                content={getOfferTypeForm}
            />
        </Layout>
    )
}

export default ConsumerNavigation


export const profileType = [
    {
        name: 'Home  or stalls/shops',
        value: 'RESIDENTIAL',
    },
    {
        name: 'Registered business',
        value: 'BUSINESS',
    },
]

export const profileSortingType = [
    {
        name: 'Default: Sort by capacity (Ascending)',
        value: 'ENERGY_CAPACITY_ASCENDING',
    },
    {
        name: 'Sort by capacity (Descending)',
        value: 'ENERGY_CAPACITY_DESCENDING',
    },
    {
        name: 'Sort alphabetically (Ascending)',
        value: 'ALPHABETICALLY_ASCENDING',
    },
    {
        name: 'Sort alphabetically (Descending)',
        value: 'ALPHABETICALLY_DESCENDING',
    },
]
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import warning from 'src/assets/images/warning-icon.svg'
import ButtonComponent from 'src/components/Button'
import PropTypes from 'prop-types'
import './override-check.scss'
import { TextArea } from 'src/components/InputFields'
import { useState } from 'react'
import { ConsumerButton } from 'src/components/Button'
import { useOverrideVerification } from '../../IdentityInformation/components/api'
import { useParams, useHistory } from 'react-router-dom'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { InlineLoader } from 'src/components/Loader'
const OverrideCheckModal = ({ onCancel, showOverrideModal, type }) => {
    const { sseBasicInfoData, sseBasicInfoRefetch } = useSSERetrieveBasicInfo({
        enabled: true,
    })
    const bvnId = sseBasicInfoData?.data?.data?.financial_verification?.bvn?.id
    const identity_id = sseBasicInfoData?.data?.data?.identity_verification?.id
    const objectId = {
        BVN: bvnId,
        ID: identity_id,
    }
    const { id: estimation_id } = useParams()
    const { mutate: mutateOverride, isLoading: isOverrideLoading } =
        useOverrideVerification()
    const [reason, setReason] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showReasonModal, setShowReasonModal] = useState(false)
    const { successAlert, errorAlert } = useCustomToast()
    const history = useHistory()
    const isDynamicKycUser = history?.location?.state?.isDynamicKycUser
    const estimationId = history?.location?.state?.estimationId
    const handleChange = e => {
        setReason(e.target.value)
    }
    const handleSubmitReason = () => {
        if (!reason) {
            setErrorMessage('Reason is required')
            return
        }
        mutateOverride(
            {
                payload: {
                    object_id: objectId[type],
                    reason,
                },
                estimation_id: isDynamicKycUser ? estimationId : estimation_id,
            },
            {
                onSuccess: () => {
                    successAlert('User data has been overridden')
                    setShowReasonModal(false)
                    sseBasicInfoRefetch()
                },
                onError: err => {
                    errorAlert(errorHandler(err?.response.data))
                },
            },
        )
    }
    return (
        <>
            <SecondaryModal
                onCancel={onCancel}
                showModal={showOverrideModal}
                showFooter={false}
                content={
                    <div className="override-container">
                        <img src={warning} />
                        <h2>Pass this customer</h2>
                        <p>
                            Changing this user {type}{' '}
                            {type === 'ID' ? 'card' : ''} result might affect
                            the initial credit decision result for this consumer
                        </p>

                        <div className="btn_group">
                            <ButtonComponent
                                type="nohover"
                                handleClick={onCancel}
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                            >
                                Cancel
                            </ButtonComponent>
                            <ButtonComponent
                                handleClick={() => {
                                    onCancel()
                                    setShowReasonModal(true)
                                }}
                                btnBgColor="#004AAD"
                                btnTextColor="#fff"
                            >
                                Confirm
                            </ButtonComponent>
                        </div>
                    </div>
                }
            />
            <SecondaryModal
                modalWidth="624px"
                onCancel={() => {
                    setShowReasonModal(false)
                    setReason('')
                }}
                showModal={showReasonModal}
                showFooter={false}
                content={
                    <div className="override-check-result">
                        <h2>Override check result</h2>
                        <p>Enter a reason for overriding check result</p>
                        <TextArea
                            placeholder="Enter reason"
                            rows={10}
                            height="146px"
                            name="reason"
                            value={reason}
                            handleChange={handleChange}
                            errorMessage={errorMessage}
                        />
                        <div className="btn_group">
                            <ConsumerButton
                                btnWidth="160px"
                                btnHeight="53px"
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                borderOutlineColor="#FFFFFF"
                                handleClick={handleSubmitReason}
                            >
                                {isOverrideLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Confirm'
                                )}
                            </ConsumerButton>
                        </div>
                    </div>
                }
            />
        </>
    )
}
OverrideCheckModal.propTypes = {
    onCancel: PropTypes.func,
    showOverrideModal: PropTypes.bool,
    type: PropTypes.string,
}
export default OverrideCheckModal

/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { useHistory } from 'react-router'
import { format, parseISO } from 'date-fns'
import { Row, Col, Skeleton, Dropdown, Menu } from 'antd'
import { useQuery } from 'react-query'
import SeoComponent from 'src/components/Seo'
import Toast from '../../../components/Toast'
import ActionModal from '../../../components/ActionModal'
import Button from '../../../components/Button'
import ButtonComponent from 'src/components/Button'
import editIcon from '../../../assets/images/edit.svg'
import Table from '../../../components/Table'
import BackNav from '../../../components/BackNav'
import { currencyFormatter } from '../../../utils/currencyFormatter'
import HelpCenter from '../../../components/HelpCenter'
import FloatingBox from '../../../components/FloatingBox'
import { checkLibraryProductAccess } from '../../../utils/checkLibraryProductAccess'
import QuestionMarkIcon from '../../../assets/images/questionMark.svg'
import { eventTrackers } from '../../../utils/eventTrackers.js'
import { appTracking } from '../../../utils/appTracker.js'
import { errorHandler } from '../../../utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'
import { addPackageApi } from 'src/api/products/library'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as ArrowDown } from 'src/assets/images/arrowDown.svg'

import {
    getSinglePackageApi,
    deleteSinglePackageApi,
} from '../../../api/products/packages'

import { convertToSentenceCase } from '../../../utils/formatting'
import { formatPackageComponents } from './formatter'
import { denyPermission } from '../../../utils/permissionFramework'
import { decodeUserInfo } from 'src/utils/auth'
import { permissionsControl } from 'src/utils/permissionsControl'

import '../package.scss'
import NairaSymbol from 'src/components/NairaSymbol'

const tableHead = [
    { key: 'name', title: 'Component Name', isBold: true },
    { key: 'quantity', title: 'Quantity' },
    { key: 'amount', title: 'Total Amount' },
    { key: 'actions', title: 'Actions' },
]

const ViewPackage = ({ match, productId }) => {
    const itemId = match?.params?.id || productId
    const history = useHistory()
    const noEditButton = history?.location?.state?.noEdit
    const providerId = history?.location?.state?.providerId
    const providerName = history?.location?.state?.providerName

    const [packageData, setPackageData] = useState({})
    const [packageComponents, setPackageComponents] = useState([])
    const [toastError, setToastError] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)
    const [errorMessage, setErrorMessage] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [makeCopyModal, setMakeCopyModal] = useState(false)
    const [usedPackage, setUsedPackage] = useState(false)

    const pageTitle = 'SunFi - Delete A Package'
    const pageUrlName = window.location.pathname
    const pageTracker = 'DELETE_PACKAGE_TRACKER'
    const userInfo = decodeUserInfo()
    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'
    const canUseOutrightSale =
        isAdminWorkspace &&
        process.env.REACT_APP_FLAG_SHOW_OUTRIGHT_SALE === 'true'

    const { isLoading, refetch, isFetchedAfterMount } = useQuery(
        ['fetchSinglePackage'],
        () => getSinglePackageApi(itemId, isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setPackageData(data?.data?.data)
                setPackageComponents(
                    formatPackageComponents(data?.data?.data?.components),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch package. Refresh page')
            },
        },
    )

    const { isFetching: deleteLoading, refetch: deleteRefetch } = useQuery(
        ['deleteSinglePackage'],
        () => deleteSinglePackageApi(itemId, isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                backToPackages('/app/products')
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['deletePackage'].action,
                    eventTrackers['deletePackage'].label,
                    eventTrackers['deletePackage'].category,
                )
            },
            onError: error => {
                let packageInUseError = errorHandler(error?.response?.data)[0]
                if (
                    packageInUseError[0] ===
                    "You can't delete this package because it's being used in a payment plan"
                ) {
                    setErrorMessage(errorHandler(error?.response?.data))
                    setUsedPackage(true)
                } else {
                    setToastError(true)
                    setDeleteModal(false)
                    setErrorMessage('Failed to delete package. Refresh page')
                }
            },
        },
    )

    const { isFetching: duplicateLoading, refetch: duplicateRefetch } =
        useQuery(
            ['duplicateSinglePackage'],
            () =>
                addPackageApi({
                    name: `${packageData.name.replace(
                        /\s*\(Copy-\w+\)/,
                        '',
                    )} (Copy-${uuidv4().toUpperCase().substring(0, 6)})`,
                    margin: packageData.margin,
                    description: packageData.description,
                    components: packageData.components.map(item => ({
                        id: item.component.id,
                        quantity: item.quantity,
                        capex_replacement_schedule:
                            item.capex_replacement_schedule,
                    })),
                    is_global: packageData.is_global,
                    warranty: packageData.warranty,
                    subscription_enabled: packageData.subscription_supported,
                    l2O_enabled: packageData.lease_to_own_supported,
                }),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    history.push({
                        pathname: `/app/products/edit-package/${data.data.data.id}`,
                        state: {
                            isAdminWorkspace: isAdminWorkspace,
                            id: providerId,
                            name: providerName,
                            sunfiId: noEditButton ? '' : providerId,
                        },
                    })
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        eventTrackers['duplicatePackage'].action,
                        eventTrackers['duplicatePackage'].label,
                        eventTrackers['duplicatePackage'].category,
                    )
                },
                onError: () => {
                    setToastError(true)
                    setMakeCopyModal(false)
                    setErrorMessage('Failed to duplicate package. Refresh page')
                },
            },
        )

    const handleModalState = () => {
        setDeleteModal(!deleteModal)
    }

    const handleMakeCopyModalState = () => {
        setMakeCopyModal(!makeCopyModal)
    }

    const handleDuplicatePackage = () => {
        duplicateRefetch()
    }

    const redirectToEditPackage = () => {
        history.push({
            pathname: `/app/products/edit-package/${packageData?.id}`,
            state: {
                previousPath: 'viewPackage',
                isAdminWorkspace: isAdminWorkspace,
            },
        })
    }

    const handleDelete = () => {
        deleteRefetch()
    }

    useEffect(() => {
        refetch()

        // eslint-disable-next-line
    }, [itemId])

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowResize)

        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    const denyBtnAction =
        packageData?.is_global &&
        !checkLibraryProductAccess(packageData?.provider_id)

    const denyEditPackageBtnAction = denyPermission(
        'admin',
        'product_library',
        'package:can_edit',
    )
    const denyDeletePackageBtnAction = denyPermission(
        'admin',
        'product_library',
        'package:can_delete',
    )

    const denyMakeCopyBtnAction = denyPermission(
        'admin',
        'product_library',
        'package:can_duplicate',
    )

    // define permissions
    const canDeletePackage = permissionsControl(['can_delete_package'])
    const canEditPackage = permissionsControl(['can_update_package'])

    const toolTipEditText = `${
        !canEditPackage
            ? 'You are not authorised to perform this action'
            : 'You are not authorised to edit a global package'
    }`

    const dropdownItems = [
        {
            id: 1,
            title: 'Duplicate Package',
            permission: 'package:can_duplicate',
            denyAction: denyMakeCopyBtnAction,
            onClick: handleMakeCopyModalState,
        },
        {
            id: 2,
            title: 'Delete Package',
            permission: 'package:can_delete',
            denyAction: denyDeletePackageBtnAction || !canDeletePackage,
            onClick: handleModalState,
        },
    ]

    const menu = (
        <Menu style={{ marginTop: `${width <= 800 ? '20px' : '15px'}` }}>
            {dropdownItems.map(item => (
                <Menu.Item
                    key={item.id}
                    onClick={
                        denyBtnAction || item.denyAction
                            ? () => {}
                            : item.onClick
                    }
                    disabled={
                        denyPermission(
                            'admin',
                            'product_library',
                            item.permission,
                        ) || item.denyAction
                    }
                >
                    <span className="dropdownLinks">{item.title}</span>
                </Menu.Item>
            ))}
        </Menu>
    )

    const Btn = () => {
        return (
            <div className="btnWrapper ViewPage">
                <div>
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnWidth={isMobile ? '160px' : '136px'}
                        btnHeight={isMobile ? '56px' : '44px'}
                        handleClick={
                            denyBtnAction || denyEditPackageBtnAction
                                ? () => {}
                                : redirectToEditPackage
                        }
                        disabled={
                            denyBtnAction ||
                            denyEditPackageBtnAction ||
                            !canEditPackage
                                ? true
                                : false
                        }
                        toolTipText={
                            denyBtnAction ||
                            denyEditPackageBtnAction ||
                            !canEditPackage
                                ? toolTipEditText
                                : null
                        }
                    >
                        Edit Package
                        <img src={editIcon} alt="" style={{ marginLeft: 4 }} />
                    </Button>
                </div>
                <div>
                    <Dropdown
                        overlay={menu}
                        placement={'bottomCenter'}
                        trigger={['click']}
                    >
                        <a onClick={e => e.preventDefault()}>
                            <ButtonComponent
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth={isMobile ? '160px' : '136px'}
                                btnHeight={isMobile ? '56px' : '44px'}
                            >
                                {' '}
                                More Actions
                                <ArrowDown
                                    style={{
                                        marginLeft: 12,
                                    }}
                                />
                            </ButtonComponent>
                        </a>
                    </Dropdown>
                </div>
            </div>
        )
    }

    const backToPackages = url => {
        history.push(
            providerId?.length < 0
                ? url
                : {
                      pathname: url,
                      search: '?tab=packages',
                      state: {
                          id: providerId,
                          name: providerName,
                          isAdminWorkspace: isAdminWorkspace,
                          sunfiId: noEditButton ? '' : providerId,
                      },
                  },
        )
    }

    return (
        <>
            <SeoComponent
                title="View Package - Products | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Products"
            />
            <ActionModal
                actionModalOpen={deleteModal}
                headerText={usedPackage ? 'Package in Use' : 'Delete Package'}
                subTitle={
                    usedPackage
                        ? errorMessage
                        : 'Are you sure you want to delete this package?'
                }
                closeModal={handleModalState}
                actionHandler={usedPackage ? handleModalState : handleDelete}
                actionType="delete"
                actionText={usedPackage ? 'Okay, Got it' : 'Delete'}
                loading={deleteLoading}
                noCancelBtn={usedPackage && true}
            />
            <ActionModal
                actionModalOpen={makeCopyModal}
                headerText={'Duplicate Package'}
                subTitle={'Are you sure you want to duplicate this package?'}
                closeModal={handleMakeCopyModalState}
                actionHandler={handleDuplicatePackage}
                actionType="confirm-action"
                actionText={'Duplicate'}
                loading={duplicateLoading}
                noCancelBtn={false}
            />
            <div className="viewPackage">
                <div className="viewMobileBackLink">
                    <BackNav
                        title="Back to Packages"
                        onClick={() => backToPackages('/app/products')}
                    />
                </div>
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}
                {!isFetchedAfterMount && !isLoading ? (
                    <div style={{ display: 'flex', marginTop: '50px' }}>
                        <div style={{ width: '60%' }}>
                            <Skeleton.Input className="ViewSkeleton" active />
                            <Skeleton.Input
                                style={{
                                    width: '85px',
                                    borderRadius: '7px',
                                    height: '25px',
                                }}
                                active
                            />

                            <div
                                style={{
                                    marginTop: '50px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div>
                                <Skeleton.Input
                                    style={{ height: '20px' }}
                                    className="DescriptionSkeleton"
                                    active
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: '45px',
                                    marginBottom: '25px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="LoadingBtnsWrapper">
                                {[...Array(4)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        className="LoadingBtns"
                                        active
                                    />
                                ))}
                            </div>

                            <div>
                                {[...Array(4)].map(i => (
                                    <div
                                        key={i * Math.random()}
                                        className="componentsSkeleton"
                                    >
                                        <Skeleton.Button
                                            className="LoadingBtns"
                                            active
                                        />
                                        <Skeleton.Button
                                            style={{
                                                width: '60px',
                                                borderRadius: '7px',
                                                height: '25px',
                                            }}
                                            active
                                        />
                                        <Skeleton.Button
                                            style={{
                                                width: '150px',
                                                borderRadius: '7px',
                                                height: '25px',
                                            }}
                                            active
                                        />
                                        <Skeleton.Button
                                            className="LoadingBtns"
                                            active
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="rightSection">
                            <div>
                                <Skeleton.Input
                                    style={{
                                        marginRight: '20px',
                                    }}
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ) : !isLoading ? (
                    Object.keys(packageData).length === 0 ? (
                        `Package Doesn't Exit`
                    ) : (
                        <div>
                            <div
                                style={{ display: 'flex' }}
                                className="ViewWrapper"
                            >
                                <div
                                    style={{ width: '68%' }}
                                    className="leftSection"
                                >
                                    <div className="titleWrapper">
                                        <h2 className="MainTitle">
                                            {convertToSentenceCase(
                                                packageData?.name,
                                            )}
                                        </h2>
                                        <div className="label">
                                            {packageData?.is_global
                                                ? 'GLOBAL PACKAGE'
                                                : 'PACKAGE'}
                                        </div>
                                    </div>
                                    <div className="DescriptionWrapper">
                                        <p className="DescriptionTitle">
                                            DESCRIPTION
                                        </p>
                                        <p className="DescriptionText">
                                            {parse(packageData?.description) ||
                                                'No description available.'}
                                        </p>
                                    </div>
                                    <div className="ViewMobileButton">
                                        {isLoading
                                            ? ''
                                            : !noEditButton && <Btn />}
                                    </div>
                                    <h2
                                        className="detailsTitle"
                                        style={{ margin: '30px 0' }}
                                    >
                                        Components
                                    </h2>

                                    <Table
                                        products={packageComponents}
                                        tableHead={tableHead}
                                        productType="componentList"
                                        noEdit={noEditButton}
                                        providerId={providerId}
                                        providerName={providerName}
                                        page={'view_package'}
                                        isAdminWorkspace={isAdminWorkspace}
                                    />
                                </div>
                                <div className="rightSection">
                                    <div className="MobiletitleWrapper">
                                        <h2 className="viewMobileMainTitle">
                                            {convertToSentenceCase(
                                                packageData?.name,
                                            )}
                                        </h2>
                                        <div className="label">PACKAGE</div>
                                    </div>
                                    <h2 className="detailsTitle">
                                        Package Details
                                    </h2>
                                    <Row gutter={[32]}>
                                        <Col span={12}>
                                            <p className="subTitle">
                                                DATE OF CREATION
                                            </p>
                                            <p className="details">
                                                {format(
                                                    parseISO(
                                                        packageData.created_at,
                                                    ),
                                                    'dd MMM, yyyy',
                                                ).toUpperCase()}
                                            </p>
                                        </Col>
                                        <Col span={12}>
                                            <p className="subTitle">
                                                COMPONENT NO
                                            </p>
                                            <p className="details">
                                                {
                                                    packageData?.components
                                                        ?.length
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row gutter={32} style={{ marginTop: 30 }}>
                                        <Col span={12}>
                                            <p className="subTitle">
                                                {canUseOutrightSale
                                                    ? 'FINANCING AMOUNT'
                                                    : 'TOTAL AMOUNT'}
                                            </p>
                                            <p className="details">
                                                <NairaSymbol />
                                                {currencyFormatter(
                                                    packageData?.total,
                                                )}
                                            </p>
                                        </Col>
                                        {canUseOutrightSale ? (
                                            <>
                                                {packageData?.outright_sale_total ? (
                                                    <Col span={12}>
                                                        <p className="subTitle">
                                                            OUTRIGHT AMOUNT
                                                        </p>
                                                        <p className="details">
                                                            <NairaSymbol />
                                                            {currencyFormatter(
                                                                packageData?.outright_sale_total,
                                                            )}
                                                        </p>
                                                    </Col>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <Col span={12}>
                                                <p className="subTitle">
                                                    PACKAGE AMOUNT
                                                </p>
                                                <p className="details">
                                                    <NairaSymbol />
                                                    {currencyFormatter(
                                                        packageData?.subtotal,
                                                    )}
                                                </p>
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="viewButtonWrapper">
                                        {isLoading
                                            ? ''
                                            : !noEditButton && <Btn />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div style={{ display: 'flex', marginTop: '50px' }}>
                        <div style={{ width: '60%' }}>
                            <Skeleton.Input className="ViewSkeleton" active />
                            <Skeleton.Input
                                style={{
                                    width: '85px',
                                    borderRadius: '7px',
                                    height: '25px',
                                }}
                                active
                            />

                            <div
                                style={{
                                    marginTop: '50px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div>
                                <Skeleton.Input
                                    style={{ height: '20px' }}
                                    className="DescriptionSkeleton"
                                    active
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: '45px',
                                    marginBottom: '25px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="LoadingBtnsWrapper">
                                {[...Array(4)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        className="LoadingBtns"
                                        active
                                    />
                                ))}
                            </div>

                            <div>
                                {[...Array(4)].map(i => (
                                    <div
                                        key={i * Math.random()}
                                        className="componentsSkeleton"
                                    >
                                        <Skeleton.Button
                                            className="LoadingBtns"
                                            active
                                        />
                                        <Skeleton.Button
                                            style={{
                                                width: '60px',
                                                borderRadius: '7px',
                                                height: '25px',
                                            }}
                                            active
                                        />
                                        <Skeleton.Button
                                            style={{
                                                width: '150px',
                                                borderRadius: '7px',
                                                height: '25px',
                                            }}
                                            active
                                        />
                                        <Skeleton.Button
                                            className="LoadingBtns"
                                            active
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="rightSection">
                            <div>
                                <Skeleton.Input
                                    style={{
                                        marginRight: '20px',
                                    }}
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="HelpCenterWrapper">
                <FloatingBox
                    floatIcon={QuestionMarkIcon}
                    floatBgColor="#011A3C"
                    floatBoxClass="floatBox"
                    renderComponent={props => <HelpCenter {...props} />}
                />
            </div>
        </>
    )
}

export default ViewPackage

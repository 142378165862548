import { useEffect } from 'react'
import styles from './benefit.module.scss'
import { benefitData } from './data'

const Benefit = () => {
    const displayActiveBenefit = id => {
        switch (id) {
            case '1':
                document.getElementById('img-' + id).className =
                    styles.active_img
                document.getElementById('text-' + id).className =
                    styles.data_container
                document.getElementById('img-' + '2').className =
                    styles.inactive_img
                document.getElementById('text-' + '2').className =
                    styles.data_container_inactive
                document.getElementById('img-' + '3').className =
                    styles.inactive_img
                document.getElementById('text-' + '3').className =
                    styles.data_container_inactive

                break
            case '2':
                document.getElementById('img-' + id).className =
                    styles.active_img
                document.getElementById('text-' + id).className =
                    styles.data_container
                document.getElementById('img-' + '1').className =
                    styles.inactive_img
                document.getElementById('text-' + '1').className =
                    styles.data_container_inactive
                document.getElementById('img-' + '3').className =
                    styles.inactive_img
                document.getElementById('text-' + '3').className =
                    styles.data_container_inactive
                break
            case '3':
                document.getElementById('img-' + id).className =
                    styles.active_img
                document.getElementById('text-' + id).className =
                    styles.data_container
                document.getElementById('img-' + '1').className =
                    styles.inactive_img
                document.getElementById('text-' + '1').className =
                    styles.data_container_inactive
                document.getElementById('img-' + '2').className =
                    styles.inactive_img
                document.getElementById('text-' + '2').className =
                    styles.data_container_inactive
                break
            default:
                document.getElementById('img-' + id).className =
                    styles.active_img
                document.getElementById('text-' + id).className =
                    styles.data_container
                document.getElementById('img-' + '2').className =
                    styles.inactive_img
                document.getElementById('text-' + '2').className =
                    styles.data_container_inactive
                document.getElementById('img-' + '3').className =
                    styles.inactive_img
                document.getElementById('text-' + '3').className =
                    styles.data_container_inactive
        }
    }

    useEffect(() => {
        displayActiveBenefit('1')
        const interval = setInterval(() => {
            const active =
                document.getElementsByClassName(
                    'benefit_data_container__Rg341',
                )[0]?.id === 'text-1'
                    ? '2'
                    : document.getElementsByClassName(
                          'benefit_data_container__Rg341',
                      )[0]?.id === 'text-2'
                    ? '3'
                    : '1'

            displayActiveBenefit(active)
        }, 12000)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className={styles.wrapper}>
            <div>
                <div>
                    <p className={styles.section_title}>
                        Enjoy more benefit with SunFi
                    </p>
                    <div>
                        {benefitData.map(item => (
                            <div
                                className={styles.data_container_inactive}
                                key={item.id}
                                id={'text-' + item.id}
                                onClick={() => displayActiveBenefit(item.id)}
                            >
                                <h2>{item.title}</h2>
                                <div>
                                    <p>{item.description.first}</p>
                                    <p>{item.description.second}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    {benefitData.map(item => (
                        <img
                            key={item.id}
                            id={'img-' + item.id}
                            src={item.img}
                            className={styles.inactive_img}
                            alt={item.title}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Benefit

export const statusIndicatorValues = (status, page) => {
    switch (page) {
        case 'outright-sale':
            return outrightSaleStatusIndicatorValues(status)
        default:
            return defaultStatusIndicatorValues(status)
    }
}

const defaultStatusIndicatorValues = status => {
    switch (status) {
        case 'IN_PROGRESS': {
            return {
                title: 'In-progress',
                tooltipText:
                    'This plan has been created but yet to submit to SunFi for approval.',
                trailColor: '#F0F0F0',
                pathColor: '#FFFFFF',
                innerCircleColor: '#FFFFFF',
                percentage: 0,
            }
        }
        case 'KYC_NOT_STARTED':
            return {
                title: 'KYC not started',
                tooltipText:
                    'Consumer has selected a package or Provider has submitted the payment plan but has not started KYC.',
                trailColor: '#F0F0F0',
                pathColor: '#C4C4C4',
                innerCircleColor: '#FFFFFF',
                percentage: 3,
            }
        case 'KYC_IN_PROGRESS':
            return {
                title: 'KYC in-progress',
                tooltipText:
                    'Consumer has started the process of filling out their KYC for credit review but they are yet to complete the process.',
                trailColor: '#F0F0F0',
                pathColor: '#C4C4C4',
                innerCircleColor: '#FFFFFF',
                percentage: 7,
            }
        case 'AWAITING_UPDATE':
            return {
                title: 'Awaiting update',
                tooltipText:
                    'Consumer has been prompted to update a required information before they can proceed with their application.',
                trailColor: '#F0F0F0',
                pathColor: '#C4C4C4',
                innerCircleColor: '#FFFFFF',
                percentage: 17,
            }
        case 'AWAITING_APPROVAL':
            return {
                title: 'Awaiting approval',
                tooltipText:
                    'Consumer has been conditionally approved but awaiting further review from an Admin.',
                trailColor: '#F0F0F0',
                pathColor: '#C4C4C4',
                innerCircleColor: '#FFFFFF',
                percentage: 22,
            }
        case 'AWAITING_DECISION':
            return {
                title: 'Awaiting decision',
                tooltipText:
                    'Consumer has completed all KYC requirement but awaiting Credit decision.',
                trailColor: '#F0F0F0',
                pathColor: '#FFC222',
                innerCircleColor: '#FFECBC',
                percentage: 25,
            }
        case 'CREDIT_DECISION_MAYBE':
            return {
                title: 'Approved - maybe',
                tooltipText:
                    'Consumer has been conditionally approved pending the fulfillment of specific requirements.',
                trailColor: '#F0F0F0',
                pathColor: '#FFC222',
                innerCircleColor: '#FFECBC',
                percentage: 40,
            }
        case 'DECLINED':
            return {
                title: 'Declined',
                tooltipText:
                    'Consumer has received a declined decision from Credit curve.',
                trailColor: '#F0F0F0',
                pathColor: '#DA1E28',
                innerCircleColor: '#F2C3C3',
                percentage: 100,
            }
        case 'TENURE_SELECTION':
            return {
                title: 'Tenure selection',
                tooltipText:
                    'Consumer has been approved but is yet to select a tenure.',
                trailColor: '#F0F0F0',
                pathColor: '#4291FB',
                innerCircleColor: '#B3D4FF',
                percentage: 60,
            }
        case 'AGREEMENT_SIGNING':
            return {
                title: 'Agreement signing',
                tooltipText:
                    'Consumer has selected a tenure but yet to sign SunFi agreement.',
                trailColor: '#F0F0F0',
                pathColor: '#4291FB',
                innerCircleColor: '#B3D4FF',
                percentage: 70,
            }
        case 'UPFRONT_DEPOSIT_PAYMENT':
            return {
                title: 'Upfront deposit payment',
                tooltipText:
                    'Consumer has signed SunFi agreement but yet to pay their upfront deposit.',
                trailColor: '#F0F0F0',
                pathColor: '#4291FB',
                innerCircleColor: '#B3D4FF',
                percentage: 75,
            }
        case 'REPAYMENT_METHOD_SETUP':
            return {
                title: 'Repayment method setup',
                tooltipText:
                    'Consumer has made their upfront deposit payment but yet to set up their repayment method.',
                trailColor: '#F0F0F0',
                pathColor: '#4291FB',
                innerCircleColor: '#B3D4FF',
                percentage: 80,
            }
        case 'SOLUTION_INSTALLED':
            return {
                title: 'Awaiting installation',
                tooltipText:
                    'Consumer is currently awaiting the installation of their solution.',
                trailColor: '#F0F0F0',
                pathColor: '#4291FB',
                innerCircleColor: '#B3D4FF',
                percentage: 85,
            }
        case 'ACTIVE':
            return {
                title: 'Active',
                tooltipText:
                    "Consumer's solution has been installed and has been successfully activated.",
                trailColor: '#F0F0F0',
                pathColor: '#32CC79',
                innerCircleColor: '#C9F0DB',
                percentage: 100,
            }

        case 'PLAN_COMPLETED':
            return {
                title: 'Plan completed',
                tooltipText:
                    'Consumer has successfully fulfilled the repayment plan for their solution.',
                trailColor: '#F0F0F0',
                pathColor: '#004AAD',
                innerCircleColor: '#E2EEFF',
                percentage: 100,
            }
        case 'ARCHIVED':
            return {
                title: 'Archived',
                tooltipText:
                    "Consumer's information has been closed and moved to archive.",
                trailColor: '#F0F0F0',
                pathColor: '#4D4D4D',
                innerCircleColor: '#E5E5E5',
                percentage: 100,
            }

        default:
            return {
                title: 'Unidentified status',
                tooltipText:
                    'Unidentified status, please contact tech support.',
                trailColor: '#F0F0F0',
                pathColor: '#C4C4C4',
                innerCircleColor: '#FFFFFF',
                percentage: 0,
            }
    }
}

const outrightSaleStatusIndicatorValues = status => {
    switch (status) {
        case 'KYC_NOT_STARTED':
            return {
                title: 'KYC not started',
                tooltipText:
                    'Consumer has received a recommendation but is yet to start the KYC process',
                trailColor: '#F0F0F0',
                pathColor: '#FFFFFF',
                innerCircleColor: '#FFFFFF',
                percentage: 0,
            }
        case 'KYC_IN_PROGRESS':
            return {
                title: 'KYC in-progress',
                tooltipText:
                    'Consumer is currently filling out their details for a credit review',
                trailColor: '#F0F0F0',
                pathColor: '#C4C4C4',
                innerCircleColor: '#FFFFFF',
                percentage: 17,
            }
        case 'AWAITING_PAYMENT':
            return {
                title: 'Awaiting payment',
                tooltipText:
                    'Consumer has completed KYC but yet to make payment.',
                trailColor: '#F0F0F0',
                pathColor: '#FFC222',
                innerCircleColor: '#FFECBC',
                percentage: 25,
            }
        case 'PAYMENT_DISBURSED':
            return {
                title: 'Payment disbursed',
                tooltipText:
                    'Consumer has made payment but yet to sign agreement form',
                trailColor: '#F0F0F0',
                pathColor: '#FFC222',
                innerCircleColor: '#FFECBC',
                percentage: 40,
            }
        case 'AGREEMENT_SIGNING':
            return {
                title: 'Signed agreement',
                tooltipText:
                    'Consumer has signed agreement form, but their solution is yet to be delivered.',
                trailColor: '#F0F0F0',
                pathColor: '#4291FB',
                innerCircleColor: '#B3D4FF',
                percentage: 60,
            }
        case 'SOLUTION_INSTALLED':
            return {
                title: 'Solution installed',
                tooltipText:
                    'Consumer has received their solution and their solution has been installed.',
                trailColor: '#F0F0F0',
                pathColor: '#4291FB',
                innerCircleColor: '#B3D4FF',
                percentage: 85,
            }

        case 'DEAL_COMPLETED':
            return {
                title: 'Deal completed',
                tooltipText: 'Consumer deal has been completed.',
                trailColor: '#F0F0F0',
                pathColor: '#004AAD',
                innerCircleColor: '#E2EEFF',
                percentage: 100,
            }

        default:
            return {
                title: 'Unidentified status',
                tooltipText:
                    'Unidentified status, please contact tech support.',
                trailColor: '#F0F0F0',
                pathColor: '#C4C4C4',
                innerCircleColor: '#FFFFFF',
                percentage: 0,
            }
    }
}

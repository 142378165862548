import styles from './uploadfield.module.scss'
import PropTypes from 'prop-types'
import { ReactComponent as Upload } from 'src/assets/images/upload-icon.svg'
import { ReactComponent as Attachment } from 'src/assets/images/attach-blue.svg'
import { ReactComponent as Close } from 'src/assets/images/close-icon-grey.svg'
const UploadField = ({
    handleUploadClick,
    handleRemoveClick,
    btnText,
    margin,
    fileUploaded,
    fileName,
    width,
    errorMessage = undefined,
    disabled,
    downloadable = false,
    fileUrl,
}) => {
    const { button, left, right, uploadedBtn, errorMsg } = styles
    const style = {
        margin: margin,
        width: width,
    }
    return (
        <>
            {fileUploaded ? (
                <>
                    {downloadable && (
                        <div style={style}>
                            <div className={styles.downloadBtn}>
                                <Attachment />
                                <p>{fileName}</p>
                                <a
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View
                                </a>
                            </div>
                        </div>
                    )}
                    {!downloadable && (
                        <div style={style}>
                            <div className={uploadedBtn}>
                                <Attachment />
                                <p>{fileName}</p>
                                <Close
                                    role="button"
                                    onClick={
                                        disabled ? () => {} : handleRemoveClick
                                    }
                                />
                            </div>
                            {errorMessage && (
                                <span className={errorMsg}>{errorMessage}</span>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div style={style}>
                    <button
                        className={button}
                        onClick={disabled ? () => {} : handleUploadClick}
                        disabled={disabled}
                    >
                        <span className={left}>
                            <Upload />
                        </span>
                        <span className={right}>
                            <p>{btnText}</p>
                        </span>
                    </button>
                    {errorMessage && (
                        <span className={errorMsg}>{errorMessage}</span>
                    )}
                </div>
            )}
        </>
    )
}

UploadField.propTypes = {
    handleUploadClick: PropTypes.func,
    handleRemoveClick: PropTypes.func,
    btnText: PropTypes.string,
    margin: PropTypes.string,
    fileUploaded: PropTypes.bool,
    fileName: PropTypes.string,
    width: PropTypes.string,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    downloadable: PropTypes.bool,
    fileUrl: PropTypes.string,
}

export default UploadField

import { useMemo } from 'react'
import { decodeUserInfo } from 'src/utils/auth'

const usePaymentPlanTypes = () => {
    const userInfo = useMemo(() => decodeUserInfo(), [])
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const showOutrightSale =
        process.env.REACT_APP_FLAG_SHOW_OUTRIGHT_SALE === 'true'

    const paymentPlanTypesData = useMemo(
        () => [
            { value: 'Lease to Own' },
            { value: 'Subscription' },
            ...(showOutrightSale && isAdminUser
                ? [{ value: 'Outright sale' }]
                : []),
        ],
        [isAdminUser, showOutrightSale],
    )

    return paymentPlanTypesData
}

export default usePaymentPlanTypes

import React, { useEffect } from 'react'
import SeoComponent from '../../components/Seo'
import { Link } from 'react-router-dom'
import styles from './appnotfound.module.scss'
import { decodeUserInfo } from '../../utils/auth'
import { ReactComponent as LeftArrow } from '../../assets/images/LeftArrow.svg'
import { ReactComponent as IllustrationBgWhite } from '../../assets/images/404IllustrationBgWhiteResp.svg'

const AppNotFound = () => {
    const { NotFoundContainer, NavHome, Illustrator } = styles
    const [width, setWidth] = React.useState(window.innerWidth)
    const breakPoint = 605

    const userInfo = decodeUserInfo()

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowResize)

        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    return (
        <>
            <SeoComponent
                title="Not Found | SunFi | Simplifying and Scaling Clean Energy"
                tracker="AppTracker"
            />
            <div className={NotFoundContainer}>
                <IllustrationBgWhite className={Illustrator} />
                <h2>Oopss, You’re Lost</h2>
                <p>
                    Energy Storage helps you store energy for consumption
                    whenever it is needed. {width > breakPoint && <br />}{' '}
                    Imagine the possibilities. As the world transitions to
                    cleaner
                </p>
                <div>
                    <Link
                        to={
                            userInfo?.isAdminWorkspaceUser === 'true'
                                ? '/app/products'
                                : process.env.REACT_APP_LOGGEDIN_LANDING_URL
                        }
                        className={NavHome}
                        style={{
                            color: 'white',
                            fontWeight: '600',
                        }}
                    >
                        <LeftArrow /> Go Home
                    </Link>
                </div>
            </div>
        </>
    )
}

export default AppNotFound

import ButtonComponent from 'src/components/Button'
import s from '../profileslistings.module.scss'
import { profileCreationModalData } from '../data'
import useAdminProfiles from '../hooks/useAdminProfiles'
import { useDispatch } from 'react-redux'
import {
    resetConsumerProfile,
    updateConsumerProfile,
} from 'src/redux/reducers/admin/profiles/create-profile'
import { useEffect } from 'react'

const ProfileCreationModal = () => {
    const { goToCreateProfile } = useAdminProfiles()
    const dispatch = useDispatch()

    const handleContinue = () => {
        const selectedValue = Array.from(
            document.getElementsByName('profile_creation_modal_type'),
        )?.find(item => item.checked === true)?.value

        switch (selectedValue) {
            case '1':
                goToCreateProfile()
                break
            case '2':
                goToCreateProfile()
                break
            case '3':
                dispatch(
                    updateConsumerProfile({
                        is_promotional: true,
                        promotional_type: 'PRIVATE',
                    }),
                )
                goToCreateProfile()
                break
            default:
                goToCreateProfile()
        }
    }

    useEffect(() => {
        dispatch(resetConsumerProfile())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={s.profile_creation_modal_wrapper}>
            <div>
                <h2>Create a new profile</h2>
                <h3>Select the type of profile that you want to create</h3>
            </div>
            <div>
                {profileCreationModalData?.map(data =>
                    !data?.disabled ? (
                        <div key={data.id}>
                            <input
                                type="radio"
                                name="profile_creation_modal_type"
                                id={data.id}
                                value={data.id}
                            />
                            <label htmlFor={data.id} className={''}>
                                <div>{data.icon}</div>
                                <div>
                                    <h3>{data.title}</h3>
                                    <p>{data.subTitle}</p>
                                </div>
                            </label>
                        </div>
                    ) : null,
                )}
            </div>
            <ButtonComponent
                btnBgColor={'#004AAD'}
                btnWidth={'160px'}
                btnHeight={'56px'}
                btnTextColor={'#FFFFFF'}
                handleClick={handleContinue}
            >
                Continue
            </ButtonComponent>
        </div>
    )
}

export default ProfileCreationModal

import { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Dropdown, Menu, Drawer } from 'antd'
import { InputFields, SelectField } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import {
    generatorSize,
    dropdownPaymentPlansData,
    getMonthlyValues,
} from './data'
import { paymentPlansData } from 'src/components/ConsumerLandingPageBody/data'
import { ConsumerButton } from 'src/components/Button'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { floatWithCommas } from 'src/utils/formatting'
import { ReactComponent as CircleDown } from 'src/assets/images/Caret-Circle-Down.svg'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'
import ChatCTA from 'src/components/ChatCTA'
import CustomToolTip from 'src/components/CustomToolTip'
import EmailModal from './EmailModal'
import { continueLaterEmailSchema } from 'src/utils/validationSchema'
import { ReactComponent as CloseIcon } from 'src/assets/images/consumer-modal-close-icon.svg'
import { solarSavingsCalculatorApi } from 'src/api/shoppingExperience/solar-savings'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { v4 as uuid } from 'uuid'

import { InlineLoader } from 'src/components/Loader'
import styles from './solarsavings.module.scss'
import '../PromoCarousel/cssepromocarousel.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'View consumer page'
import NPSRatingModal from 'src/components/NPSRatingModal'
import { useUpdateNPSValues } from 'src/utils/handleNPSValues'
import { submitSolarCalculatorRatingApi } from 'src/api/shoppingExperience/npsRating'
import { landingPageEvents } from 'src/utils/mp-event-tracker/landing-page'
import useHandleSectionScroll from 'src/utils/Hooks/useHandleSectionScroll'

const SolarSavingsCalculator = () => {
    const {
        SavingsCalculatorWrapper,
        SavingsCalculatorLeftDiv,
        SavingsHouseStyle,
        SavingsCalculatorForm,
        SavingsInputFieldsWrapper,
        SavingsCalculatorFormP,
        SavingsCalculatorTabsWrapper,
        SavingsCalculatorTabs,
        SavingsCalculatorAmount,
        SavingsCalculatorBreakdownWrapper,
        SavingsCalculatorBreakdown,
        SavingsCalculatorHelpCTAWrapper,
        SavingsCalculatorBreakdownSpan,
        SavingsCalculatorMonthsDropdown,
        OptionalSpanStyle,
        SolarSavingsDrawerWrapper,
        SolarSavingsDrawerHeader,
        SolarSavingsDrawerText,
        SavingsCalculatorSpansWrapper,
        SavingsCalculatorHelper,
        optionalField,
    } = styles
    const { errorAlert } = useCustomToast()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [paymentModel, setPaymentModel] = useState('leaseToOwn')
    const [months, setMonths] = useState(24)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [leaseToOwnAnalysis, setLeaseToOwnAnalysis] = useState([])
    const [subscriptionAnalysis, setSubscriptionAnalysis] = useState([])
    const [breakEvenMonth, setBreakEvenMonth] = useState('')
    const [errors, setErrors] = useState({})
    const [disableBtn, setDisableBtn] = useState(true)
    const [uniqueId, setUniqueId] = useState('')
    const [shouldShowDrawer, setShouldShowDrawer] = useState(false)
    const [showNPSRatingModal, setShowNPSRatingModal] = useState(false)
    const [additionalPayload, setAdditionalPayload] = useState({})
    const [submittedDetailsToCalculator, setSubmittedDetailsToCalculator] =
        useState(false)
    const [hasEnteredCalculator, setHasEnteredCalculator] = useState(false)
    const [hasExitedCalculator, setHasExitedCalculator] = useState(false)

    // Initial input values
    const initialInputs = {
        genHours: 6,
        genMaintenance: '5,000',
        genSize: '3.5kVA',
        paymentPlanDuration: '12 Months',
        monthlyPayment: '25,000',
        upfrontDeposit: '100,000',
        gridSpend: '10,000',
        session_id: uniqueId,
    }
    const [inputs, setInputs] = useState(initialInputs)

    // State to track which input field was changed
    const [changedInput, setChangedInput] = useState(null)
    const { isMobileOrTablet } = useMediaQueries()
    const { successAlert } = useCustomToast()

    const { handleSectionScroll } = useHandleSectionScroll()

    const isSubscription = paymentModel === 'subscription'
    const isLeaseToOwn = paymentModel === 'leaseToOwn'
    const { npsState, updateNPSAndResetValues } = useUpdateNPSValues(
        'solarCalculatorNPSDisplayed',
    )
    const submittedDetailsToCalculatorFromLS =
        npsState?.['solarCalculatorNPSDisplayed']

    const handleInputChange = event => {
        const { name, value } = event.target
        setErrors('')
        setShouldShowDrawer(false)
        if (disableBtn && changedInput === null) {
            const clearedInputs = {}

            // Iterate through the initialInputs and clear all except the changed one
            for (const fieldName in initialInputs) {
                if (fieldName === name) {
                    clearedInputs[fieldName] = inputs[fieldName]
                } else {
                    clearedInputs[fieldName] = ''
                }
            }
            setInputs(clearedInputs)
            setDisableBtn(false)
            setChangedInput(name)
            setSubscriptionAnalysis([])
            setLeaseToOwnAnalysis([])
            setBreakEvenMonth(0)
        } else {
            setDisableBtn(false)
        }
        if (name === 'genHours' && (value < 1 || value > 24)) {
            setErrors({ genHours: 'Enter a value between 1 and 24hours' })
            setDisableBtn(true)
        }

        const numberRegex = /^[0-9.,]*$/
        if (name === 'upfrontDeposit') {
            setInputs(prev => ({
                ...prev,
                upfrontDeposit: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs?.upfrontDeposit,
            }))
        } else if (name === 'genMaintenance') {
            setInputs(prev => ({
                ...prev,
                genMaintenance: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs?.genMaintenance,
            }))
        } else if (name === 'monthlyPayment') {
            setInputs(prev => ({
                ...prev,
                monthlyPayment: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs?.monthlyPayment,
            }))
        } else if (name === 'gridSpend') {
            setInputs(prev => ({
                ...prev,
                gridSpend: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs?.gridSpend,
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleSelectChange = (name, value) => {
        setShouldShowDrawer(false)
        if (disableBtn) {
            const clearedInputs = {}

            // Iterate through the initialInputs and clear all except the changed one
            for (const fieldName in initialInputs) {
                if (fieldName === name) {
                    clearedInputs[fieldName] = inputs[fieldName]
                } else {
                    clearedInputs[fieldName] = ''
                }
            }
            setInputs(clearedInputs)
            setChangedInput(name)
            setDisableBtn(false)
            setSubscriptionAnalysis([])
            setLeaseToOwnAnalysis([])
            setBreakEvenMonth(0)
        }
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleBlur = event => {
        continueLaterEmailSchema
            .validate({ email: event.target.value }, { abortEarly: false })
            .then(() => {
                setEmailError('')
            })
            .catch(err => {
                if (err && err.inner && err.inner.length > 0) {
                    setEmailError(err.inner[0].message)
                }
            })
    }

    const closeMenu = month => {
        setMonths(month)
    }

    const menu = (
        <Menu
            className="menu"
            style={{
                width: '96px',
                marginLeft: isSubscription ? '200px' : '160px',
            }}
        >
            {dropdownPaymentPlansData(
                isSubscription ? subscriptionAnalysis : leaseToOwnAnalysis,
            )?.map((duration, index) => {
                return (
                    <Menu.Item key={index + 1}>
                        <p
                            className="menuItem"
                            onClick={() => closeMenu(duration)}
                        >
                            {`${duration}months`}
                        </p>
                    </Menu.Item>
                )
            })}
        </Menu>
    )

    // check if any of the input is empty except grid spend
    function isInputEmpty(inputs) {
        const requiredInputs = [
            'genHours',
            'genMaintenance',
            'genSize',
            'paymentPlanDuration',
            'monthlyPayment',
            'upfrontDeposit',
        ]

        // Check if any of the required inputs are empty
        return requiredInputs.some(inputName => inputs[inputName] === '')
    }

    const handleCloseModal = () => {
        setShowEmailModal(false)
        setEmail('')
        setEmailError('')
    }

    const handleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const solarSavingsCalculatorPayload = {
        generator_size: parseFloat(inputs?.genSize),
        upfront_deposit: parseFloat(inputs?.upfrontDeposit?.replace(',', '')),
        monthly_payment: parseFloat(inputs?.monthlyPayment?.replace(',', '')),
        tenure: parseFloat(inputs?.paymentPlanDuration),
        hours_of_gen_run_time_per_day: parseFloat(inputs?.genHours),
        monthly_gen_maintenance_cost: parseFloat(
            inputs?.genMaintenance?.replace(',', ''),
        ),
        monthly_nepa_spend:
            inputs?.gridSpend === ''
                ? 0
                : parseFloat(inputs?.gridSpend?.replace(',', '')),
        session_id: uniqueId,
    }

    const {
        isFetching: solarSavingsCalculatorFetching,
        refetch: solarSavingsCalculatorRefetch,
    } = useQuery(
        'solar-savings-calculator-api',
        () => solarSavingsCalculatorApi(solarSavingsCalculatorPayload),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setLeaseToOwnAnalysis(data?.data?.data?.lease_to_own?.analysis)
                setSubscriptionAnalysis(
                    data?.data?.data?.subscription?.analysis,
                )
                sessionStorage.setItem(
                    'solar-savings-session-id',
                    data?.data?.data?.session_id,
                )
                setBreakEvenMonth(
                    data?.data?.data?.lease_to_own?.summary?.break_even?.tenure,
                )
                setShouldShowDrawer(true)
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    const submitSolarCalculatorRating = useMutation({
        mutationFn: () =>
            submitSolarCalculatorRatingApi({
                session_id: uniqueId,
                ...additionalPayload,
            }),
        onSuccess: () => {
            setShowNPSRatingModal(false)
            successAlert(
                'Thank you for your feedback!',
                isMobileOrTablet ? 320 : 500,
            )
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
        },
    })

    const handleSubmitSolarCalculatorRating = () =>
        submitSolarCalculatorRating.mutate()

    // display the drawer 120s after successfull getting savings values if no input or select field is changed again

    useEffect(() => {
        if (shouldShowDrawer) {
            const drawerTimer = setTimeout(
                () => setDrawerVisible(true),
                [120000],
            )
            return () => clearTimeout(drawerTimer)
        }
    }, [shouldShowDrawer])

    useEffect(() => {
        if (uniqueId) {
            solarSavingsCalculatorRefetch()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueId])

    useEffect(() => {
        if (!uniqueId) {
            const id = sessionStorage.getItem('solar-savings-session-id')
            if (!id) {
                const newId = uuid()
                sessionStorage.setItem('solar-savings-session-id', newId)
                setUniqueId(newId)
            } else {
                setUniqueId(id)
            }
        }
    }, [uniqueId])

    // display nps rating modal
    useEffect(() => {
        const section = document.getElementById('solar-savings-calculator')
        const handleScroll = () => {
            const rect = section.getBoundingClientRect()
            if (rect.top < window.innerHeight && rect.bottom > 0) {
                setHasEnteredCalculator(true)
            } else if (
                hasEnteredCalculator &&
                (rect.bottom < 0 || rect.top > window.innerHeight) &&
                !hasExitedCalculator &&
                !submittedDetailsToCalculatorFromLS &&
                submittedDetailsToCalculator
            ) {
                setHasExitedCalculator(true)
                setShowNPSRatingModal(true)
                window.removeEventListener('scroll', handleScroll)
                updateNPSAndResetValues()
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hasEnteredCalculator,
        hasExitedCalculator,
        submittedDetailsToCalculator,
        submittedDetailsToCalculatorFromLS,
    ])

    return (
        <>
            {showEmailModal && (
                <EmailModal
                    showModal={showEmailModal}
                    closeModal={handleCloseModal}
                    email={email}
                    setEmail={setEmail}
                    emailError={emailError}
                    handleBlur={handleBlur}
                />
            )}

            <NPSRatingModal
                showModal={showNPSRatingModal}
                closeModal={() => setShowNPSRatingModal(false)}
                feature={'savings-calculator'}
                setAdditionalPayload={setAdditionalPayload}
                handleClick={handleSubmitSolarCalculatorRating}
                btnLoading={submitSolarCalculatorRating.isLoading}
            />

            <section
                className={SavingsCalculatorWrapper}
                id="solar-savings-calculator"
            >
                <section>
                    <section>
                        <div className={SavingsCalculatorLeftDiv}>
                            <h3>
                                Save up to 50% when you switch to solar with
                                SunFi
                            </h3>
                            <p>
                                Compare your spend on the use of generator and
                                how much you’ll be saving when you switch to
                                solar with SunFi, enter your information to
                                calculate it
                            </p>
                        </div>
                        <img
                            src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/SunFi+Solar+savings+image.png"
                            className={SavingsHouseStyle}
                            alt="A solar house image"
                        />
                    </section>
                    <section
                        className={`${SavingsCalculatorHelper} SavingsCalculatorSection OnSolarCalculator`}
                        style={{ position: 'relative' }}
                    >
                        <form className={SavingsCalculatorForm}>
                            <p className={SavingsCalculatorFormP}>
                                Modify the information below for a more accurate
                                calculation
                            </p>
                            <div className={SavingsCalculatorSpansWrapper}>
                                <span>Generator details</span>
                                <span>SUNFI payment plan details</span>
                            </div>
                            <div className={SavingsInputFieldsWrapper}>
                                <SelectField
                                    values={generatorSize.map(option => ({
                                        value: option,
                                    }))}
                                    selectWidth={
                                        !isMobileOrTablet ? '250px' : '100%'
                                    }
                                    name="genSize"
                                    initialOption="Your current generator size in kVA"
                                    handleChange={handleSelectChange}
                                    marginBottom={
                                        isMobileOrTablet ? '20px' : '15px'
                                    }
                                    dropdownPositionRelative
                                    floatingLabel={
                                        inputs?.genSize === ''
                                            ? ''
                                            : 'Your current generator size in kVA'
                                    }
                                    withCheckBox
                                    selectedValue={inputs?.genSize}
                                    currentSelected={inputs?.genSize}
                                    floatingLabelFontSize="12px"
                                    fontColor="#233862"
                                    type="sse-savings-calculator"
                                    value={inputs?.genSize}
                                    showSearch
                                />

                                <IconInputField
                                    name="monthlyPayment"
                                    icon={NairaIcon}
                                    value={inputs?.monthlyPayment}
                                    width={!isMobileOrTablet ? '250px' : '100%'}
                                    handleChange={handleInputChange}
                                    title="Pay a monthly payment of"
                                    floatingLabelFontSize="12px"
                                    marginBottom={
                                        isMobileOrTablet ? '20px' : '15px'
                                    }
                                />

                                <InputFields
                                    inputWidth={
                                        !isMobileOrTablet ? '250px' : 100
                                    }
                                    title="How many hours do you run your gen/day?"
                                    type={'number'}
                                    value={inputs?.genHours}
                                    handleChange={handleInputChange}
                                    name="genHours"
                                    marginBottom={
                                        isMobileOrTablet ? '20px' : '15px'
                                    }
                                    floatingLabelFontSize="12px"
                                    fontFamily="SF-Pro-Display"
                                    errorMessage={errors['genHours']}
                                />

                                <IconInputField
                                    name="upfrontDeposit"
                                    icon={NairaIcon}
                                    value={inputs?.upfrontDeposit}
                                    width={!isMobileOrTablet ? '250px' : '100%'}
                                    handleChange={handleInputChange}
                                    title="Pay an upfront deposit of"
                                    floatingLabelFontSize="12px"
                                    marginBottom={
                                        isMobileOrTablet ? '20px' : '15px'
                                    }
                                />

                                <IconInputField
                                    name="genMaintenance"
                                    icon={NairaIcon}
                                    value={inputs?.genMaintenance}
                                    width={!isMobileOrTablet ? '250px' : '100%'}
                                    handleChange={handleInputChange}
                                    title="Gen maintenance/month cost"
                                    floatingLabelFontSize="12px"
                                    marginBottom={
                                        isMobileOrTablet ? '20px' : '15px'
                                    }
                                />

                                <SelectField
                                    values={paymentPlansData?.map(option => ({
                                        value: option,
                                    }))}
                                    selectWidth={
                                        !isMobileOrTablet ? '250px' : '100%'
                                    }
                                    name="paymentPlanDuration"
                                    initialOption="Duration of Payment Plan in months"
                                    handleChange={handleSelectChange}
                                    marginBottom={
                                        isMobileOrTablet ? '20px' : '25px'
                                    }
                                    dropdownPositionRelative
                                    floatingLabel={
                                        inputs.paymentPlanDuration === ''
                                            ? ''
                                            : 'Duration of Payment Plan in months'
                                    }
                                    withCheckBox
                                    selectedValue={inputs?.paymentPlanDuration}
                                    currentSelected={
                                        inputs?.paymentPlanDuration
                                    }
                                    floatingLabelFontSize="12px"
                                    fontColor="#233862"
                                    value={inputs?.paymentPlanDuration}
                                    showSearch
                                />
                                <div className={optionalField}>
                                    <IconInputField
                                        name="gridSpend"
                                        icon={NairaIcon}
                                        value={inputs?.gridSpend}
                                        width={
                                            !isMobileOrTablet ? '250px' : '100%'
                                        }
                                        handleChange={handleInputChange}
                                        noFloat
                                        marginBottom="0px"
                                        title="Monthly spend on Grid"
                                        floatingLabelFontSize="12px"
                                    />
                                    <span className={OptionalSpanStyle}>
                                        Optional
                                    </span>
                                </div>
                                <ConsumerButton
                                    btnBgColor="#004AAD"
                                    btnTextColor="#FFF"
                                    btnFontSize="13px"
                                    btnHeight="56px"
                                    btnWidth={
                                        !isMobileOrTablet ? '253px' : '100%'
                                    }
                                    disabled={
                                        isInputEmpty(inputs) || disableBtn
                                    }
                                    handleClick={() => {
                                        solarSavingsCalculatorRefetch()
                                        setTimeout(() => {
                                            setDrawerVisible(true)
                                        }, 20000)
                                        appTracking(
                                            pageUrlName,
                                            pageTracker,
                                            pageTitle,
                                            eventTrackers[
                                                'clickedForSolarSavingsCalculations'
                                            ].action,
                                            eventTrackers[
                                                'clickedForSolarSavingsCalculations'
                                            ].label,
                                            eventTrackers[
                                                'clickedForSolarSavingsCalculations'
                                            ].category,
                                            ['MP', 'GA'],
                                            'event',
                                            {
                                                event: solarSavingsCalculatorPayload,
                                            },
                                        )
                                        setSubmittedDetailsToCalculator(true)
                                    }}
                                >
                                    {solarSavingsCalculatorFetching ? (
                                        <InlineLoader />
                                    ) : (
                                        'See how much you’ll be saving'
                                    )}
                                </ConsumerButton>
                            </div>
                        </form>
                        <div className={SavingsCalculatorTabsWrapper}>
                            <div className={SavingsCalculatorTabs}>
                                <p
                                    onClick={() =>
                                        setPaymentModel('leaseToOwn')
                                    }
                                    style={{
                                        borderBottom:
                                            isLeaseToOwn && '1px solid #004AAD',
                                    }}
                                >
                                    {isMobileOrTablet
                                        ? 'LEASE TO OWN package'
                                        : 'For a LEASE TO OWN package'}
                                </p>
                                <p
                                    onClick={() =>
                                        setPaymentModel('subscription')
                                    }
                                    style={{
                                        borderBottom:
                                            isSubscription &&
                                            '1px solid #004AAD',
                                    }}
                                >
                                    {isMobileOrTablet
                                        ? 'RENTAL package'
                                        : 'For a RENTAL package'}
                                </p>
                            </div>

                            <div className={SavingsCalculatorAmount}>
                                {dropdownPaymentPlansData(
                                    isSubscription
                                        ? subscriptionAnalysis
                                        : leaseToOwnAnalysis,
                                ).length === 0 ? (
                                    <p>
                                        Unfortunately we are unable to generate
                                        any savings from the data provided.
                                        Kindly modify the information above for
                                        a more accurate calculation
                                    </p>
                                ) : (
                                    <>
                                        <p>
                                            {isSubscription
                                                ? 'If you rent a solar inverter from SunFi, you can save a total amount of'
                                                : 'If you go solar with SunFi, you can save a total amount of'}
                                        </p>
                                        <h3>
                                            &#8358;
                                            {(isSubscription
                                                ? getMonthlyValues(
                                                      subscriptionAnalysis,
                                                      parseInt(months),
                                                  )?.cumulative_savings
                                                : getMonthlyValues(
                                                      leaseToOwnAnalysis,
                                                      parseInt(months),
                                                  )?.break_even_amount) || 0}
                                        </h3>
                                        <Dropdown
                                            overlay={menu}
                                            trigger={['click']}
                                        >
                                            <div
                                                className={
                                                    SavingsCalculatorMonthsDropdown
                                                }
                                            >
                                                <p>{`For the next ${months}months`}</p>

                                                <CircleDown />
                                            </div>
                                        </Dropdown>
                                    </>
                                )}
                            </div>
                        </div>
                        <section className={SavingsCalculatorBreakdownWrapper}>
                            <div>
                                <div className={SavingsCalculatorBreakdown}>
                                    <p>{`Gen spend over ${months}months`} </p>
                                    <CustomToolTip
                                        text="This is an estimate of your current generator spend for the duration."
                                        toolTipFontSize="12px"
                                        toolTipMarginLeft="-153px"
                                        toolTipPopupWidth="178px"
                                    >
                                        <HelpIcon />
                                    </CustomToolTip>
                                </div>
                                <span
                                    className={SavingsCalculatorBreakdownSpan}
                                >
                                    &#8358;
                                    {(isSubscription
                                        ? getMonthlyValues(
                                              subscriptionAnalysis,
                                              parseInt(months),
                                          )?.cumulative_energy_spend
                                        : getMonthlyValues(
                                              leaseToOwnAnalysis,
                                              parseInt(months),
                                          )?.cumulative_gen_spend) || 0}
                                </span>
                            </div>

                            <div>
                                <div className={SavingsCalculatorBreakdown}>
                                    <p>{`Solar spend over ${months}months`}</p>
                                    <CustomToolTip
                                        text="This is the total amount you’ll spend on this solar system for the duration."
                                        toolTipFontSize="12px"
                                        toolTipMarginLeft="-153px"
                                        toolTipPopupWidth="178px"
                                    >
                                        <HelpIcon />
                                    </CustomToolTip>
                                </div>

                                <span
                                    className={SavingsCalculatorBreakdownSpan}
                                >
                                    &#8358;
                                    {getMonthlyValues(
                                        isSubscription
                                            ? subscriptionAnalysis
                                            : leaseToOwnAnalysis,
                                        parseInt(months),
                                    )?.cumulative_solar_spend || 0}
                                </span>
                            </div>
                            {isLeaseToOwn && (
                                <div>
                                    <div className={SavingsCalculatorBreakdown}>
                                        <p>Your break even period </p>
                                        <CustomToolTip
                                            text="The breakeven month is the month your cumulative Generator spend would be higher than the projected spend on your Solar package"
                                            toolTipFontSize="12px"
                                            toolTipMarginLeft="-153px"
                                            toolTipPopupWidth="178px"
                                        >
                                            <HelpIcon />
                                        </CustomToolTip>
                                    </div>
                                    <span
                                        className={
                                            SavingsCalculatorBreakdownSpan
                                        }
                                    >{`${breakEvenMonth}`}</span>
                                </div>
                            )}
                        </section>
                        <div className={SavingsCalculatorHelpCTAWrapper}>
                            <ChatCTA
                                text="Have questions about SunFi?"
                                fontFamily="SF-Pro-Display-Medium"
                            />
                            <ConsumerButton
                                btnBgColor="#004AAD"
                                btnTextColor="#FFF"
                                btnFontSize="13px"
                                btnWidth="227px"
                                btnHeight="44px"
                                handleClick={() => {
                                    setShowEmailModal(true)
                                    appTracking(
                                        pageUrlName,
                                        pageTracker,
                                        pageTitle,
                                        landingPageEvents['solarSavingsReport']
                                            .action,
                                        landingPageEvents['solarSavingsReport']
                                            .label,
                                        landingPageEvents['solarSavingsReport']
                                            .category,
                                        ['MP', 'GA'],
                                    )
                                }}
                            >
                                Send a detailed copy to your email
                            </ConsumerButton>
                        </div>
                        {/* Show Drawer */}
                        <Drawer
                            placement="bottom"
                            open={drawerVisible}
                            onClose={handleDrawer}
                            closable={false}
                            getContainer={false}
                            height={isMobileOrTablet ? '300px' : '266px'}
                        >
                            <section className={SolarSavingsDrawerWrapper}>
                                {!isMobileOrTablet && (
                                    <CloseIcon
                                        style={{
                                            alignSelf: 'flex-end',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setDrawerVisible(false)}
                                    />
                                )}
                                <p className={SolarSavingsDrawerHeader}>
                                    Ready to get a solar inverter?
                                </p>

                                <p className={SolarSavingsDrawerText}>
                                    We are an energy financial technology
                                    platform that connects people who want solar
                                    energy access to payment plans that match
                                    their needs. Request a payment plan now.
                                </p>
                                <ConsumerButton
                                    btnBgColor="#004AAD"
                                    btnTextColor="#FFF"
                                    btnFontSize="14px"
                                    btnHeight="53px"
                                    btnWidth="253px"
                                    handleClick={() => {
                                        handleSectionScroll('landing-page-form')
                                        appTracking(
                                            pageUrlName,
                                            pageTracker,
                                            pageTitle,
                                            eventTrackers[
                                                'clickedGetStartedButton'
                                            ].action,
                                            eventTrackers[
                                                'clickedGetStartedButton'
                                            ].label,
                                            eventTrackers[
                                                'clickedGetStartedButton'
                                            ].category,
                                            ['MP', 'GA'],
                                        )
                                    }}
                                >
                                    Get Started
                                </ConsumerButton>
                            </section>
                        </Drawer>
                    </section>
                </section>
            </section>
        </>
    )
}

export default SolarSavingsCalculator

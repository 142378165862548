//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { loginApi } from '../../api/login'
import Toast from '../../components/Toast'
import { SelectCountryCode } from '../../components/InputFields/SelectCountryCode'
import { AILoginPhoneValidationSchema } from '../../utils/validationSchema'
import { DISILoginEmailValidationSchema } from '../../utils/validationSchema'
import FormBox from '../../components/FormBox'
import { InlineLoader } from '../../components/Loader'

import FormTitleBar from '../../components/FormTitleBar'
import SeoComponent from '../../components/Seo'
import Button from '../../components/Button'
import { InputFields } from '../../components/InputFields'
import { onboardingStage, stageNumber } from './stages'
import styles from './login.module.scss'
import { formatPhoneNumber } from '../../utils/formatting'

import useErrorHandler from '../../utils/Hooks/useErrorHandler'
import { encodeUserInfo, removeToken } from '../../utils/auth'
import { encrypt } from 'src/utils/cryptography'
import { appTracking } from 'src/utils/appTracker'

const Login = ({ showTextHandler }) => {
    const [inputs, setInputs] = useState({
        email: '',
        phonenumber: '',
        password: '',
    })

    let history = useHistory()
    const is2FAFlow = process.env.REACT_APP_FLAG_SHOW_2FA_OPTION === 'true'

    const [type, setType] = useState(
        history?.location?.state?.verificationType || 'email',
    )

    const [countryCode, setCountryCode] = useState('+234')

    const [errors, setErrors] = useState({})
    const [, setErrorMessage] = useState('')

    const [errs, setErrs] = useState(null)
    const [nextPage] = useState(
        window?.location?.search?.split('next=')[1] ?? '',
    )

    const {
        ButtonBox,
        CreateAccount,
        ForgotPasswordText,
        FormBoxText,
        SwitchToOptionWrapper,
    } = styles

    useEffect(() => showTextHandler(true), [])

    const { isLoading, error, data, refetch } = useQuery(
        'login',
        () =>
            loginApi(
                type,
                type === 'email'
                    ? {
                          email: inputs.email,
                          password: inputs.password,
                      }
                    : {
                          phone_number: formatPhoneNumber(
                              inputs.phonenumber,
                              countryCode,
                          ),
                          password: inputs.password,
                      },
            ),
        {
            enabled: false,
            retry: false,
            cacheTime: is2FAFlow ? 0 : undefined,
            onError: error => {
                if (
                    error?.response?.status === 403 &&
                    error?.response?.data?.message ===
                        'Your workspace account is yet to be verified'
                ) {
                    history.push({
                        pathname: `/workspace-status`,
                        state: {
                            workspace: {
                                unapproved: true,
                            },
                        },
                    })
                }
                if (
                    error?.response?.status === 403 &&
                    error?.response?.data?.message ===
                        'Your workspace account is suspended'
                ) {
                    history.push({
                        pathname: `/workspace-status`,
                        state: {
                            workspace: {
                                suspended: true,
                            },
                        },
                    })
                }
                setErrs(error)
                // setErrorMessage(errorHandler(error))
            },
        },
    )

    const errorValue = useErrorHandler(errs)

    useEffect(() => {
        if (data) {
            if (is2FAFlow) {
                localStorage.setItem(
                    'sunfiUserLoggingIn',
                    encrypt(
                        JSON.stringify({
                            ...(inputs?.email !== '' && {
                                email: inputs.email,
                            }),
                            ...(inputs?.phonenumber !== '' && {
                                phone: formatPhoneNumber(inputs.phonenumber),
                            }),
                            contactType: type,
                            password: inputs.password,
                        }),
                    ),
                )

                sessionStorage.setItem(
                    'sunfiUserLoginStage',
                    encrypt(
                        JSON.stringify({
                            stage: 'OTP_REQUESTED',
                        }),
                    ),
                )

                history.push(`/otp${nextPage ? `?next=${nextPage}` : ''}`, {
                    from: 'LOGIN',
                })
            } else {
                appTracking('', '', '', '', '', '', ['MP'], 'identify', {
                    identify: { id: data?.data?.data?.user?.id },
                })
                appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                    people_set: {
                        'User Types': data?.data?.data?.user?.user_types,
                        last_completed_stage:
                            data?.data?.data?.last_completed_stage,
                        'Phone Number': data?.data?.data?.user?.phone_number,
                        $email: data?.data?.data?.user?.email,
                        $name: data?.data?.data?.user?.display_name,
                        $avatar: data?.data?.data?.user?.avatar_url,
                    },
                })
                encodeUserInfo(data)
                const encodedNextStage = encrypt(
                    data?.data?.data?.next_stage === null ||
                        data?.data?.data?.user?.is_system_admin
                        ? 'completed'
                        : 'ongoing',
                )
                localStorage.setItem('sunfiUserStage', encodedNextStage)
                localStorage.setItem(
                    'sunfiToken',
                    data?.data?.data?.token?.access,
                )

                if (
                    data?.data?.data?.next_stage === null ||
                    data?.data?.data?.user?.is_admin_workspace_user
                ) {
                    if (data?.data?.data?.user?.is_admin_workspace_user) {
                        history.push(
                            nextPage !== '' ? nextPage : 'admin/products',
                        )
                    } else {
                        history.push(
                            nextPage !== ''
                                ? nextPage
                                : process.env.REACT_APP_LOGGEDIN_LANDING_URL
                                ? process.env.REACT_APP_LOGGEDIN_LANDING_URL
                                : 'app/products',
                        )
                    }
                } else {
                    const hasOnboardingStarted =
                        data?.data?.data?.last_completed_stage ===
                        onboardingStage['COMPLETED_SIGN_UP']
                            ? 'notstarted'
                            : 'started'

                    history.push({
                        pathname: `/onboarding`,
                        state: {
                            onboardingState: hasOnboardingStarted,
                            nextOnboardingStage:
                                stageNumber[data?.data?.data?.next_stage],
                        },
                    })
                }
            }
        }

        // eslint-disable-next-line
    }, [data])

    const handleChange = e => {
        const { name, value } = e.target
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleSelectChange = value => {
        setCountryCode(value)
    }

    const handleClick = () => {
        setErrorMessage('')

        if (type === 'phone') {
            AILoginPhoneValidationSchema.validate(inputs, { abortEarly: false })
                .then(() => {
                    removeToken()
                    setErrs(null)
                    refetch()

                    setErrors('')
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        } else {
            DISILoginEmailValidationSchema.validate(inputs, {
                abortEarly: false,
            })
                .then(() => {
                    removeToken()
                    refetch()
                    setErrors('')
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        }
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleClick()
        }
    }

    const clearInputValue = () => {
        setInputs(preState => ({
            ...preState,
            email: '',
            password: '',
            phonenumber: '',
        }))
        let passwordValue = document.getElementsByName('password')
        passwordValue[0].value = ''
    }

    return (
        <>
            <SeoComponent title="SunFi - Login" />
            <div style={{ marginTop: 20 }} onKeyUp={handleKeyPress}>
                <FormBox>
                    <FormTitleBar
                        title="Welcome Back"
                        subtitle="Enter your details to get started"
                    />
                    {error && (
                        <Toast
                            messageType="error"
                            message={isLoading ? '' : errorValue['msg']}
                        />
                    )}
                    <>
                        <div className={SwitchToOptionWrapper}>
                            {type === 'phone' ? (
                                <p
                                    className={FormBoxText}
                                    onClick={() => {
                                        setType('email')
                                        clearInputValue()
                                        setErrors('')
                                        setErrs('')
                                    }}
                                >
                                    Switch to Email
                                </p>
                            ) : (
                                <p
                                    className={FormBoxText}
                                    onClick={() => {
                                        setType('phone')
                                        clearInputValue()
                                        setErrors('')
                                        setErrs('')
                                    }}
                                >
                                    Switch to Phone
                                </p>
                            )}
                        </div>
                        {type === 'phone' && (
                            <SelectCountryCode
                                handleChange={handleChange}
                                handleSelectChange={handleSelectChange}
                                title="Phone Number"
                                name="phonenumber"
                                inputValue={inputs.phonenumber}
                                errorMessage={errors.phonenumber}
                                onBlur={handleBlur}
                            />
                        )}
                        {type === 'email' && (
                            <InputFields
                                title="Email Address"
                                value={inputs.email}
                                inputWidth={`100%`}
                                name={'email'}
                                handleChange={handleInputChange}
                                errorMessage={errors.email}
                                onBlur={handleBlur}
                            />
                        )}
                        <InputFields
                            title="Password"
                            type="password"
                            inputValue={inputs.password}
                            inputWidth="100%"
                            name={'password'}
                            handleChange={handleInputChange}
                            errorMessage={errors.password}
                        />
                    </>
                    <Link to="/forgot-password">
                        <span className={ForgotPasswordText}>
                            Forgot Password?
                        </span>
                    </Link>

                    <div className={ButtonBox}>
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            handleClick={handleClick}
                            disabled={
                                type === 'phone'
                                    ? inputs.password === '' ||
                                      inputs.phonenumber === ''
                                    : inputs.email === '' ||
                                      inputs.password === ''
                            }
                        >
                            {isLoading ? <InlineLoader /> : 'Sign In'}
                        </Button>
                    </div>

                    <div className={CreateAccount}>
                        <span>Not yet a user?</span>
                        &nbsp;
                        <span>
                            <Link to="/signup">Create Account</Link>
                        </span>
                    </div>
                </FormBox>
            </div>
        </>
    )
}

export default Login

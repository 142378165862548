import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import UploadModal from 'src/components/UploadModal'
import {
    CACFileTypes,
    memorandumOfAssociationFileTypes,
} from 'src/utils/supportedFileTypes'
import Description from '../_components/Description'
import Layout from '../_components/Layout/Layout'
import BusinessVerificationForm from './components/BusinessVerificationForm'
import ChatCTA from 'src/components/ChatCTA'
import './businessverification.scss'
import { isMobile } from 'src/utils/mediaQueries'
import VerifyBVNModal from './components/VerifyBVNModal'
import {
    getConsumerKYCInfoForAdmin,
    getKYCInfo,
} from 'src/api/shoppingExperience/account-overview'
import { formatBusinessVerificationData } from './data'
import { uploadToS3 } from 'src/api/utils/file-upload'
import { persistBusinessDocsApi } from 'src/api/shoppingExperience/business-verification'
import { businessVerificationSchema } from 'src/utils/validationSchema'
import Toast from 'src/components/Toast'
import ActionModal from 'src/components/ActionModalV2'
import { errorHandler } from 'src/utils/errorHandler'
import SeoComponent from 'src/components/Seo'
import { decodeUserInfo } from 'src/utils/auth'
import useMappedErrors from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/hooks/useMappedErrors'
import { BUSINESS_VERIFICATION_UPDATE_REQUESTS_MAP } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/utils'
import useScrollToError from '../hooks/useScrollToError'
import RemoveFileModal from '../_components/RemoveFileModal'
import FormSkeleton from '../_components/FormSkeleton'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useLogOut from 'src/utils/Hooks/useLogOut'
import { handleAppTrackingOnKYC } from '../utils/utils'

const Verification = ({ userType }) => {
    const pageTitle =
        'KYC - Business Verification | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showBVNModal, setShowBVNModal] = useState(false)
    const [showActionModal, setShowActionModal] = useState(false)
    const [documentType, setDocumentType] = useState('')
    const [successCACUpload, setSuccessCACUpload] = useState([])
    const [successMOAUpload, setSuccessMOAUpload] = useState([])
    const [cac, setCac] = useState([])
    const [moa, setMoa] = useState([])
    const [inputs, setInputs] = useState({
        bvn: '',
        bvn_verified: false,
        cac_url: undefined,
        moa_url: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    })
    const [errors, setErrors] = useState({})
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastType, setToastType] = useState('')
    const [actionModalError, setActionModalError] = useState(false)
    const [action, setAction] = useState('')
    const [modalAction, setModalAction] = useState('')
    const [showRemoveFileModal, setShowRemoveFileModal] = useState(false)
    const [fileToBeRemoved, setFileToBeRemoved] = useState({
        title: '',
        url: '',
        fileType: '',
    })
    const userInfo = decodeUserInfo()
    const history = useHistory()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    // estimation_id from params used by admin
    const { id } = useParams()
    const mapErrors = useMappedErrors(BUSINESS_VERIFICATION_UPDATE_REQUESTS_MAP)
    const { BVNAlert, errorAlert } = useCustomToast()
    const { logOut } = useLogOut('/')
    const [verificationInProgress, setVerificationInProgress] = useState(false)

    // fetch submitted data
    const { refetch: kycRefetch, isFetching: kycFetching } = useQuery(
        'kyc',
        () => (isAdminUser ? getConsumerKYCInfoForAdmin(id) : getKYCInfo()),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const formattedData = formatBusinessVerificationData(
                    res?.data?.data,
                )
                setInputs(prev => ({
                    ...prev,
                    bvn: formattedData.director_bvn,
                    bvn_verified: formattedData.director_bvn_verified,
                    cac_url: formattedData?.cac?.[0]?.url,
                    moa_url: formattedData?.moa?.[0]?.url,
                    firstName: formattedData?.director_firstName,
                    lastName: formattedData?.director_lastName,
                    email: formattedData?.director_email,
                    phoneNumber: formattedData?.director_phoneNumber,
                }))
                setCac(formattedData.cac)
                setMoa(formattedData.moa)
            },
            onError: res => {
                setShowToast(true)
                setToastType('error-secondary')
                setToastMessage(errorHandler(res))
            },
        },
    )
    const uploadFileMutation = useMutation({
        mutationFn: ({ file, category }) => uploadToS3(category, file[0]),
        onSuccess: res => {
            const key = res?.fields?.key.includes('memorandum_of_association')
                ? 'moa_url'
                : 'cac_url'
            setInputs(prev => ({
                ...prev,
                [key]: res?.url + res?.fields?.key,
            }))
        },
        onError: res => {
            if (action === 'Save') {
                setActionModalError(true)
                setToastMessage(errorHandler(res))
            } else {
                setShowToast(true)
                setToastType('error-secondary')
                setToastMessage(errorHandler(res))
            }
        },
    })

    const handleContinue = action => {
        businessVerificationSchema
            .validate(
                {
                    cac,
                    moa,
                    bvn: inputs.bvn,
                    bvn_verified: inputs.bvn_verified,
                },
                {
                    abortEarly: false,
                },
            )
            .then(() => {
                handleSaveOrSubmit(action)
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
                errorAlert('You omitted a required field.')
            })
    }

    //upload files to s3 and submit/save
    const handleSaveOrSubmit = async action => {
        setAction(action)
        const filesToUpload = []
        if (cac?.[0]?.uid !== undefined) {
            filesToUpload.push({ file: cac, category: 'CAC_CERTIFICATE' })
        }
        if (moa?.[0]?.uid !== undefined) {
            filesToUpload.push({
                file: moa,
                category: 'MEMORANDUM_OF_ASSOCIATION',
            })
        }

        const uploadPromises = filesToUpload.map(({ file, category }) =>
            uploadFileMutation.mutateAsync({ file, category }),
        )
        await Promise.all(uploadPromises)
        persistDocsMutation.mutate(action)
    }

    const persistDocsMutation = useMutation({
        mutationFn: action =>
            persistBusinessDocsApi({
                button: action,
                ...(inputs.cac_url !== undefined && {
                    cac_certificate_url: inputs.cac_url,
                }),
                ...(inputs.moa_url !== undefined && {
                    memorandum_of_association_url: inputs.moa_url,
                }),
            }),
        onSuccess: () => {
            if (action === 'Save') {
                setModalAction('progess_saved')
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sseSaveBusinessIdentityInfoSuccessfully']
                        .action,
                    eventTrackers['sseSaveBusinessIdentityInfoSuccessfully']
                        .label,
                    eventTrackers['sseSaveBusinessIdentityInfoSuccessfully']
                        .category,
                )
            }
            if (action === 'Submit') {
                setShowToast(true)
                setToastMessage('Your details have been submitted successfully')
                setToastType('success-secondary')
                setTimeout(() => {
                    history.push(
                        '/consumer/account-setup/business-financial-information',
                    )
                }, 2000)
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sseSubmitBusinessIdentityInfoSuccessfully']
                        .action,
                    eventTrackers['sseSubmitBusinessIdentityInfoSuccessfully']
                        .label,
                    eventTrackers['sseSubmitBusinessIdentityInfoSuccessfully']
                        .category,
                )
                handleAppTrackingOnKYC('completedKYCCategory', pageTitle)
                handleAppTrackingOnKYC('completedTwoKYCCategories', pageTitle)
            }
        },
        onError: res => {
            if (action === 'Save') {
                setActionModalError(true)
                setToastMessage(errorHandler(res))
            } else {
                setShowToast(true)
                setToastType('error-secondary')
                setToastMessage(errorHandler(res))
            }
        },
    })

    useEffect(() => {
        kycRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleUploadClick = type => {
        setDocumentType(type)
        setShowUploadModal(true)
    }

    const handleCloseUploadModal = () => {
        setShowUploadModal(false)
        if (documentType === 'cac_certificate') {
            setSuccessCACUpload([])
        }
        if (documentType === 'memorandum_of_association') {
            setSuccessMOAUpload([])
        }
    }

    const openBVNModal = () => {
        setShowBVNModal(true)
    }

    const closeBVNModal = () => {
        setShowBVNModal(false)
    }

    const bvnVerifiedSuccessfully = () => {
        setShowBVNModal(false)
        setShowToast(true)
        setToastMessage('Your BVN has been verified successfully')
        setToastType('success-secondary')
        setInputs(prev => ({
            ...prev,
            bvn_verified: true,
        }))
    }
    const handleBvnVerifyLater = () => {
        closeBVNModal()
        BVNAlert('Please, go ahead to provide your other details', '511px')
    }

    const handleRemoveFile = (title, url) => {
        setFileToBeRemoved({
            title: title,
            url: url,
            fileType:
                title === 'CAC'
                    ? 'CAC_CERTIFICATE'
                    : 'MEMORANDUM_OF_ASSOCIATION',
        })
        setShowRemoveFileModal(true)
    }

    const closeRemoveFileModal = () => {
        setFileToBeRemoved({
            title: '',
            url: '',
            fileType: '',
        })
        setShowRemoveFileModal(false)
    }

    const closeRemoveFileModalAfterSuccess = () => {
        if (fileToBeRemoved.title === 'CAC') {
            setCac([])
            setInputs(prev => ({
                ...prev,
                cac_url: undefined,
            }))
        }

        if (fileToBeRemoved.title === 'MOA') {
            setMoa([])
            setInputs(prev => ({
                ...prev,
                moa_url: undefined,
            }))
        }
        setShowRemoveFileModal(false)
    }

    useEffect(() => {
        setErrors(prev => ({
            ...prev,
            ...mapErrors,
        }))
    }, [mapErrors])

    useScrollToError(mapErrors)

    const goToSSEHome = () => {
        setShowActionModal(false)
        logOut()
    }
    return (
        <>
            <SeoComponent title="KYC - Business Verification | SunFi | Simplifying and Scaling Clean Energy" />
            <UploadModal
                showModal={showUploadModal}
                onCancel={handleCloseUploadModal}
                title={
                    documentType === 'cac_certificate'
                        ? 'CAC certificate'
                        : 'Memorandum of association'
                }
                primaryBtnText={'Confirm'}
                successUpload={
                    documentType === 'cac_certificate'
                        ? successCACUpload
                        : successMOAUpload
                }
                setSuccessUpload={
                    documentType === 'cac_certificate'
                        ? setSuccessCACUpload
                        : setSuccessMOAUpload
                }
                acceptableFileTypes={
                    documentType === 'cac_certificate'
                        ? CACFileTypes
                        : memorandumOfAssociationFileTypes
                }
                setFileToState={
                    documentType === 'cac_certificate' ? setCac : setMoa
                }
            />

            <VerifyBVNModal
                showModal={showBVNModal}
                closeBVNModal={closeBVNModal}
                storedBvn={inputs.bvn}
                bvnVerifiedSuccessfully={bvnVerifiedSuccessfully}
                userType={userType}
                storedFirstName={inputs.firstName}
                storedLastName={inputs.lastName}
                storedEmail={inputs.email}
                storedPhoneNumber={inputs.phoneNumber}
                handleBvnVerifyLater={handleBvnVerifyLater}
                setVerificationInProgress={setVerificationInProgress}
            />

            <ActionModal
                actionModalOpen={showActionModal}
                closable
                onCancel={() => setShowActionModal(false)}
                title={
                    modalAction === 'save_progress'
                        ? 'Save your progress'
                        : 'Redirecting to home page'
                }
                headerText={
                    modalAction === 'save_progress'
                        ? 'Save your progress? Please confirm'
                        : 'Progress Saved, check your mail'
                }
                subTitle={
                    modalAction === 'save_progress'
                        ? 'You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue'
                        : `Progress saved! We've emailed you instructions to resume when you have the required information.`
                }
                actionType={
                    modalAction === 'save_progress'
                        ? 'warning'
                        : 'countdown-timer'
                }
                primaryBtnText={
                    modalAction === 'save_progress'
                        ? 'Confirm'
                        : 'Proceed to Log Out'
                }
                handlePrimaryBtnClick={() => {
                    if (modalAction === 'save_progress') {
                        handleSaveOrSubmit('Save')
                    } else {
                        goToSSEHome()
                    }
                }}
                primaryBtnLoading={
                    uploadFileMutation.isLoading ||
                    persistDocsMutation.isLoading
                }
                toastError={actionModalError}
                errorMessage={toastMessage}
                countdownTimerSeconds={10}
                onTimeEnd={() => goToSSEHome()}
                footerLeftContent={
                    modalAction === 'save_progress' ? 'chat' : 'button'
                }
                secondaryBtnText="Continue Application"
                handleSecondaryBtnClick={() => setShowActionModal(false)}
            />

            <RemoveFileModal
                showModal={showRemoveFileModal}
                fileToBeRemoved={fileToBeRemoved}
                closeRemoveFileModal={closeRemoveFileModal}
                closeRemoveFileModalAfterSuccess={
                    closeRemoveFileModalAfterSuccess
                }
            />

            <Layout
                description={
                    <Description
                        title={'Add your business verification details'}
                        subTitle={
                            'Your personal information are required to create your account. We are an energy financial technology platform that connects people who want \n' +
                            'solar energy'
                        }
                        step={2}
                    />
                }
                step={2}
                handleSaveAction={() => {
                    setShowActionModal(true)
                    setModalAction('save_progress')
                }}
                handleSubmitAction={() => handleContinue('Submit')}
                submitLoading={
                    persistDocsMutation.isLoading && action === 'Submit'
                }
                userType={userType}
                form={
                    <div className={'SSEVerificationFormWrapper'}>
                        {showToast && (
                            <Toast
                                message={toastMessage}
                                messageType={toastType}
                                position={'absolute'}
                                closable={true}
                            />
                        )}

                        {kycFetching ? (
                            <FormSkeleton
                                style={{
                                    padding: '24px',
                                    borderRadius: '14px',
                                }}
                            />
                        ) : (
                            <BusinessVerificationForm
                                cac={cac}
                                moa={moa}
                                setCac={setCac}
                                setMoa={setMoa}
                                handleUploadClick={handleUploadClick}
                                openBVNModal={openBVNModal}
                                inputs={inputs}
                                setInputs={setInputs}
                                errors={errors}
                                setErrors={setErrors}
                                isAdminUser={isAdminUser}
                                handleRemoveFile={handleRemoveFile}
                                verificationInProgress={verificationInProgress}
                            />
                        )}
                        {isMobile && (
                            <ChatCTA
                                text={'Have questions about this process?'}
                                margin={'50px auto 0 auto'}
                                justifyContent={'center'}
                            />
                        )}
                    </div>
                }
            />
        </>
    )
}

Verification.propTypes = {
    userType: PropTypes.string,
}

export default Verification

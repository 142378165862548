import { Link } from 'react-router-dom'
import SunFiLogo from 'src/assets/images/logo.svg'
import { ReactComponent as FacebookIcon } from 'src/assets/images/white-facebook-icon.svg'
import { ReactComponent as InstagramIcon } from 'src/assets/images/white-instagram-icon.svg'
import { ReactComponent as LinkedInIcon } from 'src/assets/images/white-linkedin-icon.svg'
import { ReactComponent as TwitterIcon } from 'src/assets/images/X_logo.svg'
import { ReactComponent as WhatsappIcon } from 'src/assets/images/whatsapp.svg'
import styles from './consumerfooter.module.scss'
import { isMobile } from 'src/utils/mediaQueries'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import NewsLetterForm from './NewsLetterForm'
import useHandleSectionScroll from 'src/utils/Hooks/useHandleSectionScroll'

const ConsumerFooter = () => {
    const { handleSectionScroll } = useHandleSectionScroll()
    const sunfiMailchimpUrl = process.env.REACT_APP_MAILCHIMP_URL
    const {
        ConsumerFooterWrapper,
        FooterSectionWrapper,
        FooterNewsletterTextStyle,
        FooterTopSectionWrapper,
        SocialIconsWrapper,
        FooterAddressStyle,
        SocialIconsStyle,
        FooterIconStyle,
        FooterLogoAndNewsletter,
        FooterPageLinks,
        FooterCopyrightTextMobile,
    } = styles

    const currentYear = new Date().getFullYear()

    return (
        <footer className={ConsumerFooterWrapper}>
            <section className={FooterSectionWrapper}>
                <section className={FooterTopSectionWrapper}>
                    <div className={FooterLogoAndNewsletter}>
                        <img
                            src={SunFiLogo}
                            alt="SunFi Logo"
                            style={{ height: '35px' }}
                        />
                        <h3>Join SunFi’s Newsletter</h3>
                        <p className={FooterNewsletterTextStyle}>
                            We&apos;re an energy fintech platform that makes it
                            easy to start your solar journey and take control of
                            your energy.
                        </p>
                        <MailchimpSubscribe
                            url={sunfiMailchimpUrl}
                            render={({ subscribe, status, message }) => {
                                return (
                                    <NewsLetterForm
                                        status={status}
                                        message={message}
                                        onValidated={formData =>
                                            subscribe(formData)
                                        }
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className={FooterPageLinks}>
                        <div>
                            <p className={FooterNewsletterTextStyle}>Company</p>
                            <p>
                                <Link to="/partners">Our Partners</Link>
                            </p>
                            <p>
                                <Link to="/learn">Blog</Link>
                            </p>
                        </div>
                        <div>
                            <p className={FooterNewsletterTextStyle}>
                                Resources
                            </p>
                            <p
                                onClick={() =>
                                    handleSectionScroll(
                                        'solar-savings-calculator',
                                    )
                                }
                            >
                                Energy Calculator
                            </p>
                            <p onClick={() => handleSectionScroll('faq')}>
                                FAQs
                            </p>
                        </div>
                        <div>
                            <p className={FooterNewsletterTextStyle}>Legal</p>
                            <p>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </p>
                            <p>
                                <Link to="/terms-of-service">
                                    Terms of Service
                                </Link>
                            </p>
                        </div>
                        <div>
                            <p className={FooterNewsletterTextStyle}>
                                Contact us
                            </p>
                            <p>
                                <a href="mailto:help@sunfi.co">help@sunfi.co</a>
                            </p>
                            <p>
                                <a
                                    href="tel:02013306111
"
                                >
                                    0201 330 6111
                                </a>
                            </p>
                        </div>
                    </div>
                </section>

                <section className={SocialIconsWrapper}>
                    <div className={SocialIconsStyle}>
                        <a
                            href="https://wa.me/+2348022426861"
                            target="_blank"
                            rel="noreferrer"
                            aria-label={'Link to the SunFi Whatsapp account'}
                        >
                            <WhatsappIcon className={FooterIconStyle} />
                        </a>
                        <a
                            href="https://twitter.com/sunfi_hq"
                            target="_blank"
                            rel="noreferrer"
                            aria-label={'Link to the SunFi Twitter page'}
                        >
                            <TwitterIcon className={FooterIconStyle} />
                        </a>
                        <a
                            href="https://www.instagram.com/sunfihq/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label={'Link to the SunFi Instagram page'}
                        >
                            <InstagramIcon className={FooterIconStyle} />
                        </a>
                        <a
                            href="https://web.facebook.com/sunfihq"
                            target="_blank"
                            rel="noreferrer"
                            aria-label={'Link to the SunFi Facebook page'}
                        >
                            <FacebookIcon className={FooterIconStyle} />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/sunfihq/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label={'Link to the SunFi LinkedIn page'}
                        >
                            <LinkedInIcon className={FooterIconStyle} />
                        </a>
                    </div>
                    <p className={FooterCopyrightTextMobile}>
                        © {currentYear} SunFi, all rights reserved
                    </p>

                    <hr />
                    <div className={FooterAddressStyle}>
                        <p>
                            9a, Joseph Adu Close, Oniru, Victoria Island, Lagos
                        </p>
                        {!isMobile && (
                            <p>© {currentYear} SunFi, all rights reserved</p>
                        )}
                    </div>
                </section>
            </section>
        </footer>
    )
}

export default ConsumerFooter

import PropTypes from 'prop-types'
import { ReactComponent as AttachmentIcon } from 'src/assets/images/attach-blue.svg'
import styles from '../financeinfo.module.scss'
import { sourceMap } from './utils'
import { capitalizeFirstLetter } from 'src/utils/textTransform'

const BankStatementDisplay = ({
    bankStatement,
    isEmpty,
    showPrimaryTag = false,
}) => {
    let metaData = {}
    let source = isEmpty ? '' : bankStatement?.source
    const bankSourceName =
        source === sourceMap.MANUAL_UPLOAD
            ? 'Manual'
            : source === sourceMap.MONO
            ? 'Mono'
            : source === sourceMap.OKRA
            ? 'Okra'
            : ''

    if (source === sourceMap.MANUAL_UPLOAD) {
        metaData = {
            bank_name: bankStatement?.document_meta_data?.bank_name || '-',
            account_name:
                bankStatement?.document_meta_data?.account_name || '-',
            account_number:
                bankStatement?.document_meta_data?.account_number || '-',
        }
    } else if (source === sourceMap.MONO) {
        metaData = {
            bank_name: bankStatement?.mono_meta_data?.bank_name || '-',
            account_name: bankStatement?.mono_meta_data?.account_name || '-',
            account_number:
                bankStatement?.mono_meta_data?.account_number || '-',
        }
    } else if (source === sourceMap.OKRA) {
        metaData = {
            bank_name: bankStatement?.okra_meta_data?.bank_name || '-',
            account_name: bankStatement?.okra_meta_data?.account_name || '-',
            account_number:
                bankStatement?.okra_meta_data?.account_number || '-',
        }
    } else {
        metaData = {
            bank_name: '-',
            account_name: '-',
            account_number: '-',
        }
    }

    const analysisStatus = {
        IN_PROGRESS: 'IN_REVIEW',
        RETRYING: 'IN_REVIEW',
        EMPTY: 'IN_REVIEW',
        FAILED: 'FAILED',
        INTIALIZATION_FAILED: 'FAILED',
        DONE: 'ANALYSED',
        INCOMPLETE: 'INCOMPLETE',
        INVALID: 'INVALID',
        SUCCESSFUL: 'SUCCESSFUL'        
    }

    const invalidStatement = bankStatement?.document_meta_data?.invalid_statement === true
    const incompleteStatement = (bankStatement?.analysis_context?.status === 'DONE' && bankStatement?.analysis_context?.statement_complete === false)
    const successfulAnalysis = (bankStatement?.analysis_context?.status === 'DONE' && bankStatement?.analysis_context?.statement_complete === true)

    const bankAnalysisStatus =
        analysisStatus[ invalidStatement ? 'INVALID' : incompleteStatement ? 'INCOMPLETE' : successfulAnalysis ? 'SUCCESSFUL' : bankStatement?.analysis_context?.status]

        const analysisStatusMapping = {
        ANALYSED: {
            bg: 'rgba(50, 204, 121, 0.15)',
            color: '#32CC79',
            text: 'ANALYSED',
        },
        SUCCESSFUL: {
            bg: 'rgba(50, 204, 121, 0.15)',
            color: '#32CC79',
            text: 'SUCCESSFUL',
        },
        IN_REVIEW: {
            bg: '#FFFAEC',
            color: '#E4AA0F',
            text: 'IN REVIEW',
        },
        FAILED: {
            bg: '#FFF1F1',
            color: '#DA1E28',
            text: 'FAILED',
        },
        INCOMPLETE: {
            bg: '#FFF1F1',
            color: '#DA1E28',
            text: 'INCOMPLETE',
        },
        INVALID: {
            bg: '#FFF1F1',
            color: '#DA1E28',
            text: 'INVALID',
        },
    }

    return (
        <div className={styles.bankActInfo}>
            <div className={styles.bankActInfo__wrapper}>
                {isEmpty ? (
                    <p className={styles.emptyStatementHeader}>
                        No bank statement uploaded or linked yet
                    </p>
                ) : (
                    <div className={styles['bankActInfo__wrapper--btns']}>
                        <a
                            className={styles.pdfBtn}
                            style={{
                                pointerEvents: 'none',
                            }}
                        >
                            <AttachmentIcon />
                            <span>{bankSourceName} Bank Statement</span>
                            <div
                                className={styles.bankAnalysisStatusStyle}
                                style={{
                                    background:
                                        analysisStatusMapping[
                                            bankAnalysisStatus
                                        ]?.bg,
                                }}
                            >
                                <span
                                    style={{
                                        color: analysisStatusMapping[
                                            bankAnalysisStatus
                                        ]?.color,
                                    }}
                                >
                                    {
                                        analysisStatusMapping[
                                            bankAnalysisStatus
                                        ]?.text
                                    }
                                </span>
                            </div>
                        </a>
                        <a
                            href={bankStatement?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.viewBtn}
                            style={{
                                cursor: isEmpty ? 'not-allowed' : 'pointer',
                            }}
                        >
                            View
                        </a>
                    </div>
                )}

                <div className={styles['bankActInfo__wrapper--items']}>
                    <div>
                        <h6>Account Number</h6>
                        <p>{metaData?.account_number}</p>
                    </div>
                    <div>
                        <h6>Bank Name</h6>
                        <p>{capitalizeFirstLetter(metaData?.bank_name)}</p>
                    </div>
                    <div>
                        <h6>Account name</h6>
                        <p>{metaData?.account_name}</p>
                    </div>
                </div>

                {showPrimaryTag && bankStatement?.primary_statement ? (
                    <div className={styles.adminPrimaryStatement}>
                        <p className={styles.adminPrimaryStatement__item}>
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                                    fill={'var(--blue)'}
                                />
                            </svg>
                            <span>Primary bank statement</span>
                        </p>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

BankStatementDisplay.propTypes = {
    bankStatement: PropTypes.object.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    showPrimaryTag: PropTypes.bool,
}

export default BankStatementDisplay

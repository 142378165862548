import { useState, useEffect, useCallback } from 'react'
import { useQuery } from 'react-query'
import { ProductHeader } from '../ProductHeader'
import { MultipleSelectField } from '../../InputFields'
import { numberWithCommas } from '../../../utils/formatting'
import Button from '../../Button'
import GetModal from '../../Modal'
import AddMargin from '../AddMargin'
import Toast from '../../Toast'
import SeoComponent from '../../Seo'
import PropTypes from 'prop-types'
import { getAllComponentsApi } from '../../../api/products/library'
import { v4 as uuidv4 } from 'uuid'
import './addcomponents.scss'
import { currencyFormatter } from '../../../utils/currencyFormatter'
import AddComponent from '../../../pages/Products/AddComponent'
import { eventTrackers } from '../../../utils/eventTrackers.js'
import { appTracking } from '../../../utils/appTracker.js'
import { InlineLoader } from '../../Loader'
import { denyPermission } from '../../../utils/permissionFramework'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import SelectedComponentsTable from './components/SelectedComponentsTable'
import DescriptionAndPricing from './components/DescriptionAndPricing'
import { decodeUserInfo } from 'src/utils/auth'
import EditWarrantyModal from './components/EditWarrantyModal'

const AddComponents = ({
    handleStage,
    handleMargin,
    name,
    selected = [],
    setSelected,
    count,
    setCount,
    margin,
    setMargin,
    outrightSaleMargin,
    handleSavePackage,
    isSubscription,
    handleComponents,
    saveLoading,
    packagePaymentPlanTypes,
    totalForOutrightSale,
    subtotalForOutrightSale,
    handleRemoveMarginForOutrightSale,
    getMarginForOutrightSale,
    packageWarranty,
    handlePackageWarranty,
    stage,
}) => {
    const [components, setComponents] = useState([])
    const [componentList, setComponentList] = useState([])
    const [errMessage, setErrMessage] = useState('')
    const [newComponentFormat, setNewComponentFormat] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [componentModal, setComponentModal] = useState(false)
    const [subtotal, setSubtotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [selectedMargin, setSelectedMargin] = useState('') // financing || outright-sale
    const [showEditWarrantyModal, setShowEditWarrantyModal] = useState(false)
    const [newPackageWarranty, setNewPackageWarranty] = useState('')

    const pageTitle = 'SunFi - Add A Package | Add Components'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_PACKAGE_TRACKER'
    //stored newly created components
    const [componentData, setComponentData] = useState()
    //pass a call back to children components
    const [componentCallback, setcomponentCallback] = useState(0)
    const callback = useCallback(componentCallback => {
        setcomponentCallback(componentCallback)
    }, [])

    const { isMobile } = useMediaQueries()
    const userInfo = decodeUserInfo()
    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'

    useEffect(() => {
        let newComponent = JSON.parse(localStorage.getItem('ComponentData'))
        newComponent !== null && setComponentData(newComponent)
        setComponentModal(false)
    }, [componentCallback])

    const handleChange = useCallback(
        value => {
            setComponents(value)
            let filteredComponent = newComponentFormat.find(item => {
                const formattedItem = `${item?.name} (₦${currencyFormatter(
                    item?.cost,
                )} | ${item?.type?.name})`
                if (formattedItem == value) return item
            })
            let preselectedfilterComponent = [...selected, filteredComponent]
            const updatedSelected = preselectedfilterComponent.map(
                component => {
                    const checkExisitingCapex =
                        component?.capex_replacement_schedule?.filter(
                            item => item.month.length > 0,
                        ).length
                    return {
                        ...component,
                        estimatedValue: `${
                            component?.name
                        } (₦${numberWithCommas(component?.cost)} | ${
                            component?.type
                        })`,
                        count: component?.count || 1,
                        realCost: component?.cost,
                        capex_replacement_schedule: checkExisitingCapex
                            ? [...component.capex_replacement_schedule]
                            : [
                                  {
                                      id: uuidv4(),
                                      cost: '',
                                      month: '',
                                  },
                              ],
                    }
                },
            )
            const countList = updatedSelected.map(item => {
                return {
                    id: item.id,
                    cost: item.cost,
                    count: item?.count || 1,
                    realCost: item?.realCost,
                }
            })

            setCount(countList)
            setSelected(updatedSelected)
            setErrMessage('')
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [newComponentFormat, selected],
    )
    const getCount = componentId => {
        return count.filter(value => value.id === componentId)[0].count
    }

    const updateCount = (currentCount, action, componentId, cost, realCost) => {
        let newCount = {
            id: componentId,
            cost: cost,
            count: currentCount,
            realCost,
        }
        if (action === 'add') {
            newCount.count = currentCount + 1
            newCount.cost = realCost * (currentCount + 1)
        }
        if (action === 'subtract' && currentCount > 1) {
            newCount.count = currentCount - 1
            newCount.cost = realCost * (currentCount - 1)
        }

        let countCopy = [...count]
        let selectedCopy = [...selected]

        let found = countCopy.findIndex(q => q.id === componentId)
        let foundSelected = selectedCopy.findIndex(q => q.id === componentId)

        if (found > -1) {
            countCopy[found] = newCount
        }

        if (foundSelected > -1) {
            selectedCopy[foundSelected] = {
                ...selectedCopy[foundSelected],
                cost: parseFloat(newCount.cost),
                count: newCount.count,
            }
        }

        setCount(countCopy)
        setSelected(selectedCopy)
    }
    const saveUserComponent = () => {
        if (components.length === 0) {
            setErrMessage('This Field is Required')
        } else {
            isSubscription
                ? handleStage('capex')
                : process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE === 'true'
                ? handleStage('protective_device')
                : handleSavePackage()
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['selectComponent'].action,
                eventTrackers['selectComponent'].label,
                eventTrackers['selectComponent'].category,
            )
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => handleComponents(selected), [selected])

    const { isLoading: fetchComponentsLoading, refetch: fetchComponents } =
        useQuery(['fetchAllComponents'], () => getAllComponentsApi(), {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setComponentList(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch components. Refresh page')
            },
        })

    useEffect(() => {
        const ComponentsList = componentList
        let formatComponentList = ComponentsList?.map(item => {
            return {
                ...item,
                value: `${item?.name} (₦${numberWithCommas(item?.cost)} | ${
                    item?.type?.name
                })`,
            }
        })
        setNewComponentFormat(formatComponentList)

        // eslint-disable-next-line
    }, [componentList])

    const handleCancel = id => {
        let newComponent = selected.filter(data => data.id !== id)
        const checked = newComponent.map(data => data.value)

        let countList = []

        newComponent.forEach(item => {
            countList.push({
                id: item.id,
                cost: item.cost,
                count: item.count || 1,
                realCost: item.realCost,
            })
        })

        setCount(countList)
        setComponents(checked)
        setSelected(newComponent)
        selected.length === 1 &&
            setMargin({
                action: '',
                amount: '',
                percentage: '',
                isExisting: false,
            })
    }

    const getMargin = (action, amount, percentage, isExisting) => {
        const formatAmount = amount.toString().replaceAll(',', '')
        setMargin({ action, amount, percentage, isExisting })
        if (action === 'Add Margin') {
            let Total = currencyFormatter(subtotal + parseFloat(formatAmount))
            handleMargin(amount, percentage)
            setTotal(Total)
        } else if (action === 'Subtract Margin') {
            let Total = currencyFormatter(subtotal - parseFloat(formatAmount))
            handleMargin(-amount, -percentage)
            setTotal(Total)
        } else {
            setTotal(subtotal)
        }
    }

    const changePackageWarranty = () => {
        handlePackageWarranty(newPackageWarranty)
        setShowEditWarrantyModal(false)
    }

    const handleWarrantiesCheck = () => {
        if (
            isAdminWorkspace &&
            packageWarranty !== '' &&
            selected?.length > 0
        ) {
            const allWarrantiesMatch = selected?.every(
                component =>
                    component?.type?.name !== 'Inverter' ||
                    !component?.meta_data?.warranty ||
                    parseInt(component?.meta_data?.warranty) ===
                        parseInt(packageWarranty),
            )

            if (!allWarrantiesMatch) {
                const nonMatchingInverter = selected?.find(
                    component =>
                        component?.type?.name === 'Inverter' &&
                        component?.meta_data?.warranty &&
                        parseInt(component?.meta_data?.warranty) !==
                            parseInt(packageWarranty),
                )
                setNewPackageWarranty(nonMatchingInverter?.meta_data?.warranty)
                setShowEditWarrantyModal(true)
            }
        }
    }

    const handleSelectComponentBlur = () => {
        handleWarrantiesCheck()
    }

    useEffect(() => {
        handleWarrantiesCheck()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage])

    useEffect(() => {
        const checkExisitingCapex = [
            componentData,
        ][0]?.capex_replacement_schedule?.filter(
            item => item.month.length > 0,
        ).length

        const allComponentsList = componentList
        const formattedComponent = [componentData][0]?.name
            ? [componentData].map(component => ({
                  ...component,
                  capex_replacement_schedule: checkExisitingCapex
                      ? [...component.capex_replacement_schedule]
                      : [
                            {
                                id: uuidv4(),
                                cost: '',
                                month: '',
                            },
                        ],
              }))
            : ''
        const packageComponentsList = [...formattedComponent, ...selected]
        const newComponentsList = [...formattedComponent, ...allComponentsList]

        let formatAllComponentList = newComponentsList?.map(item => {
            return {
                ...item,
                value: `${item.name} (₦${currencyFormatter(item.cost)} | ${
                    item.type.name
                })`,
            }
        })

        let formatPackageComponentList = packageComponentsList?.map(item => {
            return {
                ...item,
                value: `${item?.name} (₦${currencyFormatter(item?.cost)} | ${
                    item?.type?.name
                })`,
                estimatedValue: `${item?.name} (₦${currencyFormatter(
                    item?.cost,
                )} | ${item?.type?.name})`,
                count: item?.count || 1,
                realCost: item?.realCost,
            }
        })

        let countList = []
        let checked = []

        formatPackageComponentList.forEach(item => {
            checked.push(item.value)
            countList.push({
                id: item.id,
                cost: item.cost,
                count: item?.count,
                realCost: item?.realCost
                    ? parseFloat(item?.realCost, 10).toFixed(2)
                    : null,
            })
        })

        setCount(countList)
        setComponents(checked)
        setNewComponentFormat(formatAllComponentList)
        setSelected(formatPackageComponentList)

        // eslint-disable-next-line
    }, [componentList, componentData])

    useEffect(() => {
        let calculateSubtotal = count?.reduce(
            (total, item) =>
                total + (item?.realCost ?? item?.cost) * item?.count,
            0,
        )
        setSubtotal(calculateSubtotal)
        const packageMarginPercentage =
            margin && margin.percentage ? parseFloat(margin.percentage) : 0

        if (
            packageMarginPercentage !== 0 &&
            calculateSubtotal !== 0 &&
            subtotal !== 0
        ) {
            const packageMarginAmount = (
                (packageMarginPercentage / 100) *
                parseFloat(calculateSubtotal)
            ).toFixed(2)
            const marginDescription =
                margin.action === 'Subtract Margin'
                    ? 'Subtract Margin'
                    : 'Add Margin'
            getMargin(
                marginDescription,
                packageMarginAmount || '',
                packageMarginPercentage || '',
                margin.percentage === '' ? false : true,
            )
        }
        // eslint-disable-next-line
    }, [count, subtotal])

    useEffect(() => {
        fetchComponents()

        // eslint-disable-next-line
    }, [])

    const handleAddOrEditMargin = selected => {
        setSelectedMargin(selected)
        setModalOpen(true)
    }

    const handleRemoveMargin = () => {
        setMargin({ action: '', amount: '', percentage: '', isExisting: false })
        handleMargin(0, 0)
        setTotal(subtotal)
    }

    const getButtonText = () => {
        if (
            isSubscription ||
            process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE === 'true'
        )
            return 'Continue'
        if (saveLoading) return <InlineLoader />
        return 'Create Package'
    }

    const closeAddMarginModal = () => {
        setSelectedMargin('')
        setModalOpen(false)
    }

    return (
        <>
            <EditWarrantyModal
                showModal={showEditWarrantyModal}
                changePackageWarranty={changePackageWarranty}
                handleGoBack={() => handleStage('name')}
            />
            {/* page */}
            <SeoComponent
                title="SunFi - Add A Package | Add Components"
                tracker="AddPackage"
            />
            <div className="ComponentWrapper">
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}
                <div className="AddComponentRow">
                    <div className="LeftComponent">
                        <ProductHeader
                            title="Create New Product Package"
                            subtitle="Select components here"
                        />
                        <MultipleSelectField
                            values={newComponentFormat}
                            value={selected?.map(item => item.value)}
                            onSelect
                            handleMultipleSelectChange2={handleChange}
                            selectWidth="100%"
                            width="380px"
                            dropDownWidth="380px"
                            title={
                                newComponentFormat.length > 0
                                    ? 'Select Component'
                                    : 'Select Component'
                            }
                            floatingLabel={'Select Component'}
                            inputValue={selected?.map(item => item.value)}
                            errorMessage={errMessage}
                            maxTagCount="responsive"
                            maxTagPlaceholder="Select Component"
                            disabled={fetchComponentsLoading ? true : false}
                            mobileWidth
                            onBlur={handleSelectComponentBlur}
                        />
                        {!denyPermission(
                            'admin',
                            'product_library',
                            'component:can_create',
                        ) && (
                            <p
                                className="ComponentLink"
                                onClick={() => setComponentModal(true)}
                            >
                                Create New Component
                            </p>
                        )}
                        <div className="SelectedComponentsWrapper">
                            <SelectedComponentsTable
                                selected={selected}
                                updateCount={updateCount}
                                getCount={getCount}
                                handleCancel={handleCancel}
                                isMobile={isMobile}
                                currencyFormatter={currencyFormatter}
                            />
                        </div>
                        <div className="ComponentBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage('name')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={saveUserComponent}
                                disabled={saveLoading ? true : false}
                            >
                                {isSubscription ? (
                                    'Continue'
                                ) : saveLoading ? (
                                    <InlineLoader />
                                ) : process.env
                                      .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                                  'true' ? (
                                    'Continue'
                                ) : (
                                    'Create Package'
                                )}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DescriptionAndPricing
                            selected={selected}
                            subtotal={subtotal}
                            margin={margin}
                            outrightSaleMargin={outrightSaleMargin}
                            total={total}
                            handleRemoveMargin={handleRemoveMargin}
                            handleAddOrEditMargin={handleAddOrEditMargin}
                            packagePaymentPlanTypes={packagePaymentPlanTypes}
                            totalForOutrightSale={totalForOutrightSale}
                            subtotalForOutrightSale={subtotalForOutrightSale}
                            handleRemoveMarginForOutrightSale={
                                handleRemoveMarginForOutrightSale
                            }
                        />
                        <div className="MobileComponentBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage('name')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={saveUserComponent}
                                disabled={saveLoading ? true : false}
                            >
                                {getButtonText()}
                            </Button>
                        </div>
                    </div>
                </div>
                <GetModal
                    app="workspace"
                    showModal={modalOpen}
                    onCancel={closeAddMarginModal}
                    closable={true}
                    content={
                        <AddMargin
                            packageName={name}
                            subTotal={
                                selectedMargin === 'financing'
                                    ? subtotal
                                    : subtotalForOutrightSale
                            }
                            margin={
                                selectedMargin === 'financing'
                                    ? margin
                                    : outrightSaleMargin
                            }
                            handleClose={closeAddMarginModal}
                            handleMargin={
                                selectedMargin === 'financing'
                                    ? getMargin
                                    : getMarginForOutrightSale
                            }
                        />
                    }
                />

                <GetModal
                    app="workspace"
                    showModal={componentModal}
                    noPadding="24px"
                    onCancel={() => setComponentModal(false)}
                    closable={false}
                    content={
                        <AddComponent
                            modalFlow={true}
                            parentCallback={callback}
                            setComponentModal={setComponentModal}
                        />
                    }
                />
            </div>
        </>
    )
}

AddComponents.propTypes = {
    handleStage: PropTypes.func,
    handleMargin: PropTypes.func,
    name: PropTypes.string,
    handleSavePackage: PropTypes.func,
    selected: PropTypes.any,
    setSelected: PropTypes.func,
    count: PropTypes.any,
    setCount: PropTypes.func,
    margin: PropTypes.any,
    setMargin: PropTypes.func,
    outrightSaleMargin: PropTypes.object,
    isSubscription: PropTypes.bool,
    saveLoading: PropTypes.bool,
    handleComponents: PropTypes.func,
    packagePaymentPlanTypes: PropTypes.array,
    totalForOutrightSale: PropTypes.number,
    subtotalForOutrightSale: PropTypes.number,
    handleRemoveMarginForOutrightSale: PropTypes.func,
    getMarginForOutrightSale: PropTypes.func,
    packageWarranty: PropTypes.string,
    handlePackageWarranty: PropTypes.func,
    stage: PropTypes.string,
}

export default AddComponents

import { PropTypes } from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import s from '../profilesgroupslistings.module.scss'
import ButtonComponent from 'src/components/Button'
import { format, parseISO } from 'date-fns'
import { toTitleCase } from 'src/utils/formatting'
import { profileSortingTypeMapping } from '../data'
import { ReactComponent as EditIcon } from 'src/assets/images/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from 'src/assets/images/trash-icon.svg'
import { Skeleton } from 'antd'

const ViewGroup = ({ showModal, data, isLoading, onClose, showEditModal, deleteGroup }) => {
    
    return (
        <>
            <SecondaryModal
                closable={true}
                showModal={showModal?.open}
                onCancel={onClose}
                modalWidth="624px"
                content={
                    <div className={s.view_modal_wrapper}>
                        <div>
                            <div>
                                <div>
                                    <h3>Group name</h3>
                                    {!isLoading ? (
                                        <h2>{data?.name}</h2>
                                    ) : (
                                        <Skeleton.Input
                                            style={{ height: 25 }}
                                        />
                                    )}
                                </div>
                                {!isLoading ? (
                                    <div>
                                        <EditIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={showEditModal}
                                        />
                                        <DeleteIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={deleteGroup}
                                        />
                                    </div>
                                ) : (
                                    <Skeleton.Input
                                        style={{
                                            paddingTop: '8px',
                                            height: 25,
                                        }}
                                    />
                                )}
                            </div>
                            <div>
                                <div>
                                    <h3>Profile added</h3>
                                    {!isLoading ? (
                                        <p>{data?.profiles_added}</p>
                                    ) : (
                                        <Skeleton.Input
                                            style={{ height: 25 }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <h3>Profile type</h3>
                                    {!isLoading ? (
                                        <p>
                                            {toTitleCase(data?.customer_type)}
                                        </p>
                                    ) : (
                                        <Skeleton.Input
                                            style={{ height: 25 }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h3>Last Updated Time</h3>
                                    {!isLoading ? (
                                        <p>
                                            {data?.updated_at !== undefined &&
                                                format(
                                                    parseISO(data?.updated_at),
                                                    'dd MMM yyyy, HH:mm',
                                                )}
                                        </p>
                                    ) : (
                                        <Skeleton.Input
                                            style={{ height: 25 }}
                                        />
                                    )}
                                </div>
                                <div>
                                    <h3>Profile sorting type</h3>
                                    {!isLoading ? (
                                        <p>
                                            {
                                                profileSortingTypeMapping[
                                                    data?.profile_sorting
                                                ]
                                            }
                                        </p>
                                    ) : (
                                        <Skeleton.Input
                                            style={{ height: 25 }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <ButtonComponent
                                btnBgColor={'#004AAD'}
                                btnWidth={'160px'}
                                btnHeight={'56px'}
                                btnTextColor={'#FFFFFF'}
                                disabled={isLoading}
                                handleClick={() => onClose()}
                            >
                                Okay, got it
                            </ButtonComponent>
                        </div>
                    </div>
                }
            />
        </>
    )
}

ViewGroup.propTypes = {
    showModal: PropTypes.bool,
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    showEditModal: PropTypes.func,
    deleteGroup: PropTypes.func,
}

export default ViewGroup

import { removeEmptyObjects } from 'src/utils/removeEmptyValues'
import axios from '../../config/axios'

export const getSSERecommendedSystem = async (userId, systemId) => {
    const request = await axios.post(
        `/power-curve/recommended-solutions/${systemId}`,
        userId,
    )
    return request
}

export const getRecommendedSystemAuthUser = async (
    estimation_id,
    recommendation_id,
) => {
    const request = await axios.get(
        `/power-curve/auth/recommended-solutions/estimations/${estimation_id}/${recommendation_id}`,
    )
    return request
}

export const selectNewSystemAuthUser = async (
    estimation_id,
    solution_id,
    energy_profile_id,
    appliances,
    flow,
    payment_model,
) => {
    const payload = removeEmptyObjects({
        solution_id: solution_id,
        energy_profile_id,
        appliances: appliances,
        flow,
        payment_model,
    })
    const request = await axios.patch(
        `/power-curve/auth/recommended-solutions/estimations/${estimation_id}`,
        payload,
    )
    return request
}

export const savePotentialCustomerApi = async data => {
    const request = await axios.post('/sse-consumers/drop-off', data)
    return request
}

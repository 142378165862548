import axios from '../../config/axios'

export const initiateSSESignup = async payload => {
    const request = axios.post(`/sse-auth/signup/initiate`, payload)
    return request
}

export const validateSSESignupOTP = async payload => {
    const request = axios.post(`/sse-auth/validate-otp`, payload)
    return request
}

export const resendSSESignupOTP = async payload => {
    const request = axios.post(`/sse-auth/signup/resend-otp`, payload)
    return request
}

export const proceedKYCLater = async payload => {
    const request = axios.post(`/sse-auth/proceed-later`, payload)
    return request
}

import PropTypes from 'prop-types'

const VerticalSpacer = ({ top, bottom }) => {
    return <div style={{ marginTop: top, marginBottom: bottom }}></div>
}

VerticalSpacer.propTypes = {
    top: PropTypes.string || PropTypes.number,
    bottom: PropTypes.string || PropTypes.number,
}

export default VerticalSpacer

import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import {
    submitBusinessKycInfoBusiness,
    submitBusinessKycInfoContact,
} from 'src/api/create-account'
import { errorHandler } from 'src/utils/errorHandler'
import { formatErrorMessages } from '../utils/utils'

const useBusinessInfoSubmit = ({ successActions }) => {
    const [submitToastMessage, setSubmitToastMessage] = useState('')
    const [submitToastType, setSubmitToastType] = useState('success-secondary')

    useEffect(() => {
        setSubmitToastMessage('')
    }, [])

    const submitBusinessInfo = useMutation({
        mutationFn: businessInfoPayload =>
            submitBusinessKycInfoBusiness(businessInfoPayload),
        onSuccess: (response, variables) => {
            if (
                variables?.button === 'Save' &&
                (variables.businessRegistrationNumberVerified === false ||
                    variables.businessNameVerified === false)
            ) {
                setSubmitToastType('error-secondary')
                successActions?.setActionModalOpen(false)
                setSubmitToastMessage(
                    'Information saved! But you must verify your CAC number to save your business name or CAC number fields',
                )

                return
            } else {
                successActions?.setActionModalType('countdown-timer')
            }
        },
        onError: error => {
            const errString = formatErrorMessages(
                errorHandler(error?.response?.data?.errors),
            )
            successActions?.setActionModalOpen(false)
            setSubmitToastType('error-secondary')
            setSubmitToastMessage(errString || 'An error occurred')
        },
    })

    const submitBusinessContactInfo = useMutation({
        mutationFn: businessContactInfoPayload =>
            submitBusinessKycInfoContact(businessContactInfoPayload),
        onSuccess: (response, variables) => {
            if (variables?.button === 'Save') {
                successActions?.setActionModalType('countdown-timer')
            }
        },
        onError: error => {
            const errString = formatErrorMessages(
                errorHandler(error?.response?.data?.errors),
            )
            successActions?.setActionModalOpen(false)
            setSubmitToastType('error-secondary')
            setSubmitToastMessage(errString || 'An error occurred')
        },
    })

    return {
        submitBusinessInfo,
        submitBusinessContactInfo,
        setSubmitToastMessage,
        submitToastMessage,
        submitToastType,
    }
}

export default useBusinessInfoSubmit

import '../accountsetupoverview.scss'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    createResidentialAccountSteps,
    createBusinessAccountSteps,
    createOutrightSaleSteps,
    createDynamicKycSteps,
} from '../data'
import goBtn from 'src/assets/images/blue-circle-right.svg'
import Button from 'src/components/Button'
import { isMobile } from 'src/utils/mediaQueries'
import { ReactComponent as Checked } from 'src/assets/images/checked-green-2.svg'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { decodeUserInfo } from 'src/utils/auth'
import { useAppContext } from 'src/appContext/AppContext'
import CustomToolTip from 'src/components/CustomToolTip'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'
import CustomerTour from 'src/components/CustomerTour'
import { handleAppTracking } from '../utils'

const CreateAccount = ({
    useType,
    handleSwitchUseTypeWarning,
    kycStatus,
    isDEPConsumer,
    isDTCConsumer,
    estimation_id,
    requestCategories,
    bankStatementAnalysisStatus,
    estimation,
    handleShowPaymentSuccessModal,
    hasPaidOutrightly,
    isDynamicKycUser,
    kycAppModules,
}) => {
    const { isCustomerApproved } = useAppContext()
    const history = useHistory()
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const steps = hasPaidOutrightly
        ? createOutrightSaleSteps(estimation, isAdminUser)
        : isDynamicKycUser
        ? createDynamicKycSteps(kycAppModules)
        : useType === 'Residential use'
        ? createResidentialAccountSteps(estimation_id)
        : createBusinessAccountSteps(estimation_id)
    const { errorAlert } = useCustomToast()
    const bankStatementAnalysisInProgress =
        bankStatementAnalysisStatus === 'inProgress'

    const values = () => {
        if (isAdminUser !== undefined && isAdminUser) {
            return {
                title: "View customer's information",
                subTitle:
                    'Consumer submitted data is readily accessible for administrative review, with the ability to examine each section in detail.',
            }
        } else if (hasPaidOutrightly !== undefined && hasPaidOutrightly) {
            return {
                title: 'Your order is currently being processed',
                subTitle:
                    'We are an energy financial technology platform that connects people who want solar energy access to payment plans that match their needs.',
            }
        } else {
            return {
                title: 'Create your account on SunFi',
                subTitle:
                    'We are an energy financial technology platform that connects people who want solar energy access to payment plans that match their needs.',
            }
        }
    }

    const { title, subTitle } = values()

    return (
        <section
            className={`ASOCreateAccount ${
                isDEPConsumer ? 'ASOCreateAccount_DEPConsumer' : ''
            } ${isAdminUser ? 'ASOCreateAccount_Admin' : ''} ${
                hasPaidOutrightly ? 'ASOCreateAccount_OutrightSale' : ''
            }`}
            data-testid="account-setup-overview-create-account"
        >
            <h2>{title}</h2>
            <p>{subTitle}</p>
            <div className={'ASOCreateAccountSteps'}>
                {steps?.map((each, i) => {
                    const isStep1Completed = kycStatus?.[steps[0]?.completed]
                    const isStep2Completed = kycStatus?.[steps[1]?.completed]
                    return (
                        <div
                            key={i}
                            className={`ASOCreateAccountStep ${
                                isDEPConsumer ? 'ASOCreateAccountStep_DEP' : ''
                            } `}
                            style={{
                                cursor: each?.disabled
                                    ? 'not-allowed'
                                    : 'pointer',
                            }}
                            aria-disabled={each?.disabled}
                            onClick={() => {
                                if (each?.disabled) {
                                    return
                                } else if (
                                    each?.notification === 'PROCESSING ORDER'
                                ) {
                                    handleShowPaymentSuccessModal()
                                } else if (isAdminUser) {
                                    history.push({
                                        pathname: each.admin_link,
                                        state: {
                                            estimationId: estimation_id,
                                            isDynamicKycUser: isDynamicKycUser,
                                        },
                                    })
                                } else {
                                    if (i === 1 && !isStep1Completed) {
                                        errorAlert(
                                            `Please provide your ${steps[0]?.title?.toLowerCase()} before proceeding to this section`,
                                            isMobile ? 320 : 500,
                                        )
                                        return
                                    }

                                    if (
                                        i === 2 &&
                                        (!isStep1Completed || !isStep2Completed)
                                    ) {
                                        errorAlert(
                                            `Please provide your ${
                                                !isStep1Completed
                                                    ? steps[0]?.title?.toLowerCase()
                                                    : steps[1]?.title?.toLowerCase()
                                            } before proceeding to this section`,
                                            isMobile ? 320 : 500,
                                        )
                                        return
                                    }

                                    history.push(each.link)
                                    if (!isAdminUser) {
                                        handleAppTracking('viewKYCCategory')
                                    }
                                }
                            }}
                        >
                            <div className={'ASOCreateAccountStep_Icons'}>
                                {each.icon}
                                <div
                                    className="ASOCreateAccountStep_Icons_Check"
                                    style={{
                                        display: kycStatus?.[each?.completed]
                                            ? 'block'
                                            : 'none',
                                    }}
                                >
                                    <Checked role="presentation" />
                                </div>
                            </div>

                            <div className={`ASOCreateAccountStepText`}>
                                <div className="ASOCreateAccountStepText_Heading">
                                    <h5>
                                        {isAdminUser
                                            ? each.admin_title
                                            : each.title}
                                    </h5>
                                    {each?.category ===
                                        'financial_information' &&
                                        bankStatementAnalysisStatus !== '' && (
                                            <div
                                                className="ASOBankAnalysisStyle"
                                                style={{
                                                    background:
                                                        bankStatementAnalysisInProgress
                                                            ? '#E2EEFF'
                                                            : '#FFFAEC',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: bankStatementAnalysisInProgress
                                                            ? '#004AAD'
                                                            : '#E4AA0F',
                                                    }}
                                                >
                                                    {bankStatementAnalysisInProgress
                                                        ? 'IN PROGRESS'
                                                        : 'UPDATE REQUIRED'}
                                                </span>
                                                {bankStatementAnalysisInProgress && (
                                                    <CustomToolTip
                                                        text="Your bank statement is currently being reviewed & for this, your financial information is still in progress ."
                                                        toolTipFontSize="12px"
                                                        toolTipMarginLeft="-20px"
                                                        toolTipPopupWidth="174px"
                                                        borderRadius="8px"
                                                        background="#070C18"
                                                        lineHeight="18px"
                                                        arrowPositionLeft
                                                    >
                                                        <HelpIcon />
                                                    </CustomToolTip>
                                                )}
                                            </div>
                                        )}
                                    {requestCategories.includes(
                                        each?.category,
                                    ) && (
                                        <div>
                                            <span>UPDATE REQUIRED</span>
                                        </div>
                                    )}
                                    {each?.notification && (
                                        <div>
                                            <span>{each?.notification}</span>
                                        </div>
                                    )}
                                </div>
                                {!isAdminUser && <p>{each.description}</p>}
                            </div>
                            <img
                                src={goBtn}
                                alt={'go to page'}
                                role={'button'}
                            />
                        </div>
                    )
                })}
            </div>
            {!isAdminUser &&
            !isDynamicKycUser &&
            isDTCConsumer &&
            kycStatus?.account_setup_completion_status === false ? (
                <div className={'ASOCreateAccountSwitch'}>
                    <p>
                        Are you getting this solution for{' '}
                        {useType === 'Residential use'
                            ? 'business purposes'
                            : 'residential purposes'}
                        ?
                    </p>
                    <Button
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#fff'}
                        btnWidth={isMobile ? '186px' : '142px'}
                        btnHeight={'29px'}
                        handleClick={() => handleSwitchUseTypeWarning()}
                        fontFamily={'SF-Pro-Display-Medium'}
                        disabled={isCustomerApproved}
                    >
                        Switch to{' '}
                        {useType === 'Residential use'
                            ? 'business'
                            : 'residential'}
                    </Button>
                </div>
            ) : null}
            {!isAdminUser && !hasPaidOutrightly && <CustomerTour />}
        </section>
    )
}

CreateAccount.propTypes = {
    useType: PropTypes.string,
    handleSwitchUseTypeWarning: PropTypes.func,
    kycStatus: PropTypes.array,
    isDEPConsumer: PropTypes.bool,
    isDTCConsumer: PropTypes.bool,
    estimation_id: PropTypes.string,
    requestCategories: PropTypes.array,
    bankStatementAnalysisStatus: PropTypes.string,
    estimation: PropTypes.object,
    handleShowPaymentSuccessModal: PropTypes.func,
    hasPaidOutrightly: PropTypes.bool,
    isDynamicKycUser: PropTypes.bool,
    kycAppModules: PropTypes.array,
}
export default CreateAccount

/* eslint-disable react/prop-types */
import '../viewsystem.scss'
// import arnergyLogo from 'src/assets/images/arnergy-grey-logo.svg'
const PackageDescription = ({ description }) => {
    const isDescriptionString = typeof description
    if (isDescriptionString === 'string') {
        const lineBreakPosition = description?.search(/On a bad day/)
        const firstParagraph = description?.substring(0, lineBreakPosition)
        const secondParagraph = description?.substring(lineBreakPosition)
        return (
            <>
                <div className={'ViewSystemPackageDescriptionText'}>
                    <h4>Package description</h4>
                    <div className="ViewSystemPackageDescriptionParagraphs">
                        <p>{firstParagraph}</p>
                        <p>{secondParagraph}</p>
                    </div>
                </div>
                {/* TODO: change this implementation to use the inverter brand */}
                {/* <div className={'ViewSystemPackageDescriptionMaker'}>
                    <h4>PRODUCT MAKER:</h4>
                    <img src={arnergyLogo} alt="product maker logo" />
                </div> */}
            </>
        )
    }

    return (
        <>
            <div className={'ViewSystemPackageDescriptionText'}>
                <h4>Package description</h4>
                <div className="ViewSystemPackageDescriptionParagraphs">
                    <p>{description}</p>
                </div>
            </div>
            {/* TODO: change this implementation to use the inverter brand */}
            {/* <div className={'ViewSystemPackageDescriptionMaker'}>
                <h4>PRODUCT MAKER:</h4>
                <img src={arnergyLogo} alt="product maker logo" />
            </div> */}
        </>
    )
}

export default PackageDescription

import { useState } from 'react'
import { useMutation } from 'react-query'
import { otpSchema } from 'src/utils/validationSchema'
import { useHistory } from 'react-router-dom'
import {
    initiateSSEConsumerLoginApi,
    verifySSEConsumerLoginApi,
} from 'src/api/shoppingExperience/login'
import { errorHandler } from 'src/utils/errorHandler'
import { encodeUserInfo, setToken } from 'src/utils/auth'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import {
    initiateClusterRepLoginApi,
    verifyClusterRepLoginApi,
} from 'src/api/representative/auth'

const useVerifyLoginOTP = (setShowModal, method, inputs, userType) => {
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [resent, setResent] = useState(false)
    const history = useHistory()
    const isSSELogIn = location.pathname === '/consumer/login'
    const isRepLogin = location.pathname === '/rep/login'

    const verifyLoginApi =
        userType === 'consumer'
            ? verifySSEConsumerLoginApi
            : verifyClusterRepLoginApi
    const loginApi =
        userType === 'consumer'
            ? initiateSSEConsumerLoginApi
            : initiateClusterRepLoginApi

    //resend OTP
    const resendOTPMutation = useMutation({
        mutationFn: () => {
            return loginApi(
                method === 'email'
                    ? { email: inputs.email }
                    : { phone_number: inputs.phone },
            )
        },
        onSuccess: () => {
            setResent(true)
            const timeout = setTimeout(() => setResent(false), [2000])
            return () => clearTimeout(timeout)
        },
        onError: error => {
            setToastError(true)
            setResent(false)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })

    // verify submitted otp
    const verifyLoginMutation = useMutation({
        mutationFn: () => {
            return verifyLoginApi({
                code: otp.join(''),
                ...(method === 'phone'
                    ? { phone_number: inputs.phone }
                    : { email: inputs.email }),
            })
        },
        onSuccess: res => {
            const data = res?.data?.data
            setToken(res?.data?.data?.token?.access)
            encodeUserInfo(res)

            if (isRepLogin) {
                localStorage.setItem('isClusterRep', JSON.stringify(true))
                history.push('/rep/workspace')
            } else {
                localStorage.removeItem('isClusterRep')
            }

            if (isSSELogIn) {
                localStorage.setItem('isSSEConsumer', JSON.stringify(true))
                const isApproved =
                    res?.data?.data?.approval_status?.toLowerCase() ===
                        'approved' ||
                    data?.application_status === 'TENURE_SELECTION'

                const isOutrightSaleCustomer =
                    data?.payment_model === 'Outright Sale'

                if (isOutrightSaleCustomer && isApproved) {
                    history.push('/consumer/account-setup/overview')
                } else if (isOutrightSaleCustomer && !isApproved) {
                    history.push(
                        '/consumer/account-setup/outright-sale-information',
                    )
                } else {
                    history.push(
                        isApproved
                            ? '/consumer/workspace'
                            : '/consumer/account-setup/overview',
                    )
                }
            } else {
                localStorage.removeItem('isSSEConsumer')
            }
        },
        onError: err => {
            setToastError(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                verifyLoginMutation.mutate()
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    const fullMethodName = method === 'phone' ? 'phone number' : 'email address'

    const closeModal = () => {
        setShowModal(false)
        setOtp(['', '', '', '', '', ''])
        setOtpError(false)
    }

    useOTPVerify({ otp, handleOtpSubmit })

    return {
        otpError,
        toastError,
        errorMessage,
        resent,
        resendOTPMutation,
        fullMethodName,
        closeModal,
        handleOtpSubmit,
        verifyLoginMutation,
        otp,
        setOtp,
        setResent,
    }
}

export default useVerifyLoginOTP

import { useState } from 'react'
import PropTypes from 'prop-types'
import { creditReviewResMap } from '../../utils/utils'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { Tooltip } from 'antd'
import { removeCommaFromNum } from 'src/utils/NumberFormatter'
import helpIcon from 'src/assets/images/help-circle-black.svg'
import CreditScore from './CreditScore'
import { ReactComponent as CaretDown } from '../../../../../../assets/images/Caret-Circle-Down.svg'
import { ReactComponent as CaretUp } from '../../../../../../assets/images/Caret_Circle_Up.svg'
import styles from './CreditReviewTab.module.scss'

const PlanView = ({ plan, reviewType }) => {
    const [showScore, setShowScore] = useState(false)
    const totalWeightedValue = Object.values(
        plan?.credit_decision?.evaluation_criteria || {},
    ).reduce((acc, curr) => acc + curr.weight_value || 0, 0)

    return (
        <div className={styles.creditReview__body} key={plan.id}>
            <div className={styles.creditReview__body__head}>
                <h6>Payment duration</h6>
                <p>{plan.tenure} months</p>
            </div>

            {plan?.credit_decision?.sunfi_credit_score && (
                <div className={styles.creditReview__body__item}>
                    <p>Credit score</p>
                    <span
                        onClick={() => setShowScore(!showScore)}
                        className={styles.caret}
                    >
                        <span>{plan.credit_decision.sunfi_credit_score}</span>/
                        {totalWeightedValue}
                        {showScore ? <CaretUp /> : <CaretDown />}
                    </span>
                </div>
            )}

            <div
                className={`${
                    !showScore ? styles.hideScore : styles.showScore
                }`}
            >
                {<CreditScore plan={plan} />}
            </div>

            {plan?.credit_decision?.affordability_index && (
                <div className={styles.creditReview__body__item}>
                    <p>Affordability rate</p>
                    <span>
                        {plan.credit_decision.affordability_index * 100}%
                    </span>
                </div>
            )}

            {plan?.credit_decision?.credit_limit && (
                <div className={styles.creditReview__body__item}>
                    <p>Credit limit</p>
                    <span>
                        {plan?.currency.symbol}
                        {currencyFormatter(plan?.credit_decision.credit_limit)}
                    </span>
                </div>
            )}

            <div className={styles.creditReview__body__item}>
                <p>Monthly payment</p>
                <span>
                    {plan?.currency.symbol}
                    {currencyFormatter(plan?.monthly_payment)}
                </span>
            </div>

            <div className={styles.creditReview__body__item}>
                <p>
                    {reviewType === 'MANUAL'
                        ? 'Selected decision'
                        : 'Credit engine decision'}
                </p>
                <span className={styles[plan.credit_decision?.decision]}>
                    {creditReviewResMap[plan.credit_decision?.decision]}
                </span>
            </div>

            {plan.credit_decision?.decision === 'MAYBE' && (
                <div className={styles.creditReview__body__item}>
                    <div className="CR_new-deposit">
                        <p>New upfront deposit</p>
                        <Tooltip
                            overlayStyle={{
                                padding: '9px 8px',
                                maxWidth: '188px',
                            }}
                            overlayInnerStyle={{
                                fontSize: '12px',
                                fontFamily: 'SF-Pro-Display',
                            }}
                            color="#070C18"
                            placement="bottomLeft"
                            title="This upfront deposit is recommended by the credit engine to the consumer to access this tenure"
                        >
                            <img src={helpIcon} />
                        </Tooltip>
                    </div>
                    <span>
                        <strong>
                            {plan?.credit_decision?.suggestions[0]?.value}%
                        </strong>
                        ({plan?.currency.symbol}
                        {currencyFormatter(
                            (plan?.credit_decision?.suggestions[0]?.value /
                                100) *
                                removeCommaFromNum(plan?.solution_amount),
                        )}
                        )
                    </span>
                </div>
            )}
        </div>
    )
}

export default PlanView

PlanView.propTypes = {
    plan: PropTypes.object,
    reviewType: PropTypes.string,
}

import { offerStatusMap } from 'src/utils/Data/status'

export const planApprovalStatus = [
    {
        id: 'active',
        label: 'Active',
    },
    {
        id: 'closed',
        label: 'Not Active',
    },
]

export const planApplicationStatus = () => {
    return Object.entries(offerStatusMap).map(([status, title]) => ({
        id: status,
        label: title,
    }))
}

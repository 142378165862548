import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import {
    consumerHomeValidationSchema,
    consumerHomeValidationV2Schema,
    userContactSchema,
} from 'src/utils/validationSchema'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import {
    // paymentPlansData,
    paymentPlansMap,
    // reasonForSolarData,
    reasonForSolarMap,
    CONTACT_METHODS,
    PAYMENT_TYPES,
    electricityPerDayDataMap,
    paymentPlansMapReverse,
    reasonForSolarMapReverse,
} from '../data'
import useValidateExistingCustomer from './useValidateExistingCustomer'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useGetRecommendations from './useGetRecommendations'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { v4 as uuidv4 } from 'uuid'
import { getFormResponse } from 'src/api/shoppingExperience/landingPage'

import { floatWithCommas } from 'src/utils/formatting'
import { decrypt, encrypt } from 'src/utils/cryptography'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import useRegisterDropOff from './useRegisterDropOff'
import { removeCountryCode } from 'src/utils/NumberFormatter'
import { urgencyOptionsMap } from 'src/pages/Consumer/SmartShoppingExperience/Home/SelectUseType/data'
import { clearVisitCount } from 'src/utils/visitCount'
// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'SSE_LANDING_PAGE'

export const useConsumerLandingPage = () => {
    const { validateRequestInfoMutation, loading, setLoading, setErrorState } =
        useGetRecommendations()
    const history = useHistory()
    const isCustomBuild = history.location.state?.buildCustomSystem
    const customAppliances = history.location.state?.appliances

    const [errors, setErrors] = useState({})

    const { validateUserIdMutation, isExisting } = useValidateExistingCustomer({
        setErrors,
    })
    const [inputs, setInputs] = useState({
        email: '',
        phone: '',
        location: '',
        paymentPlan: '',
        monthlyPayment: '',
        generatorSpend: '',
        electricityPerDay: '',
        reasonForSolar: '',
        generatorSize: '',
        generatorMaintenance: '',
    })
    const [energyNeeds, setEnergyNeeds] = useState([
        {
            id: uuidv4(),
            name: '',
            quantity: '',
            backup_hours: '',
            ...(isCustomBuild && customAppliances
                ? { power_rating: '' }
                : { powerRating: '' }),
            backup_hours_night: '',
            powerRating: '',
            unitOfMeasurement: '',
        },
    ])

    const [checked, setChecked] = useState(true)
    const [countryCode] = useState('+234')
    const [contactMethod, setContactMethod] = useState(CONTACT_METHODS.EMAIL)
    const [emailIsExistingChecked, setEmailIsExistingChecked] = useState(false)
    const [phoneIsExistingChecked, setPhoneIsExistingChecked] = useState(false)
    const [knowsGenMonthlyCost, setKnowsGenMonthlyCost] = useState('')
    const [showNext, setShowNext] = useState(false)
    const [generatorUse, setGeneratorUse] = useState('yes')
    const [paymentModel, setPaymentModel] = useState(PAYMENT_TYPES.NULL)
    const [canMountSolar, setCanMountSolar] = useState(null)
    const [useType, setUseType] = useState('')
    const [useTypeFormAction, setUseTypeFormAction] = useState('') // get-started || view-all-solutions
    const [useTypeFormInputs, setUseTypeFormInputs] = useState({
        urgency: '',
    })

    const { isMobile } = useMediaQueries()
    const { errorAlert } = useCustomToast()

    useEffect(() => {
        if (isCustomBuild) {
            customAppliances && setEnergyNeeds(customAppliances)
            const decryptedData =
                localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
                JSON.parse(
                    decrypt(
                        localStorage?.getItem('sunfiUserSSEGettingStartedInfo'),
                    ),
                )
            if (!decryptedData) return
            else {
                const phoneNumber = removeCountryCode(decryptedData.phone)
                const emailAddress = decryptedData.email
                const typeOfContact = decryptedData.contactType
                const methodOfContact =
                    typeOfContact === 'email'
                        ? CONTACT_METHODS.EMAIL
                        : CONTACT_METHODS.PHONE
                setContactMethod(methodOfContact)
                setInputs(prev => ({
                    ...prev,
                    email: emailAddress ?? '',
                    phone: phoneNumber ?? '',
                }))
                handleInputSubmit(
                    typeOfContact,
                    typeOfContact === 'email' ? emailAddress : phoneNumber,
                    methodOfContact,
                )
            }
        }
        clearVisitCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        registerDropOffMutate,
        getStartedBtnLoading,
        viewAllSolutionsBtnLoading,
    } = useRegisterDropOff(
        {
            ...(contactMethod === CONTACT_METHODS.EMAIL
                ? {
                      email: inputs?.email,
                  }
                : {
                      phone_number: inputs?.phone,
                  }),
            ...(useType === '' ? {} : { customer_type: useType }),
            urgency: urgencyOptionsMap[useTypeFormInputs.urgency],
        },
        useType,
        useTypeFormAction,
    )

    //get returning consumer email
    const queryParams = new URLSearchParams(history?.location?.search)
    const consumerEmail = queryParams.get('email')
    // get returning consumer phone
    const consumerPhone = queryParams.get('phone')?.replace('234', '')

    const handleInputChange = event => {
        const { name, value } = event.target
        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        if (name === 'monthlyPayment') {
            setInputs(prev => ({
                ...prev,
                monthlyPayment:
                    value === '0'
                        ? ''
                        : numberRegex.test(value)
                        ? floatWithCommas(value)
                        : inputs.monthlyPayment,
            }))
        } else if (name === 'generatorSpend') {
            setInputs(prev => ({
                ...prev,
                generatorSpend: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.generatorSpend,
            }))
        } else if (name === 'email') {
            setInputs(prev => ({ ...prev, [name]: value.replaceAll(' ', '') }))
        } else if (name === 'phone') {
            setInputs(prev => ({
                ...prev,
                [name]: formatInputValue(name, value),
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }

        if (errors[name]) {
            delete errors[name]
        }

        if (name === 'email') {
            setEmailIsExistingChecked(false)
        }
        if (name === 'phone') {
            setPhoneIsExistingChecked(false)
        }
    }

    const handleChecked = () => {
        setChecked(!checked)
    }
    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const handleInputBlur = e => {
        const { name, value } = e.target
        userContactSchema
            .validate(
                { [name]: value },
                { context: { contact: name }, abortEarly: false },
            )
            .then(() => {
                handleInputSubmit(name, value)
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleInputSubmit = async (
        name,
        value,
        methodOfContact = contactMethod,
    ) => {
        const existingConsumerPayload =
            methodOfContact === CONTACT_METHODS.EMAIL
                ? { email: value }
                : { phone_number: formatPhoneNumber(value, countryCode) }

        if (
            !existingConsumerPayload['email'] &&
            methodOfContact === CONTACT_METHODS.EMAIL
        ) {
            return
        }
        if (
            existingConsumerPayload['phone_number']?.length < 5 &&
            methodOfContact === CONTACT_METHODS.PHONE
        ) {
            return
        }

        const field =
            methodOfContact === CONTACT_METHODS.EMAIL ? 'email' : 'phone number'

        const result = await validateUserIdMutation.mutateAsync(
            existingConsumerPayload,
        )
        const exists = result?.data?.data
        if (exists) {
            setErrors({
                ...errors,
                [name]: `This ${field} already exist, you can try Sign In`,
            })
        } else {
            delete errors[name]
        }
        setErrors(prevErrors => {
            delete prevErrors['checkExistingStatus']
            return { ...prevErrors }
        })

        if (field === 'email') {
            setEmailIsExistingChecked(true)
        } else {
            setPhoneIsExistingChecked(true)
        }
    }

    const validateInputs = useTypeRequired => {
        if (!emailIsExistingChecked && contactMethod === '1') {
            setErrors({
                ...errors,
                email: 'Please verify your email',
            })
            return
        } else if (!phoneIsExistingChecked && contactMethod === '2') {
            setErrors({
                ...errors,
                phone: 'Please verify phone number',
            })
            return
        }

        localStorage.setItem(
            'sunfiUserSSEGettingStartedInfo',
            encrypt(
                JSON.stringify({
                    ...(inputs?.email !== '' && {
                        email: inputs.email,
                    }),
                    ...(inputs?.phone !== '' && {
                        phone: formatPhoneNumber(inputs.phone),
                    }),
                    contactType:
                        contactMethod === CONTACT_METHODS.EMAIL
                            ? 'email'
                            : 'phone',
                    useType,
                }),
            ),
        )

        consumerHomeValidationV2Schema(
            contactMethod === CONTACT_METHODS.EMAIL ? 'email' : 'phone',
            useType,
            useTypeRequired,
            useTypeFormInputs.urgency,
            errors?.phone,
            errors?.email,
        )
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                registerDropOffMutate()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                if (
                    phoneIsExistingChecked &&
                    contactMethod === CONTACT_METHODS.PHONE
                ) {
                    setErrors(prev => ({ phone: prev.phone, ...errList }))
                } else if (
                    emailIsExistingChecked &&
                    contactMethod === CONTACT_METHODS.EMAIL
                ) {
                    setErrors(prev => ({ email: prev.email, ...errList }))
                } else setErrors(errList)
            })
    }

    const handleGetStarted = () => {
        setUseTypeFormAction('get-started')
        validateInputs(true)
    }

    const handleViewAllSystems = () => {
        setUseTypeFormAction('view-all-solutions')
        validateInputs(false)
    }

    const handleShowNext = () => {
        localStorage.setItem(
            'sunfiUserSSEGettingStartedInfo',
            encrypt(
                JSON.stringify({
                    email: inputs.email,
                    ...(inputs?.phone !== '' && {
                        phone: formatPhoneNumber(inputs.phone),
                    }),
                    contactType:
                        contactMethod === CONTACT_METHODS.EMAIL
                            ? 'email'
                            : 'phone',
                    energyNeeds,
                    knowsGenMonthlyCost: knowsGenMonthlyCost,
                }),
            ),
        )

        if (!emailIsExistingChecked && contactMethod === '1') {
            setErrors({
                ...errors,
                email: 'Please verify your email',
            })
            return
        } else if (!phoneIsExistingChecked && contactMethod === '2') {
            setErrors({
                ...errors,
                phone: 'Please verify phone number',
            })
            return
        }

        //  eslint-disable-next-line
        const emptyItems =
            energyNeeds.filter(
                item =>
                    item.name === '' ||
                    item.quantity === '' ||
                    item.backup_hours === '' ||
                    item.backup_hours_night === '' ||
                    (item.powerRating ?? item.power_rating) === '' ||
                    item.unitOfMeasurement === '',
            ) ?? []

        consumerHomeValidationSchema(
            contactMethod === CONTACT_METHODS.EMAIL ? 'email' : 'phone',
            showNext,
            knowsGenMonthlyCost,
        )
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                if (emptyItems?.length > 0) {
                    errorAlert(
                        'Please fill in at least one appliance',
                        isMobile ? 320 : 511,
                        110,
                    )

                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    })
                    return
                }
                setShowNext(true)
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
                errorAlert('You omitted a required field.')
            })
    }

    // make api call to return available systems
    function handleSubmit() {
        setErrorState('')

        consumerHomeValidationSchema(
            contactMethod === CONTACT_METHODS.EMAIL ? 'email' : 'phone',
            showNext,
            knowsGenMonthlyCost,
            generatorUse === 'yes' ? true : false,
            paymentModel,
            canMountSolar,
        )
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                getRecommendations()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
                errorAlert('You omitted a required field.')
            })
    }

    function getRecommendations() {
        setLoading(true)
        const reshapedEnergyNeeds = energyNeeds.map(item => {
            return {
                name: item.name,
                quantity: item.quantity,
                backup_hours: item.backup_hours,
                backup_hours_night: item.backup_hours_night,
                power_rating: item.powerRating ?? item.power_rating,
                unit_of_measurement: item.unitOfMeasurement,
            }
        })
        const generatorSpend =
            inputs.generatorSpend === ''
                ? 0
                : typeof inputs.generatorSpend === 'string'
                ? inputs.generatorSpend.replaceAll(',', '')
                : inputs.generatorSpend
        const payload = {
            can_mount_solar: canMountSolar,
            gets_required_grid_hours: parseInt(
                inputs.electricityPerDay.replace(/hours?/i, ''),
            ),
            feasible_repayment_tenure: paymentPlansMap[inputs.paymentPlan],
            feasible_monthly_payment:
                typeof inputs.monthlyPayment === 'string'
                    ? inputs.monthlyPayment.replaceAll(',', '')
                    : inputs.monthlyPayment,
            generator_maintenance_expense: generatorSpend,
            state_of_installation: inputs.location,
            user_id:
                contactMethod === CONTACT_METHODS.EMAIL
                    ? inputs.email
                    : formatPhoneNumber(inputs.phone),
            payment_model: paymentModel,
            solution_reason: reasonForSolarMap[inputs.reasonForSolar],
            appliances: reshapedEnergyNeeds,
        }

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseGetSolarRecommendations'].action,
            eventTrackers['sseGetSolarRecommendations'].label,
            eventTrackers['sseGetSolarRecommendations'].category,
            ['MP', 'GA'],
            'event',
            {
                event: payload,
            },
        )

        validateRequestInfoMutation.mutate(payload)
    }
    //implementation for returning consumers that have previously filled the conversational form but don't have any recomendation

    const getFormResponseMutation = useMutation({
        mutationFn: payload => getFormResponse(payload),
        onSuccess: data => {
            const inputData = data?.data?.data

            // if nothing is returned, this means the consumer has not filled the form before
            if (Object.keys(inputData).length === 0) {
                history.push('/consumer/get-started')
            } else {
                const selectedAppliances = data?.data?.data.appliances?.map(
                    appliance => ({
                        id: uuidv4(),
                        backup_hours: appliance.backup_hours,
                        backup_hours_night: appliance.backup_hours_night,
                        name: appliance.name,
                        quantity: appliance.quantity,
                        powerRating: appliance.power_rating,
                        unitOfMeasurement: appliance.unit_of_measurement,
                    }),
                )
                setEnergyNeeds(selectedAppliances)

                const gridHours = inputData?.gets_required_grid_hours

                const initialInputs = {
                    electricityPerDay: electricityPerDayDataMap[gridHours],
                    generatorSpend: inputData?.generator_maintenance_expense,
                    location: inputData?.state_of_installation,
                    monthlyPayment: inputData?.feasible_monthly_payment,
                    paymentPlan:
                        paymentPlansMapReverse[
                            inputData?.feasible_repayment_tenure
                        ]?.[0],
                    reasonForSolar:
                        reasonForSolarMapReverse[
                            inputData?.solution_reason
                        ]?.[0],
                    email: inputData?.user_id,
                    phone: consumerPhone?.trim(),
                }
                setInputs(initialInputs)
                setCanMountSolar(inputData?.can_mount_solar ? 'true' : 'false')
                setPaymentModel(
                    inputData?.payment_model === 'Subscription'
                        ? 'Subscription'
                        : 'Lease to Own',
                )
                if (consumerPhone) {
                    setContactMethod('2')
                }
                checkExisting()
            }
        },
        onError: error => {
            errorAlert(error?.response?.data, isMobile ? 320 : 511, 110)
        },
    })

    const checkExisting = async () => {
        const name = consumerEmail ? 'email' : 'phone'
        const payload = {
            ...(consumerEmail
                ? {
                      email: consumerEmail,
                  }
                : { phone_number: formatPhoneNumber(consumerPhone) }),
        }
        const result = await validateUserIdMutation.mutateAsync(payload)
        const exists = result?.data?.data
        if (exists) {
            setErrors({
                ...errors,
                [name]: `This ${name} already exist, you can try Sign In`,
            })
        } else {
            delete errors[name]
            consumerEmail
                ? setEmailIsExistingChecked(true)
                : setPhoneIsExistingChecked(true)
        }
    }
    return {
        loading,
        errors,
        setErrors,
        isExisting,
        inputs,
        setInputs,
        energyNeeds,
        setEnergyNeeds,
        checked,
        contactMethod,
        setContactMethod,
        emailIsExistingChecked,
        phoneIsExistingChecked,
        knowsGenMonthlyCost,
        setKnowsGenMonthlyCost,
        showNext,
        setShowNext,
        generatorUse,
        setGeneratorUse,
        paymentModel,
        setPaymentModel,
        canMountSolar,
        setCanMountSolar,
        handleInputChange,
        handleChecked,
        handleBlur,
        handleInputBlur,
        handleShowNext,
        handleSubmit,
        validateUserIdMutation,
        getFormResponseMutation,
        handleGetStarted,
        handleViewAllSystems,
        useType,
        setUseType,
        getStartedBtnLoading,
        viewAllSolutionsBtnLoading,
        isCustomBuild,
        customAppliances,
        useTypeFormInputs,
        setUseTypeFormInputs,
    }
}

import axios from '../../config/axios'

export const getRecommendationStatus = async payload => {
    const request = await axios.post(
        `/power-curve/recommend-solutions`,
        payload,
    )

    return request
}

export const getRecommendationStatusAuthenticatedUser = async (
    estimation_id,
    payload,
) => {
    const request = await axios.post(
        `/power-curve/auth/recommended-solutions/rerun/estimations/${estimation_id}`,
        payload,
    )

    return request
}

export const getRecommendation = async (
    userId,
    currentPage = 1,
    filtersPayload,
    priceSort = '',
    popularitySort = '',
) => {
    const request = await axios.post(
        `/power-curve/recommended-solutions?page_size=12&page=${currentPage}&min_initial_deposit=${
            filtersPayload?.min_initial_deposit || ''
        }&max_initial_deposit=${
            filtersPayload?.max_initial_deposit || ''
        }&min_monthly_repayment=${
            filtersPayload?.min_monthly_repayment || ''
        }&max_monthly_repayment=${
            filtersPayload?.max_monthly_repayment || ''
        }&payment_model=${
            filtersPayload?.payment_model || ''
        }&payment_plan_tenure=${
            filtersPayload?.payment_plan_tenure || ''
        }&price_sort=${priceSort}&popularity_sort=${popularitySort}`,
        userId,
    )

    return request
}

export const getRecommendationsForAuthUser = async (
    estimation_id,
    currentPage = 1,
    filtersPayload,
    priceSort = '',
    popularitySort = '',
) => {
    const request = await axios.get(
        `/power-curve/auth/recommended-solutions/estimations/${estimation_id}?page_size=12&page=${currentPage}&min_initial_deposit=${
            filtersPayload?.min_initial_deposit || ''
        }&max_initial_deposit=${
            filtersPayload?.max_initial_deposit || ''
        }&min_monthly_repayment=${
            filtersPayload?.min_monthly_repayment || ''
        }&max_monthly_repayment=${
            filtersPayload?.max_monthly_repayment || ''
        }&payment_model=${
            filtersPayload?.payment_model || ''
        }&payment_plan_tenure=${
            filtersPayload?.payment_plan_tenure || ''
        }&price_sort=${priceSort}&popularity_sort=${popularitySort}`,
    )
    return request
}

export const getFormResponse = async payload => {
    const request = await axios.post(`/power-curve/form-response`, payload)

    return request
}

export const validateExistingSSEConsumerApi = async inputs => {
    const request = await axios.post('/sse-auth/validate-existing-user', inputs)
    return request
}

export const requestManualRecommendation = async payload => {
    const request = await axios.post(
        '/power-curve/request-manual-recommendation',
        payload,
    )
    return request
}

export const registerDropOffApi = async payload => {
    const request = await axios.post(
        '/sse-consumers/drop-off/cnaf-registration',
        payload,
    )
    return request
}

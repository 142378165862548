import { useState } from 'react'
import PropTypes from 'prop-types'
import { InputFields, SelectField } from 'src/components/InputFields'
import Label from '../../_components/Label'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { otpSchema } from 'src/utils/validationSchema'
import Verify from 'src/components/GettingStarted/components/Verify'
import { isMobile } from 'src/utils/mediaQueries'
import { localGovtData, stateData } from 'src/utils/stateData'
import Toast from 'src/components/Toast'
import { handlePhoneNumberValidation } from 'src/utils/validatePhoneNumber'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import useConsumerKyc from '../../../hooks/useConsumerKyc'
import { useMutation } from 'react-query'
import { initiatePhoneVerify, validatePhoneOtp } from 'src/api/create-account'
import { errorHandler } from 'src/utils/errorHandler'
import { useAppContext } from 'src/appContext/AppContext'
import { decodeUserInfo } from 'src/utils/auth'
import FormSkeleton from '../../../_components/FormSkeleton'
import CaretCircle from '../../../../../../../assets/images/circle-left-white.svg'
import { appTracking } from 'src/utils/appTracker'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'

const ContactInfo = ({
    updateErrors,
    removeFromUpdateErrs,
    setCurrentTab,
    setShowVideoModal,
}) => {
    const [verificationModal, setVerificationModal] = useState(false)

    const [toastMessage, setToastMessage] = useState('')
    const [verifyField, setVerifyField] = useState('')
    const [phoneToastMssg, setPhoneToastMssg] = useState(
        `Verification code resent to your ${verifyField}`,
    )
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [showToastError, setShowToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const {
        fieldErrors,
        setFieldErrors,
        businessContactFormik,
        isCustomerApproved,
    } = useAppContext()
    const {
        values: businessContactValues,
        setFieldError,
        setFieldValue,
        setValues,
        touched,
        setFieldTouched,
        errors,
        handleChange,
        handleBlur,
    } = businessContactFormik
    const { refetch, isFetching } = useConsumerKyc('business', 1)
    const [resent, setResent] = useState(false)
    const [sentOptCount, setSentOptCount] = useState(0)

    const pageTitle =
        'KYC - Business Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const installationAddressConfirmed = businessContactValues.confirmAddress

    const countries = ['Nigeria']
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const shouldDisplayVideo =
        !isAdminUser && process.env.REACT_APP_FLAG_SHOW_HOW_TO_VIDEO === 'true'

    const closeModal = () => {
        setVerificationModal(false)
    }

    const initiatePhoneVerifyMutation = useMutation({
        mutationKey: ['initiatePhoneVerify'],
        mutationFn: payload => initiatePhoneVerify(payload),
        onSuccess: () => {
            if (sentOptCount > 0) {
                setResent(true)
            } else {
                if (verifyField === 'email') {
                    setPhoneToastMssg(
                        `Verification code sent to ${businessContactValues?.email}`,
                    )
                } else if (verifyField === 'phone') {
                    setPhoneToastMssg(
                        `Verification code sent to ${`+234******${businessContactValues?.phone.slice(
                            -4,
                        )}`}`,
                    )
                }
            }
            setSentOptCount(prev => prev + 1)
            setVerificationModal(true)

            const timeout = setTimeout(() => setResent(false), [2000])
            return () => clearTimeout(timeout)
        },
        onError: error => {
            setShowToastError(true)
            setPhoneToastMssg('')
            setErrorMessage(
                errorHandler(error?.response?.data) ||
                    'unable to send otp, please try again',
            )
        },
    })

    const handleResendOtp = () => {
        setErrorMessage('')
        setShowToastError(false)
        initiatePhoneVerifyMutation.mutate({
            phone_number: formatPhoneNumber(
                businessContactValues.phone,
                '+234',
            ),
        })
    }

    const validatePhoneOTPMutation = useMutation({
        mutationKey: ['validatePhoneOTP'],
        mutationFn: payload => validatePhoneOtp(payload),
        onSuccess: () => {
            refetch()
            closeModal()
            setToastMessage(
                `Your ${verifyField} has been verified successfully`,
            )
        },
        onError: error => {
            closeModal()
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })

    const handleSendOtp = () => {
        setShowToastError(false)
        setFieldErrors(preState => {
            return {
                ...preState,
                phone: '',
                phone_verified: '',
                email: '',
                email_verified: '',
            }
        })

        if (verifyField === 'email') {
            initiatePhoneVerifyMutation.mutate({
                email: businessContactValues.email,
            })
        } else if (verifyField === 'phone') {
            initiatePhoneVerifyMutation.mutate({
                phone_number: formatPhoneNumber(
                    businessContactValues.phone,
                    '+234',
                ),
            })
        }
    }

    // submit otp
    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                if (verifyField === 'email') {
                    validatePhoneOTPMutation.mutate({
                        email: businessContactValues.email,
                        code: otp.join(''),
                    })
                } else if (verifyField === 'phone') {
                    validatePhoneOTPMutation.mutate({
                        phone_number: formatPhoneNumber(
                            businessContactValues.phone,
                            '+234',
                        ),
                        code: otp.join(''),
                    })
                }
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_CONTACT_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    useOTPVerify({ otp, handleOtpSubmit })

    // function to handle name input and remove numbers
    const handleNameChange = e => {
        const { name, value } = e.target
        const newValue = formatInputValue(name, value)
        setFieldValue(name, newValue.trim())
        removeFromUpdateErrs(name)
    }

    return (
        <>
            {/* Modal start */}
            <SecondaryModal
                showModal={verificationModal}
                onCancel={() => closeModal()}
                modalWidth={'475px'}
                kycSecondaryButtonStopLoading
                title={`Verify your ${verifyField}`}
                v2
                content={
                    <div>
                        {phoneToastMssg.length > 1 && (
                            <>
                                <VerticalSpacer top="24px" />
                                <Toast
                                    message={phoneToastMssg}
                                    setToast={setPhoneToastMssg}
                                    messageType="success-secondary"
                                    closable={true}
                                />
                            </>
                        )}

                        <Verify
                            contact={verifyField}
                            otp={otp}
                            setOtp={setOtp}
                            otpError={otpError}
                            inputs={businessContactValues}
                            handleResendOtp={handleResendOtp}
                            showToastError={showToastError}
                            errorMessage={errorMessage}
                            resent={resent}
                            setResent={setResent}
                            disabled={validatePhoneOTPMutation.isLoading}
                        />
                    </div>
                }
                showFooter
                footerLeftContent="button"
                primaryBtnText={`Verify ${verifyField}`}
                handlePrimaryBtnClick={() => {
                    handleOtpSubmit()
                }}
                primaryBtnLoading={
                    initiatePhoneVerifyMutation.isLoading ||
                    validatePhoneOTPMutation.isLoading
                }
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                handleSecondaryBtnClick={() => closeModal()}
                secondaryBtnText="Cancel"
            />

            {/* Modal end */}

            <Toast
                message={toastMessage}
                messageType="success-secondary"
                setToast={setToastMessage}
                closable={true}
                position="fixed"
                left={isMobile ? '0%' : '12%'}
                top="13.5%"
                zIndex="5"
            />

            {showToastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                />
            )}
            {isFetching ? (
                <FormSkeleton />
            ) : (
                <>
                    {shouldDisplayVideo && (
                        <div
                            className="BusinessFormPageVideoMobile"
                            onClick={() => setShowVideoModal(true)}
                        >
                            <PlayIcon />
                            <p>Watch how to submit your info</p>
                        </div>
                    )}
                    <Label
                        title="Contact person details"
                        subTitle="Share a contact person to represent the business"
                        showHelpIcon
                        tooltipText={
                            'Type in your phone number for verification, and confirm your email address.'
                        }
                    />
                    <InputFields
                        name="firstName"
                        title="First Name"
                        handleChange={handleNameChange}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={businessContactValues?.firstName}
                        errorMessage={
                            updateErrors.firstName ||
                            (touched?.firstName && errors.firstName)
                        }
                        disabled={isAdminUser}
                    />
                    <InputFields
                        name="lastName"
                        title="Last Name"
                        handleChange={handleNameChange}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={businessContactValues?.lastName}
                        errorMessage={
                            updateErrors.lastName ||
                            (touched?.lastName && errors.lastName)
                        }
                        disabled={isAdminUser}
                    />
                    <div className="VerifyInputWrapper">
                        <SelectCountryCode
                            name="phone"
                            title="Phone Number"
                            handleChange={e => {
                                handleChange(e)
                                removeFromUpdateErrs('phone')
                            }}
                            onBlur={e => {
                                handleBlur(e)
                                handleTracking(e)
                            }}
                            inputValue={businessContactValues?.phone}
                            selectWidth="114px"
                            noMarginBottom
                            disabled={
                                businessContactValues?.phone_verified ||
                                isAdminUser
                            }
                            verified={businessContactValues?.phone_verified}
                            errorMessage={
                                updateErrors.phone ||
                                (touched.phone && errors?.phone
                                    ? errors?.phone
                                    : touched.phone &&
                                      !businessContactValues?.phone_verified &&
                                      businessContactValues.phone &&
                                      errors.phone_verified)
                            }
                        />
                        {!isAdminUser && (
                            <div className="VerifyCTA">
                                <button
                                    hidden={
                                        businessContactValues?.phone_verified ||
                                        isAdminUser
                                    }
                                    onClick={() => {
                                        setVerifyField('phone')
                                        handleSendOtp()
                                    }}
                                    disabled={
                                        handlePhoneNumberValidation(
                                            '',
                                            formatPhoneNumber(
                                                businessContactValues.phone,
                                                '+234',
                                            ),
                                        ) === false ||
                                        initiatePhoneVerifyMutation.isLoading ||
                                        businessContactValues?.phone_verified ||
                                        isAdminUser
                                    }
                                >
                                    {isMobile
                                        ? 'Verify'
                                        : 'Verify Phone Number'}
                                </button>
                            </div>
                        )}
                    </div>
                    <VerticalSpacer bottom="35px" />
                    <Label
                        title="Business email address"
                        subTitle="Share a contact person to represent the business"
                    />
                    <div className="VerifyInputWrapper">
                        <InputFields
                            name="email"
                            title="Your email address"
                            handleChange={e => {
                                handleChange(e)
                                removeFromUpdateErrs('email')
                            }}
                            onBlur={e => {
                                handleBlur(e)
                                handleTracking(e)
                            }}
                            value={businessContactValues?.email}
                            errorMessage={
                                (touched.email && errors?.email) ||
                                errors?.email_verified ||
                                updateErrors.email
                            }
                            disabled={
                                businessContactValues.email_verified ||
                                isAdminUser
                            }
                            verified={businessContactValues?.email_verified}
                        />
                        {!isAdminUser && (
                            <div className="VerifyCTA">
                                <button
                                    hidden={
                                        businessContactValues?.email_verified ||
                                        isAdminUser
                                    }
                                    onClick={() => {
                                        setVerifyField('email')
                                        handleSendOtp()
                                    }}
                                    disabled={
                                        initiatePhoneVerifyMutation.isLoading ||
                                        businessContactValues?.email_verified ||
                                        !businessContactValues?.email ||
                                        isAdminUser
                                    }
                                    style={{ width: '84px' }}
                                >
                                    {isMobile ? 'Verify' : 'Verify email'}
                                </button>
                            </div>
                        )}
                    </div>
                    <VerticalSpacer bottom="25px" />
                    <Label
                        title="Director’s Info"
                        subTitle="Provide us with your director’s name, phone number and email address"
                    />
                    <InputFields
                        name="directorsFirstName"
                        title="Director’s first name"
                        handleChange={handleNameChange}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={businessContactValues?.directorsFirstName}
                        errorMessage={
                            updateErrors.directorsFirstName ||
                            (touched.directorsFirstName &&
                                errors?.directorsFirstName)
                        }
                        disabled={isAdminUser}
                    />
                    <InputFields
                        name="directorsLastName"
                        title="Director’s surname/last name"
                        handleChange={handleNameChange}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={businessContactValues?.directorsLastName}
                        errorMessage={
                            updateErrors.directorsLastName ||
                            (touched.directorsLastName &&
                                errors?.directorsLastName)
                        }
                        disabled={isAdminUser}
                    />
                    <SelectCountryCode
                        name="directorsPhoneNumber"
                        title="Director’s phone number"
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('directorsPhoneNumber')
                        }}
                        selectWidth="114px"
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        inputValue={businessContactValues?.directorsPhoneNumber}
                        errorMessage={
                            updateErrors.directorsPhoneNumber ||
                            (touched?.directorsPhoneNumber &&
                                errors.directorsPhoneNumber)
                        }
                        disabled={isAdminUser || isCustomerApproved}
                    />
                    <InputFields
                        name="directorsEmail"
                        title="Director’s email address"
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('directorsEmail')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={businessContactValues.directorsEmail}
                        errorMessage={
                            updateErrors.directorsEmail ||
                            (touched?.directorsEmail && errors.directorsEmail)
                        }
                        disabled={isAdminUser || isCustomerApproved}
                    />
                    <Label
                        title="Your business contact address"
                        subTitle="Provide us with your contact address. This address does not need to be the same as the installation address"
                    />
                    <InputFields
                        name="businessAddress"
                        title="House no. and street address"
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('businessAddress')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        value={businessContactValues?.businessAddress}
                        errorMessage={
                            updateErrors.businessAddress ||
                            (touched?.businessAddress && errors.businessAddress)
                        }
                        disabled={isAdminUser || isCustomerApproved}
                    />
                    <div className="ContactInfo_DualSelectField-Wrapper">
                        <SelectField
                            floatLabelOnSelectedValue
                            name="businessCountry"
                            floatingLabel="Country"
                            initialOption="Country"
                            selectWidth="148px"
                            handleChange={(_, value) => {
                                setFieldValue('businessCountry', value)
                                removeFromUpdateErrs('businessCountry')
                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    `FILLED_${_?.toUpperCase()}`,
                                    'SMART_SHOPPING_EXPERIENCE_KYC',
                                    'KYC_CONTACT_INFO',
                                    ['GA', 'MP'],
                                    'event',
                                    { value },
                                )
                            }}
                            onBlur={() => {
                                setFieldTouched('businessCountry', true)
                                if (!businessContactValues.businessCountry)
                                    setFieldError(
                                        'businessCountry',
                                        errors.businessCountry,
                                    )
                            }}
                            values={countries.map(option => ({
                                value: option,
                            }))}
                            value={businessContactValues?.businessCountry}
                            showSearch
                            errorMessage={
                                updateErrors.businessCountry ||
                                (touched?.businessCountry &&
                                    errors.businessCountry)
                            }
                            dropdownPositionRelative
                            disabled={isAdminUser || isCustomerApproved}
                            withCheckBox
                            selectedValue={
                                businessContactValues?.businessCountry
                            }
                            currentSelected={
                                businessContactValues?.businessCountry
                            }
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="businessState"
                            floatingLabel="State"
                            initialOption="State"
                            selectWidth="148px"
                            handleChange={(_, value) => {
                                setFieldValue('businessState', value)
                                setFieldValue('businessCity', '')
                                removeFromUpdateErrs('businessState')
                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    `FILLED_${_?.toUpperCase()}`,
                                    'SMART_SHOPPING_EXPERIENCE_KYC',
                                    'KYC_CONTACT_INFO',
                                    ['GA', 'MP'],
                                    'event',
                                    { value },
                                )
                            }}
                            onBlur={() => {
                                setFieldTouched('businessState', true)
                                if (!businessContactValues.businessState)
                                    setFieldError(
                                        'businessState',
                                        errors.businessState,
                                    )
                            }}
                            values={stateData.map(option => ({
                                value: option,
                            }))}
                            value={businessContactValues?.businessState}
                            showSearch
                            errorMessage={
                                updateErrors.businessState ||
                                fieldErrors?.businessState
                            }
                            dropdownPositionRelative
                            disabled={isAdminUser || isCustomerApproved}
                            withCheckBox
                            selectedValue={businessContactValues?.businessState}
                            currentSelected={
                                businessContactValues?.businessState
                            }
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="businessCity"
                            floatingLabel="L.G.A."
                            initialOption="L.G.A."
                            selectWidth="140px"
                            handleChange={(_, value) => {
                                setFieldValue('businessCity', value)
                                removeFromUpdateErrs('businessCity')
                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    `FILLED_${_?.toUpperCase()}`,
                                    'SMART_SHOPPING_EXPERIENCE_KYC',
                                    'KYC_CONTACT_INFO',
                                    ['GA', 'MP'],
                                    'event',
                                    { value },
                                )
                            }}
                            onBlur={() => {
                                setFieldTouched('businessCity', true)
                                if (!businessContactValues.businessCity)
                                    setFieldError(
                                        'businessCity',
                                        errors.businessCity,
                                    )
                            }}
                            values={localGovtData[
                                businessContactValues?.businessState
                            ]?.map(option => ({
                                value: option,
                            }))}
                            value={businessContactValues?.businessCity}
                            showSearch
                            errorMessage={
                                updateErrors.businessCity ||
                                (touched?.businessCity && errors?.businessCity)
                            }
                            dropdownPositionRelative
                            disabled={isAdminUser || isCustomerApproved}
                            withCheckBox
                            selectedValue={businessContactValues?.businessCity}
                            currentSelected={
                                businessContactValues?.businessCity
                            }
                        />
                    </div>
                    <div className="ContactAddress_Confirmation-Wrapper">
                        <p>
                            Is your contact address the same as where you want
                            to install the system?
                        </p>
                        <div>
                            <button
                                onClick={() => {
                                    setValues(prev => ({
                                        ...prev,
                                        confirmAddress: 'yes',
                                        installationCity: prev.businessCity,
                                        installationAddress:
                                            prev.businessAddress,
                                        installationState: prev.businessState,
                                        installationCountry:
                                            prev.businessCountry,
                                    }))
                                }}
                                style={{
                                    color:
                                        installationAddressConfirmed === 'yes'
                                            ? '#ffffff'
                                            : '',
                                    background:
                                        installationAddressConfirmed === 'yes'
                                            ? '#011A3C'
                                            : '',
                                }}
                                className="ContactAddress_Confirmation-CTA"
                                disabled={isAdminUser || isCustomerApproved}
                            >
                                Yes, It is
                            </button>
                            <button
                                onClick={() => {
                                    setValues(prev => ({
                                        ...prev,
                                        confirmAddress: 'no',
                                        installationCity: '',
                                        installationAddress: '',
                                        installationState: '',
                                        installationCountry: '',
                                    }))
                                }}
                                style={{
                                    color:
                                        installationAddressConfirmed === 'no'
                                            ? '#ffffff'
                                            : '',
                                    background:
                                        installationAddressConfirmed === 'no'
                                            ? '#011A3C'
                                            : '',
                                }}
                                className="ContactAddress_Confirmation-CTA"
                                disabled={isAdminUser || isCustomerApproved}
                            >
                                No, Its not
                            </button>
                        </div>
                        {errors?.confirmAddress && (
                            <span className="fieldError">
                                {errors?.confirmAddress}
                            </span>
                        )}
                    </div>
                    {installationAddressConfirmed === 'no' && (
                        <>
                            <VerticalSpacer top="24px" />
                            <div>
                                <Label
                                    title="Your installation address"
                                    subTitle="Provide us with your installation address. This address does not need to be the same as the installation address"
                                />
                                <InputFields
                                    name="installationAddress"
                                    title="House no. and street address"
                                    handleChange={e => {
                                        handleChange(e)
                                        removeFromUpdateErrs(
                                            'installationAddress',
                                        )
                                    }}
                                    onBlur={e => {
                                        handleBlur(e)
                                        handleTracking(e)
                                    }}
                                    value={
                                        businessContactValues?.installationAddress
                                    }
                                    errorMessage={
                                        updateErrors.installationAddress ||
                                        (touched?.installationAddress &&
                                            errors.installationAddress)
                                    }
                                    disabled={isAdminUser || isCustomerApproved}
                                />
                                <div className="ContactInfo_DualSelectField-Wrapper">
                                    <SelectField
                                        floatLabelOnSelectedValue
                                        name="installationCountry"
                                        floatingLabel="Country"
                                        initialOption="Country"
                                        selectWidth="140px"
                                        handleChange={(_, value) => {
                                            setFieldValue(
                                                'installationCountry',
                                                value,
                                            )
                                            removeFromUpdateErrs(
                                                'installationCountry',
                                            )
                                            appTracking(
                                                pageUrlName,
                                                pageTracker,
                                                pageTitle,
                                                `FILLED_${_?.toUpperCase()}`,
                                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                                'KYC_CONTACT_INFO',
                                                ['GA', 'MP'],
                                                'event',
                                                { value },
                                            )
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(
                                                'installationCountry',
                                                true,
                                            )
                                            if (
                                                !businessContactValues.installationCountry
                                            )
                                                setFieldError(
                                                    'installationCountry',
                                                    errors.installationCountry,
                                                )
                                        }}
                                        values={countries.map(option => ({
                                            value: option,
                                        }))}
                                        value={
                                            businessContactValues?.installationCountry
                                        }
                                        showSearch
                                        errorMessage={
                                            updateErrors.installationCountry ||
                                            (touched?.installationCountry &&
                                                errors.installationCountry)
                                        }
                                        dropdownPositionRelative
                                        disabled={
                                            isAdminUser || isCustomerApproved
                                        }
                                        withCheckBox
                                        currentSelected={
                                            businessContactValues?.installationCountry
                                        }
                                        selectedValue={
                                            businessContactValues?.installationCountry
                                        }
                                    />
                                    <SelectField
                                        floatLabelOnSelectedValue
                                        name="installationState"
                                        floatingLabel="State"
                                        initialOption="State"
                                        selectWidth="140px"
                                        handleChange={(_, value) => {
                                            setFieldValue(
                                                'installationState',
                                                value,
                                            )
                                            setFieldValue(
                                                'installationCity',
                                                '',
                                            )
                                            removeFromUpdateErrs(
                                                'installationState',
                                            )
                                            appTracking(
                                                pageUrlName,
                                                pageTracker,
                                                pageTitle,
                                                `FILLED_${_?.toUpperCase()}`,
                                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                                'KYC_CONTACT_INFO',
                                                ['GA', 'MP'],
                                                'event',
                                                { value },
                                            )
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(
                                                'installationState',
                                                true,
                                            )
                                            if (
                                                !businessContactValues.installationState
                                            )
                                                setFieldError(
                                                    'installationState',
                                                    errors.installationState,
                                                )
                                        }}
                                        values={stateData.map(option => ({
                                            value: option,
                                        }))}
                                        value={
                                            businessContactValues?.installationState
                                        }
                                        showSearch
                                        errorMessage={
                                            updateErrors.installationState ||
                                            (touched?.installationState &&
                                                errors.installationState)
                                        }
                                        dropdownPositionRelative
                                        disabled={
                                            isAdminUser || isCustomerApproved
                                        }
                                        withCheckBox
                                        currentSelected={
                                            businessContactValues?.installationState
                                        }
                                        selectedValue={
                                            businessContactValues?.installationState
                                        }
                                    />
                                    <SelectField
                                        floatLabelOnSelectedValue
                                        name="installationCity"
                                        floatingLabel="L.G.A."
                                        initialOption="L.G.A."
                                        selectWidth="140px"
                                        handleChange={(_, value) => {
                                            setFieldValue(
                                                'installationCity',
                                                value,
                                            )
                                            removeFromUpdateErrs(
                                                'installationCity',
                                            )
                                            appTracking(
                                                pageUrlName,
                                                pageTracker,
                                                pageTitle,
                                                `FILLED_${_?.toUpperCase()}`,
                                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                                'KYC_CONTACT_INFO',
                                                ['GA', 'MP'],
                                                'event',
                                                { value },
                                            )
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(
                                                'installationCity',
                                                true,
                                            )
                                            if (
                                                !businessContactValues.installationCity
                                            )
                                                setFieldError(
                                                    'installationCity',
                                                    errors.installationCity,
                                                )
                                        }}
                                        values={localGovtData[
                                            businessContactValues
                                                ?.installationState
                                        ]?.map(option => ({
                                            value: option,
                                        }))}
                                        value={
                                            businessContactValues?.installationCity
                                        }
                                        showSearch
                                        errorMessage={
                                            updateErrors.installationCity ||
                                            (touched?.installationCity &&
                                                errors.installationCity)
                                        }
                                        dropdownPositionRelative
                                        disabled={
                                            isAdminUser || isCustomerApproved
                                        }
                                        withCheckBox
                                        currentSelected={
                                            businessContactValues?.installationCity
                                        }
                                        selectedValue={
                                            businessContactValues?.installationCity
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="formStep__emp_b">
                        <button
                            onClick={() => {
                                setCurrentTab('1')
                            }}
                        >
                            <span>
                                <img
                                    src={CaretCircle}
                                    alt="right icon"
                                    width={14}
                                    height={14}
                                />
                            </span>{' '}
                            Go back to business info
                        </button>
                    </div>
                </>
            )}
        </>
    )
}

ContactInfo.propTypes = {
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    setCurrentTab: PropTypes.func,
    setShowVideoModal: PropTypes.func,
}

export default ContactInfo

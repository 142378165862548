import { format, parseISO } from 'date-fns'

import { convertToSentenceCase } from '../../../utils/formatting'
import { currencyFormatter } from '../../../utils/currencyFormatter'

export const formatPackage = packages => {
    let formattedPackages = []
    packages.forEach(singlePackage => {
        const newObject = {
            name: convertToSentenceCase(singlePackage?.name),
            viewLink: `/app/products/view-package/${singlePackage?.id}`,
            editLink: `/app/products/edit-package/${singlePackage?.id}`,
            date:
                singlePackage?.created_at !== undefined &&
                format(parseISO(singlePackage?.created_at), 'dd MMM, yyyy'),
            components: singlePackage?.components?.length,
            amount: '₦' + currencyFormatter(singlePackage?.total),
            id: singlePackage?.id,
            isGlobal: singlePackage?.is_global,
            providerId: singlePackage?.provider_id,
            created_at: singlePackage?.created_at,
            energyCapacity:
                singlePackage?.energy_capacity?.value +
                singlePackage?.energy_capacity?.unit_of_measurement,
        }
        formattedPackages.push(newObject)
    })

    return formattedPackages
}

export const formatComponent = components => {
    let formattedComponents = []
    components.forEach(singleComponent => {
        const newObject = {
            name: convertToSentenceCase(singleComponent?.name),
            viewLink: `/app/products/view-component/${singleComponent?.id}`,
            editLink: `/app/products/edit-component/${singleComponent?.id}`,
            componentType: convertToSentenceCase(singleComponent?.type?.name),
            attributes: Object.keys(singleComponent?.attributes).length,
            isGlobal: singleComponent?.is_global,
            providerId: singleComponent?.provider_id,
            amount: '₦' + currencyFormatter(singleComponent?.cost),
            id: singleComponent?.id,
        }
        formattedComponents.push(newObject)
    })

    return formattedComponents
}

import 'src/pages/Admin/PaymentPlans/Listings/paymentplanslistings.scss'
import { Dropdown } from 'antd'
import ButtonComponent from 'src/components/Button'
import s from './profilesgroupslistings.module.scss'
import ProfilesListingsTable from './components/Table'
import SeoComponent from 'src/components/Seo'
import { ReactComponent as AddIcon } from 'src/assets/images/plus-icon-filled.svg'
import ListingsMenu from './components/Menu'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'
import useAdminProfiles from './hooks/useAdminProfilesGroups'
import BackNav from 'src/components/BackNav'
import CreateGroups from '../../components/create-groups'
import ActionModal from 'src/components/ActionModal'
import { actionModalTypes } from './data'
import ViewGroup from './components/ViewGroup'

const AdminProfilesGroups = () => {
    const {
        profilesGroups,
        profilesGroupsFetching,
        paginationBlock,
        goToCreateProfileGroup,
        searchTextInput,
        setSearchTextInput,
        handleFilterChange,
        addSearchTextToFilter,
        profilesGroupsRefetch,
        goToProfile,
        showCreateModal,
        showActionModal,
        setShowActionModal,
        actionModalHandler,
        showViewModal,
        setShowViewModal,
        profilesGroup,
        profilesGroupFetching,
        deleteGroup,
        closeActionModal,
        deleteProfileLoading,
        deleteProfileErrorMessage,
        deleteProfileToastError,
        closeViewGroup,
    } = useAdminProfiles()

    return (
        <>
            <SeoComponent title="SunFi | Admin | Consumer Profiles Groups" />

            {/* page */}
            <div className={s.wrapper}>
                <div className={s.titleAndMobileBtn}>
                    <BackNav
                        title="Back to consumer profiles"
                        v2
                        onClick={goToProfile}
                    />
                    <Dropdown
                        overlay={
                            <ListingsMenu
                                goToCreateProfileGroup={goToCreateProfileGroup}
                            />
                        }
                        placement="bottomCenter"
                        trigger={['click']}
                        className={s.dropdown}
                    >
                        <a onClick={e => e.preventDefault()}>
                            <AddIcon />
                        </a>
                    </Dropdown>
                </div>
                <div className={s.searchAndBtn}>
                    <div className={s.search}>
                        <input
                            placeholder="Search"
                            value={searchTextInput}
                            onChange={e => {
                                if (e.target.value === '') {
                                    setSearchTextInput('')
                                    handleFilterChange({ searchText: '' })
                                } else {
                                    const value = e.target.value
                                    setSearchTextInput(value)
                                }
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    addSearchTextToFilter()
                                    e.target.blur()
                                }
                            }}
                            onBlur={() => addSearchTextToFilter()}
                        />
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            role={'button'}
                            onClick={() => profilesGroupsRefetch()}
                        >
                            <path
                                d="M11.0207 10.0777L13.876 12.9323L12.9327 13.8757L10.078 11.0203C9.01586 11.8718 7.69471 12.3349 6.33337 12.333C3.02137 12.333 0.333374 9.64501 0.333374 6.33301C0.333374 3.02101 3.02137 0.333008 6.33337 0.333008C9.64537 0.333008 12.3334 3.02101 12.3334 6.33301C12.3353 7.69434 11.8722 9.0155 11.0207 10.0777ZM9.68337 9.58301C10.5294 8.71293 11.0019 7.54663 11 6.33301C11 3.75434 8.91137 1.66634 6.33337 1.66634C3.75471 1.66634 1.66671 3.75434 1.66671 6.33301C1.66671 8.91101 3.75471 10.9997 6.33337 10.9997C7.54699 11.0016 8.7133 10.5291 9.58337 9.68301L9.68337 9.58301Z"
                                fill={'#000000'}
                            />
                        </svg>
                    </div>
                    <div className={s.createBtnWrapper}>
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnWidth={'204.43px'}
                            btnHeight={'50px'}
                            btnTextColor={'#FFFFFF'}
                            handleClick={() => goToCreateProfileGroup()}
                        >
                            Create a new group
                        </ButtonComponent>
                    </div>
                </div>
                {profilesGroupsFetching ? (
                    <ListingsSkeletalCards total={0} columnCount={5} />
                ) : !profilesGroupsFetching && profilesGroups?.length > 0 ? (
                    <ProfilesListingsTable
                        profiles={profilesGroups}
                        setShowViewModal={setShowViewModal}
                    />
                ) : !profilesGroupsFetching && profilesGroups?.length === 0 ? (
                    <EmptyState title="No consumer profile groups found" />
                ) : null}
            </div>

            {profilesGroups?.length > 0 && !profilesGroupsFetching && (
                <div className="PaymentPlansListings_Pagination">
                    {paginationBlock()}
                </div>
            )}
            <CreateGroups
                showModal={showCreateModal}
                onClose={goToCreateProfileGroup}
                setShowActionModal={setShowActionModal}
                data={profilesGroup}
            />
            {/* showViewModal */}
            <ViewGroup
                showModal={showViewModal}
                data={profilesGroup}
                isLoading={profilesGroupFetching}
                onClose={closeViewGroup}
                showEditModal={goToCreateProfileGroup}
                deleteGroup={deleteGroup}
            />
            <ActionModal
                actionModalOpen={showActionModal.open}
                headerText={actionModalTypes(showActionModal.type).title}
                subTitle={actionModalTypes(showActionModal.type).subTitle}
                actionType={actionModalTypes(showActionModal.type).actionType}
                actionText={
                    actionModalTypes(showActionModal.type).primaryBtnText
                }
                noCancelBtn={actionModalTypes(showActionModal.type).noCancelBtn}
                noPrimaryBtn={
                    actionModalTypes(showActionModal.type).noPrimaryBtn
                }
                actionHandler={actionModalHandler}
                closeModal={closeActionModal}
                toastError={deleteProfileToastError}
                errorMessage={deleteProfileErrorMessage}
                loading={deleteProfileLoading}
                noBackLink
            />
        </>
    )
}

export default AdminProfilesGroups

import 'src/pages/Admin/Providers/Active/adminprovidersactive.scss'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import { moreActions } from '../data'

const MoreActionsMenu = ({
    storeActionAndOpenModal = () => {},
    handleUpdateProfile = () => {},
    isPublished = false,
}) => {
    return (
        <Menu>
            {moreActions(isPublished)?.map((action, i) => (
                <Menu.Item
                    key={i}
                    onClick={() => {
                        if (action?.action === 'edit-profile') {
                            handleUpdateProfile()
                        } else {
                            storeActionAndOpenModal(action.action)
                        }
                    }}
                    data-testid={`menu-item-${action.action}`}
                >
                    <span
                        className="AdminProvidersActiveDropdownLinks"
                        style={{
                            color:
                                action.action === 'delete-profile' ||
                                action.action ===
                                    'unpublish-profile-before-deleting'
                                    ? '#DA1E28'
                                    : '#233862',
                        }}
                        data-testid={`menu-item-text-${action.action}`}
                    >
                        {action.title}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    )
}

MoreActionsMenu.propTypes = {
    storeActionAndOpenModal: PropTypes.func.isRequired,
    handleUpdateProfile: PropTypes.func,
    isPublished: PropTypes.bool,
}

export default MoreActionsMenu

import { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { Tabs, Pagination, Drawer } from 'antd'
import { format, parseISO } from 'date-fns'
import { useGetProviders } from 'src/api/admin/providers/listings'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import { InlineLoader } from 'src/components/Loader'
import EmptyState from 'src/components/EmptyState'
import ButtonComponent, { AppButton } from 'src/components/Button'
import Toast from 'src/components/Toast'
import {
    DateField,
    NoFLoatingLabelInputFields,
} from 'src/components/InputFields'
import 'src/pages/Admin/Providers/Listings/providers-listing.scss'
import { errorHandler } from 'src/utils/errorHandler'
import SeoComponent from '../../../../components/Seo'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
} from 'src/utils/formatting'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const Listings = () => {
    const { TabPane } = Tabs
    const history = useHistory()
    const location = useLocation()
    const filterQuery = getSearchParamsFromUrl()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentTab, setCurrentTab] = useState('1')
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery.currentPage) || 1,
    )
    const [totalPages, setTotalPages] = useState(filterQuery.totalPages || null)
    const [filterMode, setFilterMode] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [inputs, setInputs] = useState({
        startDate: filterQuery.startDate || null,
        endDate: filterQuery.endDate || null,
    })
    const [showCalendar, setShowCalendar] = useState(0)
    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )
    const [searchText, setSearchText] = useState(filterQuery.companyName || '')
    const { isMobile } = useMediaQueries()

    const redirectTo = pageUrl => {
        history.push(pageUrl)
    }
    // redirect to the detailed page on the all providers tab
    const redirectLink = (status, type, id) => {
        let providerStatus = ''

        switch (status) {
            case 'APPROVED':
                providerStatus = 'active'
                break
            case 'UNAPPROVED':
                providerStatus = 'pending'
                break
            case 'SUSPENDED':
                providerStatus = 'deactivated'
                break
            default:
                providerStatus = ''
        }
        return history.push(
            `/admin/providers/${providerStatus}/${id}${
                type === 'AI' ? '?asi' : ''
            }`,
        )
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }
    const showDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const statusType =
        currentTab === '2'
            ? 'APPROVED'
            : currentTab === '3'
            ? 'UNAPPROVED'
            : currentTab === '4'
            ? 'SUSPENDED'
            : ''
    const { data, isLoading: isProvidersLoading } = useGetProviders(
        searchText.length > 0 ? 1 : currentPage,
        filterQuery.companyName || '',
        filterQuery.startDate || '',
        filterQuery.endDate || '',
        filterQuery.status?.toUpperCase() === 'ALL-PROVIDERS'
            ? ''
            : filterQuery.status,
        {
            onSuccess: res => {
                setTotalPages(res?.data?.total_pages)
                handleUpdateQueryParams({
                    totalPages: res?.data?.total_pages ?? 1,
                })
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    const allProviders = data?.data?.data
    const providerCount = data?.data?.count || ''

    const updatePage = page => {
        setCurrentPage(page)
        handleUpdateQueryParams({
            currentPage: page ?? 1,
        })
    }

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value.trim())
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleSearch = () => {
        handleUpdateQueryParams({
            companyName: searchText,
            currentPage: '1',
            totalPages: '1',
        })
        setCurrentPage(1)
        setFilterMode(searchText.length > 0)
    }

    const handleReset = () => {
        resetValues()
    }

    const resetValues = () => {
        setDrawerVisible(false)
        setSearchText('')
        setFilterMode(false)
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setCurrentEndDate(null)
        resetQueryParams()
    }
    const resetQueryParams = () => {
        history.push({
            pathname: location.pathname,
            search: '',
        })
    }
    const handleUpdateQueryParams = queryObj => {
        const currentQuery = queryString.parse(window.location.search)
        const updatedSearchParams = addQueryParamsToUrl(window.location.href, {
            ...currentQuery,
            ...queryObj,
        })
        history.push({
            pathname: location.pathname,
            search: updatedSearchParams,
        })
    }
    const handleAddFilter = () => {
        setCurrentPage(1)
        setSearchText('')
        setDrawerVisible(false)
        setFilterMode(true)
        history.push({
            pathname: location.pathname,
            search: '',
        })
        handleUpdateQueryParams({
            startDate: currentStartDate
                ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                : '',
            endDate: currentEndDate
                ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                : '',
            status:
                statusType.toLowerCase() === 'all-providers'
                    ? ''
                    : statusType?.toUpperCase() || '',
            currentPage: 1,
            totalPages: totalPages ?? 1,
        })
    }

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                current={currentPage}
                total={parseInt(totalPages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
            />
        )
    }

    // useeffect to make an api call when a another tab is clicked, also resets page number to 1
    useEffect(() => {
        if (Object.keys(filterQuery).length === 0) {
            history.push(`/admin/providers?status=`)
        } else {
            history.push({
                pathname: location.pathname,
                search: queryString.stringify(filterQuery),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab])

    const statusName = ['All Providers', 'Approved', 'Unapproved', 'Suspended']
    const statusNameObject = {
        Approved: 'active',
        Unapproved: 'pending',
        Suspended: 'deactivated',
    }

    useEffect(() => {
        const statusTab = {
            APPROVED: '2',
            UNAPPROVED: '3',
            SUSPENDED: '4',
            'ALL-PROVIDERS': '1',
        }
        if (filterQuery.status === '') {
            setCurrentTab('1')
        } else {
            setCurrentTab(statusTab[filterQuery.status])
        }
    }, [filterQuery.status])
    const handleTabChange = val => {
        const statusTab = {
            2: 'APPROVED',
            3: 'UNAPPROVED',
            4: 'SUSPENDED',
            1: 'ALL-PROVIDERS',
        }
        handleUpdateQueryParams({
            status: statusTab[val],
            currentPage: 1,
            totalPages: '1',
        })
        setCurrentTab(val)
        setCurrentPage(1)
        setTotalPages('1')
    }
    return (
        <>
            <SeoComponent
                title="Provider Management | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Admin-Provider-Management"
            />

            <div className="APLContainer">
                <div>
                    <p className="APLPageTitle">Provider Management</p>
                </div>
                <Drawer
                    placement="right"
                    width="367px"
                    onClose={showDrawer}
                    visible={drawerVisible}
                    closable={isMobile}
                >
                    <h2 className="ARLFilterHeader">Filter Providers</h2>
                    {/* filtering by date */}
                    <h4 className="ARLDateFilterHeader">
                        Filter By Created Date
                    </h4>
                    <div className="ARLDateFieldsWrapper">
                        <div className="ARLDateField">
                            <DateField
                                placeholder="Start Date"
                                name="startDate"
                                caretStyle="caretStyle"
                                dateValue={
                                    inputs.startDate === null
                                        ? null
                                        : new Date(inputs.startDate)
                                }
                                handleDateChange={(name, date) => {
                                    handleDateChange(name, date)
                                }}
                                openCalendar={showCalendar === 1 ? true : false}
                                closeCalendarHandler={() =>
                                    setShowCalendar(null)
                                }
                                openCalendarHandler={() => setShowCalendar(1)}
                                setHandleDateChange={name =>
                                    setHandleDateChange(name)
                                }
                            />
                        </div>

                        <div className="ARLDateField">
                            <DateField
                                placeholder="End Date"
                                name="endDate"
                                calendarClassName="APLEndDateFilter"
                                caretStyle="caretStyle"
                                dateValue={
                                    inputs.endDate === null
                                        ? null
                                        : new Date(inputs.endDate)
                                }
                                handleDateChange={(name, date) => {
                                    handleDateChange(name, date)
                                }}
                                openCalendar={showCalendar === 2 ? true : false}
                                closeCalendarHandler={() =>
                                    setShowCalendar(null)
                                }
                                openCalendarHandler={() => setShowCalendar(2)}
                                setHandleDateChange={name =>
                                    setHandleDateChange(name)
                                }
                            />
                        </div>
                    </div>

                    <div className="ARLFilterButtonWrapper">
                        <AppButton
                            btnBgColor="#E2EEFF"
                            btnTextColor="#004AAD"
                            btnWidth={160}
                            btnHeight={56}
                            handleClick={handleReset}
                        >
                            Reset Filter
                        </AppButton>
                        <AppButton
                            btnTextColor="#FFFFFF"
                            btnBgColor="#004AAD"
                            btnWidth={160}
                            btnHeight={56}
                            handleClick={handleAddFilter}
                            disabled={isProvidersLoading ? true : false}
                        >
                            {isProvidersLoading ? (
                                <InlineLoader />
                            ) : (
                                'Add Filter'
                            )}
                        </AppButton>
                    </div>
                </Drawer>
                <div className="APLWrapper">
                    <div className="APLSpaceBetween">
                        <div style={{ display: 'flex' }}>
                            <NoFLoatingLabelInputFields
                                TextPrefilled={searchText ? true : false}
                                borderRadius="10px"
                                inputWidth="350px"
                                inputHeight="45px"
                                value={searchText}
                                type="text"
                                inputBackground="#F6F6F6"
                                iconType="search"
                                prefilled
                                inputPlaceholder="Search"
                                style={{ paddingBottom: 7 }}
                                handleChange={handleSearchInputChange}
                                onIconClick={handleSearch}
                                onKeyUp={handleKeyPress}
                            />
                            <div
                                className="APLFilterBox"
                                style={{ border: '1px solid #e5e5e5' }}
                                onClick={() => setDrawerVisible(true)}
                            >
                                <svg
                                    width="12"
                                    height="15"
                                    viewBox="0 0 12 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginTop: 4 }}
                                >
                                    <path
                                        d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                        fill={'#1D2A30'}
                                    />
                                </svg>

                                <p className="APLFilterText">Filters</p>
                            </div>
                        </div>
                    </div>

                    <div className="APLTabWrapper">
                        {isProvidersLoading ? (
                            <ListingsSkeletalCards />
                        ) : (
                            <Tabs
                                activeKey={currentTab}
                                style={{ marginTop: 38 }}
                                onChange={values => {
                                    handleTabChange(values)
                                }}
                            >
                                {toastError && (
                                    <Toast
                                        messageType="error"
                                        message={errorMessage}
                                    />
                                )}
                                {statusName.map((status, index) => {
                                    return (
                                        <TabPane
                                            tab={`${status} ${
                                                index + 1 ===
                                                parseInt(currentTab, 10)
                                                    ? '(' + providerCount + ')'
                                                    : ''
                                            }`}
                                            key={index + 1}
                                        >
                                            <div className="APLTablewWrapper">
                                                {allProviders?.length > 0 ? (
                                                    <>
                                                        <div className="APLTableHeader">
                                                            <span>
                                                                Provider
                                                            </span>
                                                            <span>
                                                                Provider Type
                                                            </span>
                                                            <span>Users</span>
                                                            <span>
                                                                {status ===
                                                                'All Providers'
                                                                    ? 'Status'
                                                                    : 'Created Time'}
                                                            </span>
                                                            <span>Actions</span>
                                                        </div>
                                                        {allProviders?.map(
                                                            item => (
                                                                <div
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                    className="APLTableContent"
                                                                >
                                                                    <span>
                                                                        {
                                                                            item?.display_name
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        {
                                                                            item
                                                                                ?.user
                                                                                ?.provider
                                                                                ?.type
                                                                                ?.identifier
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        {
                                                                            item?.total_members
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                status ===
                                                                                    'All Providers' &&
                                                                                'capitalize',
                                                                        }}
                                                                    >
                                                                        {status ===
                                                                        'All Providers'
                                                                            ? item?.status.toLowerCase()
                                                                            : format(
                                                                                  parseISO(
                                                                                      item?.created_at,
                                                                                  ),
                                                                                  'dd MMM, yyyy, HH:mm',
                                                                              )}
                                                                    </span>
                                                                    <ButtonComponent
                                                                        type="XSmall"
                                                                        handleClick={
                                                                            status ===
                                                                            'All Providers'
                                                                                ? () =>
                                                                                      redirectLink(
                                                                                          item?.status,
                                                                                          item
                                                                                              ?.user
                                                                                              ?.provider
                                                                                              ?.type
                                                                                              ?.identifier,
                                                                                          item?.id,
                                                                                      )
                                                                                : () =>
                                                                                      redirectTo(
                                                                                          item
                                                                                              ?.user
                                                                                              ?.provider
                                                                                              ?.type
                                                                                              ?.identifier ===
                                                                                              'AI'
                                                                                              ? `/admin/providers/${statusNameObject[status]}/${item?.id}?asi`
                                                                                              : `/admin/providers/${statusNameObject[status]}/${item?.id}`,
                                                                                      )
                                                                        }
                                                                    >
                                                                        View
                                                                    </ButtonComponent>
                                                                </div>
                                                            ),
                                                        )}
                                                        {/* mobile table content  */}
                                                        <div className="APLTableMobile">
                                                            {allProviders?.map(
                                                                item => (
                                                                    <div
                                                                        key={
                                                                            item?.id
                                                                        }
                                                                        className="APLTableMobileContentWrapper"
                                                                    >
                                                                        <div className="APLMobileContent">
                                                                            <div className="APLMobileTopWrapper">
                                                                                <p
                                                                                    className="APLMobileContentTitle"
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'left',
                                                                                    }}
                                                                                >
                                                                                    PROVIDER
                                                                                </p>
                                                                                <span
                                                                                    className="APLMobileContentData"
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item?.display_name
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <ButtonComponent
                                                                                type="XSmall"
                                                                                handleClick={
                                                                                    status ===
                                                                                    'All Providers'
                                                                                        ? () =>
                                                                                              redirectLink(
                                                                                                  item?.status,
                                                                                                  item
                                                                                                      ?.user
                                                                                                      ?.provider
                                                                                                      ?.type
                                                                                                      ?.identifier,
                                                                                                  item?.id,
                                                                                              )
                                                                                        : () =>
                                                                                              redirectTo(
                                                                                                  item
                                                                                                      ?.user
                                                                                                      ?.provider
                                                                                                      ?.type
                                                                                                      ?.identifier ===
                                                                                                      'AI'
                                                                                                      ? `/admin/providers/${statusNameObject[status]}/${item?.id}?asi`
                                                                                                      : `/admin/providers/${statusNameObject[status]}/${item?.id}`,
                                                                                              )
                                                                                }
                                                                            >
                                                                                View
                                                                            </ButtonComponent>
                                                                        </div>
                                                                        <div className="APLMobileContent">
                                                                            <div>
                                                                                <p className="APLMobileContentTitle">
                                                                                    PROVIDER
                                                                                    TYPE
                                                                                </p>
                                                                                <span className="APLMobileContentData">
                                                                                    {
                                                                                        item
                                                                                            ?.user
                                                                                            ?.provider
                                                                                            ?.type
                                                                                            ?.identifier
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <p className="APLMobileContentTitle">
                                                                                    {status ===
                                                                                    'All Providers'
                                                                                        ? 'STATUS'
                                                                                        : 'USERS'}
                                                                                </p>
                                                                                <p
                                                                                    className="APLMobileContentData"
                                                                                    style={{
                                                                                        textTransform:
                                                                                            status ===
                                                                                                'All Providers' &&
                                                                                            'capitalize',
                                                                                        marginLeft:
                                                                                            status ===
                                                                                                'All Providers' &&
                                                                                            '15px',
                                                                                        textAlign:
                                                                                            'right',
                                                                                    }}
                                                                                >
                                                                                    {status ===
                                                                                    'All Providers'
                                                                                        ? item?.status.toLowerCase()
                                                                                        : item.total_members}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                        <div className="APLPaginationWrapper">
                                                            {paginationBlock()}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <EmptyState
                                                        type="noActivity"
                                                        title={
                                                            filterMode
                                                                ? 'There are no results, try again!'
                                                                : 'No provider'
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </TabPane>
                                    )
                                })}
                            </Tabs>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Listings

export const benefitData = [
    {
        id: '1',
        title: 'Keep the lights on.',
        description: {
            first: "Never be left in the dark again. Whether you're a remote worker, a family of five, or a business owner, SunFi allows you to rise above constant grid collapses and keep the lights on in your home and business",
            second: 'With our payment plans, you can enjoy the benefits of solar energy without stress.',
        },
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Keep+the+lights+on+image.png',
    },
    {
        id: '2',
        title: 'Power to Be',
        description: {
            first: 'With a constant and reliable power supply, you have the power to be anything you want. Embrace the limitless possibilities of a constant power supply: improved sleep, increased productivity, better meals, quality family time, and enhanced business growth.',
            second: 'We believe that uninterrupted power gives you the freedom to unlock your potential and live your best life.',
        },
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Power+to+be+image.png',
    },
    {
        id: '3',
        title: 'Protect your Wallet',
        description: {
            first: 'As fuel prices continue to skyrocket and electricity tariffs increase, protecting your wallet from these costs is important. SunFi offers an affordable and reliable solution that shields you from the financial burden of rising fuel and electricity expenses.',
            second: 'As fuel prices continue to skyrocket and electricity tariffs increase, protecting your wallet from these costs is important. SunFi offers an affordable and reliable solution that shields you from the financial burden of rising fuel and electricity expenses.',
        },
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Protect+your+wallet+image.png',
    },
]

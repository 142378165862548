/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { AppButton } from 'src/components/Button'
import EmptyState from 'src/components/EmptyState'
import './adminrepaymentschedule.scss'
import ActionModal from 'src/components/ActionModal'
import {
    synchronizePaystackApi,
    checkDebitStatusApi,
} from 'src/api/admin/payment'
import { getRepaymentScheduleApi } from 'src/api/admin/payment-plans/ssePlan'
import { errorHandler } from 'src/utils/errorHandler'
import {
    denyPermission,
    denyPermissionToolTipText,
} from 'src/utils/permissionFramework'
import { format } from 'date-fns'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'
import { TableSkeletalCards } from 'src/components/SkeletalCards'
import { isMobile } from 'src/utils/mediaQueries'
import { usePlanContext } from '../../hooks/planProvider'

const RepaymentScheduleTable = ({ data, page, triggerRefetch }) => {
    const {
        setOpenModal,
        setRepaymentDepositContent,
        setRepaymentScheduleContent,
        setModalDisplayValues,
        setInputs,
    } = usePlanContext()

    const [openSyncModal, setOpenSyncModal] = useState(false)
    const [syncSuccess, setSyncSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [toastError, setToastError] = useState(false)
    const [repaymentSchedule, setRepaymentSchedule] = useState([])
    const [displayToast, setDisplayToast] = useState(false)

    // get repayment schedule
    const {
        isFetching: repaymentScheduleFetching,
        refetch: repaymentScheduleRefetch,
    } = useQuery(
        'get-repayment-schedule',
        () => getRepaymentScheduleApi(data?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setRepaymentSchedule(data.data.data)
            },
            onError: error => {
                setDisplayToast(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        },
    )
    let invoiceId
    const handleCheckStatus = id => {
        invoiceId = id
        checkDebitStatusRefetch()
    }

    // check debit status
    const {
        isFetching: debitStatusFetching,
        refetch: checkDebitStatusRefetch,
    } = useQuery(
        'get-debit-status',
        () => checkDebitStatusApi(data?.id, invoiceId),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setDisplayToast(true)
                setErrorMessage(data?.data?.data?.status)
                setTimeout(() => setDisplayToast(false), [10000])
                if (data?.data?.data?.status_code === '00') {
                    repaymentScheduleRefetch()
                }
            },
            onError: error => {
                setDisplayToast(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    const dtcRequestPage = page === 'dtc-requests'

    useEffect(() => {
        data?.financing_start_date !== null &&
            dtcRequestPage &&
            repaymentScheduleRefetch()
    }, [
        data?.financing_start_date,
        dtcRequestPage,
        repaymentScheduleRefetch,
        triggerRefetch,
    ])

    const repayment_schedule =
        data?.financing_start_date !== null ? data?.repayment_schedule : []
    let tableData = dtcRequestPage
        ? repaymentSchedule
        : repayment_schedule || []
    let tableRows = []

    const localHandleModal = row => {
        setModalDisplayValues('Record Repayment')
        setRepaymentDepositContent([
            {
                keyID: 'RECORD_PAYMENT',
                label: 'Record Repayment',
                month: row.month,
                modalTitle: 'Record Repayment',
                modalSubTitle: 'Enter the details of repayment made',
                statusMsg: row.status === 'UNPAID' ? 'PENDING' : 'COMPLETED',
                updatedTime: row.status === 'UNPAID' ? null : row.date_paid,
                actionEvidence: row.invoice_url?.secure_url,
                methodOfPayment: dtcRequestPage
                    ? row?.invoice_data?.payment_method
                    : row.repayment_method,
                amountDue: row.amount_due,
                amountPaid: row.amount_paid,
                repaymentMethodType: data?.repayment_method_setup?.type,
            },
        ])
        setInputs(prevInputs => ({
            ...prevInputs,
            repaymentMonth: row.month,
        }))
        setOpenModal(true)
    }

    const handleRepaymentSchedule = () => {
        setModalDisplayValues('Repayment Schedule')
        setRepaymentScheduleContent([
            {
                keyID: 'REPAYMENT_SCHEDULE',
                label: 'Repayment Schedule',
                modalTitle: 'Start Repayment Schedule',
                modalSubTitle: 'Enter the details of repayment made',
                updatedTime: null,
                statusMsg: 'PENDING',
                planDuration:
                    data?.repayment_schedule_investment_meta_data
                        ?.number_of_payments,
            },
        ])
        setOpenModal(true)
    }

    tableData.forEach((row, index) => {
        // date countdown & color coding
        let statusBoxColor = ''
        let statusTextColor = ''
        let dateCountdown = ''
        let today = new Date()
        let date = row.due_date
        let mdy = date.split('/')
        let dueDate = new Date(mdy[2], mdy[0] - 1, mdy[1])
        let timeDiff = Math.ceil((dueDate - today) / (1000 * 3600 * 24))
        const processingStatus =
            dtcRequestPage && row?.invoice_data?.status === 'PROCESSING'

        const denyBtnAction = denyPermission(
            'admin',
            'approved_requests',
            row.status === 'UNPAID'
                ? 'repayment_schedule_monthly_payment:can_record'
                : 'repayment_schedule_monthly_payment:can_view',
        )
        dateCountdown = timeDiff
        dateCountdown = '\n'

        if (row.status === 'PAID') {
            dateCountdown = 'PAID'
            statusBoxColor = '#daf9ea'
            statusTextColor = '#32cc79'
        } else if (processingStatus) {
            dateCountdown = 'PROCESSING'
            statusBoxColor = '#fffaea'
            statusTextColor = '#ffc222'
        } else if (timeDiff == -1) {
            dateCountdown = 'DUE YESTERDAY'
            statusBoxColor = '#FFF1F1'
            statusTextColor = '#DA1E28'
        } else if (timeDiff < 0) {
            dateCountdown = +-1 * timeDiff + ' DAYS OVER'
            statusBoxColor = '#FFF1F1'
            statusTextColor = '#DA1E28'
        } else if (timeDiff == 0) {
            dateCountdown = 'DUE TODAY'
            statusBoxColor = '#fffaea'
            statusTextColor = '#ffc222'
        } else if (timeDiff == 1) {
            dateCountdown = 'DUE IN 1 DAY'
            statusBoxColor = '#fffaea'
            statusTextColor = '#ffc222'
        } else if (timeDiff > 1 && timeDiff < 30) {
            dateCountdown = 'DUE ' + timeDiff + ' DAYS'
            statusBoxColor = '#fffaea'
            statusTextColor = '#ffc222'
        } else {
            // Greater than 30 days
            dateCountdown = ''
            statusBoxColor = '#ffffff'
            statusTextColor = '#011A3C'
        }

        tableRows.push(
            <div className="ARSTGridItem" key={index}>
                {/* month and payment status */}
                <p className="MobileMonthText">Month</p>
                <div className="ARSTGridItemStatus">
                    {/* month of payment */}
                    <div className="ARSTGridItemStatus_TitleAndTag">
                        <p className="ARSTGridItemStatusText">
                            Month {row.month}
                        </p>
                        {/* payment status: paid / unpaid */}
                        <div
                            className="ARSTStatusTag"
                            style={{
                                backgroundColor: statusBoxColor,
                                marginLeft:
                                    isMobile && processingStatus && '-50px',
                            }}
                        >
                            <p style={{ color: statusTextColor }}>
                                {row.status === 'UNPAID'
                                    ? dateCountdown
                                    : 'PAID'}
                            </p>
                        </div>
                    </div>
                    <div className="ARSMobileButton">
                        <AppButton
                            btnBgColor="#E2EEFF"
                            btnTextColor="#004AAD"
                            btnTextColorOutline="var(--blue)"
                            btnOutlineColor="var(--purple-light)"
                            btnBgColorOutline="#E2EEFF"
                            type="outline"
                            btnWidth="100px"
                            btnHeight="37px"
                            fontSize="12px"
                            fontFamily="SF-Pro-Display-Medium"
                            handleClick={
                                denyBtnAction
                                    ? () => {}
                                    : processingStatus
                                    ? () =>
                                          handleCheckStatus(
                                              row?.invoice_data?.id,
                                          )
                                    : () => localHandleModal(row)
                            }
                            disabled={denyBtnAction ? true : false}
                            // toolTipText={
                            //     denyBtnAction
                            //         ? denyPermissionToolTipText
                            //         : null
                            // }
                        >
                            {processingStatus && debitStatusFetching ? (
                                <InlineLoader />
                            ) : processingStatus ? (
                                'Check Status'
                            ) : row.status === 'UNPAID' ? (
                                'Record'
                            ) : (
                                'View'
                            )}
                        </AppButton>
                    </div>
                </div>
                {/* payment due date */}
                <p className="ARSTGridItemStatusDate">
                    {format(new Date(row.due_date), 'd MMM yyyy')}
                </p>
                {/* amount due */}
                <p className="ARSTAmount">
                    ₦{' '}
                    {dtcRequestPage
                        ? row.amount_due
                        : data?.repayment_schedule_investment_meta_data
                              ?.monthly_payments}
                </p>
                {/* Mobile view */}
                <div className="ARSTContent">
                    <div>
                        <p className="ARSTMobileContents">Due Date</p>
                        <p className="ARSTGridItemStatusDateMobile">
                            {format(new Date(row.due_date), 'd MMM yyyy')}
                        </p>
                    </div>
                    {/* amount due */}
                    <div>
                        <p>Amount Due</p>
                        <p className="ARSTGridItemStatusDateMobile">
                            ₦{' '}
                            {dtcRequestPage
                                ? row.amount_due
                                : data?.repayment_schedule_investment_meta_data
                                      ?.monthly_payments}
                        </p>
                    </div>
                </div>
                <div className="ARSDesktopBtn">
                    <AppButton
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="100px"
                        btnHeight="37px"
                        fontSize="12px"
                        fontFamily="SF-Pro-Display-Medium"
                        handleClick={
                            denyBtnAction
                                ? () => {}
                                : processingStatus
                                ? () => handleCheckStatus(row?.invoice_data?.id)
                                : () => localHandleModal(row)
                        }
                        disabled={denyBtnAction ? true : false}
                        // toolTipText={
                        //     denyBtnAction
                        //         ? denyPermissionToolTipText
                        //         : null
                        // }
                    >
                        {processingStatus && debitStatusFetching ? (
                            <InlineLoader />
                        ) : processingStatus ? (
                            'Check Status'
                        ) : row.status === 'UNPAID' ? (
                            'Record'
                        ) : (
                            'View'
                        )}
                    </AppButton>
                </div>
            </div>,
        )
    })

    const { isFetching: syncPaystackFetching, refetch: syncPaystackRefetch } =
        useQuery('sync-paystack', () => synchronizePaystackApi(data?.id), {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setOpenSyncModal(false)
                setSyncSuccess(true)
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
                setToastError(true)
                const timer = setTimeout(() => setToastError(false), [4000])
                return () => clearTimeout(timer)
            },
        })

    const handleSyncSuccess = () => {
        setSyncSuccess(false)
        window.location.reload()
    }

    const denyStartBtnAction = denyPermission(
        'admin',
        'approved_requests',
        'repayment_schedule:can_upload',
    )

    return (
        <>
            <ActionModal
                actionModalOpen={syncSuccess}
                headerText="Repayment Synced"
                subTitle="The repayment has been synced with Paystack successfully"
                closeModal={handleSyncSuccess}
                actionHandler={handleSyncSuccess}
                actionType="success"
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
                closable={true}
            />
            <ActionModal
                actionModalOpen={openSyncModal}
                headerText="Sync Repayment with Paystack"
                subTitle="Do you want to sync the consumer repayment with paystack?"
                closeModal={() => setOpenSyncModal(false)}
                actionHandler={syncPaystackRefetch}
                onCancel={() => setOpenSyncModal(false)}
                actionType="warning"
                actionText="Sync"
                loading={syncPaystackFetching}
                actionHeaderClassName="ActionHeader"
                noBackLink
                closable={true}
                errorMessage={toastError ? errorMessage : ''}
                toastError={toastError}
            />
            <div className="ARSDesktopTable">
                {repaymentScheduleFetching ? (
                    <TableSkeletalCards total={3} />
                ) : JSON.stringify(tableData) === '[]' ? (
                    <>
                        <div>
                            <EmptyState
                                subTitle="You’ve not started a schedule yet"
                                buttonText="Start a schedule"
                                buttonClick={handleRepaymentSchedule}
                                buttonDisabled={denyStartBtnAction}
                                buttonToolTipText={
                                    denyStartBtnAction
                                        ? denyPermissionToolTipText
                                        : null
                                }
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {displayToast && (
                            <Toast
                                messageType="error"
                                message={errorMessage}
                                timeout={10000}
                            />
                        )}
                        {data?.repayment_method_setup?.type ===
                            'PAYSTACK:PLAN_SUBSCRIPTION' && (
                            <p
                                className="ARSTPLink"
                                onClick={() => setOpenSyncModal(true)}
                            >
                                Sync with Paystack
                            </p>
                        )}
                        <div className="ARSTableWrapper">
                            <div className="ARSTHead">
                                <p>Month</p>
                                <p>Due Date</p>
                                <p>Amount Due</p>
                                <p style={{ visibility: 'hidden' }}>Action</p>
                            </div>

                            <div className="ARSTGrid">{tableRows}</div>
                        </div>
                    </>
                )}
            </div>
            <div className="ARSMobileTable">
                {repaymentScheduleFetching ? (
                    <TableSkeletalCards total={3} />
                ) : JSON.stringify(tableData) === '[]' ? (
                    <>
                        <div>
                            <EmptyState
                                subTitle="You’ve not started a schedule yet"
                                buttonText="Start a schedule"
                                buttonClick={handleRepaymentSchedule}
                                buttonDisabled={denyStartBtnAction}
                                buttonToolTipText={
                                    denyStartBtnAction
                                        ? denyPermissionToolTipText
                                        : null
                                }
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {displayToast && (
                            <Toast
                                messageType="error"
                                message={errorMessage}
                                timeout={10000}
                            />
                        )}
                        <div className="ARSTableWrapper">
                            <div className="ARSTGrid">{tableRows}</div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default RepaymentScheduleTable

import '../adminprovidersactive.scss'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import { denyPermission } from 'src/utils/permissionFramework'
import { moreActions } from '../data'

const MoreActionsMenu = ({ providerIsAnAsi, handleMoreActions }) => {
    return (
        <Menu>
            {moreActions
                .filter(action => (providerIsAnAsi ? action.id > 1 : action))
                .map((action, i) => (
                    <Menu.Item
                        key={i}
                        onClick={() => handleMoreActions(action.label)}
                        disabled={denyPermission(
                            'admin',
                            'approved_providers',
                            'providers:can_assign_to_resource',
                        )}
                    >
                        <span className="AdminProvidersActiveDropdownLinks">
                            {action.action}
                        </span>
                    </Menu.Item>
                ))}
        </Menu>
    )
}

MoreActionsMenu.propTypes = {
    providerIsAnAsi: PropTypes.bool.isRequired,
    handleMoreActions: PropTypes.func.isRequired,
}

export default MoreActionsMenu

import { useState } from 'react'
import { Tabs, Pagination, Drawer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import CustomerListingTable from './table'
import { NoFLoatingLabelInputFields } from '../../../../components/InputFields'
import { AppButton } from '../../../../components/Button'
import { DateField } from '../../../../components/InputFields'
import { format } from 'date-fns'
import { ListingsSkeletalCards } from '../../../../components/SkeletalCards'
import { InlineLoader } from '../../../../components/Loader'
import EmptyState from '../../../../components/EmptyState'
import Toast from '../../../../components/Toast'
import BackNav from '../../../../components/BackNav'
import SelectPlan from './SelectPlan'
import { getCustomersApi } from '../../../../api/providers/customers'
import { useQuery } from 'react-query'
import { errorHandler } from '../../../../utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'
import styles from './providercustomerslisting.module.scss'
import { planApprovalStatus, planApplicationStatus } from './data'
import {
    updateProviderCustomersFilters,
    updatedProviderCustomersFilters,
} from 'src/redux/reducers/provider/customers/listing'
import { convertQueryParamsToObject } from 'src/utils/formatting'

const ProviderCustomerListings = () => {
    const defaultQueries = useSelector(updatedProviderCustomersFilters)
    const dispatch = useDispatch()
    const [drawerVisible, setDrawerVisible] = useState(false)

    const [inputs, setInputs] = useState({
        startDate: defaultQueries.created_at_start
            ? defaultQueries.created_at_start
            : null,
        endDate: defaultQueries.created_at_stop
            ? defaultQueries.created_at_stop
            : null,
    })

    const [currentStartDate, setCurrentStartDate] = useState(
        defaultQueries.created_at_start
            ? defaultQueries.created_at_start
            : inputs.startDate === null
            ? ''
            : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        defaultQueries.created_at_stop
            ? defaultQueries.created_at_stop
            : inputs.endDate === null
            ? ''
            : new Date(inputs.endDate),
    )
    const [showCalendar, setShowCalendar] = useState(0)
    const [filterMode, setFilterMode] = useState(
        defaultQueries?.filter_mode === 'true' ? true : false,
    )
    const [currentTab, setCurrentTab] = useState(defaultQueries?.current_tab)
    const [count, setCount] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [customers, setCustomers] = useState([])
    const [currentPage, setCurrentPage] = useState(
        Number(defaultQueries?.page) || 1,
    )
    const [searchText, setSearchText] = useState(defaultQueries?.name || '')
    const [filterLoading, setFilterLoading] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [planStatusValue, setPlanStatusValue] = useState(
        defaultQueries?.status ? [defaultQueries?.status] : [],
    )
    const [showPlanModal, setShowPlanModal] = useState(false)
    const [plans, setPlans] = useState([])
    const canUseNewOfferStatus =
        process.env.REACT_APP_FLAG_SHOW_NEW_OFFER_STATUS === 'true'

    const planStatus = canUseNewOfferStatus
        ? planApplicationStatus()
        : planApprovalStatus

    const updatePage = page => {
        setCurrentPage(page)
    }

    const handleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const handleAddFilter = () => {
        setFilterMode(true)
        setFilterLoading(true)
        customersRefetch()
        setDrawerVisible(false)
    }

    const { TabPane } = Tabs

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const customerType = [
        'All',
        'Active',
        ...(!canUseNewOfferStatus
            ? [
                  'Awaiting Approval',
                  'Approved',
                  'Awaiting Update',
                  'Re-estimate',
                  'Declined',
              ]
            : []),
    ]

    const approvalStatusMapping = {
        3: 'AWAITING_APPROVAL',
        4: 'APPROVED',
        5: 'AWAITING_UPDATE',
        6: 'DECLINED_AND_RE_ESTIMATE',
        7: 'DECLINED',
        8: 'CLOSED',
    }

    const { isFetching: customersFetching, refetch: customersRefetch } =
        useQuery(
            ['get-providers-customers', currentPage, currentTab],
            () =>
                getCustomersApi(
                    reset || searchText?.length > 0 ? 1 : currentPage,
                    reset ? '' : searchText,
                    parseInt(currentTab) === 2
                        ? 'active'
                        : reset
                        ? ''
                        : planStatusValue,
                    parseInt(currentTab) === 2
                        ? 'ACTIVE'
                        : reset
                        ? ''
                        : planStatusValue?.[0],
                    canUseNewOfferStatus,
                    approvalStatusMapping[parseInt(currentTab)],
                    reset
                        ? ''
                        : currentStartDate
                        ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                        : '',
                    reset
                        ? ''
                        : currentEndDate
                        ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                        : '',
                ),
            {
                enabled: true,
                retry: false,
                refetchOnMount: 'always',
                onSuccess: data => {
                    setCustomers(data?.data?.data)
                    setCount(data?.data?.count)
                    setTotalPages(data?.data?.total_pages)
                    if (filterMode) {
                        setFilterLoading(false)
                    }
                    const queries = data?.config?.url
                        .split('?')[1]
                        ?.replaceAll('=', ':')
                        ?.split('&')
                    dispatch(
                        updateProviderCustomersFilters(
                            convertQueryParamsToObject([
                                ...queries,
                                `current_tab:${currentTab}`,
                                `filter_mode:${filterMode}`,
                            ]),
                        ),
                    )
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                    if (filterMode) {
                        setFilterLoading(false)
                    }
                },
            },
        )

    let reset

    const resetValues = () => {
        reset = true
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setCurrentEndDate(null)
        setFilterMode(false)
        setSearchText('')
        setTotalPages(0)
        setPlanStatusValue([])
        setCustomers([])
    }

    const handleReset = () => {
        resetValues()
        customersRefetch()
        setDrawerVisible(false)
    }

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
        if (e.target.value.length === 0) {
            customersRefetch()
        }
    }

    const handleSearch = () => {
        setCustomers([])
        setTotalPages(0)
        customersRefetch()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const getSelectedProviderStatusValue = () => {
        const planStatusCheckboxes = document.querySelectorAll(
            `input[name="planStatus"]:checked`,
        )

        let planStatusValues = []

        planStatusCheckboxes.forEach(checkbox => {
            planStatusValues.push(checkbox.value)
        })

        setPlanStatusValue(planStatusValues)
    }

    const openPlanSelectModal = data => {
        setPlans(data)
        setShowPlanModal(true)
    }

    const closePlanSelectModal = () => {
        setShowPlanModal(false)
    }

    const refetchPlansTabSwitch = () => {
        setSearchText('')
        setTotalPages(0)
        setCustomers([])
        currentPage === 1 ? customersRefetch() : setCurrentPage(1)
    }

    return (
        <>
            <SelectPlan
                showModal={showPlanModal}
                onCancel={closePlanSelectModal}
                data={plans}
                tabId={parseInt(currentTab)}
            />
            <Drawer
                placement="right"
                width="367px"
                visible={drawerVisible}
                onClose={handleDrawer}
                closable={isMobile}
                headerStyle={{ border: 'none', marginLeft: 'auto' }}
            >
                <h2 className="ACLFilterHeader">Filter Customers</h2>

                {/*filer by date*/}
                <h4 className="ACLFilterByDateHeader">
                    Filter by Created Date
                </h4>
                <div className="ACLDateFieldsWrapper">
                    <div className="ACLDateField">
                        <DateField
                            placeholder="Start Date"
                            name="startDate"
                            caretStyle="caretStyle"
                            dateValue={
                                inputs.startDate === null
                                    ? null
                                    : new Date(inputs.startDate)
                            }
                            handleDateChange={(name, date) =>
                                handleDateChange(name, date)
                            }
                            openCalendar={showCalendar === 1 ? true : false}
                            closeCalendarHandler={() => setShowCalendar(null)}
                            openCalendarHandler={() => setShowCalendar(1)}
                            setHandleDateChange={name =>
                                setHandleDateChange(name)
                            }
                        />
                    </div>
                    <div className="ACLDateField">
                        <DateField
                            placeholder="End Date"
                            name="endDate"
                            caretStyle="caretStyle"
                            dateValue={
                                inputs.endDate === null
                                    ? null
                                    : new Date(inputs.endDate)
                            }
                            handleDateChange={(name, date) =>
                                handleDateChange(name, date)
                            }
                            openCalendar={showCalendar === 2 ? true : false}
                            closeCalendarHandler={() => setShowCalendar(null)}
                            openCalendarHandler={() => setShowCalendar(2)}
                            setHandleDateChange={name =>
                                setHandleDateChange(name)
                            }
                            placePopperRight
                        />
                    </div>
                </div>

                {/* filtering by plan status */}
                <h4 className="ARLStatementFilterHeader">Plan Status</h4>
                <div className="PCListingsStatementFilterWrapper">
                    {planStatus.map((data, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    getSelectedProviderStatusValue()
                                }}
                            >
                                <input
                                    type="radio"
                                    name="planStatus"
                                    id={data.id}
                                    value={data.id}
                                    style={{
                                        display: 'none',
                                    }}
                                />
                                <label
                                    htmlFor={data.id}
                                    className="ARLStatementLabel"
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                                            fill={
                                                planStatusValue.find(
                                                    query => query === data.id,
                                                )
                                                    ? 'var(--blue)'
                                                    : ' var(--grey)'
                                            }
                                        />
                                    </svg>
                                    <p>{data.label}</p>
                                </label>
                            </div>
                        )
                    })}
                </div>

                {/*    buttons*/}
                <div className="ACLFilterButtonWrapper">
                    <AppButton
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        fontFamily="SF-Pro-Display-Semibold"
                        fontSize="14px"
                        handleClick={handleReset}
                    >
                        Reset Filter
                    </AppButton>
                    <AppButton
                        btnTextColor="#FFFFFF"
                        btnBgColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        fontFamily="SF-Pro-Display-Semibold"
                        fontSize="14px"
                        handleClick={handleAddFilter}
                        disabled={filterLoading ? true : false}
                    >
                        {filterLoading ? <InlineLoader /> : 'Apply Filter'}
                    </AppButton>
                </div>
            </Drawer>
            {/*page content*/}
            <div className={styles.pageWrapper}>
                <div>
                    <h1 className="ACLTitle">
                        {filterMode ? (
                            <BackNav
                                title="Back to Customers"
                                onClick={handleReset}
                            />
                        ) : (
                            'Customers'
                        )}
                    </h1>
                </div>

                {/*    search & filter, view buttons*/}
                <div className="ACLTopWrapper">
                    <div className="ACLInputWrapper">
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            borderRadius="10px"
                            inputWidth="350px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            inputBackground="#F6F6F6"
                            iconType="search"
                            prefilled
                            inputPlaceholder="Search"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />
                        {currentTab === '1' && (
                            <div
                                className="AdminRequestListingFilterBox"
                                style={{ border: '1px solid #e5e5e5' }}
                                onClick={handleDrawer}
                            >
                                <svg
                                    width="12"
                                    height="15"
                                    viewBox="0 0 12 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginTop: 4 }}
                                >
                                    <path
                                        d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                        fill={'#1D2A30'}
                                    />
                                </svg>

                                <p className="AdminRequestListingFilterText">
                                    Filters
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {/*    tabpane and tables*/}
                <div className="ARLTableWrapper">
                    {filterMode && (
                        <h1 className="ACLFilterModeText">
                            Filter Results ({count})
                        </h1>
                    )}
                    {customersFetching ? (
                        <ListingsSkeletalCards
                            total={canUseNewOfferStatus ? 2 : 7}
                            columnCount={5}
                        />
                    ) : (
                        <Tabs
                            activeKey={currentTab}
                            style={{ marginTop: 38 }}
                            onChange={values => {
                                refetchPlansTabSwitch(values)
                                setCurrentTab(values)
                            }}
                        >
                            {toastError && (
                                <Toast
                                    messageType="error"
                                    message={errorMessage}
                                />
                            )}
                            {customerType.map((data, index) => {
                                return (
                                    <TabPane
                                        tab={`${data} ${
                                            index + 1 === parseInt(currentTab)
                                                ? '(' + count + ')'
                                                : ''
                                        }`}
                                        key={index + 1}
                                    >
                                        {customers.length > 0 ? (
                                            <CustomerListingTable
                                                tableData={customers}
                                                tabId={parseInt(currentTab)}
                                                openModal={openPlanSelectModal}
                                            />
                                        ) : (
                                            <div className="NoActivityStyle">
                                                <EmptyState
                                                    type="noActivity"
                                                    title="No recent Customers"
                                                    subTitle={
                                                        parseInt(currentTab) -
                                                            1 ===
                                                        0
                                                            ? ''
                                                            : `No ${
                                                                  parseInt(
                                                                      currentTab,
                                                                  ) > 1 &&
                                                                  customerType[
                                                                      parseInt(
                                                                          currentTab,
                                                                      ) - 1
                                                                  ]
                                                              } Customers yet`
                                                    }
                                                />
                                            </div>
                                        )}
                                    </TabPane>
                                )
                            })}
                        </Tabs>
                    )}
                </div>
                {customers.length > 0 && !customersFetching ? (
                    <div className="ACLPaginationWrapper">
                        <Pagination
                            defaultCurrent={currentPage}
                            total={parseInt(totalPages, 10)}
                            defaultPageSize={1}
                            showSizeChanger={false}
                            showTitle={false}
                            showLessItems
                            onChange={updatePage}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

export default ProviderCustomerListings

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import s from '../profilesdetails.module.scss'
import PropTypes from 'prop-types'

const ClusterInformation = ({ data }) => {
    const history = useHistory()
    return (
        <div className={s.cluster_info_wrapper}>
            <div>
                <h3>{data?.cluster_origination?.name}</h3>
                <button
                    onClick={() => {
                        history.push(
                            `/admin/consumer-profiles/edit-cluster-information/${data?.id}`,
                        )
                    }}
                >
                    Edit cluster information
                </button>
            </div>
            <div>
                <h3>Cluster website</h3>
                <a
                    href={data?.cluster_origination?.website}
                    rel="noreferrer"
                    target="_blank"
                >
                    {data?.cluster_origination?.website}
                </a>
            </div>
            <div>
                <h3>Cluster representatives</h3>
                <p>{data?.cluster_origination?.representative_count}</p>
            </div>
            <div>
                <h3>Cluster members</h3>
                <p>{data?.cluster_origination?.members_count}</p>
            </div>
        </div>
    )
}

ClusterInformation.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ClusterInformation

import { useDispatch } from 'react-redux'
import { useState } from 'react'
import SeoComponent from 'src/components/Seo'
import PackagesList from './components/PackagesList'
import './profilereview.scss'
import ActionModal from 'src/components/ActionModal'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'src/utils/mediaQueries'
import BackNav from 'src/components/BackNav'
import ProfileDetails from './components/ProfileDetails'
import { Skeleton } from 'antd'
import ButtonComponent from 'src/components/Button'
import Toast from 'src/components/Toast'
import EditPackageDrawer from '../Details/components/EditPackageDrawer'
import useAdminProfileDetails from '../Details/hooks/useAdminProfileDetails'
import useAdminProfileReview from './hooks/useAdminProfileReview'
import { actionModalValues } from '../Details/data'
import Representatives from '../NewProfile/ClusterInfo/components/Representatives'
import { updateConsumerProfile } from 'src/redux/reducers/admin/profiles/create-profile'

const AdminProfileReview = () => {
    const history = useHistory()
    const isPrivateProfile = history.location.pathname.includes('private')
    const [step, setStep] = useState(1)
    const dispatch = useDispatch()

    const {
        handleActionModal,
        actionHeaderMapping,
        actionSubtitleMapping,
        fecthingProfileDetails,
        confirmAction,
        profileDetails,
        updateEnergyProfile,
        handleConfirmation,
        errorMessage,
        toastError,
        actionType,
        showActionModal,
        setShowActionModal,
        energyNeeds,
        setEnergyNeeds,
        paginationBlock,
        profilePackages,
        profilePackagesFetching,
        setPage,
        //
        appliances,
        openEditDrawer,
        toggleEditDrawer,
        setAppliances,
        handlePackageUpdate,
        cluster: privateProfileCluster,
        setCluster: setPrivateProfileCluster,
        packageData,
        createProfileLoading,
        representatives,
        setRepresentatives,
        setNotifyRepresentatives,
        paymentType: privatePaymentType,
        setPaymentType: privateSetPaymentType,
        paymentTypeError: privatePaymentTypeError,
        setPaymentTypeError: privateSetPaymentTypeError,
    } = useAdminProfileReview()
    const {
        handleEditPackage,
        toggleEditPackageDrawer,
        showEditPackageDrawer,
        singlePackageEnergyInfo,
        singlePackageEnergyNeeds,
        setSinglePackageEnergyNeeds,
        handleSavePackage,
        handleRemovePackage,
        updateProfilePackageLoading,
        showActionModal: showPackageActionModal,
        actionModalHandler,
        closeActionModal,
        deleteProfileErrorMessage,
        deleteProfileLoading,
        updateProfileLoading,
        deleteProfileToastError,
        action,
        cluster,
        setCluster,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    } = useAdminProfileDetails({
        selectedPackages: profileDetails?.packages || [],
        setPage,
    })

    console.log('iiinfoo', profileDetails?.packages, singlePackageEnergyNeeds)

    const {
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalShowCancelBtn,
        actionModalActionText,
    } = actionModalValues(action)

    return (
        <>
            <SeoComponent title="SunFi | Admin | Consumer Profile Details Review" />
            {toastError && <Toast messageType="error" message={errorMessage} />}
            <div className="ReviewProfilePage">
                <BackNav
                    title={isMobile ? 'Back to Consumer Profiles' : 'Go Back'}
                    marginTop={isMobile ? 3 : -35}
                    marginBottom={isMobile ? 34 : 65}
                    v2
                    onClick={() => (step == 2 ? setStep(1) : history.goBack())}
                />
                <div className="ProfileActions">
                    <h4 className="ProfileTitle">
                        {step === 2
                            ? 'Review the cluster'
                            : 'Review this Consumer profile'}
                    </h4>
                    <div className="Actions">
                        {fecthingProfileDetails ? (
                            [...Array(2)].map(index => (
                                <Skeleton.Button
                                    key={index}
                                    style={{
                                        width: '134px',
                                        borderRadius: '7px',
                                        height: '25px',
                                    }}
                                    active
                                ></Skeleton.Button>
                            ))
                        ) : isPrivateProfile && step === 1 ? (
                            <ButtonComponent
                                btnBgColor={'#004AAD'}
                                btnWidth={'184.43px'}
                                btnHeight={'50px'}
                                btnTextColor={'#FFFFFF'}
                                handleClick={() => setStep(2)}
                            >
                                Review the cluster
                            </ButtonComponent>
                        ) : (
                            <>
                                <ButtonComponent
                                    btnBgColor="#E2EEFF"
                                    btnWidth="184.43px"
                                    btnHeight="50px"
                                    btnTextColor="#004AAD"
                                    type={'secondary'}
                                    handleClick={() =>
                                        handleActionModal('save')
                                    }
                                >
                                    Save and continue later
                                </ButtonComponent>

                                <ButtonComponent
                                    btnBgColor={'#004AAD'}
                                    btnWidth={'204.43px'}
                                    btnHeight={'50px'}
                                    btnTextColor={'#FFFFFF'}
                                    handleClick={() =>
                                        handleActionModal('publish')
                                    }
                                >
                                    Publish this consumer profile
                                </ButtonComponent>
                            </>
                        )}
                    </div>
                </div>
                {step === 2 ? (
                    <div className="ReviewProfileContainer">
                        <ProfileDetails
                            profileDetails={profileDetails}
                            fetchingProfile={fecthingProfileDetails}
                            energyNeeds={energyNeeds}
                            setEnergyNeeds={setEnergyNeeds}
                            handleActionModal={handleActionModal}
                            step={step}
                            isPrivateProfile={isPrivateProfile}
                        />
                        <div className="PackageList">
                            <p className="InfoHead">CLUSTER REPRESENTATIVES</p>
                            <Representatives
                                data={representatives}
                                addRepInfo={false}
                                setData={setRepresentatives}
                                onAfterUpdate={data => {
                                    dispatch(
                                        updateConsumerProfile({
                                            representatives: data,
                                        }),
                                    )
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="ReviewProfileContainer">
                        <ProfileDetails
                            profileDetails={profileDetails}
                            fetchingProfile={fecthingProfileDetails}
                            energyNeeds={energyNeeds}
                            setEnergyNeeds={setEnergyNeeds}
                            handleActionModal={handleActionModal}
                            step={step}
                            isPrivateProfile={isPrivateProfile}
                        />

                        <PackagesList
                            packages={
                                isPrivateProfile
                                    ? {
                                          data: profilePackages,
                                          total_pages: 1,
                                      }
                                    : profilePackages
                            }
                            isProfileDetailsLoading={
                                profilePackagesFetching ||
                                fecthingProfileDetails
                            }
                            handleEditPackage={
                                isPrivateProfile
                                    ? toggleEditDrawer
                                    : handleEditPackage
                            }
                            paginationBlock={paginationBlock}
                            isPrivateProfile={isPrivateProfile}
                        />
                    </div>
                )}
            </div>

            <ActionModal
                actionModalOpen={showActionModal}
                actionType={confirmAction ? 'success' : 'warning'}
                actionText="Confirm"
                headerText={actionHeaderMapping(actionType)}
                subTitle={actionSubtitleMapping(actionType)}
                closable
                noCancelBtn={confirmAction}
                onCancel={() => setShowActionModal(false)}
                closeModal={() => setShowActionModal(false)}
                actionHandler={handleConfirmation}
                loading={updateEnergyProfile.isLoading || createProfileLoading}
                showCheckBox={
                    isPrivateProfile &&
                    actionType === 'publish' &&
                    !confirmAction
                }
                checkBoxText={'Notify cluster representative of the new promo'}
                handleChecked={e => {
                    const { checked } = e
                    setNotifyRepresentatives(checked)
                }}
            />

            <ActionModal
                actionModalOpen={showPackageActionModal}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                noCancelBtn={!actionModalShowCancelBtn}
                actionText={actionModalActionText}
                actionHandler={actionModalHandler}
                closable
                onCancel={() => closeActionModal()}
                closeModal={() => closeActionModal()}
                toastError={toastError || deleteProfileToastError}
                errorMessage={errorMessage || deleteProfileErrorMessage}
                loading={updateProfileLoading || deleteProfileLoading}
                noBackLink
            />

            {isPrivateProfile ? (
                <EditPackageDrawer
                    visible={openEditDrawer}
                    toggleEditPackageDrawer={toggleEditDrawer}
                    singlePackageEnergyNeeds={appliances}
                    singlePackageEnergyInfo={packageData}
                    handleSavePackage={() => handlePackageUpdate(true)}
                    handleRemovePackage={handlePackageUpdate}
                    setSinglePackageEnergyNeeds={setAppliances}
                    singleCluster={privateProfileCluster}
                    setSingleCluster={setPrivateProfileCluster}
                    paymentPlanTypes={privatePaymentType}
                    setPaymentPlanTypes={privateSetPaymentType}
                    paymentPlanTypesError={privatePaymentTypeError}
                    setPaymentPlanTypesError={privateSetPaymentTypeError}
                />
            ) : (
                <EditPackageDrawer
                    visible={showEditPackageDrawer}
                    toggleEditPackageDrawer={toggleEditPackageDrawer}
                    singlePackageEnergyNeeds={singlePackageEnergyNeeds}
                    setSinglePackageEnergyNeeds={setSinglePackageEnergyNeeds}
                    singlePackageEnergyInfo={singlePackageEnergyInfo}
                    handleSavePackage={handleSavePackage}
                    savePackageLoading={updateProfilePackageLoading}
                    handleRemovePackage={handleRemovePackage}
                    singleCluster={cluster}
                    setSingleCluster={setCluster}
                    paymentPlanTypes={paymentType}
                    setPaymentPlanTypes={setPaymentType}
                    paymentPlanTypesError={paymentTypeError}
                    setPaymentPlanTypesError={setPaymentTypeError}
                />
            )}
        </>
    )
}

export default AdminProfileReview

import styles from './bankstatement.module.scss'
import PropTypes from 'prop-types'
import attachIcon from 'src/assets/images/attach-blue.svg'
import circleCheck from 'src/assets/images/circle-check-green.svg'
import closeIcon from 'src/assets/images/close-icon-grey.svg'
import { decodeUserInfo } from 'src/utils/auth'

const BankStatement = ({
    meta_data,
    handleRemoveFile,
    showPrimaryStatement,
    checkedStatementId,
    handleCheck,
    disableCheck,
}) => {
    const {
        source,
        mono_meta_data,
        okra_meta_data,
        document_meta_data,
        analysis_context,
    } = meta_data

    const bankSource = {
        OKRA: okra_meta_data,
        MONO: mono_meta_data,
        MANUAL_UPLOAD: document_meta_data,
    }
    const bankDetails = bankSource[source]
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const analysisStatus = {
        IN_PROGRESS: 'IN_REVIEW',
        RETRYING: 'IN_REVIEW',
        EMPTY: 'IN_REVIEW',
        FAILED: 'FAILED',
        INTIALIZATION_FAILED: 'FAILED',
        DONE: 'ANALYSED',
        INCOMPLETE: 'INCOMPLETE',
        INVALID: 'INVALID',
        SUCCESSFUL: 'SUCCESSFUL',   
    }
    const invalidStatement = document_meta_data?.invalid_statement === true
    const incompleteStatement = (analysis_context?.status === 'DONE' && analysis_context?.statement_complete === false)
    const successfulAnalysis = (analysis_context?.status === 'DONE' && analysis_context?.statement_complete === true)

    const bankAnalysisStatus = analysisStatus[invalidStatement ? 'INVALID' : incompleteStatement ? 'INCOMPLETE' :  successfulAnalysis ? 'SUCCESSFUL' :analysis_context?.status]

    const analysisStatusMapping = {
        ANALYSED: {
            bg: 'rgba(50, 204, 121, 0.15)',
            color: '#32CC79',
            text: 'ANALYSED',
        },
        SUCCESSFUL: {
            bg: 'rgba(50, 204, 121, 0.15)',
            color: '#32CC79',
            text: 'SUCCESSFUL',
        },
        IN_REVIEW: {
            bg: '#FFFAEC',
            color: '#E4AA0F',
            text: 'IN REVIEW',
        },
        FAILED: {
            bg: '#FFF1F1',
            color: '#DA1E28',
            text: 'FAILED',
        },
        INCOMPLETE: {
            bg: '#FFF1F1',
            color: '#DA1E28',
            text: 'INCOMPLETE',
        },
        INVALID: {
            bg: '#FFF1F1',
            color: '#DA1E28',
            text: 'INVALID',
        },
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.source}>
                <div style={{ display: 'flex' }}>
                    {source === 'MONO' ? (
                        <div className={styles.third_party}>
                            <img src={circleCheck} />
                            <p>Mono Connected</p>
                        </div>
                    ) : source === 'MANUAL_UPLOAD' ? (
                        <div className={styles.manual_upload}>
                            <img src={attachIcon} />
                            <p>Bank Statement</p>
                        </div>
                    ) : source === 'OKRA' ? (
                        <div className={styles.third_party}>
                            <img src={circleCheck} />
                            <p>Okra Connected</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className={styles.bankAnalysisStatusStyle}
                        style={{
                            background:
                                analysisStatusMapping[bankAnalysisStatus]?.bg,
                        }}
                    >
                        <span
                            style={{
                                color: analysisStatusMapping[bankAnalysisStatus]
                                    ?.color,
                            }}
                        >
                            {analysisStatusMapping[bankAnalysisStatus]?.text}
                        </span>
                    </div>
                </div>
                {source === 'MANUAL_UPLOAD' &&
                    !meta_data?.analysis_complete && (
                        <img
                            src={closeIcon}
                            alt={'close'}
                            role={'button'}
                            className={styles.remove}
                            onClick={() => {
                                if (isAdminUser) {
                                    return
                                } else {
                                    handleRemoveFile(
                                        'bank-statement',
                                        'bank statement',
                                        null,
                                        meta_data?.id,
                                    )
                                }
                            }}
                            style={{
                                cursor: isAdminUser ? 'not-allowed' : 'pointer',
                            }}
                        />
                    )}
            </div>
            <div className={styles.bank_details}>
                <div className={styles.account_number}>
                    <h3>Account Number</h3>
                    <p>{bankDetails?.account_number || '-'}</p>
                </div>
                <div className={styles.bank_name}>
                    <h3>Bank Name</h3>
                    <p>{bankDetails?.bank_name || '-'}</p>
                </div>
                <div className={styles.account_name}>
                    <h3>Account Name</h3>
                    <p>{bankDetails?.account_name || '-'}</p>
                </div>
            </div>
            {showPrimaryStatement ? (
                <button
                    className={styles.bank_primary_statement}
                    onClick={e => handleCheck(e, bankDetails?.bank_name)}
                    type="button"
                    disabled={disableCheck}
                    aria-label="Select primary bank statement"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                            fill={
                                checkedStatementId === meta_data.id
                                    ? 'var(--blue)'
                                    : 'var(--grey)'
                            }
                        />
                    </svg>
                    <p>Set as primary bank statement</p>
                </button>
            ) : null}
        </div>
    )
}
BankStatement.propTypes = {
    meta_data: PropTypes.object,
    handleRemoveFile: PropTypes.func,
    showPrimaryStatement: PropTypes.bool,
    checkedStatementId: PropTypes.string,
    handleCheck: PropTypes.func,
    disableCheck: PropTypes.bool,
}
export default BankStatement

/* eslint-disable react/prop-types */
import styles from './label.module.scss'

const { LabelBox } = styles
const Label = ({ title, fontSize, padding, handleClick }) => {
    return (
        <span
            style={{
                fontSize: fontSize ? fontSize : '9px',
                padding: padding ? padding : '8px 10px',
            }}
            className={LabelBox}
            onClick={handleClick}
        >
            {title}
        </span>
    )
}

export default Label

import ChatCTA from 'src/components/ChatCTA'
import Button from 'src/components/Button'
import { InlineLoader, InlineLoaderDark } from 'src/components/Loader'
import { footerPropTypes } from '../utils'

const Footer = ({
    footerLeftContent = 'chat',
    chatCTAText,
    secondaryBtnWidth,
    secondaryBtnHeight,
    handleSecondaryBtnClick,
    secondaryBtnDisabled,
    secondaryBtnLoading,
    secondaryBtnText,
    primaryBtnWidth,
    primaryBtnHeight,
    handlePrimaryBtnClick,
    primaryBtnDisabled,
    primaryBtnLoading,
    primaryBtnText,
}) => {
    return (
        <div className={'consumer-modal-base-footer'}>
            {footerLeftContent === 'chat' ? (
                <ChatCTA width={'fit-content'} text={chatCTAText} />
            ) : footerLeftContent === 'button' ? (
                <Button
                    btnBgColorOutline={'#FFFFFF'}
                    btnTextColorOutline={'#004AAD'}
                    btnWidth={secondaryBtnWidth || '160px'}
                    btnHeight={secondaryBtnHeight || '53px'}
                    handleClick={handleSecondaryBtnClick}
                    type="outline"
                    disabled={secondaryBtnDisabled}
                >
                    {secondaryBtnLoading ? (
                        <InlineLoaderDark />
                    ) : (
                        secondaryBtnText
                    )}
                </Button>
            ) : null}

            <Button
                btnBgColor={'#004AAD'}
                btnTextColor={'#FFFFFF'}
                btnWidth={primaryBtnWidth || '160px'}
                btnHeight={primaryBtnHeight || '53px'}
                handleClick={handlePrimaryBtnClick}
                marginLeft={'auto'}
                disabled={primaryBtnDisabled}
            >
                {primaryBtnLoading ? <InlineLoader /> : primaryBtnText}
            </Button>
        </div>
    )
}

Footer.propTypes = {
    ...footerPropTypes,
}

export default Footer

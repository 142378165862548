import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styles from './promolistings.module.scss'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import CSSEPromoListingsHeader from '../Components/CSSEPromoListingsHeader'
import CSSECTA from 'src/components/CSSECTA'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import { ReactComponent as FilterIcon } from 'src/assets/images/filter-blue.svg'
import { ReactComponent as MobileFilterIcon } from 'src/assets/images/mobile-filter.svg'
import { isMobile } from 'src/utils/mediaQueries'
import ConsumerFooter from 'src/components/ConsumerFooter'
import FilterRecommendations from '../../SolarSystemsListings/FilterRecommendations'
import { ReactComponent as ConsumerCloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import SeoComponent from 'src/components/Seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { activePromos, promoPackages } from '../PromoCarousel/data'
import { removeToken } from 'src/utils/auth'
import CookieNotification from 'src/components/CookieNotificiation'
import { encrypt } from 'src/utils/cryptography'

const CSSEPromoListings = () => {
    const {
        cssePromoListingsWrapper,
        csseSearchInputWrapper,
        csseFilterWrapperStyle,
        cssepromoListingsImg,
        cssePromoListingsStyle,
    } = styles

    const [searchText, setSearchText] = useState('')
    const [addFilter, setAddFilter] = useState(false)
    const [, setFiltersPayload] = useState({})
    const [showPackages, setShowPackages] = useState(false)

    const handleSearchInputChange = e => {
        setSearchText(e.target.value)
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }
    const handleSearch = () => {}

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleFilter = filterVariables => {
        setFiltersPayload(filterVariables)
        setAddFilter(false)
    }

    const history = useHistory()
    const isAuthenticatedUser =
        history.location?.state?.isAuthenticatedUser ?? false
    const estimation_id = history.location?.state?.estimation_id ?? null

    useEffect(() => {
        if (!isAuthenticatedUser) {
            removeToken()
        }
    }, [isAuthenticatedUser])

    useEffect(() => {
        if (activePromos.length === 0) {
            history.push('/')
        }
    }, [history])

    return (
        <>
            <SeoComponent
                title="Promos | SunFi | Simplifying and Scaling Clean Energy"
                tracker="PromoTracker"
            />
            {addFilter && (
                <FilterRecommendations
                    addFilter={addFilter}
                    setAddFilter={setAddFilter}
                    setShowPackages={setShowPackages}
                    handleFilter={handleFilter}
                    isLoading={false}
                />
            )}
            <ConsumerNavigation />
            <section>
                <CSSEPromoListingsHeader
                    title="Browse some available promos for you"
                    subTitle="Get access to some already available product, all you just need to do is choose the product that can best power what you want to power and choose a plan"
                />
                <section className={cssePromoListingsWrapper}>
                    <div className={csseSearchInputWrapper}>
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            border="0px"
                            inputWidth="350px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            inputBackground="#FFFFFF"
                            iconType="promo-search"
                            prefilled
                            inputPlaceholder="Search for a particular promo"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />

                        <>
                            {showPackages ? (
                                <div
                                    className={csseFilterWrapperStyle}
                                    onClick={() => {
                                        setShowPackages(false),
                                            setFiltersPayload({})
                                    }}
                                >
                                    <ConsumerCloseIcon
                                        fill={'#004AAD'}
                                        stroke={'#004AAD'}
                                    />
                                    <span>Filtered promos</span>
                                </div>
                            ) : (
                                <div
                                    className={csseFilterWrapperStyle}
                                    onClick={() => setAddFilter(true)}
                                >
                                    {isMobile ? (
                                        <MobileFilterIcon />
                                    ) : (
                                        <FilterIcon />
                                    )}
                                    <span>Filter promos</span>
                                </div>
                            )}
                        </>
                    </div>
                    <div className={cssePromoListingsStyle}>
                        {activePromos?.map((promo, i) => (
                            <div
                                key={i}
                                onClick={() => {
                                    const packages = promoPackages(
                                        promo.type === 'dlight'
                                            ? ''
                                            : promo.type,
                                    )
                                    history.push({
                                        pathname:
                                            packages.length > 1
                                                ? `/consumer/promos/${promo.type}/packages`
                                                : `/consumer/view-system/${packages[0]?.package_id}`,
                                        state: {
                                            page: 'promo',
                                            clusterCode:
                                                packages.length > 1
                                                    ? promo?.clusterCode
                                                    : packages[0]?.clusterCode,
                                            recommendedCombo: [],
                                            promoType: promo.type,
                                            isAuthenticatedUser:
                                                isAuthenticatedUser,
                                            action: 'select_new_system',
                                            estimation_id: estimation_id,
                                        },
                                    })
                                    localStorage.setItem(
                                        'promoDetails',
                                        encrypt(
                                            JSON.stringify({
                                                promoType: promo.type,
                                            }),
                                        ),
                                    )
                                }}
                            >
                                <LazyLoadImage
                                    src={
                                        isMobile
                                            ? promo?.mobileImgSrc
                                            : promo?.desktopImgSrc
                                    }
                                    alt={promo.title}
                                    className={cssepromoListingsImg}
                                />
                            </div>
                        ))}
                    </div>
                </section>
            </section>
            <CSSECTA />
            <CookieNotification />
            <ConsumerFooter />
        </>
    )
}

export default CSSEPromoListings

import { useHistory } from 'react-router-dom'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import ConsumerFooter from 'src/components/ConsumerFooter'
import PageNav from './components/PageNav'
import ProfileCard from './components/ProfileCard'
import BuildSystemCard from './components/BuildSystemCard'

import './profilelistings.scss'
import { useProfilelistings } from './hook'
import { SkeletalCards } from 'src/components/SkeletalCards'
import { Pagination, Skeleton } from 'antd'
import EmptyState from 'src/components/EmptyState'
import BuildYourSystemModal from './components/BuildSystemModal/BuildSystemModal'
import SeoComponent from 'src/components/Seo'
import { useEffect } from 'react'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'

const ProfileListings = () => {
    const history = useHistory()
    const isBusiness =
        history.location.pathname === '/consumer/business-profiles'

    const pageTitle = 'Profiles | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `ProfilesTracker`

    const {
        getEnergyProfilesData,
        getEnergyProfilesFetching,
        currentPage,
        setCurrentPage,
        showModal,
        setShowModal,
        toggleModal,
    } = useProfilelistings({ isBusiness })

    useEffect(() => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            consumerProfileEvents(isBusiness ? 'BP' : 'RP')['viewListing']
                .action,
            consumerProfileEvents(isBusiness ? 'BP' : 'RP').label,
            consumerProfileEvents(isBusiness ? 'BP' : 'RP').category,
            ['MP', 'GA'],
            'event',
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <SeoComponent
                title="Profiles | SunFi | Simplifying and Scaling Clean Energy"
                tracker="ProfilesTracker"
            />
            <ConsumerNavigation showUserBtn page="proileListings" />
            <div className="ProfileListingsWrapper">
                <div className="ProfileListingsContainer">
                    <PageNav isBusiness={isBusiness} loading={getEnergyProfilesFetching} />
                    {getEnergyProfilesFetching ? (
                        <Skeleton active loading paragraph={{ rows: 3 }} />
                    ) : !getEnergyProfilesFetching &&
                      getEnergyProfilesData?.data?.data?.length > 0 ? (
                        <div className="ProfileListingsTitle">
                            <h2>
                                To get started, select a profile that best
                                describes your energy needs
                            </h2>
                            <p>
                                We&apos;ve designed each profile to meet your
                                energy needs. Select any profile that matches
                                your needs.
                            </p>
                        </div>
                    ) : null}
                    {getEnergyProfilesFetching ? (
                        <SkeletalCards total={9} />
                    ) : !getEnergyProfilesFetching &&
                      getEnergyProfilesData?.data?.data?.length > 0 ? (
                        <div className="ProfileCardGrid">
                            {getEnergyProfilesData?.data?.data?.map(
                                (profile, index) => (
                                    <ProfileCard
                                        key={index}
                                        id={profile.id}
                                        title={profile.name}
                                        description={profile.description}
                                        specification={`${profile.energy_capacity.minimum_capacity?.value}${profile.energy_capacity.minimum_capacity?.unit_of_measurement} - ${profile.energy_capacity.maximum_capacity?.value}${profile.energy_capacity.maximum_capacity?.unit_of_measurement}`}
                                        appliances={
                                            profile.smallest_package.appliances
                                        }
                                        img={profile.image_url}
                                    />
                                ),
                            )}
                            <BuildSystemCard
                                showModal={showModal}
                                toggleModal={toggleModal}
                            />
                        </div>
                    ) : (
                        <div className="PLEmptyStateWrapper">
                            <EmptyState
                                type={'noActivity'}
                                showBrightIcon
                                title={' This Profile is Currently Empty'}
                                subTitle={
                                    'This profile does not contain any packages at the moment, you can also build your own system'
                                }
                                buttonVisible
                                buttonText={'Build your own system'}
                                buttonWidth="100%"
                                buttonClick={() => setShowModal(true)}
                            />
                        </div>
                    )}
                    {getEnergyProfilesData?.data?.total_pages > 1 && (
                        <div className="ACLPaginationWrapper">
                            <Pagination
                                defaultCurrent={1}
                                current={currentPage}
                                total={parseInt(
                                    getEnergyProfilesData?.data?.total_pages,
                                    10,
                                )}
                                defaultPageSize={1}
                                showSizeChanger={false}
                                showTitle={false}
                                showLessItems
                                onChange={v => setCurrentPage(v)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <BuildYourSystemModal
                showModal={showModal}
                toggleModal={toggleModal}
                setShowModal={setShowModal}
            />
            <ConsumerFooter />
        </>
    )
}

export default ProfileListings

import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
    createDebitMandateApi,
    getUserBankDetailsApi,
    printMandateApi,
    requestMandateActivationApi,
    validateMandateActivationApi,
    initializePaystackDirectDebitApi,
    initializeRecovaDirectDebitApi,
} from 'src/api/consumer/directDebit'
import { appTracking } from 'src/utils/appTracker'
import { errorHandler } from 'src/utils/errorHandler'

export const useDirectDebitRepaymentSetup = ({
    estimationId,
    showMandate,
    closeMandate,
    closeNonMandate,
    closeSubModal,
    recovaModal
}) => {
    const [bankDetails, setBankDetails] = useState({
        bankName: '',
        bankCode: '',
    })
    const [accountName, setAccountName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [directDebitBanks, setDirectDebitBanks] = useState([])
    const [errors, setErrors] = useState('')
    const [errorMessage, setErrorMessage] = useState({
        bankName: '',
        accountNumber: '',
    })
    const [toastError, setToastError] = useState(false)
    const [showVerification, setShowVerification] = useState(false)
    const [showBankCard, setShowBankCard] = useState(false)
    const [otp, setOtp] = useState(['', '', '', ''])
    const [card, setCard] = useState(['', '', '', ''])
    const [bankOtpResent, setBankOtpResent] = useState(false)
    const [authParams, setAuthParams] = useState({})
    const [remitaTransRef, setRemitaTransRef] = useState('')

    // retrieve user bank details
    const {
        isFetching: userBankDetailsFetching,
        refetch: userBankDetailsRefetch,
    } = useQuery(
        'fetch-user-bank-details',
        () => getUserBankDetailsApi(accountNumber, bankDetails.bankCode),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const userBankDetails = data?.data?.data
                setAccountName(userBankDetails.account_name)
                appTracking(
                    '',
                    '',
                    '',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_BANK_DETAILS_RETRIEVED_SUCCESSFULLY',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_BANK_DETAILS',
                    'REPAYMENT_SETUP',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        },
    )

    // create debit mandate
    const { isFetching: debitMandateFetching, refetch: debitMandateRefetch } =
        useQuery(
            'create-debit-mandate',
            () =>
                createDebitMandateApi(
                    estimationId,
                    bankDetails.bankCode,
                    accountNumber,
                    accountName,
                ),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    const otpSupported = data?.data?.data?.otp_supported
                    if (otpSupported) {
                        requestMandateActivationRefetch()
                    }
                    if (otpSupported === false) {
                        showMandate()
                    }
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error.response.data))
                },
            },
        )

    // print mandate
    const { isFetching: printMandateFetching, refetch: printMandateRefetch } =
        useQuery('print-mandate', () => printMandateApi(estimationId), {
            enabled: false,
            retry: false,
            onSuccess: data => {
                closeMandate()
                let newWindow = window.open('', 'response', 'resizable=yes')
                newWindow.document.write(data?.data)
                setAccountNumber('')
                setAccountName('')
                setBankDetails({
                    bankCode: '',
                    bankName: '',
                })
                appTracking(
                    '',
                    '',
                    '',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_PRINT_MANDATE_SUCCESSFUL',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_MANDATE',
                    'REPAYMENT_SETUP',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        })

    // request mandate activation
    const {
        isFetching: requestMandateActivationFetching,
        refetch: requestMandateActivationRefetch,
    } = useQuery(
        'request-mandate-activation',
        () => requestMandateActivationApi(estimationId),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const params = data?.data?.data
                setAuthParams(params?.authParams[0])
                setRemitaTransRef(params?.remitaTransRef)
                closeSubModal()
                setShowVerification(true)
                appTracking(
                    '',
                    '',
                    '',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_OTP_GENERATED_SUCCESSFULLY',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_OTP',
                    'REPAYMENT_SETUP',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        },
    )

    const authParamsPayload = [
        {
            param1: 'OTP',
            value: otp.join(',').replaceAll(',', ''),
        },
        {
            param2: 'CARD',
            value: card.join(',').replaceAll(',', ''),
        },
    ]

    // validate mandate activation
    const {
        isFetching: validateMandateActivationFetching,
        refetch: validateMandateActivationRefetch,
    } = useQuery(
        'validate-mandate-activation',
        () =>
            validateMandateActivationApi(
                estimationId,
                remitaTransRef,
                authParamsPayload,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeNonMandate()
                setShowBankCard(false)
                appTracking(
                    '',
                    '',
                    '',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_OTP_SUCCESSFUL',
                    'REPAYMENT_SETUP_DIRECT_DEBIT_OTP',
                    'REPAYMENT_SETUP',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
            onError: error => {
                setToastError(true)
                if (error.response?.data?.message) {
                    setErrorMessage(error.response?.data?.message)
                } else {
                    setErrorMessage(errorHandler(error.response.data))
                }
            },
        },
    )

    const handleSelectChange = (name, value) => {
        setErrors('')
        if (value === '') {
            setErrors('Select Bank Name')
        } else {
            const bankCode = directDebitBanks.filter(
                bank => bank.name === value,
            )
            setBankDetails({
                bankName: bankCode[0].name,
                bankCode: bankCode[0].code,
            })
            setAccountNumber('')
            setAccountName('')
        }
    }

    const handleInputChange = event => {
        if (event.target.value === '') {
            setErrors('Enter Account Number')
        }
        setAccountNumber(event.target.value)
    }

    const handleOnBlur = event => {
        setAccountNumber(event.target.value.trim())
    }

    useEffect(() => {
        if (bankDetails?.bankName?.length > 0 && accountNumber?.length === 10) {
            userBankDetailsRefetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountNumber?.length, bankDetails?.bankName?.length])

    //resend otp
    const handleResend = () => {
        requestMandateActivationRefetch()
        setBankOtpResent(true)
    }

    // initialize paystack direct debit
    const {
        isFetching: initializePaystackDirectdebitFetching,
        refetch: initializePaystackDirectDebitRefetch,
    } = useQuery(
        'initialize-paystack-direct-debit',
        () =>
            initializePaystackDirectDebitApi(
                estimationId,
                bankDetails.bankCode,
                accountNumber,
                accountName,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const redirectUrl = data?.data?.data?.redirect_url
                window.location.href = redirectUrl
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        },
    )

    // initialize recova direct debit
    const {
        isFetching: initializeRecovaDirectdebitFetching,
        refetch: initializeRecovaDirectDebitRefetch,
    } = useQuery(
        'initialize-recova-direct-debit',
        () =>
            initializeRecovaDirectDebitApi(
                estimationId,
                bankDetails.bankCode,
                accountNumber,
                accountName,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                recovaModal()
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        },
    )

    return {
        errors,
        errorMessage,
        toastError,
        showVerification,
        showBankCard,
        otp,
        setOtp,
        accountName,
        accountNumber,
        bankDetails,
        setAccountNumber,
        setAccountName,
        setBankDetails,
        authParams,
        userBankDetailsFetching,
        debitMandateFetching,
        directDebitBanks,
        setDirectDebitBanks,
        debitMandateRefetch,
        printMandateFetching,
        printMandateRefetch,
        requestMandateActivationFetching,
        validateMandateActivationFetching,
        handleSelectChange,
        handleInputChange,
        handleOnBlur,
        handleResend,
        setToastError,
        setErrorMessage,
        setShowVerification,
        setShowBankCard,
        card,
        setCard,
        bankOtpResent,
        setBankOtpResent,
        validateMandateActivationRefetch,
        initializePaystackDirectdebitFetching,
        initializePaystackDirectDebitRefetch,
        initializeRecovaDirectdebitFetching,
        initializeRecovaDirectDebitRefetch,
    }
}

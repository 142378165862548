import axios from '../../../config/axios'

export const getCustomersApi = async (
    page,
    searchText = '',
    status = '',
    applicationStatus = '',
    canUseNewOfferStatus = false,
    approvalStatus = '',
    startDate = '',
    endDate = '',
) => {
    const request = await axios.get(
        `/providers/consumers?page=${page}&${
            canUseNewOfferStatus
                ? `application_status=${applicationStatus}`
                : `status=${status}`
        }&approval_status=${approvalStatus}&name=${searchText}&created_at_start=${startDate}&created_at_stop=${endDate}`,
    )

    return request
}

export const getSingleCustomerApi = async id => {
    const request = await axios.get(`/estimations/${id}`)
    return request
}

export const getProviderCustomersApi = async (
    page,
    searchText = '',
    startDate = '',
    customerType = '',
) => {
    const request = await axios.get(
        `/estimations/mini/customers?page=${page}&name=${searchText}&start_date=${startDate}&customer_type=${customerType}`,
    )

    return request
}

/* eslint-disable react/prop-types */

import { Button } from 'antd'
import styles from './button.module.scss'
import CustomToolTip from '../CustomToolTip'
import { InlineLoader } from '../Loader'

const ButtonComponent = ({
    btnTextColor,
    type,
    children,
    btnBgColor,
    btnBgColorOutline,
    btnTextColorOutline,
    handleClick,
    btnOutlineColor,
    btnWidth,
    disabled,
    btnHeight,
    btnPadding,
    left,
    fontFamily,
    marginBottom,
    marginTop,
    marginLeft,
    fontSize,
    id,
    toolTipText,
    opacity,
    border,
    toolTipFontSize,
    toolTipPadding,
    toolTipPopupWidth,
    toolTipMarginLeft,
    arrowPositionCenter,
    showTooltip,
    dataTestId = undefined,
    loading,
    noFocus,
}) => {
    const {
        AppButton,
        ButtonSmall,
        AppButtonWhite,
        AppButtonOutline,
        AppButtonStylePrimary,
        AppButtonStyleSecondary,
        ButtonPrimaryXSmall,
        mainButtonNoBg,
        AppButtonOutlineNoShadow,
        AppButtonNoFocus,
    } = styles

    const BtnStyle = {
        width: `${
            btnWidth
                ? btnWidth
                : type === 'partner'
                ? '200px'
                : type === 'projects'
                ? '132px'
                : type === 'projectlist'
                ? '100px'
                : type === 'projectDetail'
                ? '327px'
                : type === 'responsive-nav'
                ? '241px'
                : type === 'overviewLargeScreenBtn'
                ? '135px'
                : type === 'overview-responsive-btn'
                ? '145px'
                : type === 'others'
                ? 'fit-content'
                : '160px'
        }`,
        height: `${
            type === 'projects'
                ? '38px'
                : type === 'overviewLargeScreenBtn'
                ? '40px'
                : type === 'projectlist'
                ? '38px'
                : type === 'projectslibrary'
                ? '37px'
                : btnHeight
                ? btnHeight
                : '50.5px'
        }`,
        border: `${
            btnTextColorOutline ? `1px solid ${btnTextColorOutline}` : '0px'
        }`,
        borderRadius: '30px',
        backgroundColor: btnBgColor,
        color: btnTextColor,
        fontSize:
            type === 'overviewLargeScreenBtn' ? '14px' : fontSize || '13px',
        fontWeight:
            type === 'overviewLargeScreenBtn' && 'overview-responsive-btn'
                ? '500'
                : '600',
        fontFamily: fontFamily ? fontFamily : 'SF-Pro-Display-Semibold',
        padding: btnPadding ? btnPadding : '0px',
        top: type === 'projectDetail' ? '20px' : 'none',
        opacity: disabled ? '0.7' : opacity,
        left: left && left,
        marginBottom: marginBottom || '0px',
        marginTop: marginTop,
        marginLeft: marginLeft,
    }

    //TODO: This is to remove fontweight from these two fonts. We can use a better way to redesign this for the component
    if (
        [
            'SF-Pro-Display-Semibold',
            'SF-Pro-Display-Bold',
            'SF-Pro-Display-Medium',
        ].includes(BtnStyle.fontFamily)
    ) {
        delete BtnStyle.fontWeight
    }

    const BtnOutlineStyle = {
        width: `${btnWidth ? btnWidth : '160px'}`,
        height: `${btnHeight ? btnHeight : '50px'}`,
        border: border
            ? border
            : `1px solid ${
                  btnOutlineColor ? btnOutlineColor : btnTextColorOutline
              }`,
        backgroundColor: `${btnBgColorOutline}`,
        borderRadius: '30px',
        color: btnTextColorOutline,
        fontSize: fontSize || '13px',
        fontFamily: 'SF-Pro-Display-Semibold',
        padding: btnPadding ? btnPadding : '10px',
        opacity: disabled && '0.7',
    }

    const BtnOutlineSmallStyle = {
        width: '100px',
        height: '50px',
        border: `1px solid ${btnTextColorOutline}`,
        backgroundColor: `${btnBgColorOutline}`,
        borderRadius: '30px',
        color: btnTextColorOutline,
        fontSize: '13px',
        fontFamily: 'SF-Pro-Display-Semibold',
    }

    const Btn = (
        <Button
            style={
                type === 'outline' || type === 'outline-no-shadow'
                    ? BtnOutlineStyle
                    : type === 'small'
                    ? BtnOutlineSmallStyle
                    : BtnStyle
            }
            className={`${
                type === 'primary'
                    ? AppButtonStylePrimary
                    : type === 'nohover'
                    ? ''
                    : type === 'secondary'
                    ? AppButtonStyleSecondary
                    : type === 'small'
                    ? ButtonSmall
                    : type === 'XSmall'
                    ? ButtonPrimaryXSmall
                    : type === 'MainButtonTwo'
                    ? mainButtonNoBg
                    : type !== 'outline' && type !== 'outline-no-shadow'
                    ? type === 'default'
                        ? AppButtonWhite
                        : noFocus
                        ? AppButtonNoFocus
                        : AppButton
                    : type === 'outline-no-shadow'
                    ? AppButtonOutlineNoShadow
                    : AppButtonOutline
            }`}
            onClick={handleClick}
            disabled={disabled}
            id={id}
            data-testid={dataTestId}
        >
            {loading ? <InlineLoader /> : children}
        </Button>
    )

    return toolTipText == null ? (
        Btn
    ) : (
        <CustomToolTip
            text={toolTipText}
            toolTipFontSize={toolTipFontSize}
            toolTipPadding={toolTipPadding}
            toolTipPopupWidth={toolTipPopupWidth}
            toolTipMarginLeft={toolTipMarginLeft}
            arrowPositionCenter={arrowPositionCenter}
            showTooltip={showTooltip}
        >
            {Btn}
        </CustomToolTip>
    )
}

export default ButtonComponent

export const AppButton = ({
    children,
    type,
    btnTextColor,
    btnBgColor,
    handleClick,
    btnWidth,
    btnHeight,
    opacityValue,
    display,
    fontSize,
    disabled,
    fontFamily,
    fontWeight,
    toolTipText,
    borderRadius,
    margin,
    toolTipFontSize,
    toolTipPadding,
    toolTipPopupWidth,
    toolTipMarginLeft,
    arrowPositionCenter,
    showTooltip,
}) => {
    const { AppButtonStyle, AppButtonStyleTwo } = styles

    const ButtonStyle = {
        borderRadius: borderRadius || '30px',
        color: btnTextColor,
        height: btnHeight,
        width: btnWidth,
        backgroundColor: btnBgColor,
        border: 0,
        fontFamily: fontFamily || 'SF-Pro-Display',
        opacity: opacityValue ? opacityValue : disabled ? '0.7' : '1',
        display: display && display,
        fontSize: fontSize,
        fontWeight,
        margin: margin,
    }

    //TODO: This is to remove fontweight from these two fonts. We can use a better way to redesign this for the component
    if (
        ['SF-Pro-Display-Semibold', 'SF-Pro-Display-Bold'].includes(
            ButtonStyle.fontFamily,
        )
    ) {
        delete ButtonStyle.fontWeight
    }

    const Btn = (
        <Button
            style={ButtonStyle}
            onClick={handleClick}
            className={
                type === 'buttonTwo' ? AppButtonStyleTwo : AppButtonStyle
            }
            disabled={disabled}
        >
            {children}
        </Button>
    )

    return toolTipText == null ? (
        Btn
    ) : (
        <CustomToolTip
            text={toolTipText}
            toolTipFontSize={toolTipFontSize}
            toolTipPadding={toolTipPadding}
            toolTipPopupWidth={toolTipPopupWidth}
            toolTipMarginLeft={toolTipMarginLeft}
            arrowPositionCenter={arrowPositionCenter}
            showTooltip={showTooltip}
        >
            {Btn}
        </CustomToolTip>
    )
}

export const ConsumerButton = ({
    children,
    btnTextColor,
    btnBgColor,
    handleClick,
    btnWidth,
    btnHeight,
    btnFontSize,
    disabled,
    marginTop,
    toolTipText,
    consumerPaymentPlanToolTip,
    toolTipWidth,
    toolTipFontSize,
    toolTipPadding,
    borderOutlineColor,
    borderRadius,
    hover,
    hoverNoFill,
    type,
    loading,
    secondaryHover,
    role,
    fontFamily,
    border,
    padding,
    primaryhHover,
}) => {
    const {
        ConsumerHoverStyle,
        ConsumerHoverNoFillStyle,
        ConsumerSecondaryHoverStyle,
        ConsumerPrimaryHoverStyle,
    } = styles

    const ButtonStyle = {
        borderRadius: borderRadius || '30px',
        color: btnTextColor,
        height: btnHeight,
        width: btnWidth,
        backgroundColor: btnBgColor,
        border: border
            ? border
            : borderOutlineColor
            ? `1px solid ${borderOutlineColor}`
            : '0px',
        fontFamily: fontFamily || 'SF-Pro-Display-Semibold',
        fontSize: btnFontSize,
        position: 'relative',
        marginTop: marginTop || '0px',
        opacity: disabled ? '0.7' : '1',
        display: type === 'iconBtn' && 'flex',
        alignItems: type === 'iconBtn' && 'center',
        justifyContent: type === 'iconBtn' && 'center',
        padding: padding || '',
    }

    const Btn = (
        <Button
            style={ButtonStyle}
            onClick={handleClick}
            disabled={disabled}
            className={
                hover
                    ? ConsumerHoverStyle
                    : secondaryHover
                    ? ConsumerSecondaryHoverStyle
                    : primaryhHover
                    ? ConsumerPrimaryHoverStyle
                    : hoverNoFill && ConsumerHoverNoFillStyle
            }
            role={`${role}-button`}
        >
            {loading ? <InlineLoader /> : children}
        </Button>
    )

    return toolTipText == null ? (
        Btn
    ) : (
        <CustomToolTip
            text={toolTipText}
            consumerPaymentPlanToolTip={consumerPaymentPlanToolTip}
            toolTipWidth={toolTipWidth}
            toolTipFontSize={toolTipFontSize}
            toolTipPadding={toolTipPadding}
        >
            {Btn}
        </CustomToolTip>
    )
}

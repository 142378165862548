import { useState } from 'react'
import { useQuery } from 'react-query'
import { errorHandler } from 'src/utils/errorHandler'
import { determineCreditDecisionRunStatus } from 'src/api/shoppingExperience/account-overview'
import { useAppContext } from 'src/appContext/AppContext'

const acceptedDecisions = ['YES', 'MAYBE']

export const useCreditDecisionStatus = (estimationId, errorAlert) => {
    const [showDecisionInProgressModal, setShowDecisionInProgressModal] =
        useState(false)
    const [upfrontDepositPaid, setUpfrontDepositPaid] = useState(false)
    const { setDisableCompletedKYCBtn } = useAppContext()

    useQuery(
        'determineCreditDecisionRunStatus',
        () => determineCreditDecisionRunStatus(estimationId),
        {
            enabled: estimationId !== undefined,
            onSuccess: res => {
                const data = res?.data?.data
                if (
                    data?.ran_credit_decision &&
                    (!data?.completed_bio_data_check ||
                        !acceptedDecisions.includes(data?.decision))
                ) {
                    setShowDecisionInProgressModal(true)
                    setDisableCompletedKYCBtn(true)
                }
                if (data?.paid_upfront_deposit) {
                    setUpfrontDepositPaid(true)
                }
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    return { showDecisionInProgressModal, upfrontDepositPaid }
}

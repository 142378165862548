import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
    getConsumerKYCInfoForAdmin,
    getKYCInfo,
} from 'src/api/shoppingExperience/account-overview'
import { decodeUserInfo } from 'src/utils/auth'
import { sameAddressOptions } from '../utils'
import { parsePhoneNumber } from 'libphonenumber-js'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'

const useFetchKYCInfo = (inputs, setInputs) => {
    const [userType, setUserType] = useState('')
    const [kycData, setKycData] = useState({})
    const userInfo = decodeUserInfo()
    const { id } = useParams()
    const { errorAlert } = useCustomToast()

    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const { isFetching, refetch } = useQuery(
        'fetch-outright-sale-kyc',
        () => (isAdminUser ? getConsumerKYCInfoForAdmin(id) : getKYCInfo()),
        {
            enabled: true,
            retry: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data
                const type = data?.consumer?.customer_type

                setKycData(data)
                setUserType(type)
                let generalInfo = {
                    firstName:
                        data?.consumer?.user?.first_name ?? inputs?.firstName,
                    lastName:
                        data?.consumer?.user?.last_name ?? inputs?.lastName,
                    phone: data?.consumer?.user?.phone_number
                        ? parsePhoneNumber(
                              data?.consumer?.user?.phone_number || '',
                          )?.nationalNumber
                        : inputs?.phone,
                    phoneVerified: data?.consumer?.user?.phone_verified,
                    email: data?.consumer?.user?.email ?? inputs?.email,
                    emailVerified: data?.consumer?.user?.email_verified,
                    contactAddress:
                        data?.consumer?.user?.street_address ??
                        inputs?.contactAddress,
                    contactState:
                        data?.consumer?.user?.state_of_residence ??
                        inputs?.contactState,
                    contactCity:
                        data?.consumer?.user?.city ?? inputs?.contactCity,
                    contactCountry:
                        data?.consumer?.user?.country ?? inputs?.contactCountry,
                    sameAddress:
                        data?.consumer?.user?.street_address ===
                            data?.consumer?.installation_details
                                ?.street_address &&
                        data?.consumer?.user?.state_of_residence ===
                            data?.consumer?.installation_details?.state &&
                        data?.consumer?.user?.city ===
                            data?.consumer?.installation_details?.city
                            ? sameAddressOptions[0]
                            : data?.consumer?.user?.street_address !==
                                  data?.consumer?.installation_details
                                      ?.street_address &&
                              data?.consumer?.installation_details
                                  ?.street_address?.length > 0 &&
                              data?.consumer?.installation_details?.state
                                  ?.length > 0 &&
                              data?.consumer?.installation_details?.city
                                  ?.length > 0
                            ? sameAddressOptions[1]
                            : sameAddressOptions[0],
                    installationAddress:
                        data?.consumer?.installation_details?.street_address ??
                        inputs?.installationAddress,
                    installationState:
                        data?.consumer?.installation_details?.state ??
                        inputs?.installationState,
                    installationCity:
                        data?.consumer?.installation_details?.city ??
                        inputs?.installationCity,
                    installationCountry:
                        data?.consumer?.installation_details?.country ??
                        inputs.installationCountry,
                }

                let businessInfo = {
                    businessName:
                        data?.consumer?.business?.business_name ??
                        inputs?.businessName,
                    businessType:
                        data?.consumer?.business?.business_type ??
                        inputs?.businessType,
                    businessIndustry:
                        data?.consumer?.business?.business_industry ??
                        inputs?.businessIndustry,
                }

                setInputs(prev => ({
                    ...prev,
                    ...generalInfo,
                }))

                if (type === 'BUSINESS') {
                    setInputs(prev => ({
                        ...prev,
                        ...businessInfo,
                    }))
                }
            },
            onError: error => {
                errorAlert(
                    errorHandler(
                        error?.response?.data ||
                            'An error occurred, please try again later.',
                    ),
                )
            },
        },
    )

    return {
        kycData,
        kycRefetch: refetch,
        userType,
        kycInfoFetching: isFetching,
    }
}

export default useFetchKYCInfo

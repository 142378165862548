import residenceIcon from 'src/assets/images/residence-icon.svg'
import businessIcon from 'src/assets/images/business-icon.svg'

export const useTypeDetails = [
    {
        icon: residenceIcon,
        title: 'For your home or stalls/shops',
        description:
            'Suitable for remote workers, shop owners, residential homes and apartments.',
        value: 'RESIDENTIAL',
    },
    {
        icon: businessIcon,
        title: 'For your registered business',
        description:
            'Suitable for NGOs, LLCs, or large businesses with CAC registration.',
        value: 'BUSINESS',
    },
]

export const urgencyOptions = [
    'Not sure - exploring options',
    'At some point this year',
    'Within the next month or so',
    'Within the next 2 weeks',
    'ASAP, within the next few days',
]

export const urgencyOptionsMap = {
    [urgencyOptions[0]]: 'NOT_SURE',
    [urgencyOptions[1]]: 'THIS_YEAR',
    [urgencyOptions[2]]: 'NEXT_MONTH',
    [urgencyOptions[3]]: 'IN_2_WEEKS',
    [urgencyOptions[4]]: 'IN_FEW_DAYS',
}

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Pagination } from 'antd'
import Button from 'src/components/Button'
import BackNav from 'src/components/BackNav'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import { NewSolutionSkeletalCards } from 'src/components/SkeletalCards'
import close from 'src/assets/images/close-icon.svg'
import EmptyState from 'src/components/EmptyState'
import { formatPackageData } from './data'
import RecommendedPackage from './components/RecommendedPackage'
import { useGetAllPackages } from './hooks/useGetAllPackages'
import { InlineLoader } from 'src/components/Loader'

import styles from './addpackagedrawer.module.scss'

const AddPackageDrawer = ({
    showAddPackageModal,
    setShowAddPackageModal,
    selectedPackages = [],
    setSelectedPackages,
    addPackagesLoading,
    handleAddPackagesClick,
    minCapacity,
    maxCapacity,
    profileName,
    isOnePackage,
}) => {
    const [searchText, setSearchText] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedSolutions, setSelectedSolutions] = useState([])
    const [isOpen, setIsOpen] = useState([])

    const { packagesData, listTotal, isLoadingPackages, refetchPackages } =
        useGetAllPackages({
            name: searchText,
            pageNumber: currentPage,
            packageDataFormatter: formatPackageData,
            minCapacity,
            maxCapacity,
        })

    const hideDrawer = () => {
        setShowAddPackageModal(false)
    }

    const handlePage = pageNumber => {
        setCurrentPage(pageNumber)
    }

    const handleInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleSearch = () => {
        refetchPackages()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }
    const toggleBox = index => {
        setIsOpen(prevOpen => {
            const newOpen = [...prevOpen]
            newOpen[index] = !newOpen[index]
            return newOpen
        })
        //close all other boxes
        for (let i = 0; i < isOpen.length; i++) {
            if (i !== index) {
                setIsOpen(prevOpen => {
                    const newOpen = [...prevOpen]
                    newOpen[i] = false
                    return newOpen
                })
            }
        }
    }

    const handleAddPackages = () => {
        if (handleAddPackagesClick) {
            handleAddPackagesClick(selectedSolutions)
        } else {
            setSelectedPackages && setSelectedPackages(selectedSolutions)
            hideDrawer()
        }
    }

    useEffect(() => {
        if (packagesData?.length) {
            setIsOpen(Array(packagesData.length).fill(false))
        }
    }, [packagesData])

    useEffect(() => {
        setSelectedSolutions(selectedPackages)
    }, [selectedPackages])

    useEffect(() => {
        if (listTotal < currentPage) {
            setCurrentPage(listTotal)
        }
    }, [listTotal, currentPage])

    return (
        <div>
            <Drawer
                visible={showAddPackageModal}
                closable={false}
                width="450px"
                onClose={hideDrawer}
                className={styles.DrawerContainer}
            >
                <div className={styles.SelectEnergyCloseAndBackNav}>
                    <BackNav title="Go back" onClick={hideDrawer} />
                    <div>
                        <img src={close} alt="close" onClick={hideDrawer} />
                    </div>
                </div>

                <div className={styles.SelectEnergyContentWrapper}>
                    <div className={styles.SelectEnergyTitleAndButton}>
                        <h1 className={styles.SelectEnergyTitle}>
                            {isOnePackage
                                ? 'Available Packages'
                                : 'Select Energy Solution'}
                        </h1>
                        <Button
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            fontSize={'14px'}
                            handleClick={handleAddPackages}
                            disabled={selectedSolutions?.length === 0}
                        >
                            {addPackagesLoading ? (
                                <InlineLoader />
                            ) : isOnePackage ? (
                                'Confirm'
                            ) : (
                                `Add Packages (${selectedSolutions?.length})`
                            )}
                        </Button>
                    </div>
                    <div className={styles.SearchProductWrapper}>
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            borderRadius="7px"
                            inputWidth="390px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            iconType="search"
                            prefilled
                            inputPlaceholder="Search Products"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleBlur}
                        />
                    </div>

                    {isLoadingPackages ? (
                        <NewSolutionSkeletalCards total={10} />
                    ) : searchText !== '' && packagesData.length === 0 ? (
                        <div className={styles.EmptySearchWrapper}>
                            <EmptyState
                                type="noActivity"
                                title="No Result Found"
                                subTitle={
                                    <>
                                        We can&apos;t find any result for &quot;
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                color: '#011A3C',
                                            }}
                                        >
                                            {searchText}
                                        </span>
                                        &quot;
                                    </>
                                }
                            />
                        </div>
                    ) : (
                        packagesData?.map((data, i) => {
                            return (
                                <RecommendedPackage
                                    key={data.id}
                                    index={i}
                                    data={data}
                                    setSelectedSolutions={setSelectedSolutions}
                                    selectedSolutions={selectedSolutions}
                                    toggleBox={toggleBox}
                                    isOpen={isOpen}
                                    profileName={profileName}
                                    isOnePackage={isOnePackage}
                                />
                            )
                        })
                    )}

                    {packagesData?.length > 0 && (
                        <div className={styles.EnergyProductPaginationWrapper}>
                            <Pagination
                                defaultCurrent={currentPage}
                                defaultPageSize={1}
                                total={parseInt(listTotal, 10)}
                                showSizeChanger={false}
                                showTitle={false}
                                showLessItems
                                current={currentPage}
                                onChange={value => {
                                    handlePage(value)
                                }}
                            />
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    )
}

AddPackageDrawer.propTypes = {
    setShowAddPackageModal: PropTypes.func,
    setSelectedPackages: PropTypes.func,
    handleAddPackagesClick: PropTypes.func,
    selectedPackages: PropTypes.array,
    showAddPackageModal: PropTypes.bool,
    addPackagesLoading: PropTypes.bool,
    minCapacity: PropTypes.number,
    maxCapacity: PropTypes.number,
    profileName: PropTypes.string,
    isOnePackage: PropTypes.bool,
}

export default AddPackageDrawer

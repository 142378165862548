import { Tabs } from 'antd'
import ChatCTA from 'src/components/ChatCTA'
import styles from '../solarsystemslistingsv2.module.scss'
import PropTypes from 'prop-types'
import SolarCity from 'src/assets/images/solar_city.png'
import HandShake from 'src/assets/images/handshake.svg'
import ButtonComponent from 'src/components/Button'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { CONTACT_METHODS } from '../data'
import { ReactComponent as EmailIconNoStroke } from 'src/assets/images/email-no-stroke.svg'
import { ReactComponent as PhoneIconNoStroke } from 'src/assets/images/phone-no-stroke.svg'
import { useSharedForm } from '../hooks/useSharedForm'
import '../sharedform.scss'
import { InlineLoader } from 'src/components/Loader'
import { useSolarSystemsContext } from '../hooks/useSolarSystems'

const SharedForm = ({ view }) => {
    const {
        SharedFormWrapper,
        ClosedClusterWrapper,
        ClosedClusterEmailWrapper,
    } = styles
    const { TabPane } = Tabs
    const isPackage = view?.isPackage

    const { isClosedCluster, getEnergyProfileData } = useSolarSystemsContext()
    const closedClusterData = getEnergyProfileData?.data?.data

    const {
        errors,
        inputs,
        setInputs,
        contactMethod,
        setContactMethod,
        handleInputChange,
        validateUserIdMutation,
        handleGetStarted,
        registerDropOffLoading,
        handleClosedClusterGetStarted,
    } = useSharedForm(closedClusterData, isClosedCluster)

    const handleTabChange = key => {
        if (key === '1') {
            setContactMethod(CONTACT_METHODS.EMAIL)
        }
        if (key === '2') {
            setContactMethod(CONTACT_METHODS.PHONE)
        }
    }

    const handleSelectChange = (name, value, errors, setInputs) => {
        setInputs(prev => ({ ...prev, [name]: value }))
        if (errors[name]) {
            delete errors[name]
        }
    }

    const closedClusterLogo = closedClusterData?.cluster_origination?.logo
    return (
        <div
            className={`${SharedFormWrapper} ${
                isClosedCluster && ClosedClusterWrapper
            }`}
        >
            <div>
                <div>
                    <img
                        src={closedClusterLogo ?? HandShake}
                        alt="handshake"
                        width={234}
                        height={104}
                    />
                    <h2>
                        {isClosedCluster
                            ? `Hey, welcome to SunFi!`
                            : `Welcome to SunFi`}
                    </h2>
                    {isPackage ? (
                        <h3>
                            The link to this package;
                            <span> {view.name} </span>
                            has been shared with you. Please enter your email
                            address to continue.
                        </h3>
                    ) : isClosedCluster ? (
                        <>
                            <h3>
                                You have been invited to select a system. Select
                                a system that best fit your energy needs
                            </h3>
                        </>
                    ) : (
                        <h3>
                            We&apos;re thrilled to have you here. Feel free to
                            explore and discover all that we have to offer.
                            Please enter your email address to continue.
                        </h3>
                    )}

                    <div>
                        {isClosedCluster ? (
                            <div className={ClosedClusterEmailWrapper}>
                                <p>Add your work email address</p>
                                <p>
                                    Ensure the provided email is your work email
                                    address.
                                </p>
                                <InputFields
                                    name="email"
                                    value={inputs.email}
                                    handleChange={handleInputChange}
                                    paddingBottom="18px"
                                    title="Your work email address"
                                    errorMessage={
                                        errors['email'] ||
                                        errors['checkExistingStatus']
                                    }
                                    activateEnterClick
                                    enterFunc={handleClosedClusterGetStarted}
                                />
                            </div>
                        ) : (
                            <div
                                className={'TabStyle TabsStyle_WithUseTypeForm'}
                            >
                                <Tabs
                                    onChange={handleTabChange}
                                    activeKey={contactMethod}
                                >
                                    <TabPane
                                        tab={
                                            <p
                                                className={`tabHeadingStyle ${
                                                    contactMethod === '1'
                                                        ? 'tabHeadingStyle_active'
                                                        : ''
                                                }`}
                                            >
                                                <EmailIconNoStroke />
                                                Use email address
                                            </p>
                                        }
                                        key="1"
                                    >
                                        <InputFields
                                            name="email"
                                            value={inputs.email}
                                            handleChange={handleInputChange}
                                            paddingBottom="18px"
                                            title="Enter your email address"
                                            errorMessage={
                                                errors['email'] ||
                                                errors['checkExistingStatus']
                                            }
                                            activateEnterClick
                                            enterFunc={handleGetStarted}
                                        />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <p
                                                className={`tabHeadingStyle ${
                                                    contactMethod === '2'
                                                        ? 'tabHeadingStyle_active'
                                                        : ''
                                                }`}
                                            >
                                                <PhoneIconNoStroke />
                                                Use phone number
                                            </p>
                                        }
                                        key="2"
                                    >
                                        <SelectCountryCode
                                            handleChange={handleInputChange}
                                            handleSelectChange={(name, value) =>
                                                handleSelectChange(
                                                    name,
                                                    value,
                                                    errors,
                                                    setInputs,
                                                )
                                            }
                                            title="Enter your phone number"
                                            name="phone"
                                            inputValue={inputs.phone}
                                            errorMessage={
                                                errors['phone'] ||
                                                errors['checkExistingStatus']
                                            }
                                            sseUse={true}
                                            activateEnterClick
                                            enterFunc={handleGetStarted}
                                        />
                                    </TabPane>
                                </Tabs>
                            </div>
                        )}
                    </div>

                    <div>
                        <ButtonComponent
                            btnBgColor="#004AAD"
                            btnTextColor="#fff"
                            btnWidth={'100%'}
                            marginTop={'16px'}
                            handleClick={() => {
                                isClosedCluster
                                    ? handleClosedClusterGetStarted()
                                    : handleGetStarted()
                            }}
                        >
                            {registerDropOffLoading ||
                            validateUserIdMutation.isLoading ? (
                                <InlineLoader />
                            ) : (
                                'Get Started'
                            )}
                        </ButtonComponent>
                        <ChatCTA />
                    </div>
                </div>
                <div>
                    <img src={SolarCity} alt="shared-form-layout-right-img" />
                </div>
            </div>
        </div>
    )
}

SharedForm.propTypes = {
    view: PropTypes.object,
}

export default SharedForm

import PropTypes from 'prop-types'
import ButtonComponent from 'src/components/Button'
import s from './costcard.module.scss'
import NairaSymbol from 'src/components/NairaSymbol'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { marginValues } from './utils'

const CostCard = ({
    title,
    margin,
    total,
    costOfComponents,
    selected,
    handleMargin,
    handleRemoveMargin,
    styleMargin,
    width,
}) => {
    const removeBtnDisabled =
        margin.amount === '' || margin.amount === 0 || margin.amount === 'NaN'
    const { color, symbol } = marginValues(margin.action)

    return (
        <div className={s.card} style={{ margin: styleMargin, width: width }}>
            <h4 className={s.cardTitle}>{title}</h4>
            <div className={s.row}>
                <h5>Cost of components</h5>
                <p>
                    <NairaSymbol />
                    {currencyFormatter(costOfComponents)}
                </p>
            </div>
            {margin?.isExisting && selected.length > 0 && (
                <>
                    <div className={s.row}>
                        <h5>Margin</h5>
                        <p style={{ color: color }}>
                            {symbol}
                            {margin.percentage}
                            {' %'}( <NairaSymbol />
                            {currencyFormatter(margin.amount)})
                        </p>
                    </div>
                    <div className={s.row}>
                        <h5 style={{ fontFamily: 'SF-Pro-Display-Semibold' }}>
                            Total
                        </h5>
                        <p>
                            <NairaSymbol />
                            {currencyFormatter(total)}
                        </p>
                    </div>
                </>
            )}

            <div className={s.btnsRow}>
                <span
                    role={'button'}
                    aria-disabled={removeBtnDisabled}
                    onClick={handleRemoveMargin}
                    className={
                        removeBtnDisabled
                            ? s.removeBtnInactive
                            : s.removeBtnActive
                    }
                >
                    Remove margin
                </span>
                <ButtonComponent
                    handleClick={handleMargin}
                    btnWidth={'88px'}
                    btnHeight={'29px'}
                    btnBgColor={'#E2EEFF'}
                    btnTextColor={'#004AAD'}
                >
                    {margin?.isExisting ? 'Edit' : 'Add'} Margin
                </ButtonComponent>
            </div>
        </div>
    )
}

CostCard.propTypes = {
    title: PropTypes.string,
    margin: PropTypes.shape({
        isExisting: PropTypes.bool,
        action: PropTypes.string,
        percentage: PropTypes.number,
        amount: PropTypes.number,
    }).isRequired,
    total: PropTypes.number,
    selected: PropTypes.arrayOf(PropTypes.object),
    costOfComponents: PropTypes.number,
    handleMargin: PropTypes.func,
    handleRemoveMargin: PropTypes.func,
    styleMargin: PropTypes.string,
    width: PropTypes.number || PropTypes.string,
}

export default CostCard

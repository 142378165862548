/* eslint-disable react/prop-types */
import Button from '../../../../../../components/Button'
import './investmentmetrics.scss'
const InvestmentMetrics = ({ investmentData, closeModal }) => {
    const data = [
        {
            title: 'IRR',
            subtitle:
                investmentData?.repayment_schedule_investment_meta_data?.irr ||
                investmentData?.investment_meta_data?.irr,
        },
        {
            title: 'MIRR',
            subtitle:
                investmentData?.repayment_schedule_investment_meta_data
                    ?.mirr_cashflow ||
                investmentData?.investment_meta_data?.mirr_cashflow,
        },
    ]

    const leftData = [
        {
            title: 'XIRR',
            subtitle:
                investmentData?.repayment_schedule_investment_meta_data
                    ?.xirr_cashflow ||
                investmentData?.investment_meta_data?.xirr_cashflow,
        },
        {
            title: 'Actual Annualize Rate',
            subtitle:
                investmentData?.repayment_schedule_investment_meta_data
                    ?.actual_ann_lzd ||
                investmentData?.investment_meta_data?.actual_ann_lzd,
        },
    ]

    return (
        <>
            <div className="InvestmentMetricsWrapper">
                <div>
                    <div className="InvestmentContentWrapper">
                        <div>
                            <p className="InvestmentInterest">
                                {investmentData
                                    ?.repayment_schedule_investment_meta_data
                                    ?.annual_interest ||
                                    investmentData?.investment_meta_data
                                        ?.annual_interest}{' '}
                                RATE
                            </p>
                            <p className="InvestmentMonth">
                                {`${
                                    investmentData?.financing_term ||
                                    investmentData
                                        ?.repayment_schedule_investment_meta_data
                                        ?.number_of_payments ||
                                    investmentData?.investment_meta_data
                                        ?.number_of_payments
                                }`}{' '}
                                Months
                            </p>
                        </div>
                    </div>
                    <p className="InvestmentMetricsText">Investment Metrics</p>
                    <div className="InvestmentContentWrapper">
                        <div>
                            {data.map(data => (
                                <>
                                    <p className="InvestmentTitle">
                                        {data.title}
                                    </p>
                                    <p className="InvestmentSubtitle">
                                        {data.subtitle}
                                    </p>
                                </>
                            ))}
                        </div>
                        <div>
                            {leftData.map(data => (
                                <>
                                    <p className="InvestmentTitle">
                                        {data.title}
                                    </p>
                                    <p className="InvestmentSubtitle">
                                        {data.subtitle}
                                    </p>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="InvestmentBtnWrapper">
                <Button
                    btnHeight="56px"
                    btnWidth="160px"
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    handleClick={() => closeModal()}
                >
                    Okay, Got it
                </Button>
            </div>
        </>
    )
}

export default InvestmentMetrics

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ButtonComponent from '../../../../../components/Button'
import { Skeleton } from 'antd'
import EmptyState from '../../../../../components/EmptyState'

const GlobalContributors = ({ data, loading }) => {
    const history = useHistory()

    return (
        <div className="AdminPLTablewWrapper">
            {!loading && data.length === 0 ? (
                <EmptyState subTitle="There are no global contributors" />
            ) : (
                <>
                    {/* desktop table */}
                    <div className="AdminPLTableHeaderTwo">
                        <span>Provider Name</span>
                        <span>Global packages</span>
                        <span>Global Components</span>
                        <span>Actions</span>
                    </div>
                    {!loading &&
                        data.map(item => (
                            <div
                                key={item?.id}
                                className="AdminPLTableContentTwo"
                            >
                                <span>{item?.provider_name}</span>
                                <span>{item?.global_packages}</span>
                                <span>{item?.global_components}</span>
                                <div className="AdminPLBtnWrapper">
                                    <ButtonComponent
                                        type="XSmall"
                                        handleClick={() =>
                                            history.push(
                                                item?.admin
                                                    ? {
                                                          pathname:
                                                              '/app/products',
                                                          state: {
                                                              name: item?.provider_name,
                                                              tab: 'globalcontributors',
                                                              sunfiId: item?.id,
                                                          },
                                                      }
                                                    : {
                                                          pathname:
                                                              '/app/products',
                                                          state: {
                                                              id: item?.id,
                                                              name: item?.provider_name,
                                                              tab: 'globalcontributors',
                                                          },
                                                      },
                                            )
                                        }
                                    >
                                        View
                                    </ButtonComponent>
                                </div>
                            </div>
                        ))}
                    {loading &&
                        [...Array(5)].map(i => (
                            <div key={i} className="AdminPLTableContent">
                                <span>
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '125px' }}
                                    />
                                </span>
                                <span>
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '80px' }}
                                    />
                                </span>
                                <span>
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '50px' }}
                                    />
                                </span>
                                <span>
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '50px' }}
                                    />
                                </span>
                                <div className="AdminPLBtnWrapper">
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '80px' }}
                                    />
                                </div>
                            </div>
                        ))}
                    {/* mobile card */}
                    {loading &&
                        [...Array(5)].map(i => (
                            <div key={i} className="APLMobileCard">
                                <div>
                                    <div>
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    </div>
                                    <div className="AdminPLBtnWrapper">
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    </div>
                                    <div>
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    {!loading &&
                        data.map(item => (
                            <div key={item?.id} className="APLMobileCard">
                                <div>
                                    <div>
                                        <h2>Provider Name</h2>
                                        <p style={{ width: '170px' }}>
                                            {item?.provider_name}
                                        </p>
                                    </div>
                                    <div className="AdminPLBtnWrapper">
                                        <ButtonComponent
                                            type="XSmall"
                                            handleClick={() =>
                                                history.push(
                                                    item?.admin
                                                        ? {
                                                              pathname:
                                                                  '/app/products',
                                                              state: {
                                                                  name: item?.provider_name,
                                                                  tab: 'globalcontributors',
                                                                  sunfiId:
                                                                      item?.id,
                                                              },
                                                          }
                                                        : {
                                                              pathname:
                                                                  '/app/products',
                                                              state: {
                                                                  id: item?.id,
                                                                  name: item?.provider_name,
                                                                  tab: 'globalcontributors',
                                                              },
                                                          },
                                                )
                                            }
                                        >
                                            View
                                        </ButtonComponent>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h2>Global Packages</h2>
                                        <p>{item?.global_packages}</p>
                                    </div>
                                    <div>
                                        <h2>global components</h2>
                                        <p style={{ textAlign: 'right' }}>
                                            {item?.global_components}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </>
            )}
        </div>
    )
}

GlobalContributors.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default GlobalContributors

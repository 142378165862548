import { useQuery } from 'react-query'
import {
    getEnergyProfile,
    getEnergyProfileSolutions,
    getEnergyProfileSolutionsFilter,
    getEnergyProfiles,
} from './api'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

export const useGetEnergyProfile = ({
    enabled = false,
    onSuccess,
    onError,
    params = {
        id: '',
    },
}) => {
    const { errorAlert } = useCustomToast()
    const { data, refetch, isFetching } = useQuery(
        ['energy-profile', params.id],
        () =>
            getEnergyProfile({
                id: params.id,
            }),
        {
            enabled: enabled,
            retry: false,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    return {
        getEnergyProfileRefetch: refetch,
        getEnergyProfileFetching: isFetching,
        getEnergyProfileData: data,
    }
}

export const useGetEnergyProfiles = ({
    enabled = false,
    onSuccess,
    onError,
    params = {
        customer_type: '',
        name: '',
        page: 1,
    },
}) => {
    const { errorAlert } = useCustomToast()
    const { data, refetch, isFetching } = useQuery(
        ['energy-profiles', params.customer_type, params.name, params.page],
        () =>
            getEnergyProfiles({
                customer_type: params.customer_type,
                name: params.name,
                page: params.page,
            }),
        {
            enabled: enabled,
            retry: false,
            refetchOnMount: true,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    return {
        getEnergyProfilesRefetch: refetch,
        getEnergyProfilesFetching: isFetching,
        getEnergyProfilesData: data,
    }
}

export const useGetEnergyProfileSolutions = ({
    enabled = false,
    onSuccess,
    onError,
    params = {
        profile_id: '',
        page: 1,
        price_sort: '',
        battery_types: '',
        system_types: '',
        payment_plan: '',
        min_cost: '',
        max_cost: '',
        min_backup_hour: '',
        max_backup_hour: '',
        min_system_size: '',
        max_system_size: '',
        solution_id: '',
    },
}) => {
    const { errorAlert } = useCustomToast()
    const { data, refetch, isFetching } = useQuery(
        [
            'energy-profile-solutions',
            params.page,
            params.price_sort,
            params.battery_types,
            params.system_types,
            params.payment_plan,
            params.min_cost,
            params.max_cost,
            params.min_backup_hour,
            params.max_backup_hour,
            params.min_system_size,
            params.max_system_size,
        ],
        () =>
            getEnergyProfileSolutions({
                profile_id: params.profile_id,
                page: params.page,
                price_sort: params.price_sort,
                battery_types: params.battery_types,
                system_types: params.system_types,
                payment_plan: params.payment_plan,
                min_cost: params.min_cost,
                max_cost: params.max_cost,
                min_backup_hour: params.min_backup_hour,
                max_backup_hour: params.max_backup_hour,
                min_system_size: params.min_system_size,
                max_system_size: params.max_system_size,
                solution_id: params.solution_id,
            }),
        {
            enabled: enabled,
            retry: false,
            refetchOnMount: true,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    return {
        getEnergyProfileSolutionsRefetch: refetch,
        getEnergyProfileSolutionsFetching: isFetching,
        getEnergyProfileSolutionsData: data,
    }
}

export const useGetEnergyProfileSolutionsFilter = ({
    enabled = false,
    onSuccess,
    onError,
    params = {
        profile_id: '',
    },
}) => {
    const { errorAlert } = useCustomToast()
    const { data, refetch, isFetching } = useQuery(
        'energy-profile-solutions-filter',
        () =>
            getEnergyProfileSolutionsFilter({
                profile_id: params.profile_id,
            }),
        {
            enabled: enabled,
            retry: false,
            refetchOnMount: true,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    return {
        getEnergyProfileSolutionsFilterRefetch: refetch,
        getEnergyProfileSolutionsFilterFetching: isFetching,
        getEnergyProfileSolutionsFilterData: data,
    }
}

/* eslint-disable react/prop-types */
import { AppButton } from 'src/components/Button'
import formatToDoData from './data'
import { format, parseISO } from 'date-fns'
import { formatOutrightSaleToDoData } from './data'

import './approvedtodotable.scss'
import { denyPermission } from 'src/utils/permissionFramework'

const ApprovedToDoTable = ({
    data,
    handleModal,
    customCallBack,
    sseUse,
    disabled,
    isOutrightSale,
}) => {
    const todoData = isOutrightSale
        ? formatOutrightSaleToDoData
        : formatToDoData
    return (
        <>
            <div
                style={{ fontFamily: 'SF-Pro-Display' }}
                className="ATDTLargeTable"
            >
                <div className={`ATDTHead ${sseUse && 'seeUseHead'}`}>
                    <p>Task</p>
                    <p>Updated Time</p>
                    <p>Action</p>
                </div>

                <div className="ATDTGrid">
                    {todoData(data).map((info, i) => (
                        <div
                            className={`ATDTGridItem ${
                                sseUse && 'sseGridItem'
                            }`}
                            key={i}
                        >
                            <div className="ATDTGridItemStatus">
                                {/* action to be/that has been taken */}
                                <p className="ATDTGridItemStatusText">
                                    {info.label}
                                </p>
                                {/* status - pending or completed */}
                                <div
                                    className="ATDTStatusTag"
                                    style={{
                                        backgroundColor: `${info.statusBox}`,
                                    }}
                                >
                                    <p
                                        style={{
                                            color: `${info.statusText}`,
                                        }}
                                    >
                                        {info.statusMsg}
                                    </p>
                                </div>
                                {info.keyID === 'REPAYMENT_METHOD_SETUP' &&
                                    info.statusMsg == 'COMPLETED' && (
                                        <div
                                            className="ATDTStatusTag"
                                            style={{
                                                backgroundColor: `${info.activeStatusBox}`,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    color: `${info.activeStateText}`,
                                                }}
                                            >
                                                {info.activeStateMsg}
                                            </p>
                                        </div>
                                    )}
                            </div>
                            {/* time last updated */}
                            <p className="ATDTGridItemStatusDate">
                                {info?.updatedTime === null
                                    ? 'N/A'
                                    : info?.updatedTime &&
                                      format(
                                          parseISO(info?.updatedTime),
                                          'dd MMM yyyy, HH:mm',
                                      )}
                            </p>

                            <AppButton
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="70px"
                                btnHeight="37px"
                                fontSize="12px"
                                fontFamily="SF-Pro-Display-Medium"
                                handleClick={() => {
                                    if (
                                        info.keyID === 'REPAYMENT_SCHEDULE' &&
                                        info.btnText === 'View'
                                    ) {
                                        customCallBack()
                                    } else {
                                        handleModal(info)
                                    }
                                }}
                                disabled={
                                    denyPermission(
                                        'admin',
                                        'approved_requests',
                                        info.permission,
                                    ) ||
                                    (info.keyID === 'UPFRONT_DEPOSIT' &&
                                        data?.upfront_deposit_percentage ===
                                            0) ||
                                    disabled
                                }
                                // toolTipText={denyPermission('admin', 'approved_requests', info.permission) ? denyPermissionToolTipText : null}
                            >
                                {info.btnText}
                            </AppButton>
                        </div>
                    ))}
                </div>
            </div>
            <div
                style={{ fontFamily: 'SF-Pro-Display' }}
                className="ATDTableMobile"
            >
                <div className="ATDTGrid">
                    {todoData(data).map((info, i) => (
                        <div className="ATDTGridItem" key={i}>
                            <div className="ATDTGridItemStatus">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {/* action to be/that has been taken */}
                                    <p className="ATDTGridItemStatusText">
                                        {info.label}
                                    </p>
                                    {/* status - pending or completed */}
                                    <div
                                        className="ATDTStatusTag"
                                        style={{
                                            backgroundColor: `${info.statusBox}`,
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: `${info.statusText}`,
                                            }}
                                        >
                                            {info.statusMsg}
                                        </p>
                                    </div>
                                    {info.keyID === 'REPAYMENT_METHOD_SETUP' &&
                                        info.statusMsg == 'COMPLETED' && (
                                            <div
                                                className="ATDTStatusTag"
                                                style={{
                                                    backgroundColor: `${info.activeStatusBox}`,
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        color: `${info.activeStateText}`,
                                                    }}
                                                >
                                                    {info.activeStateMsg}
                                                </p>
                                            </div>
                                        )}
                                </div>
                                <AppButton
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="#004AAD"
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="70px"
                                    btnHeight="37px"
                                    fontSize="12px"
                                    fontFamily="SF-Pro-Display-Medium"
                                    handleClick={() => {
                                        if (
                                            info.keyID ===
                                                'REPAYMENT_SCHEDULE' &&
                                            info.btnText === 'View'
                                        ) {
                                            customCallBack()
                                        } else {
                                            handleModal(info)
                                        }
                                    }}
                                    disabled={
                                        denyPermission(
                                            'admin',
                                            'approved_requests',
                                            info.permission,
                                        ) || disabled
                                    }
                                    // toolTipText={denyPermission('admin', 'approved_requests', info.permission) ? denyPermissionToolTipText : null}
                                >
                                    {info.btnText}
                                </AppButton>
                            </div>

                            {/* time last updated */}
                            <div style={{ marginTop: 35 }}>
                                <p className="MobileTimeUpdatedText">
                                    Updated time
                                </p>
                                <p className="ATDTGridItemStatusDate">
                                    {info?.updatedTime === null
                                        ? 'N/A'
                                        : info?.updatedTime &&
                                          format(
                                              parseISO(info?.updatedTime),
                                              'dd MMM yyyy, HH:mm',
                                          )}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ApprovedToDoTable

import { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'
import { ConsumerButton } from '../Button'
import { InputFields, SelectField } from '../InputFields'
import { SelectCountryCode } from '../InputFields/SelectCountryCode'
import { stateData } from 'src/utils/stateData'
import { paymentPlansData, reasonForSolarData, CONTACT_METHODS } from './data'
import ConsumerModal from '../Modal/ConsumerModal'
import UpdateNeeds from '../UpdateNeeds'
import { ReactComponent as EmailIcon } from 'src/assets/images/consumer-email-icon.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/images/consumer-phone-icon.svg'
import { ReactComponent as EmailIconNoStroke } from 'src/assets/images/email-no-stroke.svg'
import { ReactComponent as PhoneIconNoStroke } from 'src/assets/images/phone-no-stroke.svg'
import LightBulbIcon from 'src/assets/images/Bulb.svg'
import { ReactComponent as GoBackIcon } from 'src/assets/images/blue-circle-left.svg'
import techCabalIcon from 'src/assets/images/techcabal-icon.png'
import norrskenIcon from 'src/assets/images/norrsken-icon.png'
import techCrunchIcon from 'src/assets/images/techcrunch-icon.png'
import nasdaqIcon from 'src/assets/images/nasdaq-icon.png'
import Checkbox from '../Checkbox'
import ConsumerEnergyNeedsSummary from './ConsumerEnergyNeedsSummary'
import ConsumerLoader from '../ConsumerLoader'
import { formatPhoneNumber } from 'src/utils/formatting'
import './consumermainsection.scss'
import PackageSteps from 'src/pages/Consumer/SmartShoppingExperience/ViewSystem/components/PackageSteps'
import ChatCTA from 'src/components/ChatCTA'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import GetOfferForm from './GetOfferForm'
import { handleSelectChange, handleSearch } from './utils'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import ReactPlayer from 'react-player/lazy'
import SecondaryModal from '../Modal/SecondaryModal'
import { useConsumerLandingPage } from './hooks/useConsumerLandingPage'
import SelectUseType from 'src/pages/Consumer/SmartShoppingExperience/Home/SelectUseType'
import PlatformUpdateMessage from './PlatformUpdateMessage'
import NewUpdateNotification from './NewUpdateNotification'
import { landingPageEvents } from 'src/utils/mp-event-tracker/landing-page'
import { appTracking } from 'src/utils/appTracker'

// eslint-disable-next-line react/prop-types
const ConsumerMainSection = ({ page }) => {
    const {
        loading,
        errors,
        setErrors,
        isExisting,
        inputs,
        setInputs,
        energyNeeds,
        setEnergyNeeds,
        checked,
        contactMethod,
        setContactMethod,
        emailIsExistingChecked,
        phoneIsExistingChecked,
        knowsGenMonthlyCost,
        setKnowsGenMonthlyCost,
        showNext,
        setShowNext,
        generatorUse,
        setGeneratorUse,
        paymentModel,
        setPaymentModel,
        canMountSolar,
        setCanMountSolar,
        handleInputChange,
        handleChecked,
        handleBlur,
        handleInputBlur,
        handleShowNext,
        handleSubmit,
        validateUserIdMutation,
        getFormResponseMutation,
        handleGetStarted,
        handleViewAllSystems,
        useType,
        setUseType,
        getStartedBtnLoading,
        viewAllSolutionsBtnLoading,
        isCustomBuild,
        customAppliances,
        useTypeFormInputs,
        setUseTypeFormInputs,
    } = useConsumerLandingPage()

    // app tracker variables
    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'View consumer page'
    const sunfiMailchimpUrl = process.env.REACT_APP_MAILCHIMP_URL

    const [showApplianceModal, setShowApplianceModal] = useState(false)

    const history = useHistory()

    const [searchValue, setSearchValue] = useState('')
    const [search, setSearch] = useState(false)
    const { isMobile } = useMediaQueries()
    const [showVideoModal, setShowVideoModal] = useState(false)

    const { TabPane } = Tabs

    //get returning consumer email
    const queryParams = new URLSearchParams(history?.location?.search)
    const consumerEmail = queryParams.get('email')

    // get returnimg consumer phone
    const consumerPhone = queryParams.get('phone')?.replace('234', '')
    const showUseTypeForm =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true' &&
        (history.location?.pathname === '/' ||
            history.location?.pathname === '/home')
    const showPlatformUpdateMessage =
        process.env.REACT_APP_FLAG_SHOW_CSSE_V2_LAUNCH_ANOUNCEMENT === 'true'
    const showNewUpdateNotification =
        process.env.REACT_APP_FLAG_SHOW_NEW_UPATE_NOTIFICATION === 'true'

    const handleTabChange = key => {
        if (key === '1') {
            setContactMethod(CONTACT_METHODS.EMAIL)
        }
        if (key === '2') {
            setContactMethod(CONTACT_METHODS.PHONE)
        }
    }

    const updateEnergyNeeds = appliances => {
        setEnergyNeeds(appliances)
    }

    const deleteErrorOnSelection = key => {
        delete errors[key]
    }

    const words = ['reliable solar', 'affordable solar', 'sustainable solar']
    const [currentWordIndex, setCurrentWordIndex] = useState(0)

    useEffect(() => {
        setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length)
        const interval = setInterval(() => {
            setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length)
        }, 2000)
        return () => {
            clearInterval(interval)
        }
    }, [words.length])

    //summary calculation
    const wattsPerAppliance = energyNeeds?.map(
        item => item.powerRating ?? item.power_rating * item.quantity,
    )
    const totalWattsAdded = wattsPerAppliance?.reduce((a, b) => a + b)

    const dayUsage = energyNeeds?.map(
        item =>
            item.powerRating ??
            item.power_rating * item.backup_hours * item.quantity,
    )
    const totalDayUsage = dayUsage?.reduce((a, b) => a + b)
    const nightUsage = energyNeeds?.map(
        item =>
            item.powerRating ??
            item.power_rating * item.backup_hours_night * item.quantity,
    )
    const totalNightUsage = nightUsage?.reduce((a, b) => a + b)

    // eslint-disable-next-line no-unused-vars

    const nextButtonDisabled =
        (contactMethod === CONTACT_METHODS.PHONE && !phoneIsExistingChecked) ||
        (contactMethod === CONTACT_METHODS.EMAIL && !emailIsExistingChecked)

    const isGetstartedPage = page === 'getStarted'

    useEffect(() => {
        if (consumerEmail || consumerPhone) {
            getFormResponseMutation.mutate({
                user_id: consumerEmail || formatPhoneNumber(consumerPhone),
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consumerEmail, consumerPhone])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [showNext])

    return (
        <>
            <ConsumerModal
                showModal={showApplianceModal}
                modalWidth="649px"
                modalHeight="687px"
                closable={false}
                content={
                    <UpdateNeeds
                        closeModal={() => setShowApplianceModal(false)}
                        updateEnergyNeeds={updateEnergyNeeds}
                        energyNeeds={energyNeeds}
                        page="consumer-SSE"
                        sseUse={true}
                        selectedAppliances={energyNeeds}
                        underscoredKeys={!!isCustomBuild && !!customAppliances}
                    />
                }
            />

            {showVideoModal && (
                <SecondaryModal
                    showModal={showVideoModal}
                    modalWidth="1000px"
                    modalHeight="100%"
                    onCancel={() => {
                        setShowVideoModal(false)
                    }}
                    noPadding
                    modalClass="videoModal"
                    content={
                        <div>
                            <ReactPlayer
                                url={
                                    showUseTypeForm
                                        ? 'https://youtu.be/LA-4gc4Miw0?si=Sio09fiqZCYoV_E_'
                                        : 'https://www.youtube.com/watch?v=ynFAc4C3AcU'
                                }
                                width="100%"
                                height={isMobile ? '250px' : '643px'}
                                playing={showVideoModal}
                                controls={true}
                                loop={true}
                            />
                        </div>
                    }
                />
            )}
            <section
                className={`consumerSectionwrapper ${
                    showUseTypeForm && !isGetstartedPage
                        ? 'consumerSectionwrapper_WithUseTypeForm'
                        : ''
                }`}
                style={{
                    height: isGetstartedPage ? '100%' : '',
                    padding: isGetstartedPage ? '0 20px 60px 20px' : '',
                }}
            >
                <div
                    className={
                        showNext
                            ? 'HeroFormWrapper2'
                            : `HeroFormWrapper ${
                                  isGetstartedPage ? 'GetStarted' : ''
                              } ${
                                  showUseTypeForm ? 'WithUseTypeForm' : ''
                              } : ''`
                    }
                    style={{
                        background: isGetstartedPage ? 'transparent' : '',
                        maxHeight: isGetstartedPage ? '100%' : '',
                    }}
                >
                    {isMobile && isGetstartedPage && (
                        <div className="CSSEPromoGetstartedWrapper Mobile">
                            <h2>
                                Fill the energy get offer form to get started
                            </h2>
                            <p className="CSSEPromoGetstartedP">
                                We are an energy financial technology platform
                                that connects people who want solar energy
                                access to payment plans that match their needs.
                                Request a payment plan now.
                            </p>
                        </div>
                    )}
                    <div
                        className={`ImgContentsWrapper ${
                            isGetstartedPage && 'GetStartedContentsWrapper'
                        }`}
                    >
                        {isGetstartedPage ? (
                            <div className="CSSEPromoGetstartedWrapper">
                                {isMobile && isGetstartedPage && (
                                    <div className="CSSEPromoGetstartedWrapper Mobile">
                                        <h2>
                                            Fill the energy get offer form to
                                            get started
                                        </h2>
                                        <p className="CSSEPromoGetstartedP">
                                            We are an energy financial
                                            technology platform that connects
                                            people who want solar energy access
                                            to payment plans that match their
                                            needs. Request a payment plan now.
                                        </p>
                                    </div>
                                )}
                                <PackageSteps page="promo" />
                                <div style={{ paddingLeft: '23px' }}>
                                    <ChatCTA
                                        text="Have questions our process?"
                                        fontFamily="SF-Pro-Display-Medium"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`LeftContentStyle ${
                                    showUseTypeForm
                                        ? 'LeftContentStyle_WithUseTypeForm'
                                        : ''
                                }`}
                            >
                                {showPlatformUpdateMessage && (
                                    <PlatformUpdateMessage />
                                )}

                                {showNewUpdateNotification && (
                                    <NewUpdateNotification />
                                )}
                                <h2>
                                    The most trusted place to access and pay for
                                    {!isMobile ? <br /> : ' '}
                                    <div className="ConsumerAnimatedTextWrapper">
                                        {words.map((word, index) => (
                                            <span
                                                key={index}
                                                className={`animate__animated ${
                                                    index === 0
                                                        ? 'animate__slideInDown animate__faster'
                                                        : 'animate__slideInUp'
                                                } ${
                                                    index === currentWordIndex
                                                        ? 'slide-down'
                                                        : 'hidden'
                                                }`}
                                                style={{ color: '#FFC222' }}
                                            >
                                                {word}
                                            </span>
                                        ))}
                                    </div>
                                    <br />
                                </h2>
                                <p>
                                    Say goodbye to power wahala! SunFi helps you
                                    access reliable solar energy experiences
                                    that save you the most money.
                                </p>
                                {!showUseTypeForm && (
                                    <ConsumerButton
                                        btnWidth="160px"
                                        btnHeight="53px"
                                        btnBgColor="transparent"
                                        btnTextColor="#FFFFFF"
                                        borderOutlineColor="#FFFFFF"
                                        handleClick={() =>
                                            history.push('/consumer/welcome')
                                        }
                                        role="signin"
                                    >
                                        Sign In
                                    </ConsumerButton>
                                )}
                            </div>
                        )}
                        <MailchimpSubscribe
                            url={sunfiMailchimpUrl}
                            render={({ subscribe }) => {
                                return (
                                    <section
                                        id="landing-page-form"
                                        className={`ConsumerLandingPageFormStyle ${
                                            showUseTypeForm
                                                ? 'ConsumerLandingPageFormStyle_WithUseTypeForm'
                                                : ''
                                        }`}
                                        style={{
                                            border: loading && 0,
                                            marginTop:
                                                isGetstartedPage && '20px',
                                        }}
                                    >
                                        {loading && (
                                            <ConsumerLoader text="Generating our recommendations for you, this will only take a second" />
                                        )}
                                        <h3 id="begin-your-solar-journey">
                                            {showUseTypeForm
                                                ? `Begin Your Solar Journey Today`
                                                : 'Get a system tailored to your energy needs'}
                                        </h3>
                                        <p className="ConsumerLandingPageSubHeading">
                                            {showUseTypeForm
                                                ? `Provide your ${
                                                      contactMethod ===
                                                      CONTACT_METHODS.EMAIL
                                                          ? 'Email'
                                                          : 'Phone number'
                                                  }, select for either home or business, and choose recommendation preference.`
                                                : showNext
                                                ? 'Kindly complete the remaining details to receive a tailored solar recommendation.'
                                                : 'Input the appliances you want to power and receive an instant solar energy recommendation'}
                                        </p>
                                        <div
                                            className="ConsumerLandingPageVideo"
                                            onClick={() =>
                                                setShowVideoModal(true)
                                            }
                                        >
                                            <PlayIcon />
                                            <p>
                                                {showUseTypeForm
                                                    ? 'Watch the SunFi story'
                                                    : 'Need help? Watch this video'}
                                            </p>
                                        </div>

                                        {!showUseTypeForm && (
                                            <div className="ConsumerBackButtonStyle">
                                                {showNext && (
                                                    <div
                                                        className="ConsumerBackButtonWrapper"
                                                        onClick={() =>
                                                            setShowNext(false)
                                                        }
                                                    >
                                                        <GoBackIcon />
                                                        <span className="ConsumerBackButtonSpan">
                                                            Go back
                                                        </span>
                                                    </div>
                                                )}
                                                <hr
                                                    style={{
                                                        background: showNext
                                                            ? 'linear-gradient(to right, #004AAD 50%, #F9FCFF 50%)'
                                                            : '#F9FCFF',
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {showNext ? (
                                            <GetOfferForm
                                                inputs={inputs}
                                                errors={errors}
                                                generatorUse={generatorUse}
                                                knowsGenMonthlyCost={
                                                    knowsGenMonthlyCost
                                                }
                                                canMountSolar={canMountSolar}
                                                paymentModel={paymentModel}
                                                setPaymentModel={
                                                    setPaymentModel
                                                }
                                                setCanMountSolar={
                                                    setCanMountSolar
                                                }
                                                deleteErrorOnSelection={
                                                    deleteErrorOnSelection
                                                }
                                                handleInputChange={
                                                    handleInputChange
                                                }
                                                handleSelectChange={(
                                                    name,
                                                    value,
                                                ) =>
                                                    handleSelectChange(
                                                        name,
                                                        value,
                                                        errors,
                                                        setInputs,
                                                        setSearch,
                                                    )
                                                }
                                                handleBlur={handleBlur}
                                                handleSubmit={handleSubmit}
                                            />
                                        ) : (
                                            <>
                                                {showUseTypeForm ? null : energyNeeds[0]
                                                      .name === '' ? (
                                                    <div
                                                        className="AddAppliancesStyle"
                                                        onClick={() =>
                                                            setShowApplianceModal(
                                                                true,
                                                            )
                                                        }
                                                        data-testid="add-appliance"
                                                    >
                                                        <div>
                                                            <img
                                                                src={
                                                                    LightBulbIcon
                                                                }
                                                                alt="light bulb icon"
                                                            />
                                                        </div>
                                                        <p>
                                                            Add appliances you
                                                            want to power
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <ConsumerEnergyNeedsSummary
                                                        energyNeeds={
                                                            energyNeeds
                                                        }
                                                        setShowApplianceModal={
                                                            setShowApplianceModal
                                                        }
                                                        totalWattsAdded={
                                                            totalWattsAdded
                                                        }
                                                        totalDayUsage={
                                                            totalDayUsage
                                                        }
                                                        totalNightUsage={
                                                            totalNightUsage
                                                        }
                                                    />
                                                )}
                                                <div
                                                    className={`TabStyle ${
                                                        showUseTypeForm
                                                            ? 'TabsStyle_WithUseTypeForm'
                                                            : ''
                                                    }`}
                                                >
                                                    <Tabs
                                                        onChange={
                                                            handleTabChange
                                                        }
                                                        activeKey={
                                                            contactMethod
                                                        }
                                                    >
                                                        <TabPane
                                                            tab={
                                                                <p
                                                                    className={`tabHeadingStyle ${
                                                                        contactMethod ===
                                                                        '1'
                                                                            ? 'tabHeadingStyle_active'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {showUseTypeForm ? (
                                                                        <EmailIconNoStroke />
                                                                    ) : (
                                                                        <EmailIcon />
                                                                    )}
                                                                    Use email
                                                                    address
                                                                </p>
                                                            }
                                                            key="1"
                                                        >
                                                            <InputFields
                                                                name="email"
                                                                value={
                                                                    inputs.email
                                                                }
                                                                handleChange={
                                                                    handleInputChange
                                                                }
                                                                paddingBottom="18px"
                                                                title="Enter your email address"
                                                                errorMessage={
                                                                    errors[
                                                                        'email'
                                                                    ] ||
                                                                    errors[
                                                                        'checkExistingStatus'
                                                                    ]
                                                                }
                                                                onBlur={e => {
                                                                    if (
                                                                        !emailIsExistingChecked
                                                                    ) {
                                                                        handleInputBlur(
                                                                            e,
                                                                        )
                                                                    }
                                                                    appTracking(
                                                                        pageUrlName,
                                                                        pageTracker,
                                                                        pageTitle,
                                                                        landingPageEvents[
                                                                            'email'
                                                                        ]
                                                                            .action,
                                                                        landingPageEvents[
                                                                            'email'
                                                                        ].label,
                                                                        landingPageEvents[
                                                                            'email'
                                                                        ]
                                                                            .category,
                                                                        [
                                                                            'MP',
                                                                            'GA',
                                                                        ],
                                                                        'event',
                                                                        {
                                                                            email: e
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    )
                                                                }}
                                                                exemptMouseOut
                                                                loadingIcon={
                                                                    validateUserIdMutation.isLoading
                                                                }
                                                                verified={
                                                                    !validateUserIdMutation.isLoading &&
                                                                    emailIsExistingChecked &&
                                                                    !isExisting
                                                                }
                                                            />
                                                        </TabPane>
                                                        <TabPane
                                                            tab={
                                                                <p
                                                                    className={`tabHeadingStyle ${
                                                                        contactMethod ===
                                                                        '2'
                                                                            ? 'tabHeadingStyle_active'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {showUseTypeForm ? (
                                                                        <PhoneIconNoStroke />
                                                                    ) : (
                                                                        <PhoneIcon />
                                                                    )}
                                                                    Use phone
                                                                    number
                                                                </p>
                                                            }
                                                            key="2"
                                                        >
                                                            <SelectCountryCode
                                                                handleChange={
                                                                    handleInputChange
                                                                }
                                                                handleSelectChange={(
                                                                    name,
                                                                    value,
                                                                ) =>
                                                                    handleSelectChange(
                                                                        name,
                                                                        value,
                                                                        errors,
                                                                        setInputs,
                                                                        setSearch,
                                                                    )
                                                                }
                                                                title="Enter your phone number"
                                                                name="phone"
                                                                inputValue={
                                                                    inputs.phone
                                                                }
                                                                errorMessage={
                                                                    errors[
                                                                        'phone'
                                                                    ] ||
                                                                    errors[
                                                                        'checkExistingStatus'
                                                                    ]
                                                                }
                                                                onBlur={e => {
                                                                    if (
                                                                        !phoneIsExistingChecked
                                                                    ) {
                                                                        handleInputBlur(
                                                                            e,
                                                                        )
                                                                    }
                                                                    appTracking(
                                                                        pageUrlName,
                                                                        pageTracker,
                                                                        pageTitle,
                                                                        landingPageEvents[
                                                                            'phone'
                                                                        ]
                                                                            .action,
                                                                        landingPageEvents[
                                                                            'phone'
                                                                        ].label,
                                                                        landingPageEvents[
                                                                            'phone'
                                                                        ]
                                                                            .category,
                                                                        [
                                                                            'MP',
                                                                            'GA',
                                                                        ],
                                                                        'event',
                                                                        {
                                                                            phone_number:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        },
                                                                    )
                                                                }}
                                                                loadingIcon={
                                                                    validateUserIdMutation.isLoading
                                                                }
                                                                verified={
                                                                    !validateUserIdMutation.isLoading &&
                                                                    phoneIsExistingChecked &&
                                                                    !isExisting
                                                                }
                                                                sseUse={true}
                                                            />
                                                        </TabPane>
                                                    </Tabs>
                                                    {showUseTypeForm ? (
                                                        <SelectUseType
                                                            handleGetStarted={
                                                                handleGetStarted
                                                            }
                                                            handleViewAllSystems={
                                                                handleViewAllSystems
                                                            }
                                                            useType={useType}
                                                            setUseType={
                                                                setUseType
                                                            }
                                                            errors={errors}
                                                            setErrors={
                                                                setErrors
                                                            }
                                                            getStartedBtnLoading={
                                                                getStartedBtnLoading
                                                            }
                                                            viewAllSolutionsBtnLoading={
                                                                viewAllSolutionsBtnLoading
                                                            }
                                                            useTypeFormInputs={
                                                                useTypeFormInputs
                                                            }
                                                            setUseTypeFormInputs={
                                                                setUseTypeFormInputs
                                                            }
                                                        />
                                                    ) : (
                                                        <form>
                                                            <SelectField
                                                                values={stateData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                selectWidth="338px"
                                                                name="location"
                                                                initialOption={
                                                                    inputs.location ===
                                                                        '' ||
                                                                    searchValue ===
                                                                        ''
                                                                        ? 'Select your location'
                                                                        : inputs.location
                                                                }
                                                                handleChange={(
                                                                    name,
                                                                    value,
                                                                ) =>
                                                                    handleSelectChange(
                                                                        name,
                                                                        value,
                                                                        errors,
                                                                        setInputs,
                                                                        setSearch,
                                                                    )
                                                                }
                                                                handleSearch={(
                                                                    name,
                                                                    value,
                                                                ) =>
                                                                    handleSearch(
                                                                        name,
                                                                        value,
                                                                        setInputs,
                                                                        setSearch,
                                                                        setSearchValue,
                                                                    )
                                                                }
                                                                marginBottom="20px"
                                                                floatingLabel={
                                                                    inputs.location !==
                                                                        '' &&
                                                                    'Select your location'
                                                                }
                                                                value={
                                                                    search
                                                                        ? searchValue
                                                                        : inputs.location
                                                                }
                                                                currentSelected={
                                                                    inputs.location
                                                                }
                                                                selectedValue={
                                                                    inputs.location
                                                                }
                                                                dropdownPositionRelative
                                                                errorMessage={
                                                                    errors[
                                                                        'location'
                                                                    ]
                                                                }
                                                                showSearch
                                                                onSearch
                                                                searchValue
                                                                withCheckBox
                                                            />
                                                            <SelectField
                                                                values={paymentPlansData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                selectWidth="338px"
                                                                name="paymentPlan"
                                                                initialOption={
                                                                    inputs.paymentPlan ||
                                                                    'How long do you want a payment plan?'
                                                                }
                                                                handleChange={(
                                                                    name,
                                                                    value,
                                                                ) =>
                                                                    handleSelectChange(
                                                                        name,
                                                                        value,
                                                                        errors,
                                                                        setInputs,
                                                                        setSearch,
                                                                    )
                                                                }
                                                                marginBottom="20px"
                                                                dropdownPositionRelative
                                                                errorMessage={
                                                                    errors[
                                                                        'paymentPlan'
                                                                    ]
                                                                }
                                                                floatingLabel={
                                                                    inputs.paymentPlan ===
                                                                    ''
                                                                        ? ''
                                                                        : 'How long do you want a payment plan?'
                                                                }
                                                                withCheckBox
                                                                showSearch
                                                                selectedValue={
                                                                    inputs.paymentPlan
                                                                }
                                                                currentSelected={
                                                                    inputs.paymentPlan
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginBottom:
                                                                        '22px',
                                                                }}
                                                            >
                                                                <p className="FormPStyle">
                                                                    Do you know
                                                                    your monthly
                                                                    cost for gen
                                                                    and
                                                                    maintenance?
                                                                </p>
                                                                <div className="AnswerStyleWrapper GenCostQuestionStyle">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setKnowsGenMonthlyCost(
                                                                                'yes',
                                                                            )
                                                                            setGeneratorUse(
                                                                                'yes',
                                                                            )
                                                                            deleteErrorOnSelection(
                                                                                'knowsMonthlyGenCostErr',
                                                                            )
                                                                        }}
                                                                        className={
                                                                            knowsGenMonthlyCost ===
                                                                                'yes' &&
                                                                            'AnswerStyleWrapper__active'
                                                                        }
                                                                    >
                                                                        Yes, I
                                                                        do
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setKnowsGenMonthlyCost(
                                                                                'no',
                                                                            )
                                                                            setGeneratorUse(
                                                                                'no',
                                                                            )
                                                                            deleteErrorOnSelection(
                                                                                'knowsMonthlyGenCostErr',
                                                                            )
                                                                        }}
                                                                        className={
                                                                            knowsGenMonthlyCost ===
                                                                                'no' &&
                                                                            'AnswerStyleWrapper__active'
                                                                        }
                                                                    >
                                                                        No, I
                                                                        don&apos;t
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setKnowsGenMonthlyCost(
                                                                                'invalid',
                                                                            )
                                                                            setGeneratorUse(
                                                                                'no',
                                                                            )
                                                                            deleteErrorOnSelection(
                                                                                'knowsMonthlyGenCostErr',
                                                                            )
                                                                        }}
                                                                        className={
                                                                            knowsGenMonthlyCost ===
                                                                                'invalid' &&
                                                                            'AnswerStyleWrapper__active'
                                                                        }
                                                                        style={{
                                                                            width: isMobile
                                                                                ? '105px'
                                                                                : '',
                                                                        }}
                                                                    >
                                                                        {isMobile
                                                                            ? 'No gen'
                                                                            : `I don't use gen`}
                                                                    </button>
                                                                </div>
                                                                <div className="AnswerStyleWrapperBottomTexts">
                                                                    <p
                                                                        className="AnswerStyleWrapperError"
                                                                        style={{
                                                                            margin: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            errors[
                                                                                'knowsMonthlyGenCostErr'
                                                                            ]
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <SelectField
                                                                values={reasonForSolarData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                selectWidth="338px"
                                                                name="reasonForSolar"
                                                                initialOption={
                                                                    inputs.reasonForSolar ||
                                                                    (isMobile
                                                                        ? 'Your primary goal to solve with Solar'
                                                                        : 'What is your primary goal to solve with Solar?')
                                                                }
                                                                handleChange={(
                                                                    name,
                                                                    value,
                                                                ) =>
                                                                    handleSelectChange(
                                                                        name,
                                                                        value,
                                                                        errors,
                                                                        setInputs,
                                                                        setSearch,
                                                                    )
                                                                }
                                                                marginBottom="20px"
                                                                dropdownPositionRelative
                                                                errorMessage={
                                                                    errors[
                                                                        'reasonForSolar'
                                                                    ]
                                                                }
                                                                floatingLabel={
                                                                    inputs.reasonForSolar ===
                                                                    ''
                                                                        ? ''
                                                                        : isMobile
                                                                        ? 'Your primary goal to solve with Solar'
                                                                        : 'What is your primary goal to solve with Solar?'
                                                                }
                                                                selectedValue={
                                                                    inputs.reasonForSolar
                                                                }
                                                                currentSelected={
                                                                    inputs.reasonForSolar
                                                                }
                                                                withCheckBox
                                                            />

                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        '20px',
                                                                }}
                                                            >
                                                                <ConsumerButton
                                                                    btnWidth={
                                                                        isMobile
                                                                            ? '100%'
                                                                            : '334px'
                                                                    }
                                                                    btnHeight="53px"
                                                                    btnBgColor="#004AAD"
                                                                    btnTextColor="#FFFFFF"
                                                                    handleClick={() => {
                                                                        handleShowNext()
                                                                        if (
                                                                            checked
                                                                        ) {
                                                                            subscribe(
                                                                                {
                                                                                    EMAIL: inputs.email,
                                                                                },
                                                                            )
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        nextButtonDisabled
                                                                    }
                                                                    role="next"
                                                                >
                                                                    Next
                                                                </ConsumerButton>
                                                            </div>
                                                            <div className="ConsumerCheckboxWrapper">
                                                                <Checkbox
                                                                    handleChecked={
                                                                        handleChecked
                                                                    }
                                                                    signupchecked={
                                                                        checked
                                                                    }
                                                                    isChecked={
                                                                        checked
                                                                    }
                                                                >
                                                                    <p>
                                                                        Join
                                                                        SunFi’s
                                                                        community
                                                                        for
                                                                        exclusive
                                                                        updates
                                                                        on our
                                                                        solutions
                                                                        and stay
                                                                        up-to-date
                                                                        on the
                                                                        latest
                                                                        renewable
                                                                        energy
                                                                        news.
                                                                    </p>
                                                                </Checkbox>
                                                            </div>
                                                        </form>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </section>
                                )
                            }}
                        />
                    </div>
                </div>

                {!isGetstartedPage && (
                    <section className="FeaturedIconsStyle">
                        <p>SUNFI HAS BEEN FEATURED ON</p>

                        <div>
                            <>
                                <a
                                    href="https://techcrunch.com/2023/02/03/sunfi-wants-to-become-the-fastest-way-for-nigerians-to-find-finance-install-and-manage-solar/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAADYlsMn1SCoVFKyiJvnenSyxyh26QH7TAK0hcBpucRcRtbOCvEBYZUgxD87hOQG1q96hu4YrIxNBA_oo_2p8RCFGyKukEvJ2N8IGl_kwTeCQfHUrr1ksKWDzP9Sial7986kMCNXBUmnk-aTmeAVKd-X6mtTHtuE24OSXMlcjPGZS"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="TechCrunch"
                                        src={techCrunchIcon}
                                    />
                                </a>
                                <a
                                    href="https://techcabal.com/2022/04/28/sunfi-wants-to-improve-access-to-solar-services-for-middle-class-nigerians/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="TechCabal"
                                        src={techCabalIcon}
                                    />
                                </a>
                                <a
                                    href="https://www.norrsken.org/impact100"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="Norrsken"
                                        src={norrskenIcon}
                                    />
                                </a>
                                <a
                                    href="https://www.norrsken.org/impact100"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img className="Nasdaq" src={nasdaqIcon} />
                                </a>
                            </>
                        </div>
                    </section>
                )}
            </section>
        </>
    )
}

export default ConsumerMainSection

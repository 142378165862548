export const pageTitle =
    'Consumer Welcome | SunFi | Simplifying and Scaling Clean Energy'
export const pageUrlName = window.location.pathname
export const pageTracker = 'KYC_LOGIN'

export const signInOptions = [
    {
        title: 'Sign In as a Consumer',
        description: 'You purchased a system for personal/business use',
        link: '/consumer/login',
    },
    {
        title: 'Sign In as a Provider',
        description:
            'You own a business that sells and/or installs solar systems',
        link: '/login',
    },
]

process.env.REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE === 'true' &&
    signInOptions.push({
        title: 'Sign in as a Representative',
        description:
            'You represent your compay for group solar system interest',
        link: '/rep/login',
    })

import PropTypes from 'prop-types'
import SecondaryModal from '../../../../../components/Modal/SecondaryModal'
import FormTitleBar from '../../../../../components/FormTitleBar'
import Button from '../../../../../components/Button'
import styles from './modal.module.scss'
import { format, parseISO } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { statusMapping, pathMapping } from '../../Active/data'
import StatusIndicator from 'src/components/StatusIndicator'

const SelectPlan = ({ showModal, onCancel, data, tabId }) => {
    const history = useHistory()

    const canUseNewOfferStatus =
        process.env.REACT_APP_FLAG_SHOW_NEW_OFFER_STATUS === 'true'

    const redirectTo = pageUrl => {
        history.push({
            pathname: pageUrl,
        })
    }
    const {
        Wrapper,
        Content,
        PlansWrapper,
        Main,
        Left,
        Right,
        TopLeft,
        BottomLeft,
        SmallHeading,
        SmallText,
    } = styles
    return (
        <>
            <SecondaryModal
                showModal={showModal}
                onCancel={onCancel}
                closable
                modalWidth="630px"
                modalHeight="625px"
                content={
                    <div className={Wrapper}>
                        <div className={Content}>
                            <FormTitleBar
                                title={`Plans - ${data?.display_name}`}
                                subtitle="Select the plan you want to view"
                                fontFamily="SF-Pro-Display-Bold"
                                titleFontSize="20px"
                                marginBottom="50px"
                            />
                            <div className={PlansWrapper}>
                                {data?.estimation?.map((plan, i) => (
                                    <div key={i} className={Main}>
                                        <div className={Left}>
                                            <div className={TopLeft}>
                                                {canUseNewOfferStatus ? (
                                                    <StatusIndicator
                                                        status={
                                                            plan?.application_status
                                                        }
                                                        margin="0 0 4px 0"
                                                        arrowPositionLeft
                                                        toolTipMarginLeft="-18px"
                                                    />
                                                ) : (
                                                    <h4
                                                        style={{
                                                            color: `${
                                                                tabId === 2 &&
                                                                plan.status ===
                                                                    'active'
                                                                    ? '#004AAD'
                                                                    : '#233862E0'
                                                            }`,
                                                        }}
                                                    >
                                                        {tabId === 2 &&
                                                        plan?.status ===
                                                            'active'
                                                            ? 'ACTIVE'
                                                            : statusMapping[
                                                                  plan
                                                                      ?.approval_status
                                                              ]}
                                                    </h4>
                                                )}
                                                <h3>
                                                    {plan?.package_name ??
                                                        'N/A'}
                                                </h3>
                                            </div>
                                            <div className={BottomLeft}>
                                                <h4 className={SmallHeading}>
                                                    APPROVAL DATE
                                                </h4>
                                                <p className={SmallText}>
                                                    {plan?.approval_date !==
                                                    null
                                                        ? format(
                                                              parseISO(
                                                                  plan?.approval_date,
                                                              ),
                                                              'dd MMM, yyyy',
                                                          )
                                                        : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className={Right}>
                                            <div>
                                                <Button
                                                    btnWidth="70px"
                                                    btnHeight="37px"
                                                    btnBgColor="#004AAD"
                                                    btnTextColor="#FFFFFF"
                                                    fontSize="12px"
                                                    handleClick={() => {
                                                        if (
                                                            tabId === 2 &&
                                                            plan?.status ===
                                                                'active'
                                                        ) {
                                                            redirectTo(
                                                                `/app/customers/active/${plan?.id}`,
                                                            )
                                                        } else {
                                                            redirectTo(
                                                                `/app/customers/${
                                                                    pathMapping[
                                                                        plan
                                                                            ?.approval_status
                                                                    ]
                                                                }/${plan?.id}`,
                                                            )
                                                        }
                                                    }}
                                                >
                                                    View
                                                </Button>
                                            </div>
                                            <div>
                                                <h4 className={SmallHeading}>
                                                    FINANCING TENURE
                                                </h4>
                                                <p className={SmallText}>
                                                    {plan?.tenure} Months
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    )
}

SelectPlan.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    data: PropTypes.array,
    tabId: PropTypes.number,
}

export default SelectPlan

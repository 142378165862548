import { offerStatusTitles } from 'src/utils/Data/status'

export const statusOptions = [
    {
        value: 'Approved',
    },
    {
        value: 'Awaiting Approval',
    },
    {
        value: 'In Progress',
    },
    {
        value: 'Declined',
    },
]

export const newDEPStatusOptions = offerStatusTitles?.map(status => ({
    value: status,
}))

export const newDTCStatusOptions = offerStatusTitles
    ?.filter(status => status !== 'In-progress')
    ?.map(status => ({
        value: status,
    }))

export const approvalStatusMapping = {
    APPROVED: {
        bg: '#E2EEFF',
        color: '#004AAD',
        text: 'APPROVED',
    },
    AWAITING_APPROVAL: {
        bg: '#E5E5E580',
        color: '#233862B2',
        text: 'AWAITING APPROVAL',
    },
    IN_PROGRESS: {
        bg: '#FFFAEC',
        color: '#E4AA0F',
        text: 'IN PROGRESS',
    },
    AWAITING_UPDATE: {
        bg: '#FFFAEC',
        color: '#E4AA0F',
        text: 'IN PROGRESS',
    },
    DECLINED: {
        bg: '#F9FCFF',
        color: '#233862',
        text: 'DECLINED',
    },
    CLOSED: {
        bg: '#F9FCFF',
        color: '#233862',
        text: 'CLOSED',
    },
}

export const statusInverseMapping = {
    APPROVED: 'Approved',
    AWAITING_APPROVAL: 'Awaiting Approval',
    IN_PROGRESS: 'In Progress',
    AWAITING_UPDATE: 'In Progress',
    DECLINED: 'Declined',
    CLOSED: 'Declined',
}

export const statusPayloadMapping = {
    Approved: 'APPROVED',
    'Awaiting Approval': 'AWAITING_APPROVAL',
    'In Progress': 'IN_PROGRESS,AWAITING_UPDATE',
    Declined: 'DECLINED,CLOSED',
}

export const AlternateStatusPayloadMapping = {
    Approved: 'APPROVED',
    'Awaiting Approval': 'AWAITING_APPROVAL',
    'In Progress': 'IN_PROGRESS,AWAITING_UPDATE',
    Declined: 'DECLINED,CLOSED',
}

export const statementTypePayloadMap = {
    Mono: 'mono',
    Okra: 'okra',
    Manual: 'document',
}

export const providerTypePayloadMap = {
    OEMI: 'OEM Installer',
    DISI: 'Distributor Installer',
    ASI: 'Associate Installer',
}

export const providerTypeInverseMap = {
    'OEM Installer': 'OEMI',
    'Distributor Installer': 'DISI',
    'Associate Installer': 'ASI',
}

export const campaignTypePayloadMap = {
    Promo: 'PROMO',
    Cluster: 'CLUSTER',
}

export const onboardingTypePayloadMap = {
    Powercurve: 'ENERGY_ASSESSMENT',
    Profiles: 'ENERGY_PROFILE',
    Systems: 'ALL_SOLUTIONS',
    Promo: 'PROMO',
}
export const onboardingTypeColorMapping = {
    ALL_SOLUTIONS: {
        bgColor: '#FFFAEC',
        color: '#E4AA0F',
    },
    ENERGY_ASSESSMENT: {
        bgColor: '#E2EEFF',
        color: '#004AAD',
    },
    ENERGY_PROFILE: {
        bgColor: 'rgba(229, 229, 229, 0.50)',
        color: '#4D4D4D',
    },
    PROMO: {
        bgColor: 'rgba(50, 204, 121, 0.15)',
        color: '#32CC79',
    },
}

export const onboardingTypeMapping = {
    ENERGY_ASSESSMENT: 'Powercurve',
    ENERGY_PROFILE: 'Profile',
    ALL_SOLUTIONS: 'Select System',
    PROMO: 'Promo',
}

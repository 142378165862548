import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import BackNav from '../BackNav'
import { Dropdown, Skeleton, Tabs } from 'antd'
import './detailspage.scss'
import TabPaneAndTableOneSkeleton from '../AdminSkeletons/TabPaneAndTableOne'
import RightSection from './RightSectionSkeleton'
import Button, { AppButton } from '../Button'
import { ReactComponent as ArrowDown } from '../../assets/images/arrowDown.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { denyPermission } from 'src/utils/permissionFramework'
import { ReactComponent as ShareIcon } from 'src/assets/images/share-icon.svg'

const DetailsPage = ({
    backNavTitle,
    backNavUrl,
    backNavOnClick,
    name,
    status,
    statusType,
    nameAndStatusClassName,
    customStatusElement,
    noStatusFlag,
    infoSubhead,
    infoButton,
    infoButtonText,
    handleInfoButton,
    infoParagraph,
    tabPaneTwo,
    tabPaneThree,
    tabPaneFour,
    tabPaneOneTab,
    tabPaneTwoTab,
    tabPaneThreeTab,
    tabPaneFourTab,
    tabPaneOneContent,
    tabPaneTwoContent,
    tabPaneThreeContent,
    tabPaneFourContent,
    rightSectionHeading,
    placeRightSectionHeadingBottom,
    rowCount,
    gridItemOneHeading,
    gridItemTwoHeading,
    gridItemThreeHeading,
    gridItemFourHeading,
    gridItemFiveHeading,
    gridItemSixHeading,
    gridItemSevenHeading,
    gridItemEightHeading,
    gridItemOneContent,
    gridItemTwoContent,
    gridItemThreeContent,
    gridItemFourContent,
    gridItemFiveContent,
    gridItemSixContent,
    gridItemSevenContent,
    gridItemEightContent,
    isLoading,
    actionButton,
    secondButton,
    secondButtonType,
    buttonOneDisabled,
    buttonTwoDisabled,
    buttonOneHandleClick,
    buttonTwoHandleClick,
    buttonOneText,
    buttonTwoText,
    buttonOneWidth,
    dropdownMenu,
    gridItemWidth,
    noTopInfo,
    customRightSectionComponent,
    useCustomTable,
    customTable,
    additionalLeftSectionComponent,
    infoSubheadFontSize,
    userType,
    tabPaneFive,
    tabPaneFiveTab,
    tabPaneFiveContent,
    shareProfileClick,
    tabCurrentTab = '1',
    isUpdateTabUrl = false,
}) => {
    const history = useHistory()
    const [showDropdown, setShowDropdown] = useState(false)
    const { TabPane } = Tabs
    const { isMobile } = useMediaQueries()
    const [currentTab, setCurrentTab] = useState(tabCurrentTab)
    const isConsumerProfile = userType === 'admin_consumer_profile'
    // detect click outside dropdown
    const ref = useRef(null)
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropdown(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [showDropdown])

    const handleShowDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    useEffect(() => {
        if (isUpdateTabUrl) {
            history.replace(window.location.pathname + `?tab=${currentTab}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab])

    return (
        <div className="DetailsPageWrapper">
            <div>
                <BackNav
                    title={backNavTitle}
                    url={backNavUrl}
                    onClick={backNavOnClick}
                    marginTop={isMobile ? 0 : -35}
                    marginBottom={isMobile ? 50 : 65}
                    v2
                />

                <div className="DetailsPageMain">
                    <section className="DetailsPageLeftSection">
                        <div className="space-between">
                            <div className="DetailsPageNameAndStatus Desktop">
                                {isLoading ? (
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{
                                            width: '225px',
                                        }}
                                    />
                                ) : (
                                    <h2>{name}</h2>
                                )}
                                {!noStatusFlag && (
                                    <>
                                        {isLoading && (
                                            <Skeleton.Input
                                                active
                                                size="small"
                                                style={{ width: '125px' }}
                                            />
                                        )}
                                        {!isLoading &&
                                            customStatusElement &&
                                            customStatusElement}
                                        {!isLoading && !customStatusElement && (
                                            <div
                                                className={
                                                    statusType === 'secondary'
                                                        ? 'DetailsPageStatusSecTag'
                                                        : 'DetailsPageStatusTag'
                                                }
                                            >
                                                <p>{status}</p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            {isConsumerProfile && (
                                <div className="APDShareParentWrapper Desktop">
                                    {isLoading ? (
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{
                                                width: '100px',
                                            }}
                                        />
                                    ) : (
                                        <button
                                            className="APDShareWrapper"
                                            onClick={shareProfileClick}
                                        >
                                            <ShareIcon /> Share this consumer
                                            profile
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        {noTopInfo ? (
                            <></>
                        ) : (
                            <>
                                <div className="DetailsPageInfoSubheadAndButton Desktop">
                                    {isLoading ? (
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    ) : (
                                        <h4
                                            className="DetailsPageInfoSubhead"
                                            style={{
                                                fontSize: infoSubheadFontSize,
                                            }}
                                        >
                                            {infoSubhead}
                                        </h4>
                                    )}

                                    {infoButton && (
                                        <>
                                            {isLoading ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{ width: '125px' }}
                                                />
                                            ) : (
                                                <p
                                                    onClick={handleInfoButton}
                                                    className="DetailsPageInfoButton"
                                                >
                                                    {infoButtonText}
                                                </p>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div
                                    className={`DetailsPageInfoParagraphWrapper Desktop ${
                                        nameAndStatusClassName ?? ''
                                    }`}
                                >
                                    {isLoading ? (
                                        <div style={{ display: 'grid' }}>
                                            {[...Array(3)].map(i => (
                                                <Skeleton.Input
                                                    active
                                                    key={i}
                                                    size="small"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                        marginTop: '10px',
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="DetailsPageInfoParagraph">
                                            {infoParagraph}
                                        </p>
                                    )}
                                </div>
                            </>
                        )}

                        {useCustomTable ? (
                            <div>{customTable}</div>
                        ) : (
                            <div
                                style={{
                                    marginTop: noTopInfo && '48px',
                                }}
                            >
                                {isLoading ? (
                                    <TabPaneAndTableOneSkeleton />
                                ) : (
                                    <div>
                                        <Tabs
                                            activeKey={currentTab}
                                            onChange={e => {
                                                setCurrentTab(e)
                                            }}
                                        >
                                            <TabPane
                                                tab={tabPaneOneTab}
                                                key="1"
                                            >
                                                <div style={{ marginTop: 16 }}>
                                                    {tabPaneOneContent}
                                                </div>
                                            </TabPane>
                                            {tabPaneTwo && (
                                                <TabPane
                                                    tab={tabPaneTwoTab}
                                                    key="2"
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: 16,
                                                        }}
                                                    >
                                                        {tabPaneTwoContent}
                                                    </div>
                                                </TabPane>
                                            )}
                                            {tabPaneThree && (
                                                <TabPane
                                                    tab={tabPaneThreeTab}
                                                    key="3"
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: 16,
                                                        }}
                                                    >
                                                        {tabPaneThreeContent}
                                                    </div>
                                                </TabPane>
                                            )}
                                            {tabPaneFour && (
                                                <TabPane
                                                    tab={tabPaneFourTab}
                                                    key="4"
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: 16,
                                                        }}
                                                    >
                                                        {tabPaneFourContent}
                                                    </div>
                                                </TabPane>
                                            )}
                                            {tabPaneFive && (
                                                <TabPane
                                                    tab={tabPaneFiveTab}
                                                    key="5"
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: 16,
                                                        }}
                                                    >
                                                        {tabPaneFiveContent}
                                                    </div>
                                                </TabPane>
                                            )}
                                        </Tabs>
                                    </div>
                                )}
                            </div>
                        )}

                        {!isLoading &&
                            additionalLeftSectionComponent &&
                            additionalLeftSectionComponent}
                    </section>
                    <div className="DetailsPageDivider"></div>
                    <section className="DetailsPageRightSection">
                        {isLoading ? (
                            <RightSection
                                actionButton={actionButton}
                                secondButton={secondButton}
                                rowCount={rowCount}
                                infoButton={infoButton}
                                noStatusFlag={noStatusFlag}
                                noTopInfo={noTopInfo}
                            />
                        ) : (
                            <>
                                <div>
                                    <div
                                        className={`DetailsPageNameAndStatus Mobile ${
                                            nameAndStatusClassName ?? ''
                                        }`}
                                    >
                                        <h2>{name}</h2>
                                        {!noStatusFlag && (
                                            <>
                                                {isLoading && (
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '125px',
                                                        }}
                                                    />
                                                )}
                                                {!isLoading &&
                                                    customStatusElement &&
                                                    customStatusElement}
                                                {!isLoading &&
                                                    !customStatusElement && (
                                                        <div className="DetailsPageStatusTag Mobile">
                                                            <p>{status}</p>
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                    {isConsumerProfile && (
                                        <div className="APDShareParentWrapper Mobile">
                                            {isLoading ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '100px',
                                                    }}
                                                />
                                            ) : (
                                                <button
                                                    className="APDShareWrapper"
                                                    onClick={shareProfileClick}
                                                >
                                                    <ShareIcon /> Share this
                                                    consumer profile
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <h3
                                    className={`DetailsPageRightSectionHeading ${
                                        placeRightSectionHeadingBottom
                                            ? 'Heading'
                                            : ''
                                    }`}
                                >
                                    {rightSectionHeading}
                                </h3>
                                {rowCount > 0 && (
                                    <div className="DetailsPageRightSectionGrid">
                                        <div>
                                            <h4>{gridItemOneHeading}</h4>
                                            <p>{gridItemOneContent}</p>
                                        </div>
                                        <div>
                                            <h4>{gridItemTwoHeading}</h4>
                                            <p>{gridItemTwoContent}</p>
                                        </div>
                                    </div>
                                )}
                                {rowCount >= 2 && (
                                    <div className="DetailsPageRightSectionGrid">
                                        <div>
                                            <h4>{gridItemThreeHeading}</h4>
                                            <p>{gridItemThreeContent}</p>
                                        </div>
                                        <div>
                                            <h4>{gridItemFourHeading}</h4>
                                            <p>{gridItemFourContent}</p>
                                        </div>
                                    </div>
                                )}
                                {rowCount >= 3 && (
                                    <div className="DetailsPageRightSectionGrid">
                                        <div>
                                            <h4>{gridItemFiveHeading}</h4>
                                            <p style={{ width: gridItemWidth }}>
                                                {gridItemFiveContent}
                                            </p>
                                        </div>
                                        <div>
                                            <h4>{gridItemSixHeading}</h4>
                                            <p>{gridItemSixContent}</p>
                                        </div>
                                    </div>
                                )}
                                {rowCount >= 4 && (
                                    <div className="DetailsPageRightSectionGrid">
                                        <div>
                                            <h4>{gridItemSevenHeading}</h4>
                                            <p style={{ width: gridItemWidth }}>
                                                {gridItemSevenContent}
                                            </p>
                                        </div>
                                        <div>
                                            <h4>{gridItemEightHeading}</h4>
                                            <p>{gridItemEightContent}</p>
                                        </div>
                                    </div>
                                )}

                                {/*info section for mobile*/}
                                {noTopInfo ? (
                                    <></>
                                ) : (
                                    <>
                                        <div className="DetailsPageInfoSubheadAndButton Mobile">
                                            <h4 className="DetailsPageInfoSubhead">
                                                {infoSubhead}
                                            </h4>

                                            {infoButton && (
                                                <p
                                                    onClick={handleInfoButton}
                                                    className="DetailsPageInfoButton"
                                                >
                                                    {infoButtonText}
                                                </p>
                                            )}
                                        </div>

                                        <div className="DetailsPageInfoParagraphWrapper Mobile">
                                            <p className="DetailsPageInfoParagraph">
                                                {infoParagraph}
                                            </p>
                                        </div>
                                        <h3
                                            className={`DetailsPageRightSectionHeading ${
                                                placeRightSectionHeadingBottom
                                                    ? 'BottomHeading'
                                                    : 'RemoveBottomHeading'
                                            }`}
                                        >
                                            {rightSectionHeading}
                                        </h3>
                                    </>
                                )}
                                <div className="DetailsPageButtonsWrapper">
                                    {actionButton && (
                                        <AppButton
                                            btnWidth={
                                                isMobile
                                                    ? '160px'
                                                    : buttonOneWidth ?? '136px'
                                            }
                                            btnBgColor="var(--blue)"
                                            btnTextColor="var(--white)"
                                            btnHeight={
                                                isMobile ? '56px' : '44px'
                                            }
                                            disabled={
                                                buttonOneDisabled ||
                                                (buttonOneText === 'Approve' &&
                                                    userType === 'admin-user')
                                                    ? denyPermission(
                                                          'admin',
                                                          'unapproved_providers',
                                                          'providers:can_approve',
                                                      )
                                                    : buttonOneText ===
                                                          'Activate' &&
                                                      userType === 'admin-user'
                                                    ? denyPermission(
                                                          'admin',
                                                          'suspended_providers',
                                                          'providers:can_activate',
                                                      )
                                                    : userType === 'admin-user'
                                                    ? denyPermission(
                                                          'admin',
                                                          'approved_providers',
                                                          'providers:can_add_user',
                                                      )
                                                    : null
                                            }
                                            handleClick={buttonOneHandleClick}
                                        >
                                            {buttonOneText}
                                        </AppButton>
                                    )}
                                    {secondButton && (
                                        <>
                                            {secondButtonType ===
                                            'moreActions' ? (
                                                <div ref={ref}>
                                                    <Dropdown
                                                        overlay={dropdownMenu}
                                                        visible={showDropdown}
                                                        placement="bottomCenter"
                                                    >
                                                        <Button
                                                            btnBgColor="var(--purple-light)"
                                                            btnTextColor="#004AAD"
                                                            btnTextColorOutline="var(--blue)"
                                                            btnOutlineColor="var(--purple-light)"
                                                            btnBgColorOutline="#E2EEFF"
                                                            type="outline"
                                                            btnHeight={
                                                                isMobile
                                                                    ? '56px'
                                                                    : '44px'
                                                            }
                                                            btnWidth={
                                                                isMobile
                                                                    ? '160px'
                                                                    : '136px'
                                                            }
                                                            handleClick={
                                                                handleShowDropdown
                                                            }
                                                        >
                                                            More Actions
                                                            <ArrowDown
                                                                style={{
                                                                    marginLeft: 12,
                                                                }}
                                                            />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                            ) : (
                                                <AppButton
                                                    btnBgColor="#E2EEFF"
                                                    btnTextColor="#004AAD"
                                                    btnTextColorOutline="var(--blue)"
                                                    btnOutlineColor="#E2EEFF"
                                                    btnWidth={
                                                        isMobile
                                                            ? '160px'
                                                            : '136px'
                                                    }
                                                    btnHeight={
                                                        isMobile
                                                            ? '56px'
                                                            : '44px'
                                                    }
                                                    disabled={buttonTwoDisabled}
                                                    handleClick={
                                                        buttonTwoHandleClick
                                                    }
                                                >
                                                    {buttonTwoText}
                                                </AppButton>
                                            )}
                                        </>
                                    )}
                                </div>

                                {customRightSectionComponent}
                            </>
                        )}
                    </section>
                </div>
            </div>
        </div>
    )
}

DetailsPage.propTypes = {
    backNavTitle: PropTypes.string,
    backNavUrl: PropTypes.string,
    backNavOnClick: PropTypes.func,
    name: PropTypes.string,
    status: PropTypes.string,
    statusType: PropTypes.string,
    nameAndStatusClassName: PropTypes.string,
    customStatusElement: PropTypes.node,
    noStatusFlag: PropTypes.bool,
    infoSubhead: PropTypes.string,
    infoButton: PropTypes.bool,
    infoButtonText: PropTypes.string,
    handleInfoButton: PropTypes.func,
    infoParagraph: PropTypes.string,
    tabPaneTwo: PropTypes.bool,
    tabPaneThree: PropTypes.bool,
    tabPaneFour: PropTypes.bool,
    tabPaneOneTab: PropTypes.string,
    tabPaneTwoTab: PropTypes.string,
    tabPaneThreeTab: PropTypes.string,
    tabPaneFourTab: PropTypes.string,
    tabPaneOneContent: PropTypes.element,
    tabPaneTwoContent: PropTypes.element,
    tabPaneThreeContent: PropTypes.element,
    tabPaneFourContent: PropTypes.element,
    rightSectionHeading: PropTypes.string,
    placeRightSectionHeadingBottom: PropTypes.bool,
    rowCount: PropTypes.number,
    gridItemOneHeading: PropTypes.string,
    gridItemTwoHeading: PropTypes.string,
    gridItemThreeHeading: PropTypes.string,
    gridItemFourHeading: PropTypes.string,
    gridItemFiveHeading: PropTypes.string,
    gridItemSixHeading: PropTypes.string,
    gridItemSevenHeading: PropTypes.string,
    gridItemEightHeading: PropTypes.string,
    gridItemOneContent: PropTypes.string || PropTypes.number,
    gridItemTwoContent: PropTypes.string || PropTypes.number,
    gridItemThreeContent: PropTypes.string || PropTypes.number,
    gridItemFourContent: PropTypes.string || PropTypes.number,
    gridItemFiveContent: PropTypes.string || PropTypes.number,
    gridItemSixContent: PropTypes.string || PropTypes.number,
    gridItemSevenContent: PropTypes.string || PropTypes.number,
    gridItemEightContent: PropTypes.string || PropTypes.number,
    isLoading: PropTypes.bool,
    actionButton: PropTypes.bool,
    secondButton: PropTypes.bool,
    secondButtonType: PropTypes.string,
    buttonOneDisabled: PropTypes.bool,
    buttonTwoDisabled: PropTypes.bool,
    buttonOneHandleClick: PropTypes.func,
    buttonTwoHandleClick: PropTypes.func,
    buttonOneText: PropTypes.string,
    buttonTwoText: PropTypes.string,
    buttonOneWidth: PropTypes.string,
    dropdownMenu: PropTypes.any,
    gridItemWidth: PropTypes.string,
    noTopInfo: PropTypes.bool,
    customRightSectionComponent: PropTypes.node,
    useCustomTable: PropTypes.bool,
    customTable: PropTypes.element,
    additionalLeftSectionComponent: PropTypes.node,
    infoSubheadFontSize: PropTypes.string,
    userType: PropTypes.string,
    tabPaneFive: PropTypes.bool,
    tabPaneFiveTab: PropTypes.string,
    tabPaneFiveContent: PropTypes.element,
    shareProfileClick: PropTypes.func,
    tabCurrentTab: PropTypes.string,
    isUpdateTabUrl: PropTypes.bool,
}

export default DetailsPage

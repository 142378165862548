import { useState } from 'react'
import PropTypes from 'prop-types'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import Label from '../../_components/Label'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { businessIndustry, businessType } from 'src/utils/business'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'
import Toast from 'src/components/Toast'
import { IconInputField } from 'src/components/InputFields/CostField'
import LinkIcon from 'src/assets/images/link.svg'
import useConsumerKyc from '../../../hooks/useConsumerKyc'
import useVerifyCac from '../hooks/useVerifyCac'
import { useAppContext } from 'src/appContext/AppContext'
import { decodeUserInfo } from 'src/utils/auth'
// import CacDataInfo from './CacDataInfo'
import useScrollToError from '../../../hooks/useScrollToError'
import FormSkeleton from '../../../_components/FormSkeleton'
import CaretCircle from '../../../../../../../assets/images/circle-right-white.svg'
import { appTracking } from 'src/utils/appTracker'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import VerificationFailedContent from '../../../Verification/components/verificationHelp'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'

const BusinessInfo = ({
    updateErrors,
    removeFromUpdateErrs,
    setCurrentTab,
    setShowVideoModal,
}) => {
    const { refetch, isFetching } = useConsumerKyc()
    const [verificationFailedModal, setVerificationFailedModal] =
        useState(false)
    const [modalOnlyInputError, setModalOnlyInputError] = useState({})
    const handleModalInputError = (action, field, msg) => {
        if (action === 'add') {
            setModalOnlyInputError(prevState => ({
                ...prevState,
                [field]: msg,
            }))
        } else {
            setModalOnlyInputError(prevState => {
                const newState = { ...prevState }
                delete newState[field]
                return newState
            })
        }
    }
    const {
        validateCacMutation,
        toastMessage,
        setToastMessage,
        toastType,
        modalToastType,
        modalToastMessage,
    } = useVerifyCac({
        closeModal,
        refetch,
        setVerificationFailedModal,
        handleModalInputError,
    })
    const [verificationModal, setVerificationModal] = useState(false)
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const shouldDisplayVideo =
        !isAdminUser && process.env.REACT_APP_FLAG_SHOW_HOW_TO_VIDEO === 'true'

    const { businessInfoFormik, isCustomerApproved } = useAppContext()
    const {
        values: businessInfoValues,
        setFieldError,
        setFieldValue,
        touched,
        setFieldTouched,
        errors,
        handleChange,
        handleBlur,
    } = businessInfoFormik
    function closeModal() {
        setVerificationModal(false)
        setVerificationFailedModal(false)
    }
    const pageTitle =
        'KYC - Business Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const [verificationInProgress, setVerificationInProgress] = useState(false)

    const confirmCac = () => {
        validateCacMutation.mutate({
            cac_number: businessInfoValues.cacNumber,
            business_name: businessInfoValues.businessName,
        })
    }

    // at this point, contact person email or phone number hasn't been inputed and endpoint does not accept empty string
    //  so I'm using the email or phonenumber used to sign in and adding a placeholder email and phonenumber as backup

    const cacHelpPayload = {
        email: userInfo?.email || 'support@sunfi.co',
        name: businessInfoValues?.businessName,
        message: 'CAC Verification failed and consumer is requesting for help',
        phone_number: userInfo?.phoneNumber || '+2347080639920',
    }

    const handleCacVerifyModal = () => {
        setVerificationModal(true)
    }

    useScrollToError(updateErrors)

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_BUSINESS_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    const { BVNAlert } = useCustomToast()

    return (
        <>
            {/* Modal start */}
            <SecondaryModal
                showModal={verificationModal}
                onCancel={() => closeModal()}
                modalWidth={'475px'}
                title={
                    verificationFailedModal
                        ? 'Unable to verify'
                        : 'Verify CAC number'
                }
                content={
                    verificationFailedModal ? (
                        <VerificationFailedContent
                            payload={cacHelpPayload}
                            onCancel={closeModal}
                            setVerificationInProgress={
                                setVerificationInProgress
                            }
                            setToastMessage={setToastMessage}
                            type={'cac'}
                        />
                    ) : (
                        <div className="BusinessInfo_CACMOdalContent-Wrapper">
                            <div style={{ position: 'relative' }}>
                                {modalToastMessage && (
                                    <Toast
                                        message={modalToastMessage}
                                        messageType={modalToastType}
                                        closable
                                        width={isMobile ? '100%' : '367px'}
                                    />
                                )}
                                <Label
                                    title="Confirm your business name"
                                    subTitle="Please confirm your registered name before continuing"
                                />
                                <InputFields
                                    title="Business Name"
                                    name="businessName"
                                    value={businessInfoValues.businessName}
                                    handleChange={e => {
                                        handleChange(e)
                                        handleModalInputError(
                                            'remove',
                                            'businessName',
                                        )
                                    }}
                                    onBlur={e => {
                                        handleBlur(e)
                                        handleTracking(e)
                                    }}
                                    errorMessage={
                                        (touched.businessName &&
                                            errors.businessName) ||
                                        modalOnlyInputError.businessName
                                    }
                                    disabled={isCustomerApproved}
                                />
                                <VerticalSpacer top="32px" />
                                <Label
                                    title="Confirm your CAC number"
                                    subTitle="Please confirm your CAC number before continuing"
                                />
                                <InputFields
                                    title="CAC Number"
                                    name="cacNumber"
                                    value={businessInfoValues.cacNumber}
                                    handleChange={handleChange}
                                    onBlur={e => {
                                        handleBlur(e)
                                        handleTracking(e)
                                    }}
                                    errorMessage={
                                        touched.cacNumber && errors.cacNumber
                                    }
                                    disabled={isCustomerApproved}
                                />
                            </div>
                        </div>
                    )
                }
                showFooter
                footerLeftContent={verificationFailedModal ? 'chat' : 'button'}
                handleSecondaryBtnClick={() => {
                    setFieldError('businessNameVerified', '')
                    setFieldError('businessRegistrationNumberVerified', '')
                    closeModal()
                    BVNAlert(
                        'Please, go ahead to provide your other details',
                        isMobile ? '100%' : '511px',
                    )
                }}
                secondaryBtnText="I'll verify later"
                primaryBtnText={
                    verificationFailedModal ? 'Try again' : 'Confirm'
                }
                v2
                handlePrimaryBtnClick={confirmCac}
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                primaryBtnLoading={validateCacMutation.isLoading}
                kycSecondaryButtonStopLoading
            />
            {/* Modal end */}

            <Toast
                message={toastMessage}
                messageType={toastType}
                setToast={setToastMessage}
                closable={true}
                position="fixed"
                left={isMobile ? '0%' : '12%'}
                top="13.5%"
                zIndex="5"
            />
            {isFetching ? (
                <FormSkeleton />
            ) : (
                <>
                    {shouldDisplayVideo && (
                        <div
                            className="BusinessFormPageVideoMobile"
                            onClick={() => setShowVideoModal(true)}
                        >
                            <PlayIcon />
                            <p>Watch how to submit your info</p>
                        </div>
                    )}
                    <Label
                        title="Whats your business name?"
                        subTitle="Provide us with your business name as it was registered"
                    />
                    <InputFields
                        title="Registered Business Name"
                        name="businessName"
                        value={businessInfoValues.businessName}
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('businessName')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        verified={businessInfoValues.businessNameVerified}
                        errorMessage={
                            updateErrors.businessName ||
                            (touched.businessName && errors?.businessName
                                ? errors?.businessName
                                : touched.cacNumber &&
                                  businessInfoValues.cacNumber &&
                                  errors?.businessNameVerified)
                        }
                        disabled={
                            (businessInfoValues.businessRegistrationNumberVerified &&
                                businessInfoValues.businessNameVerified) ||
                            isAdminUser ||
                            isCustomerApproved
                        }
                    />
                    <Label
                        title="Business Details"
                        subTitle="Provide us with information on your business and confirm your CAC number"
                        showHelpIcon
                        tooltipText={
                            'With your CAC number we can validate your business name, and also verify your business.'
                        }
                    />
                    <SelectField
                        floatLabelOnSelectedValue
                        floatingLabel="Business Type"
                        name="businessType"
                        initialOption="Business Type"
                        selectWidth="100%"
                        marginBottom="24px"
                        values={businessType.map(option => ({
                            value: option,
                        }))}
                        value={businessInfoValues.businessType}
                        currentSelected={businessInfoValues.businessType}
                        handleChange={(_, value) => {
                            setFieldValue('businessType', value)
                            removeFromUpdateErrs('businessType')
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                `FILLED_${_?.toUpperCase()}`,
                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                'KYC_BUSINESS_INFO',
                                ['GA', 'MP'],
                                'event',
                                { value },
                            )
                        }}
                        onBlur={() => {
                            setFieldTouched('businessType', true)
                            if (!businessInfoValues.businessType)
                                setFieldError(
                                    'businessType',
                                    errors.businessType,
                                )
                        }}
                        withCheckBox
                        errorMessage={
                            updateErrors.businessType ||
                            (touched.businessType && errors?.businessType)
                        }
                        dropdownPositionRelative
                        disabled={isAdminUser || isCustomerApproved}
                    />
                    <SelectField
                        floatLabelOnSelectedValue
                        floatingLabel="Business Industry"
                        initialOption="Business Industry"
                        name="businessIndustry"
                        selectWidth="100%"
                        marginBottom="24px"
                        values={businessIndustry.map(option => ({
                            value: option,
                        }))}
                        value={businessInfoValues.businessIndustry}
                        currentSelected={businessInfoValues.businessIndustry}
                        handleChange={(_, value) => {
                            setFieldValue('businessIndustry', value)
                            removeFromUpdateErrs('businessIndustry')
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                `FILLED_${_?.toUpperCase()}`,
                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                'KYC_BUSINESS_INFO',
                                ['GA', 'MP'],
                                'event',
                                { value },
                            )
                        }}
                        onBlur={() => {
                            setFieldTouched('businessIndustry', true)
                            if (!businessInfoValues.businessIndustry)
                                setFieldError(
                                    'businessIndustry',
                                    errors.businessIndustry,
                                )
                        }}
                        withCheckBox
                        errorMessage={
                            updateErrors.businessIndustry ||
                            (touched?.businessIndustry &&
                                errors?.businessIndustry)
                        }
                        dropdownPositionRelative
                        disabled={isAdminUser || isCustomerApproved}
                    />
                    <div className="VerifyInputWrapper">
                        <InputFields
                            title="CAC number"
                            name="cacNumber"
                            value={businessInfoValues.cacNumber}
                            handleChange={e => {
                                handleChange(e)
                                removeFromUpdateErrs('cacNumber')
                            }}
                            onBlur={e => {
                                handleBlur(e)
                                handleTracking(e)
                            }}
                            marginBottom="6px"
                            verified={
                                businessInfoValues.businessRegistrationNumberVerified &&
                                businessInfoValues.businessNameVerified
                            }
                            disabled={
                                (businessInfoValues.businessRegistrationNumberVerified &&
                                    businessInfoValues.businessNameVerified) ||
                                isAdminUser ||
                                isCustomerApproved ||
                                verificationInProgress
                            }
                            errorMessage={
                                updateErrors.cacNumber ||
                                (touched.cacNumber && errors.cacNumber
                                    ? errors.cacNumber
                                    : touched.cacNumber &&
                                      businessInfoValues.cacNumber &&
                                      errors?.businessRegistrationNumberVerified)
                            }
                            inprogress={verificationInProgress}
                        />
                        {verificationInProgress && (
                            <span className="verificationInProgressStyle">
                                Verification In Progress
                            </span>
                        )}
                        {!verificationInProgress && (
                            <div className="VerifyCTA">
                                <button
                                    hidden={
                                        isAdminUser ||
                                        (businessInfoValues.businessRegistrationNumberVerified &&
                                            businessInfoValues.businessNameVerified)
                                    }
                                    disabled={
                                        businessInfoValues.businessName ===
                                            '' ||
                                        (businessInfoValues.businessRegistrationNumberVerified &&
                                            businessInfoValues?.businessNameVerified) ||
                                        errors.cacNumber ||
                                        isAdminUser ||
                                        isCustomerApproved
                                    }
                                    onClick={() => {
                                        handleCacVerifyModal()
                                    }}
                                    style={{ width: '126px' }}
                                >
                                    {isMobile ? 'Verify' : 'Verify CAC Number'}
                                </button>
                            </div>
                        )}
                        {/* TODO: To be implemented later
                                It requires a pending task from the backend
                             */}
                        {/* {isAdminUser && <CacDataInfo title="CAC DATA" />} */}
                    </div>
                    <VerticalSpacer bottom="24px" />
                    <InputFields
                        title="Number of years in operation"
                        name="numberOfYearsInOperation"
                        value={businessInfoValues.numberOfYearsInOperation}
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('numberOfYearsInOperation')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        errorMessage={
                            updateErrors.numberOfYearsInOperation ||
                            (touched?.numberOfYearsInOperation &&
                                errors?.numberOfYearsInOperation)
                        }
                        disabled={isAdminUser || isCustomerApproved}
                    />

                    <IconInputField
                        icon={LinkIcon}
                        name="website"
                        title="Website"
                        width={'100%'}
                        value={businessInfoValues.website}
                        handleChange={handleChange}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        errorMessage={touched.website && errors?.website}
                        disabled={isAdminUser || isCustomerApproved}
                        optionalBottom={errors?.website ? false : true}
                    />

                    <TextArea
                        title="Description of business"
                        name="businessDescription"
                        value={businessInfoValues.businessDescription}
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('businessDescription')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        rows={5}
                        height="162px"
                        errorMessage={
                            updateErrors.businessDescription ||
                            (touched?.businessDescription &&
                                errors.businessDescription)
                        }
                        disabled={isAdminUser || isCustomerApproved}
                    />

                    <div className="formStep_b">
                        <p>Completed your business info?</p>
                        <button
                            onClick={() => {
                                setCurrentTab('2')
                            }}
                        >
                            Go to contact info{' '}
                            <span>
                                <img
                                    src={CaretCircle}
                                    alt="right icon"
                                    width={14}
                                    height={14}
                                />
                            </span>
                        </button>
                    </div>
                </>
            )}
        </>
    )
}

BusinessInfo.propTypes = {
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    setCurrentTab: PropTypes.func,
    setShowVideoModal: PropTypes.func,
}

export default BusinessInfo

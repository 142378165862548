import { ReactComponent as LayerIcon } from 'src/assets/images/layer-icon.svg'
import { ReactComponent as PublicProfileIcon } from 'src/assets/images/public-profile-icon.svg'
import { ReactComponent as ProfilesIcon } from 'src/assets/images/profiles-icon.svg'

export const tabs = [
    {
        key: '1',
        tab: 'Published consumer profiles',
    },
    {
        key: '2',
        tab: 'Unpublished consumer profiles',
    },
]

export const tableHeaders = [
    'Consumer profile name',
    'Packages added',
    'Consumer profile type',
    'Last Updated Time',
    'Actions',
]

export const profileCreationModalData = [
    {
        id: 1,
        disabled: false,
        title: 'Create as a non-promotional profile',
        subTitle: 'Create a profile that is not being promoted',
        icon: (
            <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.125 9.5835L11.5 3.8335L2.875 9.5835L11.5 15.3335L20.125 9.5835Z"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.125 13.4165L11.5 19.1665L2.875 13.4165"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        id: 2,
        disabled: true,
        title: 'Create as a public promotional profile',
        subTitle: 'Create a profile to be promoted to consumers',
        icon: (
            <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.86663 11.9974L11.0033 19.1341C11.3776 19.5083 11.9844 19.5083 12.3586 19.1341L19.1351 12.3576C19.5093 11.9834 19.5093 11.3766 19.1351 11.0023L11.9984 3.86565C11.7972 3.66454 11.5172 3.56315 11.234 3.5889L5.02221 4.15361C4.5615 4.19549 4.19646 4.56052 4.15458 5.02124L3.58988 11.233C3.56413 11.5162 3.66552 11.7963 3.86663 11.9974Z"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.95256 7.95305C8.32682 7.5788 8.9336 7.5788 9.30785 7.95305C9.68211 8.32731 9.68211 8.93409 9.30785 9.30834C8.9336 9.68259 8.32682 9.68259 7.95256 9.30834C7.57831 8.93409 7.57831 8.32731 7.95256 7.95305Z"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        id: 3,
        disabled:
            process.env
                .REACT_APP_FLAG_ENABLE_PRIVATE_PROMOTIONAL_CONSUMER_PROFILE ===
            'true'
                ? false
                : true,
        title: 'Create as a private promotional profile',
        subTitle: 'Create a profile for a specific type of consumer',
        icon: (
            <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.71094 19.1665C6.71094 17.5787 8.85624 16.2915 11.5026 16.2915C14.149 16.2915 16.2943 17.5787 16.2943 19.1665"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.25 13.6558C18.9423 14.0994 20.125 15.1126 20.125 16.2915"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.75 13.6558C4.05767 14.0994 2.875 15.1126 2.875 16.2915"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.5 13.4165C13.0878 13.4165 14.375 12.1293 14.375 10.5415C14.375 8.95369 13.0878 7.6665 11.5 7.6665C9.91218 7.6665 8.625 8.95369 8.625 10.5415C8.625 12.1293 9.91218 13.4165 11.5 13.4165Z"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.2474 9.80944C17.8356 9.28301 18.2057 8.51798 18.2057 7.6665C18.2057 6.07869 16.9185 4.7915 15.3307 4.7915C14.5944 4.7915 13.9227 5.06832 13.4141 5.52357"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.7474 9.80944C5.15922 9.28301 4.78906 8.51798 4.78906 7.6665C4.78906 6.07869 6.07624 4.7915 7.66406 4.7915C8.4004 4.7915 9.07209 5.06832 9.58073 5.52357"
                    stroke="#004AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
]

export const newProfileTypes = [
    {
        id: 1,
        title: 'Create as a non-promotional profile',
        subtitle: 'Create a profile to be accessed through lorem ipsum',
        icon: LayerIcon,
    },
    {
        id: 2,
        title: 'Create as a public promotional profile',
        subtitle: 'Create a profile to be promoted to consumers',
        icon: PublicProfileIcon,
    },
    {
        id: 3,
        title: 'Create as a private promotional profile',
        subtitle: 'Create a profile for a specific type of consumer',
        icon: ProfilesIcon,
    },
]

import { createContext, useContext, useState } from 'react'

import {
    useGetEnergyProfile,
    useGetEnergyProfileSolutions,
    useGetEnergyProfileSolutionsFilter,
} from 'src/api/shoppingExperience/energy-profile/queries'
import { sortByParam } from '../utils'
import { useParams } from 'react-router-dom'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'

export const useSolarSystems = () => {
    const { id } = useParams()
    const pageTitle =
        'Solar systems | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `SOLAR_SYSTEM_LISTING`
    const sharedProfile = pageUrlName.includes('/shared')
    // states
    const [showBuildCustomModal, setShowBuildCustomModal] = useState({
        open: false,
        type: 'cancel',
    })
    const [sortBy, setSortBy] = useState('Price: low to high')
    const [currentPage, setCurrentPage] = useState(1)
    const [filterInputs, setFilterInputs] = useState({
        system_size: [0, 0],
        system_type: '',
        payment_plan: '',
        battery_type: '',
        backup_hours: [0, 0],
        cost: [0, 0],
        backup_system: '',
    })
    const [isClosedCluster, setIsClosedCluster] = useState(false)

    const { getEnergyProfileSolutionsData, getEnergyProfileSolutionsFetching } =
        useGetEnergyProfileSolutions({
            enabled: true,
            params: {
                profile_id: id ?? '',
                page: currentPage,
                price_sort: sortByParam[sortBy],
                battery_types: filterInputs?.battery_type
                    ?.toLowerCase()
                    ?.replaceAll(' ', '_'),
                system_types: filterInputs?.system_type
                    ?.toLowerCase()
                    ?.replaceAll(' ', '_'),
                payment_plan: filterInputs?.payment_plan
                    ?.toUpperCase()
                    ?.replaceAll(' ', '_'),
                min_system_size:
                    filterInputs?.system_size[0] === 0
                        ? ''
                        : filterInputs?.system_size[0],
                max_system_size:
                    filterInputs?.system_size[1] === 0
                        ? ''
                        : filterInputs?.system_size[1],
                min_cost:
                    filterInputs?.cost[0] === 0 ? '' : filterInputs?.cost[0],
                max_cost:
                    filterInputs?.cost[1] === 0 ? '' : filterInputs?.cost[1],
                min_backup_hour:
                    filterInputs?.backup_hours[0] === 0
                        ? ''
                        : filterInputs?.backup_hours[0],
                max_backup_hour:
                    filterInputs?.backup_hours[1] === 0
                        ? ''
                        : filterInputs?.backup_hours[1],
            },
        })
        const getFilterDataOnSuccess = data => {
            setFilterInputs(prev => ({
                ...prev,
                system_size: [data?.min_system_size, data?.max_system_size],
            }))            
        }    
        const {
            getEnergyProfileSolutionsFilterData,
            getEnergyProfileSolutionsFilterFetching,
        } = useGetEnergyProfileSolutionsFilter({
            enabled: true,
            params: {
                profile_id: id ?? '',
            },
            onSuccess: getFilterDataOnSuccess,
        })

    const { getEnergyProfileData, getEnergyProfileFetching } =
        useGetEnergyProfile({
            enabled: id?.length ? true : false,
            params: {
                id: id ?? '',
            },
            onSuccess: data => {
                const isResidential = data?.customer_type === 'RESIDENTIAL'
                setIsClosedCluster(data?.promotional_type === 'PRIVATE')
                if (sharedProfile) {
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')[
                            'viewedSharedProfile'
                        ].action,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .label,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .category,
                        ['MP', 'GA'],
                        'event',
                    )
                } else {
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')[
                            'viewListing'
                        ].action,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .label,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .category,
                        ['MP', 'GA'],
                        'event',
                    )
                }
            },
        })

    const clearFilters = (data) => {
        setFilterInputs({
            system_size: [data?.min_system_size, data?.max_system_size],
            system_type: '',
            payment_plan: '',
            battery_type: '',
            backup_hours: [0, 0],
            cost: [0, 0],
            backup_system: '',
        })        
    }

    return {
        id,
        getEnergyProfileSolutionsData,
        getEnergyProfileSolutionsFetching,
        currentPage,
        setCurrentPage,
        sortBy,
        setSortBy,
        getEnergyProfileSolutionsFilterData,
        getEnergyProfileSolutionsFilterFetching,
        filterInputs,
        setFilterInputs,
        clearFilters,
        showBuildCustomModal,
        setShowBuildCustomModal,
        getEnergyProfileData,
        getEnergyProfileFetching,
        isClosedCluster,
        setIsClosedCluster,
    }
}

export const SolarSystemsContext = createContext({
    id: null,
    getEnergyProfileSolutionsData: {},
    getEnergyProfileSolutionsFetching: false,
    currentPage: 1,
    setCurrentPage: () => {},
    getEnergyProfileSolutionsFilterData: {},
    getEnergyProfileSolutionsFilterFetching: false,
    filterInputs: {
        system_size: [0, 0],
        system_type: '',
        payment_plan: '',
        battery_type: '',
        backup_hours: [0, 0],
        cost: [0, 0],
        backup_system: '',
    },
    setFilterInputs: () => {},
    sortBy: 'All',
    setSortBy: () => {},
    clearFilters: () => {},
    showBuildCustomModal: false,
    setShowBuildCustomModal: () => {},
    getEnergyProfileData: {},
    getEnergyProfileFetching: false,
    isClosedCluster: false,
    setIsClosedCluster: () => {},
})

export const useSolarSystemsContext = () => useContext(SolarSystemsContext)

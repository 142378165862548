import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { useState, useEffect } from 'react'
import './residenceform.scss'
import FormWrapper from '../_components/FormWrapper'
import PersonalInfo from './components/PersonalInfo'
import EmploymentInfo from './components/EmploymentInfo/EmploymentInfo'
import Toast from 'src/components/Toast'
import { validatePersonalInfoKyc } from 'src/utils/validationSchema'
import { usePersonalInfoContext } from '../context'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { decodeUserInfo } from 'src/utils/auth'
import useMappedErrors from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/hooks/useMappedErrors'
import { RESIDENTIAL_BASIC_INFO_UPDATE_REQUESTS_MAP } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/utils'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import CSSEVideoPlayer from 'src/components/CSSEVideoPlayer'

const ResidenceForm = ({
    toastMessage,
    setToastMessage,
    currentTab,
    setCurrentTab,
}) => {
    const [updateErrors, setUpdateErrors] = useState({})
    const { errorAlert } = useCustomToast()
    const { activeTab, setActiveTab, personalInfoFormik } =
        usePersonalInfoContext()
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const mappedErrors = useMappedErrors(
        RESIDENTIAL_BASIC_INFO_UPDATE_REQUESTS_MAP,
    )

    const shouldDisplayVideo =
        !isAdminUser && process.env.REACT_APP_FLAG_SHOW_HOW_TO_VIDEO === 'true'

    const [showVideoModal, setShowVideoModal] = useState(shouldDisplayVideo)
    const [showVideo, setShowVideo] = useState(false)

    const handleTabChange = tab => {
        if (tab === '2' && !isAdminUser) {
            validatePersonalInfoKyc
                .validate(personalInfoFormik.values, {
                    abortEarly: false,
                })
                .then(() => {
                    setCurrentTab(tab)
                    setActiveTab(tab)
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = {
                            ...errList,
                            [e.path]: e.message,
                        }
                    })
                    personalInfoFormik.setErrors(errList)
                    personalInfoFormik.setTouched(errList)

                    setCurrentTab('1')
                    setActiveTab('1')
                    errorAlert(Object.values(errList)[0])
                })
        } else {
            setCurrentTab(tab)
            setActiveTab(tab)
        }
    }

    const removeFromUpdateErrs = field => {
        const newErrs = { ...updateErrors }
        delete newErrs[field]
        setUpdateErrors(newErrs)
    }

    useEffect(() => {
        function scrolldiv() {
            var element = document.getElementById('scrolltoview_residence')
            element.scrollIntoView()
        }
        scrolldiv()
    }, [currentTab])

    useEffect(() => {
        setUpdateErrors(mappedErrors)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mappedErrors])

    return (
        <FormWrapper>
            <div className="ResidenceFormWrapper">
                <Toast
                    message={toastMessage?.message ?? ''}
                    messageType={toastMessage?.type ?? ''}
                    setToast={setToastMessage}
                    position={'fixed'}
                    top={'10%'}
                    left={'0%'}
                    zIndex={10}
                />
                {shouldDisplayVideo && (
                    <div
                        className="BusinessFormPageVideo"
                        onClick={() => setShowVideoModal(true)}
                    >
                        <PlayIcon />
                        <p>Watch how to fill your basic info</p>
                    </div>
                )}
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    <TabPane tab="Personal Info" key="1">
                        <PersonalInfo
                            updateErrors={updateErrors}
                            removeFromUpdateErrs={removeFromUpdateErrs}
                            setShowVideoModal={setShowVideoModal}
                        />
                    </TabPane>
                    <TabPane tab="Employment info" key="2">
                        <EmploymentInfo
                            setCurrentTab={handleTabChange}
                            updateErrors={updateErrors}
                            removeFromUpdateErrs={removeFromUpdateErrs}
                            setShowVideoModal={setShowVideoModal}
                        />
                    </TabPane>
                </Tabs>
            </div>
            {(showVideoModal || showVideo) && (
                <CSSEVideoPlayer
                    showVideoModal={showVideoModal}
                    setShowVideoModal={setShowVideoModal}
                    showVideo={showVideo}
                    setShowVideo={setShowVideo}
                    modalTitle="Get started with your KYC"
                    header="Submit your basic information"
                    videoInfo="Here’s a video to guide you on how you submit your basic information"
                    videoUrl="https://www.youtube.com/watch?v=ABdhW8N1rPk"
                    videoGIF="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_to_Submit_Your_Personal_Information.gif"
                    description="We need you to submit your full name (Exactly how you have it on your ID card), contact/installation address, and employment info"
                />
            )}
        </FormWrapper>
    )
}

ResidenceForm.propTypes = {
    loading: PropTypes.bool,
    inputs: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phone: PropTypes.string,
        phoneVerified: PropTypes.bool,
        email: PropTypes.string,
        emailVerified: PropTypes.bool,
        contactAddress: PropTypes.string,
        contactState: PropTypes.string,
        contactCity: PropTypes.string,
        contactCountry: PropTypes.string,
        confirmAddress: PropTypes.string,
        installationState: PropTypes.string,
        installationCity: PropTypes.string,
        installationCountry: PropTypes.string,
        installationAddress: PropTypes.string,
        // employment info
        employmentType: PropTypes.string,
        // employed inputs
        companyName: PropTypes.string,
        employmentContractUrl: PropTypes.string,
        jobTitle: PropTypes.string,
        jobStartDate: PropTypes.string,
        // employed & retired inputs
        linkedinPage: PropTypes.string,
        // retired inputs
        pensionManager: PropTypes.string,
        retirementDate: PropTypes.string,
        // self-employed inputs
        businessName: PropTypes.string,
        businessNameVerified: PropTypes.bool,
        businessType: PropTypes.string,
        businessIndustry: PropTypes.string,
        cacNumber: PropTypes.string,
        cacNumberVerified: PropTypes.bool,
        isBusinessRegistered: PropTypes.string,
        numberOfYearsInOperation: PropTypes.string,
        website: PropTypes.string,
        businessDescription: PropTypes.string,
        successUpload: PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
            originFileObj: PropTypes.string,
        }),
    }).isRequired,
    setInputs: PropTypes.func.isRequired,
    toastMessage: PropTypes.string.isRequired,
    setToastMessage: PropTypes.func.isRequired,
    currentTab: PropTypes.string.isRequired,
    setCurrentTab: PropTypes.func.isRequired,
}

export default ResidenceForm

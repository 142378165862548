import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import CloseIcon from 'src/assets/images/black-close-icon.svg'
import FormTitleBar from 'src/components/FormTitleBar'
import { isMobile } from 'src/utils/mediaQueries'
import styles from './CreditReviewTab.module.scss'
import Button from 'src/components/Button'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { floatWithCommas } from 'src/utils/formatting'
import PlanView from './PlanView'
import ActionModal from 'src/components/ActionModal'
import { rerunCreditDecision } from 'src/api/shoppingExperience/creditDecision'
import { getUserBankDetailsApi } from 'src/api/consumer/directDebit'
import { useAppContext } from 'src/appContext/AppContext'
import { InlineLoader } from 'src/components/Loader'
import DisputeCreditDecionBankStatement from './DisputeCreditDecisionBankStatement'
import {
    categoryMap,
    creditReportScoreData,
    creditReportScoreMapping,
    simulationData,
    simulationDataMapping,
    accountTypeData,
    isCategoryDisabled,
} from './data'
import { useGetBanks } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/FinancialInformation/FinancialInfoForm/api'

const DisputeCreditDecision = ({
    closeModal,
    evaluationCriteria,
    openModal,
    customerType,
    estimationId,
    accountType,
    adminRunCreditDecision = false,
}) => {
    const {
        DisputeCreditDecisionWrapper,
        DCDCategoryWrapperStyle,
        DCDCategorySectionStyle,
        DCDButtonWrapper,
        DCDCurrentScoreStyle,
        DCDCategoryWrapperPlanStyle,
    } = styles

    const { errorAlert, postDocumentToS3, isUploadingFileUrl } = useAppContext()

    const { data: bankLists } = useGetBanks()

    const initialInputs = {
        creditReportScore: '',
        creditReportSource: '',
        upfrontDeposit: '',
        generatorSpend: '',
        solarSpend: '',
        loanRepayment: '',
        avgRecurringExpense: '',
        avgMonthlyBalance: '',
        closingBalance: '',
        avgSalary: '',
        avgOtherIncome: '',
        avgCredit: '',
        avgDebit: '',
        accountSweep: '',
        gamblingRate: '',
        avgLoanAmount: '',
        reason: '',
        simulationType: '',
        accountType:
            accountType === true ? accountTypeData[0] : accountTypeData[1],
        bankDetails: '',
        accountNumber: '',
    }

    const [categoryId, setCategoryId] = useState(0)
    const [disputedCategories, setDisputedCategories] = useState({
        creditReport: false,
        upfrontDeposit: false,
        technoEconomicFit: false,
        bankStatement: false,
    })
    const [showReason, setShowReason] = useState(false)
    const [inputs, setInputs] = useState(initialInputs)
    const [newPlans, setNewPlans] = useState([])
    const [showNewPlans, setShowNewPlans] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [rerunCreditDecison, setRerunCreditDecision] = useState(false)
    const [actionType, setActionType] = useState('')
    const [checked, setChecked] = useState(false)
    const [showCheckBox, setShowCheckbox] = useState(false)
    const [fileValid, setFileValid] = useState(true)
    const [successUpload, setSuccessUpload] = useState([])
    const [bankStatementUrl, setBankStatementUrl] = useState('')
    const [accountName, setAccountName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const canUseSalaryAccFeature =
        process.env.REACT_APP_FLAG_SHOW_SALARY_ACCOUNT_OPTION === 'true'

    const handleInputChange = event => {
        setErrorMessage('')
        const { name, value } = event.target
        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/

        if (name === 'reason' || name === 'accountNumber') {
            setInputs(prev => ({ ...prev, [name]: value }))
        } else {
            setInputs(prev => ({
                ...prev,
                [name]: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs[name],
            }))
        }
    }

    const handleSelectChange = (name, value) => {
        if (name === 'bankDetails') {
            const bankDetails = bankLists.data?.filter(
                bank => bank.name === value,
            )[0]

            setInputs(prev => ({
                ...prev,
                [name]: bankDetails,
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }
    }
    const handleBlur = e => {
        const { name, value } = e.target
        if (name === 'accountNumber' && value.length !== 10) {
            setErrorMessage(prev => ({
                ...prev,
                [name]: 'Account number cannot be less than 10 digits',
            }))
        }

        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }
    const getCurrentScore = keyName => {
        return `${evaluationCriteria?.[keyName]?.weighted_score || '-'} / ${
            evaluationCriteria?.[keyName]?.weight_value || '-'
        }`
    }

    const categoryData = [
        {
            category: 'Credit report',
            currentScore: getCurrentScore('CREDIT_REPORT'),
            id: 1,
            key: 'creditReport',
        },
        {
            category: 'Upfront deposit',
            currentScore: getCurrentScore('UPFRONT_DEPOSIT'),
            id: 2,
            key: 'upfrontDeposit',
        },
        {
            category: 'Techno-economic fit',
            currentScore: getCurrentScore('TECHNO_ECONOMICS'),
            id: 3,
            key: 'technoEconomicFit',
        },
        {
            category: 'Bank statement',
            currentScore: getCurrentScore('BANK_STATEMENT_ANALYSIS'),
            id: 4,
            key: 'bankStatement',
        },
    ]

    const filteredCategoryData = adminRunCreditDecision
        ? categoryData?.filter(category => category.key === 'bankStatement')
        : categoryData

    const titleData = {
        0: showNewPlans
            ? "Consumer's New Assessment Criteria"
            : 'Select the category you want to dispute',
        1: 'Dispute Credit report result',
        2: `Dispute Upfront deposit ${showReason && 'result'}`,
        3: showReason
            ? 'Dispute Techno-economic result'
            : 'Update Techno-economic fit',
        4: showReason
            ? 'Dispute Bank statement result'
            : 'Update Bank statement',
        5: showReason
            ? 'Dispute Bank statement result'
            : 'Update Bank statement',
    }

    const subtitleData = {
        0: showNewPlans
            ? 'Based on the new updates that you have added for this consumer, see the updated assessment criteria below'
            : 'What category do you want to dispute for this consumer',
        1: "Please enter the following details to dispute the consumer's Credit report",
        2: "Please enter the following details to dispute the consumer's Upfront deposit",
        3: "Please enter the following details to update the consumer's Techno-economic fit",
        4: "Please enter the following details to update the consumer's Bank statement",
        5: "Please enter the following details to update the consumer's Bank statement",
    }

    const rerunCreditDecisionPayload = {
        reason: inputs?.reason,
        persist_dispute: actionType === 'apply' ? true : false,
        ...(disputedCategories?.creditReport && {
            credit_score: {
                credit_report_score: parseFloat(
                    inputs?.creditReportScore.replaceAll(',', ''),
                ),
                credit_report_source:
                    creditReportScoreMapping[inputs?.creditReportSource],
            },
        }),
        ...(disputedCategories?.upfrontDeposit && {
            upfront_deposit: parseFloat(inputs?.upfrontDeposit),
        }),
        ...(disputedCategories?.technoEconomicFit && {
            techno_economic_fit: {
                solar_spend: parseFloat(inputs?.solarSpend.replaceAll(',', '')),
                generator_spend: parseFloat(
                    inputs?.generatorSpend.replaceAll(',', ''),
                ),
            },
        }),
        ...(disputedCategories?.bankStatement && {
            bank_statement: {
                average_credit: parseFloat(
                    inputs?.avgCredit.replaceAll(',', ''),
                ),
                average_other_income: parseFloat(
                    inputs?.avgOtherIncome.replaceAll(',', ''),
                ),
                loan_repayment: parseFloat(
                    inputs?.loanRepayment.replaceAll(',', ''),
                ),
                average_recurring_expense: parseFloat(
                    inputs?.avgRecurringExpense.replaceAll(',', ''),
                ),
                average_monthly_balance: parseFloat(
                    inputs?.avgMonthlyBalance.replaceAll(',', ''),
                ),
                closing_balance: parseFloat(
                    inputs?.closingBalance.replaceAll(',', ''),
                ),
                average_debit: parseFloat(inputs?.avgDebit.replaceAll(',', '')),
                account_sweep: inputs?.accountSweep === 'Yes' ? true : false,
                account_number: inputs?.accountNumber,
                bank_code: inputs?.bankDetails?.code,
                gambling_rate: parseInt(inputs?.gamblingRate),
                ...(canUseSalaryAccFeature && {
                    is_salary_account:
                        inputs.accountType === accountTypeData[0]
                            ? true
                            : false,
                }),
                ...((!canUseSalaryAccFeature &&
                    customerType === 'RESIDENTIAL') ||
                (canUseSalaryAccFeature &&
                    inputs.accountType === accountTypeData[0])
                    ? {
                          average_salary: parseFloat(
                              inputs?.avgSalary.replaceAll(',', ''),
                          ),
                      }
                    : {
                          average_loan_amount: parseFloat(
                              inputs?.avgLoanAmount.replaceAll(',', ''),
                          ),
                      }),
                bank_statement_url: bankStatementUrl,
            },
        }),
        decision_result_type: simulationDataMapping[inputs?.simulationType],
        ...(checked && { notify_consumer: true }),
    }

    // rerun credit decision
    const rerunCreditDecisionApi = useMutation({
        mutationKey: ['rerunCreditDecision'],
        mutationFn: payload => rerunCreditDecision(estimationId, payload),
        onSuccess: data => {
            setNewPlans(data?.data?.data?.payment_plans)
            if (actionType === 'apply') {
                setActionType('success')
            } else {
                setShowNewPlans(true)
                setShowReason(false)
            }
        },
        onError: error => {
            errorAlert(
                error?.response?.data?.message ||
                    'An error occured, please try again later.',
            )
        },
    })

    //upload to s3
    const handleUploadBankStatementUrl = () => {
        postDocumentToS3(
            {
                category: 'BANK_STATEMENT',
                file: successUpload[0],
            },
            {
                onSuccess: res => {
                    const docUrl = res?.url + res?.fields?.key || ''
                    setBankStatementUrl(docUrl)
                },
                onError: error => {
                    errorAlert(
                        error?.response?.data?.message ||
                            'An error occured, please try again later.',
                    )
                },
            },
        )
    }

    const handleReason = () => {
        const name = categoryMap[categoryId]
        setDisputedCategories(category => ({ ...category, [name]: true }))
        setCategoryId(0)
        setRerunCreditDecision(true)
    }

    // make api call to rerun credit decision
    const handleRerunCreditDecision = () => {
        rerunCreditDecisionApi.mutate(rerunCreditDecisionPayload)
    }

    const handleCloseDisputeModal = () => {
        setCategoryId(0)
        setInputs(initialInputs)
        setSuccessUpload([])
        setShowReason(false)
        setRerunCreditDecision(false)
        setDisputedCategories({
            creditReport: false,
            upfrontDeposit: false,
            technoEconomicFit: false,
            bankStatement: false,
        })
        setNewPlans([])
        setShowNewPlans(false)
        setActionType('')
        setShowConfirmModal(false)
        closeModal()
        actionType === 'success' && window.location.reload()
    }

    const handleDiscard = () => {
        setShowConfirmModal(false)
        handleCloseDisputeModal()
    }

    const handleApplySuccess = () => {
        // make api call to apply changes
        rerunCreditDecisionApi.mutate(rerunCreditDecisionPayload)
    }

    const handleApply = () => {
        setShowConfirmModal(true)
        setActionType('apply')
        const showCheckBox = newPlans.some(
            plan =>
                plan?.credit_decision?.decision === 'YES' ||
                plan?.credit_decision?.decision === 'MAYBE',
        )
        setShowCheckbox(showCheckBox)
        closeModal()
    }

    const disableBtn = isCategoryDisabled(
        accountName,
        inputs,
        categoryId,
        customerType,
        canUseSalaryAccFeature,
        successUpload,
    )

    const handleChecked = () => {
        setChecked(!checked)
    }

    // retrieve user bank details
    const { refetch: userBankDetailsRefetch } = useQuery(
        'fetch-user-bank-details',
        () =>
            getUserBankDetailsApi(
                inputs.accountNumber,
                inputs.bankDetails.code,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const userBankDetails = data?.data?.data
                setAccountName(userBankDetails.account_name)
            },
            onError: error => {
                errorAlert(error.response.data.message)
            },
        },
    )

    // Call Resolve endpoint after bank and account number is filled
    useEffect(() => {
        if (inputs.bankDetails !== '' && inputs.accountNumber.length === 10) {
            userBankDetailsRefetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs.bankDetails, inputs.accountNumber])

    return (
        <>
            {/* Modal for discard, apply and success */}
            <ActionModal
                actionModalOpen={showConfirmModal}
                headerText={
                    actionType === 'success'
                        ? 'Decision disputed successfully'
                        : actionType === 'discard'
                        ? 'Confirm you want to discard changes'
                        : 'Confirm you want to apply changes'
                }
                subTitle={
                    actionType === 'success'
                        ? 'You have successfully disputed the credit decision and this consumer is not approved'
                        : actionType === 'discard'
                        ? "You'll loose all the changes that you’ve made to the credit decision"
                        : 'Confirm your want to make changes to the consumer’s credit decision'
                }
                closeModal={() => {
                    if (actionType === 'discard') {
                        setShowConfirmModal(false)
                        openModal()
                    } else {
                        setActionType('discard')
                    }
                }}
                actionHandler={() => {
                    actionType === 'success'
                        ? handleCloseDisputeModal()
                        : actionType === 'discard'
                        ? handleDiscard()
                        : handleApplySuccess()
                }}
                onCancel={() => {
                    setShowConfirmModal(false)
                    openModal()
                }}
                actionType={actionType === 'success' ? 'success' : 'warning'}
                actionText={
                    actionType === 'success'
                        ? 'Okay, Got it'
                        : actionType === 'discard'
                        ? 'Confirm'
                        : 'Yes'
                }
                cancelText={actionType === 'discard' ? 'Go back' : 'No'}
                noCancelBtn={actionType === 'success' ? true : false}
                loading={rerunCreditDecisionApi.isLoading}
                actionHeaderClassName="ActionHeader"
                showCheckBox={actionType === 'apply' && showCheckBox}
                checked={checked}
                handleChecked={handleChecked}
                noBackLink
            />

            <section className={DisputeCreditDecisionWrapper}>
                <div className="ACVAddTopWrapper">
                    <span
                        className="AWCloseIconWrapper"
                        style={{ alignSelf: isMobile ? 'flex-start' : '' }}
                    >
                        <img
                            src={CloseIcon}
                            alt="close icon"
                            onClick={handleCloseDisputeModal}
                            style={{ width: '27px', marginTop: '-10px' }}
                        />
                    </span>
                    <FormTitleBar
                        title={titleData[categoryId]}
                        subtitle={
                            showReason
                                ? 'Add a note before disputing the result'
                                : subtitleData[categoryId]
                        }
                        titleFontSize={
                            isMobile && !showNewPlans ? '14px' : '20px'
                        }
                        marginBottom={isMobile && '0'}
                        subtitleFontSize={
                            showNewPlans && isMobile ? '12px' : '15px'
                        }
                        subtitleWidth={
                            showNewPlans && !isMobile ? '400px' : '290px'
                        }
                        subtitleLineHeight="normal"
                        subtitleColor="rgba(35, 56, 98, 0.70)"
                        textAlignCenter
                    />
                </div>
                <div
                    className={`${DCDCategoryWrapperStyle} ${
                        showNewPlans ? DCDCategoryWrapperPlanStyle : ''
                    }`}
                >
                    {showReason ? (
                        <TextArea
                            placeholder="Enter Reason"
                            rows={10}
                            height="146px"
                            name="reason"
                            value={inputs.reason}
                            handleChange={handleInputChange}
                        />
                    ) : showNewPlans && newPlans?.length !== 0 ? (
                        newPlans?.map(plan => (
                            <PlanView plan={plan} key={plan.id} />
                        ))
                    ) : categoryId === 0 ? (
                        <div>
                            {filteredCategoryData.map(category => (
                                <section
                                    key={category.key}
                                    className={DCDCategorySectionStyle}
                                >
                                    <div>
                                        <span>Category</span>
                                        <p>{category.category} </p>
                                    </div>
                                    <div>
                                        <span>Current score</span>
                                        <p className={DCDCurrentScoreStyle}>
                                            {category.currentScore}
                                        </p>
                                    </div>
                                    <p
                                        onClick={() => {
                                            setCategoryId(category.id)
                                        }}
                                    >
                                        {disputedCategories[category.key]
                                            ? 'Edit Dispute'
                                            : 'Dispute'}
                                    </p>
                                </section>
                            ))}
                            {rerunCreditDecison &&
                                process.env
                                    .REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE ===
                                    'true' && (
                                    <SelectField
                                        initialOption="Select Credit Decision Result"
                                        floatingLabel="Select Credit Decision Result"
                                        floatLabelOnSelectedValue
                                        values={simulationData.map(option => ({
                                            value: option,
                                        }))}
                                        selectWidth={'100%'}
                                        name="simulationType"
                                        handleChange={handleSelectChange}
                                        currentSelected={inputs.simulationType}
                                        selectedValue={inputs.simulationType}
                                        marginTop="24px"
                                        withCheckBox
                                    />
                                )}
                        </div>
                    ) : categoryId === 1 ? (
                        <>
                            <InputFields
                                name="creditReportScore"
                                title="Enter consumer's new score"
                                value={inputs.creditReportScore}
                                handleChange={handleInputChange}
                                marginBottom={'15px'}
                            />
                            <SelectField
                                values={creditReportScoreData.map(option => ({
                                    value: option,
                                }))}
                                selectWidth="100%"
                                name="creditReportSource"
                                initialOption={
                                    inputs.creditReportSource === '' &&
                                    'Where did you get this result from?'
                                }
                                handleChange={handleSelectChange}
                                marginBottom="10px"
                                dropdownPositionRelative
                                floatingLabel={
                                    inputs.creditReportSource === ''
                                        ? ''
                                        : 'Where did you get this result from?'
                                }
                                currentSelected={inputs.creditReportSource}
                                selectedValue={inputs.creditReportSource}
                                withCheckBox
                            />
                        </>
                    ) : categoryId === 2 ? (
                        <InputFields
                            name="upfrontDeposit"
                            title="Enter a new percentage user wants to pay"
                            value={inputs.upfrontDeposit}
                            handleChange={handleInputChange}
                            marginBottom={'15px'}
                            type="number"
                        />
                    ) : categoryId === 3 ? (
                        <>
                            <IconInputField
                                icon={NairaIcon}
                                name="generatorSpend"
                                title="Generator Spend"
                                value={inputs.generatorSpend}
                                handleChange={handleInputChange}
                                onBlur={handleBlur}
                                width="100%"
                            />
                            <IconInputField
                                icon={NairaIcon}
                                name="solarSpend"
                                title="Solar Spend"
                                value={inputs.solarSpend}
                                handleChange={handleInputChange}
                                onBlur={handleBlur}
                                width="100%"
                            />
                        </>
                    ) : (
                        (categoryId === 4 || categoryId === 5) && (
                            <DisputeCreditDecionBankStatement
                                categoryId={categoryId}
                                inputs={inputs}
                                handleInputChange={handleInputChange}
                                handleSelectChange={handleSelectChange}
                                setFileValid={setFileValid}
                                successUpload={successUpload}
                                customerType={customerType}
                                setSuccessUpload={setSuccessUpload}
                                bankLists={bankLists?.data}
                                handleBlur={handleBlur}
                                errorMessage={errorMessage}
                                accountName={accountName}
                            />
                        )
                    )}
                </div>

                <div className={DCDButtonWrapper}>
                    {categoryId === 0 && !showNewPlans ? (
                        <Button
                            btnBgColor="#004AAD"
                            btnWidth="186px"
                            btnTextColor="#FFFFFF"
                            btnHeight="56px"
                            handleClick={() => {
                                setShowReason(true)
                                if (showReason) {
                                    handleRerunCreditDecision()
                                }
                            }}
                            disabled={
                                (categoryId === 0 && !rerunCreditDecison) ||
                                (showReason && inputs.reason === '') ||
                                (categoryId === 0 &&
                                    process.env
                                        .REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE ===
                                        'true' &&
                                    inputs.simulationType === '')
                            }
                        >
                            {rerunCreditDecisionApi.isLoading ? (
                                <InlineLoader />
                            ) : showReason ? (
                                'Confirm'
                            ) : (
                                'Rerun credit decision'
                            )}
                        </Button>
                    ) : (
                        <>
                            <Button
                                btnBgColor="#E2EEFF"
                                btnWidth="132px"
                                btnTextColor="#004AAD"
                                btnHeight="50px"
                                handleClick={() => {
                                    if (showNewPlans) {
                                        setShowConfirmModal(true)
                                        setActionType('discard')
                                        closeModal()
                                    } else {
                                        setCategoryId(0)
                                    }
                                }}
                            >
                                {showNewPlans ? 'Discard' : 'Previous'}
                            </Button>
                            &nbsp; &nbsp; &nbsp;
                            <Button
                                btnBgColor="#004AAD"
                                btnWidth="132px"
                                btnTextColor="#FFFFFF"
                                btnHeight="50px"
                                disabled={disableBtn || !fileValid}
                                handleClick={() => {
                                    if (showNewPlans) {
                                        handleApply()
                                    } else {
                                        if (categoryId === 4) {
                                            setCategoryId(5)
                                        } else {
                                            if (successUpload.length > 0) {
                                                handleUploadBankStatementUrl()
                                            }
                                            handleReason()
                                        }
                                    }
                                }}
                            >
                                {isUploadingFileUrl ? (
                                    <InlineLoader />
                                ) : showNewPlans ? (
                                    'Apply'
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default DisputeCreditDecision
DisputeCreditDecision.propTypes = {
    closeModal: PropTypes.func,
    evaluationCriteria: PropTypes.object,
    openModal: PropTypes.func,
    customerType: PropTypes.string,
    estimationId: PropTypes.string,
    accountType: PropTypes.bool,
    adminRunCreditDecision: PropTypes.bool,
}

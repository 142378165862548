export const moreActions = [
    { id: 1, label: 'assign to resource', action: 'Assign To Resource' },
    { id: 2, label: 'deactivate provider', action: 'Deactivate Provider' },
]

export const actionMappings = {
    'deactivate-provider': {
        headerText: 'Provider Deactivated Successfully',
        subTitle: 'Provider has been deactivated successfully.',
    },
    'assign-resource': {
        headerText: 'Resource Added Successfully',
        subTitle: 'Resource has been successfully assigned to the provider',
    },
    'add-user': {
        headerText: 'Invitation Successfully Sent',
        subTitle: 'The invitation has been successfully sent to the user.',
    },
    default: {
        headerText: '',
        subTitle:
            'We’ve solved the problems that make it difficult for energy providers to transition millions',
    },
}

export const userTableActionModalMappings = {
    'resend-invite': {
        actionType: 'warning',
        headerText: 'Resend Invitation?',
        subTitle:
            'We will be sending another invitation to this user, would you like to continue?',
        actionText: 'Resend',
    },
    'revoke-invite': {
        actionType: 'delete',
        headerText: 'Revoke Invitation?',
        subTitle:
            'The invitation sent to the user will be revoked, would you like to continue?',
        actionText: 'Revoke',
    },
    default: {
        actionType: '',
        headerText: '',
        subTitle: '',
        actionText: '',
    },
}

export const userTableSuccessActionModalMappings = {
    'resend-invite': {
        successSubTitle:
            'The invitation has been successfully sent to the user.',
    },
    'revoke-invite': {
        successSubTitle: 'The invitation has successfully been revoked.',
    },
    default: {
        successSubTitle:
            'We’ve solved the problems that make it difficult for energy providers to transition millions',
    },
}

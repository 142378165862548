import { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import { errorHandler } from 'src/utils/errorHandler'
import { replaceHtmlTags } from 'src/utils/formatting'
import {
    CreateProfileAdditionalFieldsValidator,
    CreateProfileFieldsValidator,
} from 'src/utils/validationSchema'

export const usePageForm = ({
    setProfileData,
    profileData,
    handleSubmit,
    isEdit,
    setPendingUpdates,
    initialProfileData,
}) => {
    const location = useLocation()
    const { id } = useParams()
    const { errorAlert } = useCustomToast()
    const step = location.state?.step ?? 1
    const history = useHistory()
    const [errors, setErrors] = useState({ ...initialProfileData, sort_by: '' })
    const [availableCapacities, setAvailableCapacities] = useState([])
    const characterLimit = 250
    const [charactersLeft, setCharactersLeft] = useState(characterLimit)
    const isPrivateProfile =
        profileData?.promotional_type?.toLowerCase() === 'private' &&
        profileData?.is_promotional === true
    const isPrivateProfileEdit = location?.state?.isPrivateProfile
    const updateProfileData = update => {
        setProfileData(prev => ({
            ...prev,
            ...update,
        }))
    }

    const updatePendingUpdates = update => {
        setPendingUpdates(prev => ({
            ...prev,
            ...update,
        }))
    }

    const updateErrors = update => {
        setErrors(prev => ({
            ...prev,
            ...update,
        }))
    }

    const { uploadToS3Loading, uploadToS3Mutate } = useUploadToS3({
        onSuccess: data => {
            updateProfileData({ image_url: data })

            if (isEdit) {
                updatePendingUpdates({ image_url: data })
            }

            updateErrors({ image_url: '' })
        },
        onError: e => {
            errorAlert(errorHandler(e) || 'Something went wrong')
        },
    })

    const handleSelectChange = (name, value) => {
        updateProfileData({ [name]: value })

        if (isEdit) {
            updatePendingUpdates({ [name]: value })
        }

        if (value) {
            updateErrors({ [name]: '' })
        }
    }

    const handleInputChange = event => {
        const { name, value } = event.target

        updateProfileData({ [name]: value })

        if (isEdit) {
            updatePendingUpdates({ [name]: value })
        }

        if (value) {
            updateErrors({ [name]: '' })
        }
    }
    const handleEditorChange = value => {
        updateProfileData({ description: value })
        if (isEdit) {
            updatePendingUpdates({ description: value })
        }
        if (value) {
            updateErrors({ description: '' })
        }
        const currentCharactersLeft =
            characterLimit - replaceHtmlTags(value)?.length
        setCharactersLeft(currentCharactersLeft)
        if (currentCharactersLeft < 0) {
            updateErrors({ description: 'Character limit exceeded' })
        }
    }

    const handleSetAppliances = data => {
        if (data?.length && errors.appliances) {
            updateErrors({ appliances: '' })
        }

        updateProfileData({ appliances: data })

        if (isEdit) {
            updatePendingUpdates({ appliances: data })
        }
    }

    const handleStepOne = () => {
        CreateProfileFieldsValidator.validate(profileData, {
            abortEarly: false,
        })
            .then(() => {
                isPrivateProfile || isPrivateProfileEdit
                    ? handleSubmit()
                    : history.push({
                          pathname: window.location.pathname,
                          state: { step: 2 },
                      })
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })

                setErrors(errList)
            })
    }

    const handleStepTwo = () => {
        CreateProfileAdditionalFieldsValidator.validate(profileData, {
            abortEarly: false,
        })
            .then(() => {
                if (handleSubmit && profileData?.name?.length > 0) {
                    handleSubmit()
                } else {
                    history.push({
                        pathname: window.location.pathname,
                        state: { step: 1 },
                    })
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    const editKyc = () => {
        history.push({
            pathname: '/admin/consumer-profiles/edit-kyc-information',
            state: {
                credit_scorecard_id: profileData?.credit_scorecard_id,
                modules: profileData?.modules,
                profile_id: id,
            },
        })
    }

    return {
        handleInputChange,
        handleSelectChange,
        handleEditorChange,
        handleSetAppliances,
        handleNext: step === 1 ? handleStepOne : handleStepTwo,
        errors,
        setErrors,
        availableCapacities,
        setAvailableCapacities,
        charactersLeft,
        step,
        uploadToS3Loading,
        uploadToS3Mutate,
        isPrivateProfileEdit,
        editKyc,
    }
}

import PropTypes from 'prop-types'
import { AppButton } from 'src/components/Button'
import { denyPermission } from 'src/utils/permissionFramework'
import { shortenEmail } from 'src/utils/shortenEmail'

const UserCards = ({ data, handleButtonOne, handleButtonTwo }) => (
    <div className="AdminProviderUserTableMobile">
        {data.map((each, i) => (
            <div className="AdminProviderUserMobileCard" key={i}>
                <div className="AdminProviderUserCardSection">
                    <div className="AdminProviderUserTableUserDetails Mobile">
                        <h4>{shortenEmail(each?.user?.display_name)}</h4>
                    </div>
                    <div className="AdminProviderUserCardRoleDetails">
                        <h4>Role</h4>
                        <p>{each?.user?.role ?? 'Not Set'}</p>
                    </div>
                </div>
                <div className="AdminProviderUserCardSection">
                    {/*pending or deactivated status flag*/}
                    <div
                        style={{
                            backgroundColor: `${
                                each?.user?.state === 'ACTIVE' &&
                                each?.user?.email_verified === false
                                    ? '#FFF9E7'
                                    : each?.user?.state === 'ACTIVE' &&
                                      each?.user?.user_types?.includes(
                                          'DEACTIVATED_USER',
                                      )
                                    ? '#E5E5E580'
                                    : 'none'
                            }`,
                        }}
                        className="AdminProviderUserStatusFlag"
                    >
                        <p
                            style={{
                                color: `${
                                    each?.user?.state === 'ACTIVE' &&
                                    each?.user?.email_verified === false
                                        ? '#FFC222'
                                        : each?.user?.state === 'ACTIVE' &&
                                          each?.user?.user_types?.includes(
                                              'DEACTIVATED_USER',
                                          )
                                        ? '#233862E0'
                                        : ''
                                }`,
                                display: `${
                                    (each?.user?.state === 'ACTIVE' &&
                                        each?.user?.email_verified === false) ||
                                    (each?.user?.state === 'ACTIVE' &&
                                        each?.user?.user_types?.includes(
                                            'DEACTIVATED_USER',
                                        ))
                                        ? 'block'
                                        : 'none'
                                }`,
                            }}
                        >
                            {each?.user?.state === 'ACTIVE' &&
                            each?.user?.email_verified === false
                                ? 'PENDING'
                                : each?.user?.state === 'ACTIVE' &&
                                  each?.user?.user_types?.includes(
                                      'DEACTIVATED_USER',
                                  )
                                ? 'DEACTIVATED'
                                : ''}
                        </p>
                        {/*    workspace owner status flag*/}
                        {each?.owner === true && (
                            <div
                                className="AdminProviderUserStatusFlag"
                                style={{
                                    backgroundColor: '#E2EEFF',
                                }}
                            >
                                <p style={{ color: '#004AAD' }}>OWNER</p>
                            </div>
                        )}
                    </div>

                    <div className="AdminProviderUserCardButtons">
                        <AppButton
                            btnWidth="67px"
                            btnBgColor="#E2EEFF"
                            btnTextColor="var(--blue)"
                            btnHeight="37px"
                            fontSize="12px"
                            fontFamily="SF-Pro-Display-Medium"
                            handleClick={() =>
                                handleButtonOne(
                                    each?.user?.state === 'ACTIVE' &&
                                        each?.user?.email_verified === false
                                        ? 'PENDING'
                                        : 'ACTIVE',
                                    each?.user?.email,
                                    each?.user?.id,
                                )
                            }
                            disabled={
                                each?.user?.email_verified === false &&
                                denyPermission(
                                    'admin',
                                    'approved_providers',
                                    'providers:can_resend',
                                )
                            }
                        >
                            {each?.user?.state === 'ACTIVE' &&
                            each?.user?.email_verified === false
                                ? 'Resend'
                                : 'View'}
                        </AppButton>
                        <AppButton
                            type="MainButtonTwo"
                            btnWidth="67px"
                            btnBgColor="#E2EEFF"
                            btnTextColor="var(--blue)"
                            btnHeight="37px"
                            fontSize="12px"
                            fontFamily="SF-Pro-Display-Medium"
                            handleClick={() =>
                                handleButtonTwo(
                                    each?.user?.state === 'ACTIVE' &&
                                        each?.user?.email_verified === false
                                        ? 'PENDING'
                                        : 'ACTIVE',
                                    each,
                                    each?.user?.id,
                                )
                            }
                            disabled={
                                each?.user?.email_verified === false
                                    ? denyPermission(
                                          'admin',
                                          'approved_providers',
                                          'providers:can_revoke',
                                      )
                                    : denyPermission(
                                          'admin',
                                          'approved_providers',
                                          'providers:can_edit',
                                      )
                            }
                        >
                            {each?.user?.state === 'ACTIVE' &&
                            each?.user?.email_verified === false
                                ? 'Revoke'
                                : 'Edit'}
                        </AppButton>
                    </div>
                </div>
            </div>
        ))}
    </div>
)

UserCards.propTypes = {
    data: PropTypes.array.isRequired,
    handleButtonOne: PropTypes.func.isRequired,
    handleButtonTwo: PropTypes.func.isRequired,
}

export default UserCards

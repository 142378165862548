import PropTypes from 'prop-types'
import { InputFields } from '../../InputFields'
import { useTypeDetails } from '../data'
import '../gettingstarted.scss'
import { SelectCountryCode } from '../../InputFields/SelectCountryCode'
import Toast from 'src/components/Toast'
import checked from 'src/assets/images/circle_check_blue.svg'

const UseType = ({
    contact,
    inputs,
    setInputs,
    errors,
    setErrors,
    showToastError,
    errorMessage,
    showUseTypeField = true,
}) => {
    const handleSelectChange = (name, value) => {
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }
    const handleInputChange = event => {
        const { name, value } = event.target
        setErrors(prev => ({ ...prev, email: '', phone: '' }))
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    return (
        <div
            className={'GettingStartedWrapper UseType'}
            data-testid="use-type-wrapper"
        >
            {showToastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                />
            )}
            {showUseTypeField && (
                <div className={'GettingStartedHeadingAndText'}>
                    <h3>What are you getting this system for?</h3>
                    <p>
                        Will the solar system be used for residential, small
                        business or other business purposes?
                    </p>
                    <div
                        className={'GettingStartedUseTypes'}
                        data-testid="use-types"
                    >
                        {useTypeDetails?.map((each, i) => (
                            <div
                                key={i}
                                className={'GettingStartedUseType'}
                                role={'button'}
                                onClick={() => {
                                    handleSelectChange('useType', each.value)
                                    setErrors(prev => ({
                                        ...prev,
                                        useType: '',
                                    }))
                                }}
                                style={{
                                    backgroundColor:
                                        inputs.useType === each.value
                                            ? '#EBF3FF'
                                            : '#F9FCFF',
                                    border:
                                        inputs.useType === each.value
                                            ? '0.5px solid #004AAD'
                                            : '0.5px solid #FFFFFF',
                                }}
                                data-testid={`use-type-option-${i}`}
                            >
                                <img
                                    src={each.icon}
                                    alt={each.title}
                                    className={'GettingStartedUseType_Img'}
                                />
                                <div>
                                    <h4>{each.title}</h4>
                                    <p>{each.description}</p>
                                </div>
                                {inputs.useType === each.value && (
                                    <img
                                        src={checked}
                                        alt={'checked'}
                                        className={
                                            'GettingStartedUseType_CheckedIcon'
                                        }
                                    />
                                )}
                            </div>
                        ))}
                        {(errors?.useType || errors?.customer_type) && (
                            <p className={'GettingStartedUseTypes_Err'}>
                                {errors?.useType || errors?.customer_type}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div
                className={'GettingStartedHeadingAndText UseTypeContact'}
                data-testid="contact-info"
            >
                <h3>
                    Your added{' '}
                    {contact === 'email' ? 'email address' : 'phone number'}
                </h3>
                <p>
                    {contact === 'email' ? 'Email' : 'Phone number'} submitted
                    when filling in your appliances
                </p>
                {contact === 'email' ? (
                    <InputFields
                        title={'Email Address'}
                        name={'email'}
                        value={inputs?.email}
                        errorMessage={errors?.email}
                        handleChange={handleInputChange}
                        marginBottom={0}
                    />
                ) : (
                    <SelectCountryCode
                        title="Phone Number"
                        name="phone"
                        inputValue={inputs?.phone}
                        errorMessage={errors?.phone}
                        handleChange={handleInputChange}
                        marginBottom={0}
                        sseUse={true}
                    />
                )}
            </div>
        </div>
    )
}

UseType.propTypes = {
    contact: PropTypes.string,
    inputs: PropTypes.object,
    setInputs: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    showToastError: PropTypes.bool,
    errorMessage: PropTypes.string,
    showUseTypeField: PropTypes.bool,
}

export default UseType

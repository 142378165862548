// react libraries
import { Route, Switch, Redirect } from 'react-router-dom'
import Partners from 'src/pages/Partners'
import NotFound from 'src/pages/NotFound'
import Learn from 'src/pages/Learn'
// import Projects from 'src/pages/Projects'
// import ProjectDetailsPage from 'src/pages/ProjectDetailsPage'
import PrivacyPolicy from 'src/pages/PrivacyPolicy'
import TermsOfService from 'src/pages/TermsOfService'
import LearnContentOne from 'src/pages/LearnContent/ContentOne'
import LearnContentTwo from 'src/pages/LearnContent/ContentTwo'
import LearnContentThree from 'src/pages/LearnContent/ContentThree'
import LearnContentFour from 'src/pages/LearnContent/ContentFour'
import LearnContentFive from 'src/pages/LearnContent/ContentFive'
import LearnContentSix from 'src/pages/LearnContent/ContentSix'
import LearnContentEight from 'src/pages/LearnContent/ContentEight'
import LearnContentNine from 'src/pages/LearnContent/ContentNine'
import LearnContentTen from 'src/pages/LearnContent/ContentTen'
import ConsumerHome from 'src/pages/Consumer/SmartShoppingExperience/Home'
import ConsumerSolarSystemListings from 'src/pages/Consumer/SmartShoppingExperience/SolarSystemsListings'
import ConsumerSolarSystemListingsV2 from 'src/pages/Consumer/SmartShoppingExperience/SolarSystemsListingsV2'
import AccountSetupOverview from '../pages/Consumer/SmartShoppingExperience/AccountSetupOverview'
import Verification from '../pages/Consumer/SmartShoppingExperience/CreateAccount/Verification'
import CreditDecision from 'src/pages/Consumer/SmartShoppingExperience/CreditDecision'
import ProfileListings from 'src/pages/Consumer/ProfileListings'

// SunFi App Routes
import AppRoutes from './AppRoutes'
import LearnContentSeven from '../pages/LearnContent/ContentSeven'
import ViewSystem from 'src/pages/Consumer/SmartShoppingExperience/ViewSystem'
import PersonalInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation'
import FinancialInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/FinancialInformation'
import {
    CREATE_BUSINESS_ACCOUNT,
    CREATE_RESIDENTIAL_ACCOUNT,
    LEARN_ROUTES,
} from './pathnames'
import IdentityInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/IdentityInformation'
import CSSEPromoListings from 'src/pages/Consumer/SmartShoppingExperience/Home/PromoListings'
import ConsumerGetStarted from 'src/pages/Consumer/SmartShoppingExperience/Home/GetStarted'
import MultiplePackagesPromoListings from 'src/pages/Consumer/SmartShoppingExperience/Home/MultiplePackagePromoListings'
import LearnContentEleven from 'src/pages/LearnContent/ContentEleven'
import LearnContentTwelve from 'src/pages/LearnContent/ContentTwelve'
import LearnContentThirteen from 'src/pages/LearnContent/ContentThirteen'
import OutrightSaleInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/OutrightSaleInformation'
import DynamicInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/DynamicInformation'

const Routes = () => (
    <>
        {process.env.REACT_APP_REDIRECT_TO_MAINTENANCE_PAGE === 'true' ? (
            <>
                <Redirect to="/" />

                <Switch>
                    <Route path="/" component={ConsumerHome} />
                </Switch>
            </>
        ) : (
            <Switch>
                <Route exact path="/" component={ConsumerHome} />
                <Route
                    path="/consumer/get-started"
                    component={ConsumerGetStarted}
                />
                <Route
                    exact
                    path="/consumer/promos"
                    component={CSSEPromoListings}
                />
                <Route
                    exact
                    path="/consumer/promos/:id/packages"
                    component={MultiplePackagesPromoListings}
                />
                <Route
                    path="/consumer/view-system/:id"
                    component={ViewSystem}
                />
                <Route
                    path="/consumer/view-system/:id/shared"
                    component={ViewSystem}
                />
                <Route path="/consumer/account-setup/business-verification">
                    <Verification userType={'business'} />
                </Route>
                <Route path="/consumer/account-setup/identity-information">
                    <IdentityInformation userType={'residential'} />
                </Route>
                <Route
                    path="/consumer/systems"
                    component={ConsumerSolarSystemListings}
                />
                {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                    'true' && (
                    <Route
                        path="/consumer/profile-details/:id"
                        component={ConsumerSolarSystemListingsV2}
                    />
                )}
                {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                    'true' && (
                    <Route
                        path="/consumer/profile-details/:id/shared"
                        component={ConsumerSolarSystemListingsV2}
                    />
                )}
                {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true' &&
                    process.env.REACT_APP_FLAG_SHOW_AVAILABLE_SOLUTIONS ===
                        'true' && (
                        <Route
                            path="/consumer/all-systems"
                            component={ConsumerSolarSystemListingsV2}
                        />
                    )}
                <Route
                    path={'/consumer/account-setup/overview'}
                    component={AccountSetupOverview}
                />
                <Route path="/consumer/account-setup/business-information">
                    <PersonalInformation userType="business" />
                </Route>
                <Route path="/consumer/account-setup/residential-information">
                    <PersonalInformation userType="residential" />
                </Route>
                <Route path={CREATE_BUSINESS_ACCOUNT.FINANCIAL_INFO}>
                    <FinancialInformation userType="business" />
                </Route>
                <Route path={CREATE_RESIDENTIAL_ACCOUNT.FINANCIAL_INFO}>
                    <FinancialInformation userType="residential" />
                </Route>
                <Route path="/consumer/account-setup/business-information">
                    <PersonalInformation userType="business" />
                </Route>
                <Route path="/consumer/account-setup/residential-information">
                    <PersonalInformation userType="residential" />
                </Route>
                {process.env.REACT_APP_FLAG_SHOW_OUTRIGHT_SALE === 'true' && (
                    <Route path="/consumer/account-setup/outright-sale-information">
                        <OutrightSaleInformation />
                    </Route>
                )}
                <Route
                    exact
                    path="/consumer/account-setup/credit-decision"
                    component={CreditDecision}
                />
                {process.env.REACT_APP_FLAG_SHOW_DYNAMIC_KYC === 'true' && (
                    <Route
                        path="/admin/account-setup/:id"
                        component={DynamicInformation}
                    />
                )}
                {process.env.REACT_APP_FLAG_SHOW_DYNAMIC_KYC === 'true' && (
                    <Route
                        path="/consumer/account-setup/:id"
                        component={DynamicInformation}
                    />
                )}
                {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                    'true' && (
                    <Route path="/consumer/individual-profiles">
                        <ProfileListings />
                    </Route>
                )}
                {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                    'true' && (
                    <Route path="/consumer/business-profiles">
                        <ProfileListings />
                    </Route>
                )}
                {/* admin - consumer KYC routes */}
                <Route path={`/admin/plans/:id/kyc/residential-information`}>
                    <PersonalInformation userType="residential" />
                </Route>
                <Route path={`/admin/plans/:id/kyc/identity-information`}>
                    <IdentityInformation userType={'residential'} />
                </Route>
                <Route
                    path={`/admin/plans/:id/kyc/residential-financial-information`}
                >
                    <FinancialInformation userType="residential" />
                </Route>
                <Route path={`/admin/plans/:id/kyc/business-information`}>
                    <PersonalInformation userType="business" />
                </Route>
                <Route path={`/admin/plans/:id/kyc/business-verification`}>
                    <Verification userType={'business'} />
                </Route>
                <Route
                    path={`/admin/plans/:id/kyc/business-financial-information`}
                >
                    <FinancialInformation userType="business" />
                </Route>
                <Route
                    path={`/admin/plans/:id/kyc`}
                    component={AccountSetupOverview}
                />
                {/* KYC routes for outright sale*/}
                {process.env.REACT_APP_FLAG_SHOW_OUTRIGHT_SALE === 'true' && (
                    <Route path="/admin/outright-sale/:id/kyc/information">
                        <OutrightSaleInformation />
                    </Route>
                )}
                {/* end admin KYC routes */}
                <Route exact path="/home" component={ConsumerHome} />
                <Route path="/partners" component={Partners} />
                <Route exact path="/learn" component={Learn} />
                <Route
                    exact
                    path="/learn/we-envision-solar-on-every-roof"
                    component={LearnContentOne}
                />
                <Route
                    exact
                    path="/learn/a-virtual-agent-network-of-associate-installers-will-accelerate-the-transition"
                    component={LearnContentTwo}
                />
                <Route
                    exact
                    path="/learn/predictions-of-the-future-with-SunFi-Launch"
                    component={LearnContentFour}
                />
                <Route
                    exact
                    path="/learn/energy-storage-will-be-in-every-home-and-building"
                    component={LearnContentThree}
                />
                <Route
                    exact
                    path="/learn/payment-plans-are-the-bottleneck-of-clean-energy"
                    component={LearnContentFive}
                />
                <Route
                    exact
                    path="/learn/5-reasons-why-you-should-switch-to-solar-in-2023"
                    component={LearnContentSix}
                />
                <Route
                    exact
                    path="/learn/five-ways-clean-energy-benefits-nigerian-businesses"
                    component={LearnContentSeven}
                />
                <Route
                    exact
                    path={LEARN_ROUTES.learnContentEight}
                    component={LearnContentEight}
                />
                <Route
                    exact
                    path={LEARN_ROUTES.learnContentNine}
                    component={LearnContentNine}
                />
                <Route
                    exact
                    path={LEARN_ROUTES.learnContentTen}
                    component={LearnContentTen}
                />
                <Route
                    exact
                    path={LEARN_ROUTES.learnContentEleven}
                    component={LearnContentEleven}
                />
                <Route
                    exact
                    path={LEARN_ROUTES.learnContentTwelve}
                    component={LearnContentTwelve}
                />
                <Route
                    exact
                    path={LEARN_ROUTES.learnContentThirteenOldUrl}
                    component={() => (
                        <Redirect to={LEARN_ROUTES.learnContentThirteen} />
                    )}
                />
                <Route
                    exact
                    path={LEARN_ROUTES.learnContentThirteen}
                    component={LearnContentThirteen}
                />
                {/* <Route path="/projects" exact component={Projects} /> */}
                {/* <Route path="/projects/:id" exact component={ProjectDetailsPage} /> */}
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-of-service" component={TermsOfService} />
                <Route exact path="/error/404" component={NotFound} />
                <Route path="/not-found" component={NotFound} />
                <AppRoutes />
                <Route path="*" component={NotFound} />
            </Switch>
        )}
    </>
)

export default Routes

import { useState, useEffect, useRef } from 'react'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import {
    Tabs,
    Pagination,
    Drawer,
    Slider,
    Skeleton,
    Menu,
    Dropdown,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
    DateField,
    NoFLoatingLabelInputFields,
} from 'src/components/InputFields'
import Button from 'src/components/Button'
import EstimationCard from 'src/components/EstimationCard'
import { approvalStatus, applicationStatus } from './data.js'
import './estimations.scss'
import EmptyState from 'src/components/EmptyState'
import HelpCenter from 'src/components/HelpCenter'
import FloatingBox from 'src/components/FloatingBox'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import QuestionMarkIcon from 'src/assets/images/questionMark.svg'
import { useQuery } from 'react-query'
import {
    getAllEstimationsApi,
    getApprovedEstimationsApi,
    getCancelledEstimationsApi,
    getOngoingEstimationsApi,
    getInProgressEstimationsApi,
    getActiveEstimationsApi,
} from 'src/api/estimations/estimations'
import Toast from 'src/components/Toast'
import UpfrontDeposit from './upfrontDeposit'
import { ReactComponent as PlusIcon } from 'src/assets/images/plusIcon.svg'
import LeftArrow from 'src/assets/images/left-blue-arrow.svg'
import { eventTrackers } from 'src/utils/eventTrackers.js'
import { appTracking } from 'src/utils/appTracker.js'
import SeoComponent from 'src/components/Seo'
import CustomerTypeModal from './CustomerTypeModal'

import {
    getCreditVariables,
    getCreditVariableCount,
} from 'src/api/estimations/estimations-step-one'
import { getProviderSubscriptionModelEnabled } from 'src/api/estimations/estimations-step-one'
import { isMobile, isTablet } from 'src/utils/mediaQueries'
import simulationIcon from 'src/assets/images/simulation-icon.svg'
import { permissionsControl } from 'src/utils/permissionsControl.js'
import CustomerType from './CustomerType.jsx'
import ActionModal from 'src/components/ActionModal/index.jsx'
import {
    updateProviderPaymentPlanFilters,
    updatedProviderPaymentPlanFilters,
} from 'src/redux/reducers/provider/payment-plan/listing.js'
import { convertQueryParamsToObject } from 'src/utils/formatting.js'

const Estimations = () => {
    const { TabPane } = Tabs
    const history = useHistory()
    const defaultQueries = useSelector(updatedProviderPaymentPlanFilters)
    const dispatch = useDispatch()
    const [inputFocus, setInputFocus] = useState(false)
    const [searchText, setSearchText] = useState(defaultQueries.name || '')
    const [visible, setVisible] = useState(false)
    const [showCalendar, setShowCalendar] = useState(0)
    const [range, setRange] = useState([
        defaultQueries?.start_amount || 0,
        defaultQueries?.end_amount || 0,
    ])
    const [range2, setRange2] = useState([
        defaultQueries?.start_tenure || 0,
        defaultQueries?.end_tenure || 0,
    ])
    const [allEstimationsData, setAllEstimationsData] = useState([])
    const [activeEstimationsData, setActiveEstimationsData] = useState([])
    const [approvedEstimationsData, setApprovedEstimationsData] = useState([])
    const [ongoingEstimationsData, setOngoingEstimationsData] = useState([])
    const [cancelledEstimationsData, setCancelledEstimationsData] = useState([])
    const [inProgressEstimationsData, setInProgressEstimationsData] = useState(
        [],
    )
    const [currentTab, setCurrentTab] = useState(defaultQueries?.current_tab)
    const [currentPageAll, setCurrentPageAll] = useState(
        defaultQueries?.current_tab === '1' ? Number(defaultQueries.page) : 1,
    )
    const [currentPageActive, setCurrentPageActive] = useState(
        defaultQueries?.current_tab === '2' ? Number(defaultQueries.page) : 1,
    )
    const [currentPageApproved, setCurrentPageApproved] = useState(
        defaultQueries?.current_tab === '2' ? Number(defaultQueries.page) : 1,
    )
    const [currentPageOngoing, setCurrentPageOngoing] = useState(
        defaultQueries?.current_tab === '3' ? Number(defaultQueries.page) : 1,
    )
    const [currentPageInProgress, setCurrentPageInProgress] = useState(
        defaultQueries?.current_tab === '4' ? Number(defaultQueries.page) : 1,
    )
    const [currentPageCancelled, setCurrentPageCancelled] = useState(
        defaultQueries?.current_tab === '5' ? Number(defaultQueries.page) : 1,
    )
    const [listTotalAll, setListTotalAll] = useState(1)
    const [listTotalActive, setListTotalActive] = useState(1)
    const [listTotalApproved, setListTotalApproved] = useState(1)
    const [listTotalOngoing, setListTotalOngoing] = useState(1)
    const [listTotalCancelled, setListTotalCancelled] = useState(1)
    const [listTotalInProgress, setListTotalInProgress] = useState(1)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [countAll, setCountAll] = useState(0)
    const [countActive, setCountActive] = useState(0)
    const [countApproved, setCountApproved] = useState(0)
    const [countOngoing, setCountOngoing] = useState(0)
    const [countInProgress, setCountInProgress] = useState(0)
    const [countCancelled, setCountCancelled] = useState(0)
    const [showFilterResults, setShowFilterResults] = useState(
        defaultQueries?.filter_mode === 'true' ? true : false,
    )
    const [statuses, setStatuses] = useState(
        defaultQueries?.statuses?.split(',') || [],
    )
    const [showDropdown, setShowDropdown] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showCustomerType, setShowCustomerType] = useState(false)
    const [selectedPlanType, setSelectedPlanType] = useState('')
    const [creditVariables, setCreditVariables] = useState([])
    const [subscriptionModelEnabled, setSubscriptionModelEnabled] =
        useState(false)
    const [showCustomerTypeModal, setShowCustomerTypeModal] = useState(false)
    const [useType, setUseType] = useState({})
    const [useTypeError, setUseTypeError] = useState({})
    const [creditVariableCheck, setCreditVariableCheck] = useState({
        hasSubscription: false,
        hasLeaseToOwn: false,
        hasNoCreditVariable: false,
    })
    const [showNoCreditVariableModal, setShowNoCreditVariableModal] =
        useState(false)

    let selectedStatus = [statuses.join(',')]
    const canUseNewOfferStatus =
        process.env.REACT_APP_FLAG_SHOW_NEW_OFFER_STATUS === 'true'
    const Status = canUseNewOfferStatus ? applicationStatus() : approvalStatus

    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREATE_PAYMENT_PLAN_TRACKER'

    const createPaymentPlan = () => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['createPaymentPlan'].action,
            eventTrackers['createPaymentPlan'].label,
            eventTrackers['createPaymentPlan'].category,
        )
        setShowCustomerTypeModal(true)
    }

    function getSelectedCheckboxValues() {
        const checkboxes = document.querySelectorAll(
            `input[name="status"]:checked`,
        )
        let values = []
        checkboxes.forEach(checkbox => {
            values.push(checkbox.value)
        })
        setStatuses(values)
        return values
    }

    const [inputs, setInputs] = useState({
        startDate: defaultQueries.start_date ? defaultQueries.start_date : null,
        endDate: defaultQueries.end_date ? defaultQueries.end_date : null,
    })
    const [currentStartDate, setCurrentStartDate] = useState(
        defaultQueries.start_date
            ? defaultQueries.start_date
            : inputs.startDate === null
            ? ''
            : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        defaultQueries.end_date
            ? defaultQueries.end_date
            : inputs.endDate === null
            ? ''
            : new Date(inputs.endDate),
    )

    const showDrawer = () => {
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'yyyy-MM-dd'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'yyyy-MM-dd'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }

    const handleInputChange = e => {
        setSearchText(e.target.value)
        if (e.target.value.length === 0) {
            switch (currentTab) {
                case '1':
                    fetchAllEstimations()
                    break
                case '2':
                    canUseNewOfferStatus
                        ? fetchActiveEstimations()
                        : !canUseNewOfferStatus
                        ? fetchApprovedEstimations()
                        : null
                    break
                case '3':
                    fetchOngoingEstimations()
                    break
                case '4':
                    fetchInProgressEstimations()
                    break
                case '5':
                    fetchCancelledEstimations()
                    break
                default:
                    break
            }
        }
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleSearch = () => {
        switch (currentTab) {
            case '1':
                fetchAllEstimations()
                break
            case '2':
                canUseNewOfferStatus
                    ? fetchActiveEstimations()
                    : !canUseNewOfferStatus
                    ? fetchApprovedEstimations()
                    : null
                break
            case '3':
                fetchOngoingEstimations()
                break
            case '4':
                fetchInProgressEstimations()
                break
            case '5':
                fetchCancelledEstimations()
                break
            default:
                break
        }
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const maxFilterAmount = 100000000

    const resetFilter = () => {
        setSearchText('')
        setInputs({
            startDate: null,
            endDate: null,
        })
        setCurrentStartDate('')
        setCurrentEndDate('')
        selectedStatus.length = 0
        statuses.length = 0
        setRange([0, 0])
        setRange2([0, 0])
        setTimeout(() => {
            fetchAllEstimations()
        }, 1000)
    }

    const closeCustomerType = () => {
        setShowCustomerType(false)
        setUseType({})
        setUseTypeError({})
    }

    //fetch all estimations
    const { refetch: fetchAllEstimations, isFetching: allEstimationsLoading } =
        useQuery(
            ['fetchAllEstimations', currentPageAll],
            () =>
                getAllEstimationsApi(
                    searchText ? 1 : currentPageAll,
                    searchText,
                    currentStartDate,
                    currentEndDate,
                    selectedStatus,
                    canUseNewOfferStatus,
                    range[0],
                    range[1] > 0 ? range[1] : '',
                    range2[0],
                    range2[1] > 0 ? range2[1] : '',
                ),
            {
                enabled: defaultQueries?.current_tab === '1' ? true : false,
                refetchIntervalInBackground: true,
                retry: false,
                refetchOnMount: 'always',
                onSuccess: data => {
                    setAllEstimationsData(data?.data?.data)
                    setListTotalAll(data?.data?.total_pages)
                    setCountAll(data?.data?.count)
                    const queries = data?.config?.url
                        .split('?')[1]
                        ?.replaceAll('=', ':')
                        ?.split('&')
                    dispatch(
                        updateProviderPaymentPlanFilters(
                            convertQueryParamsToObject([
                                ...queries,
                                `current_tab:${currentTab}`,
                                `filter_mode:${showFilterResults}`,
                            ]),
                        ),
                    )
                },
                onError: () => {
                    setToastError(true)
                    setErrorType('component')
                    setErrorMessage(
                        'Failed to fetch All Estimations. Refresh page',
                    )
                },
            },
        )
    //fetch approved estimations
    const {
        refetch: fetchActiveEstimations,
        isFetching: activeEstimationsLoading,
    } = useQuery(
        ['fetchActiveEstimations', currentPageActive],
        () =>
            getActiveEstimationsApi(
                searchText ? 1 : currentPageActive,
                searchText,
            ),
        {
            enabled:
                defaultQueries?.current_tab === '2' &&
                canUseNewOfferStatus === true
                    ? true
                    : false,
            refetchIntervalInBackground: true,
            retry: false,
            refetchOnMount: 'always',
            onSuccess: data => {
                setActiveEstimationsData(data?.data?.data)
                setListTotalActive(data?.data?.total_pages)
                setCountActive(data?.data?.count)
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateProviderPaymentPlanFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            'start_date:',
                            'end_date:',
                            'start_amount:',
                            'end_amount:',
                            'start_tenure:',
                            'end_tenure:',
                            'statuses:',
                            `current_tab:${currentTab}`,
                            `filter_mode:${showFilterResults}`,
                        ]),
                    ),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorType('component')
                setErrorMessage(
                    'Failed to fetch Active Payment Plans. Refresh page',
                )
            },
        },
    )
    //fetch approved estimations
    const {
        refetch: fetchApprovedEstimations,
        isFetching: approvedEstimationsLoading,
    } = useQuery(
        ['fetchApprovedEstimations', currentPageApproved],
        () =>
            getApprovedEstimationsApi(
                searchText ? 1 : currentPageApproved,
                searchText,
            ),
        {
            enabled:
                defaultQueries?.current_tab === '2' &&
                canUseNewOfferStatus === false
                    ? true
                    : false,
            refetchIntervalInBackground: true,
            retry: false,
            refetchOnMount: 'always',
            onSuccess: data => {
                setApprovedEstimationsData(data?.data?.data)
                setListTotalApproved(data?.data?.total_pages)
                setCountApproved(data?.data?.count)
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateProviderPaymentPlanFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            'start_date:',
                            'end_date:',
                            'start_amount:',
                            'end_amount:',
                            'start_tenure:',
                            'end_tenure:',
                            'statuses:',
                            `current_tab:${currentTab}`,
                            `filter_mode:${showFilterResults}`,
                        ]),
                    ),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorType('component')
                setErrorMessage(
                    'Failed to fetch All Payment Plans. Refresh page',
                )
            },
        },
    )
    //fetch ongoing estimations
    const {
        refetch: fetchOngoingEstimations,
        isFetching: ongoingEstimationsLoading,
    } = useQuery(
        ['fetchOngoingEstimations', currentPageOngoing],
        () =>
            getOngoingEstimationsApi(
                searchText ? 1 : currentPageOngoing,
                searchText,
            ),
        {
            enabled:
                defaultQueries?.current_tab === '3' &&
                canUseNewOfferStatus === false
                    ? true
                    : false,
            refetchIntervalInBackground: true,
            retry: false,
            refetchOnMount: 'always',
            onSuccess: data => {
                setOngoingEstimationsData(data?.data?.data)
                setListTotalOngoing(data?.data?.total_pages)
                setCountOngoing(data?.data?.count)
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateProviderPaymentPlanFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            'start_date:',
                            'end_date:',
                            'start_amount:',
                            'end_amount:',
                            'start_tenure:',
                            'end_tenure:',
                            'statuses:',
                            `current_tab:${currentTab}`,
                            `filter_mode:${showFilterResults}`,
                        ]),
                    ),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorType('component')
                setErrorMessage(
                    'Failed to fetch Ongoing Payment Plans. Refresh page',
                )
            },
        },
    )

    //fetch in progress estimations
    const {
        refetch: fetchInProgressEstimations,
        isFetching: inProgressEstimationsLoading,
    } = useQuery(
        ['fetchInProgressEstimations', currentPageInProgress],
        () =>
            getInProgressEstimationsApi(
                searchText ? 1 : currentPageInProgress,
                searchText,
            ),
        {
            enabled:
                defaultQueries?.current_tab === '4' &&
                canUseNewOfferStatus === false
                    ? true
                    : false,
            refetchIntervalInBackground: true,
            retry: false,
            refetchOnMount: 'always',
            onSuccess: data => {
                setInProgressEstimationsData(data?.data?.data)
                setListTotalInProgress(data?.data?.total_pages)
                setCountInProgress(data?.data?.count)
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateProviderPaymentPlanFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            'start_date:',
                            'end_date:',
                            'start_amount:',
                            'end_amount:',
                            'start_tenure:',
                            'end_tenure:',
                            'statuses:',
                            `current_tab:${currentTab}`,
                            `filter_mode:${showFilterResults}`,
                        ]),
                    ),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorType('component')
                setErrorMessage(
                    'Failed to fetch In Progress Payment Plans. Refresh page',
                )
            },
        },
    )

    //fetch cancelled estimations
    const {
        refetch: fetchCancelledEstimations,
        isFetching: cancelledEstimationsLoading,
    } = useQuery(
        ['fetchCancelledEstimations', currentPageCancelled],
        () =>
            getCancelledEstimationsApi(
                searchText ? 1 : currentPageCancelled,
                searchText,
            ),
        {
            enabled:
                defaultQueries?.current_tab === '5' &&
                canUseNewOfferStatus === false
                    ? true
                    : false,
            refetchIntervalInBackground: true,
            retry: false,
            refetchOnMount: 'always',
            onSuccess: data => {
                setCancelledEstimationsData(data?.data?.data)
                setListTotalCancelled(data?.data?.total_pages)
                setCountCancelled(data?.data?.count)
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateProviderPaymentPlanFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            'start_date:',
                            'end_date:',
                            'start_amount:',
                            'end_amount:',
                            'start_tenure:',
                            'end_tenure:',
                            'statuses:',
                            `current_tab:${currentTab}`,
                            `filter_mode:${showFilterResults}`,
                        ]),
                    ),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorType('component')
                setErrorMessage(
                    'Failed to fetch Cancelled Payment Plans. Refresh page',
                )
            },
        },
    )

    const paginationRequestAll = () => {
        if (currentTab === '1') {
            return listTotalAll > 1 && !allEstimationsLoading
                ? paginationBlockAll()
                : ''
        }
    }
    const paginationRequestActive = () => {
        if (currentTab === '2') {
            return listTotalActive > 1 && !activeEstimationsLoading
                ? paginationBlockActive()
                : ''
        }
    }
    const paginationRequestApproved = () => {
        if (currentTab === '2') {
            return listTotalApproved > 1 && !approvedEstimationsLoading
                ? paginationBlockApproved()
                : ''
        }
    }
    const paginationRequestOngoing = () => {
        if (currentTab === '3') {
            return (listTotalOngoing > 1) & !ongoingEstimationsLoading
                ? paginationBlockOngoing()
                : ''
        }
    }
    const paginationRequestInProgress = () => {
        if (currentTab === '4') {
            return listTotalInProgress > 1 && !inProgressEstimationsLoading
                ? paginationBlockInProgress()
                : ''
        }
    }

    const paginationRequestCancelled = () => {
        if (currentTab === '5') {
            return listTotalCancelled > 1 && !cancelledEstimationsLoading
                ? paginationBlockCancelled()
                : ''
        }
    }

    const handlePageAll = pageNumber => {
        setCurrentPageAll(pageNumber)
    }
    const handlePageActive = pageNumber => {
        setCurrentPageActive(pageNumber)
    }
    const handlePageApproved = pageNumber => {
        setCurrentPageApproved(pageNumber)
    }
    const handlePageOngoing = pageNumber => {
        setCurrentPageOngoing(pageNumber)
    }
    const handlePageCancelled = pageNumber => {
        setCurrentPageCancelled(pageNumber)
    }
    const handlePageInProgress = pageNumber => {
        setCurrentPageInProgress(pageNumber)
    }
    const paginationBlockAll = () => {
        return (
            <Pagination
                defaultCurrent={currentPageAll}
                defaultPageSize={1}
                total={listTotalAll}
                showTitle={false}
                showLessItems={true}
                onChange={value => handlePageAll(value)}
            />
        )
    }
    const paginationBlockActive = () => {
        return (
            <Pagination
                defaultCurrent={currentPageActive}
                defaultPageSize={1}
                total={parseInt(listTotalActive, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePageActive(value)}
            />
        )
    }
    const paginationBlockApproved = () => {
        return (
            <Pagination
                defaultCurrent={currentPageApproved}
                defaultPageSize={1}
                total={parseInt(listTotalApproved, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePageApproved(value)}
            />
        )
    }
    const paginationBlockOngoing = () => {
        return (
            <Pagination
                defaultCurrent={currentPageOngoing}
                defaultPageSize={1}
                total={parseInt(listTotalOngoing, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePageOngoing(value)}
            />
        )
    }

    const paginationBlockInProgress = () => {
        return (
            <Pagination
                defaultCurrent={currentPageInProgress}
                defaultPageSize={1}
                total={parseInt(listTotalInProgress, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePageInProgress(value)}
            />
        )
    }

    const paginationBlockCancelled = () => {
        return (
            <Pagination
                defaultCurrent={currentPageCancelled}
                defaultPageSize={1}
                total={parseInt(listTotalCancelled, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePageCancelled(value)}
            />
        )
    }

    // function refetches in progress and ongoing payment plan
    //when a payment plan has been submitted for approval on the drawer
    const refetchPaymentPlans = () => {
        fetchInProgressEstimations()
        fetchOngoingEstimations()
        fetchAllEstimations()
    }

    const refetchPlansTabSwitch = tab => {
        setSearchText('')
        switch (tab) {
            case '1':
                currentPageAll === 1
                    ? fetchAllEstimations()
                    : setCurrentPageAll(1)
                break
            case '2':
                currentPageActive === 1 && canUseNewOfferStatus
                    ? fetchActiveEstimations()
                    : currentPageActive === 1 && canUseNewOfferStatus
                    ? setCurrentPageActive(1)
                    : currentPageApproved === 1 && !canUseNewOfferStatus
                    ? fetchApprovedEstimations()
                    : currentPageApproved > 1 && !canUseNewOfferStatus
                    ? setCurrentPageApproved(1)
                    : null
                break
            case '3':
                currentPageOngoing === 1
                    ? fetchOngoingEstimations()
                    : setCurrentPageOngoing(1)
                break
            case '4':
                currentPageInProgress === 1
                    ? fetchInProgressEstimations()
                    : setCurrentPageInProgress(1)
                break
            case '5':
                currentPageCancelled === 1
                    ? fetchCancelledEstimations()
                    : setCurrentPageCancelled(1)
                break
            default:
                break
        }
    }

    useQuery('get-credit-variables-simulation', () => getCreditVariables(), {
        enabled: true,
        retry: false,
        onSuccess: data => {
            setCreditVariables(data?.data?.data)
        },
    })

    useQuery('get-credit-variable-count', () => getCreditVariableCount(), {
        enabled: true,
        retry: false,
        onSuccess: data => {
            const creditVariableData = data?.data?.data

            creditVariableData.forEach(data => {
                if (data?.payment_model === 'SUBSCRIPTION' && data.count > 0) {
                    setCreditVariableCheck(prev => ({
                        ...prev,
                        hasSubscription: true,
                    }))
                }

                if (data?.payment_model === 'LEASE_TO_OWN' && data.count > 0) {
                    setCreditVariableCheck(prev => ({
                        ...prev,
                        hasLeaseToOwn: true,
                    }))
                }
            })

            setCreditVariableCheck(prev => ({
                ...prev,
                hasNoCreditVariable:
                    !prev.hasSubscription && !prev.hasLeaseToOwn,
            }))
        },
    })
    // get subscription model enabled
    useQuery(
        'get-provider-subscription-model-enabled',
        () => getProviderSubscriptionModelEnabled(),
        {
            enabled: true,
            retry: false,
            onSuccess: data => {
                setSubscriptionModelEnabled(data?.data?.data)
            },
        },
    )

    const options = [
        {
            id: 1,
            text: 'Lease to own',
            condition: creditVariableCheck?.hasLeaseToOwn,
        },
        {
            id: 2,
            text: 'Subscription',
            condition: creditVariableCheck?.hasSubscription,
        },
    ]

    const filterOptions = options.filter(opt => opt.condition)

    const menu = (
        <Menu>
            {filterOptions?.map(opt => (
                <Menu.Item
                    onClick={() => {
                        setShowModal(true)
                        setShowDropdown(false)
                        setSelectedPlanType(opt.text)
                    }}
                    key={opt.id}
                >
                    <span className="dropdownLinks">{opt.text}</span>
                </Menu.Item>
            ))}
        </Menu>
    )

    const handleShowDropdown = () => {
        if (!subscriptionModelEnabled) {
            setShowModal(true)
            setSelectedPlanType('Lease to own')
            return setShowDropdown(false)
        }
        setShowDropdown(!showDropdown)
    }
    const handleCloseModal = () => setShowModal(false)

    // detect click outside dropdown
    const ref = useRef(null)
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropdown(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [showDropdown])

    const [customerType, setCustomerType] = useState('')

    const handleCloseCustomerTypeModal = () => {
        setShowCustomerTypeModal(false)
    }

    const canSimulatePlans = permissionsControl(['can_simulate_plans'])
    const canCreatePaymentPlan = permissionsControl(['can_create_estimation'])

    return (
        <>
            <SeoComponent
                title={`Payment Plans - ${
                    currentTab === '2'
                        ? 'Approved'
                        : currentTab === '3'
                        ? 'Awaiting Approval'
                        : currentTab === '4'
                        ? 'In Progress'
                        : currentTab === '5'
                        ? 'Declined'
                        : 'All'
                } | SunFi | Simplifying and Scaling Clean Energy`}
                tracker="PaymentPlansTracker"
            />
            <SecondaryModal
                showModal={showModal}
                modalHeight="475px"
                modalWidth="624px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={handleCloseModal}
                content={
                    <UpfrontDeposit
                        selectedPlanType={selectedPlanType}
                        closeModal={handleCloseModal}
                        creditVariables={creditVariables}
                    />
                }
            />
            <SecondaryModal
                showModal={showCustomerTypeModal}
                modalHeight="487px"
                modalWidth="459px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={() => {
                    handleCloseCustomerTypeModal()
                    setCustomerType('')
                }}
                content={
                    <CustomerTypeModal
                        closeModal={handleCloseCustomerTypeModal}
                        customerType={customerType}
                        setCustomerType={setCustomerType}
                        setShowCustomerType={setShowCustomerType}
                    />
                }
            />
            <SecondaryModal
                showModal={showCustomerType}
                onCancel={() => {
                    closeCustomerType()
                    setCustomerType('')
                }}
                modalWidth={'475px'}
                title={'Select the customer use type'}
                v2
                content={
                    <CustomerType
                        useType={useType}
                        setUseType={setUseType}
                        useTypeError={useTypeError}
                        setUseTypeError={setUseTypeError}
                    />
                }
                showFooter
                footerLeftContent={'chat'}
                primaryBtnText={'Confirm'}
                handlePrimaryBtnClick={() => {
                    if (
                        useType?.useType?.length === undefined ||
                        useType?.useType?.length === 0
                    ) {
                        setUseTypeError({
                            useType: 'Please select your use type to continue',
                        })
                    } else {
                        if (customerType === 'new') {
                            history.push({
                                pathname: '/app/payment-plans/create/one',
                                state: { newCustomerType: useType?.useType },
                            })
                        } else {
                            history.push({
                                pathname: '/app/payment-plans/create',
                                state: { customerType },
                            })
                        }
                    }
                }}
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />
            {/* modal */}
            <ActionModal
                actionType="warning"
                actionModalOpen={showNoCreditVariableModal}
                headerText={`You can't create and simulate a plan yet`}
                subTitle="To start creating and simulating a plan, a credit variable needs to be created for you."
                actionText="Okay, Got it"
                closable
                onCancel={() => setShowNoCreditVariableModal(false)}
                actionHandler={() => setShowNoCreditVariableModal(false)}
                noBackLink
                noCancelBtn
            />
            <div className="Estimation_Container">
                <div className="Estimation_Title_Mobile_Btn_Container">
                    {showFilterResults ? (
                        <p
                            className="PrevButton"
                            onClick={() => {
                                setShowFilterResults(false)
                                resetFilter()
                            }}
                        >
                            <img src={LeftArrow} alt="left-arrow" /> Back to
                            Payment Plans
                        </p>
                    ) : (
                        <h1 className="Title">Payment Plans</h1>
                    )}
                    <div className="CreateEstimation_Mobile-btn">
                        {canSimulatePlans && (
                            <div ref={ref}>
                                <Dropdown
                                    overlay={menu}
                                    placement="bottomCenter"
                                    trigger={['click']}
                                    visible={showDropdown}
                                >
                                    <div onClick={() => handleShowDropdown()}>
                                        <img
                                            src={simulationIcon}
                                            alt="manual simulation button"
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                            }}
                                        />
                                    </div>
                                </Dropdown>
                            </div>
                        )}
                        {canCreatePaymentPlan && (
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="40px"
                                btnHeight="40px"
                                handleClick={createPaymentPlan}
                            >
                                <PlusIcon />
                            </Button>
                        )}
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="EstimationSearchFilterWrapper">
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            inputWidth={isTablet ? '250px' : '361px'}
                            inputHeight="52px"
                            value={searchText}
                            type="text"
                            inputBackground="#F6F6F6"
                            iconType="search"
                            prefilled
                            onBlur={handleOnBlur}
                            inputPlaceholder="Search Payment Plans"
                            handleChange={handleInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                        />
                        &nbsp; &nbsp;
                        {currentTab === '1' && (
                            <div
                                className="Estimation_filterBox"
                                onClick={() => {
                                    showDrawer()
                                }}
                                onMouseEnter={() => setInputFocus(true)}
                                onMouseLeave={() => setInputFocus(false)}
                                style={{
                                    border: `${
                                        inputFocus
                                            ? '1px solid var(--blue)'
                                            : '1px solid #e5e5e5'
                                    }`,
                                }}
                            >
                                <svg
                                    width="12"
                                    height="15"
                                    viewBox="0 0 12 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginTop: 4 }}
                                >
                                    <path
                                        d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                        fill={`${
                                            inputFocus
                                                ? 'var(--blue)'
                                                : '#1D2A30'
                                        }`}
                                    />
                                </svg>

                                <p className="filter">Filters</p>
                            </div>
                        )}
                    </div>
                    <Drawer
                        placement="right"
                        visible={visible}
                        closable={isMobile}
                        width="367px"
                        onClose={onClose}
                    >
                        <h2 className="EstimationFilterTitle">
                            Filter Payment Plans
                        </h2>
                        <p className="FilterSubTitle">
                            Filter by Created Dates
                        </p>
                        <div className="dateWrapper">
                            <div className="DateInputLeft">
                                <DateField
                                    placeholder="Start Date"
                                    name="startDate"
                                    caretStyle="caretStyle"
                                    dateValue={
                                        inputs.startDate === null
                                            ? null
                                            : new Date(inputs.startDate)
                                    }
                                    handleDateChange={(name, date) =>
                                        handleDateChange(name, date)
                                    }
                                    openCalendar={
                                        showCalendar === 1 ? true : false
                                    }
                                    closeCalendarHandler={() =>
                                        setShowCalendar(null)
                                    }
                                    openCalendarHandler={() =>
                                        setShowCalendar(1)
                                    }
                                    setHandleDateChange={name =>
                                        setHandleDateChange(name)
                                    }
                                />
                            </div>
                            <span className="Separator"></span>
                            <div className="DateInputRight">
                                <DateField
                                    placeholder="End Date"
                                    name="endDate"
                                    caretStyle="caretStyle"
                                    dateValue={
                                        inputs.endDate === null
                                            ? null
                                            : new Date(inputs.endDate)
                                    }
                                    handleDateChange={(name, date) =>
                                        handleDateChange(name, date)
                                    }
                                    openCalendar={
                                        showCalendar === 2 ? true : false
                                    }
                                    closeCalendarHandler={() =>
                                        setShowCalendar(null)
                                    }
                                    openCalendarHandler={() =>
                                        setShowCalendar(2)
                                    }
                                    setHandleDateChange={name =>
                                        setHandleDateChange(name)
                                    }
                                    placePopperRight
                                />
                            </div>
                        </div>
                        <p className="FilterSubTitle" style={{ marginTop: 30 }}>
                            Filter By Status
                        </p>
                        <div className="Estimations_StatusFilter">
                            {Status.map((payment, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            getSelectedCheckboxValues()
                                        }}
                                        style={{ marginBottom: 20 }}
                                    >
                                        <input
                                            type="checkbox"
                                            name="status"
                                            id={payment.id}
                                            value={payment.id}
                                            style={{ display: 'none' }}
                                        />
                                        <label
                                            htmlFor={payment.id}
                                            className="StatusLabel"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                                                    fill={
                                                        statuses.find(
                                                            status =>
                                                                status ===
                                                                payment.id,
                                                        )
                                                            ? 'var(--blue)'
                                                            : ' var(--grey)'
                                                    }
                                                />
                                            </svg>
                                            <p
                                                className="EstimationFilterStatusText"
                                                style={{ marginBottom: 0 }}
                                            >
                                                {payment.payment}
                                            </p>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                        <p className="FilterSubTitle" style={{ marginTop: 40 }}>
                            Filter By Total Amount
                        </p>
                        <Slider
                            range={{ draggableTrack: true }}
                            defaultValue={[0, 0]}
                            max={maxFilterAmount}
                            value={range}
                            tooltipPlacement={'bottom'}
                            onChange={value => setRange(value)}
                            tipFormatter={value =>
                                new Intl.NumberFormat().format(value)
                            }
                        />
                        <p className="FilterSubTitle" style={{ marginTop: 60 }}>
                            Filter By Tenure of Payment (in Months)
                        </p>
                        <Slider
                            range={{ draggableTrack: true }}
                            defaultValue={[0, 0]}
                            max={24}
                            value={range2}
                            tooltipPlacement={'bottom'}
                            onChange={value => setRange2(value)}
                            tipFormatter={value =>
                                new Intl.NumberFormat().format(value)
                            }
                        />
                        <div style={{ display: 'flex', marginTop: 40 }}>
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="150px"
                                handleClick={() => resetFilter()}
                            >
                                Reset Filter
                            </Button>
                            &nbsp; &nbsp; &nbsp;
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="150px"
                                handleClick={() => {
                                    setShowFilterResults(true)
                                    fetchAllEstimations()
                                }}
                            >
                                Add Filter
                            </Button>
                        </div>
                    </Drawer>
                    <div className="CreateEstimation_Desktop-btn">
                        {canSimulatePlans && (
                            <div
                                style={{
                                    marginRight: 10,
                                }}
                            >
                                <Dropdown
                                    overlay={menu}
                                    placement="bottomCenter"
                                    trigger={['click']}
                                    visible={showDropdown}
                                >
                                    <Button
                                        btnBgColor="var(--purple-light)"
                                        btnTextColor="var(--blue)"
                                        btnTextColorOutline="var(--blue)"
                                        btnOutlineColor="var(--purple-light)"
                                        btnBgColorOutline="#E2EEFF"
                                        type="outline"
                                        btnWidth="150px"
                                        handleClick={() => {
                                            creditVariableCheck?.hasNoCreditVariable
                                                ? setShowNoCreditVariableModal(
                                                      true,
                                                  )
                                                : handleShowDropdown()
                                        }}
                                    >
                                        Simulate Plans
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                        {canCreatePaymentPlan && (
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="150px"
                                disabled={allEstimationsLoading}
                                handleClick={() => {
                                    creditVariableCheck?.hasNoCreditVariable
                                        ? setShowNoCreditVariableModal(true)
                                        : allEstimationsData.length > 0
                                        ? setShowCustomerTypeModal(true)
                                        : history.push({
                                              pathname:
                                                  '/app/payment-plans/create',
                                              state: { customerType: 'new' },
                                          })
                                }}
                            >
                                Create Payment Plans
                            </Button>
                        )}
                    </div>
                </div>
                {showFilterResults ? (
                    <div style={{ marginTop: 46 }}>
                        <h2 className="EstimationFilterText">
                            Filter Results (
                            {allEstimationsLoading ? 0 : countAll})
                        </h2>
                        <div style={{ marginTop: 46 }}>
                            {toastError &&
                                errorType === 'All Payment Plans' && (
                                    <Toast
                                        messageType="error"
                                        message={errorMessage}
                                    />
                                )}
                            {allEstimationsLoading ||
                            allEstimationsData.length > 0 ? (
                                <div className="card-container">
                                    <EstimationCard
                                        loading={allEstimationsLoading}
                                        products={allEstimationsData}
                                        refetch={refetchPaymentPlans}
                                    />
                                </div>
                            ) : (
                                <EmptyState
                                    subTitle={
                                        'There are no results, try again!'
                                    }
                                />
                            )}
                            <div className="pages">
                                {paginationRequestAll()}
                            </div>
                        </div>
                    </div>
                ) : (
                    <Tabs
                        defaultActiveKey={currentTab}
                        onChange={values => {
                            refetchPlansTabSwitch(values)
                            setCurrentTab(values)
                        }}
                        style={{ marginTop: 38 }}
                    >
                        <TabPane
                            tab={
                                <>
                                    {allEstimationsLoading ? (
                                        <Skeleton.Button
                                            style={{
                                                width: '123px',
                                                height: '22px',
                                            }}
                                            active
                                        />
                                    ) : (
                                        `All Payment Plans ${
                                            currentTab === '1' &&
                                            !allEstimationsLoading
                                                ? `(${countAll})`
                                                : ''
                                        }`
                                    )}
                                </>
                            }
                            key="1"
                        >
                            {toastError &&
                                errorType === 'All Payment Plans' && (
                                    <Toast
                                        messageType="error"
                                        message={errorMessage}
                                    />
                                )}
                            {allEstimationsData.length > 0 ||
                            allEstimationsLoading ? (
                                <div className="card-container">
                                    <EstimationCard
                                        products={allEstimationsData}
                                        loading={allEstimationsLoading}
                                        refetch={refetchPaymentPlans}
                                    />
                                </div>
                            ) : (
                                <EmptyState
                                    subTitle={
                                        'You’ve not created any payment plans.'
                                    }
                                />
                            )}
                            <div className="pages">
                                {paginationRequestAll()}
                            </div>
                        </TabPane>
                        {canUseNewOfferStatus && (
                            <TabPane
                                tab={
                                    <>
                                        {activeEstimationsLoading ? (
                                            <Skeleton.Button
                                                style={{
                                                    width: '100px',
                                                    height: '22px',
                                                }}
                                                active
                                            />
                                        ) : (
                                            `Active ${
                                                currentTab === '2' &&
                                                !activeEstimationsLoading
                                                    ? `(${countActive})`
                                                    : ''
                                            }`
                                        )}{' '}
                                    </>
                                }
                                key="2"
                            >
                                {toastError &&
                                    errorType === 'Active Payment Plans' && (
                                        <Toast
                                            messageType="error"
                                            message={errorMessage}
                                        />
                                    )}
                                {activeEstimationsLoading ||
                                activeEstimationsData?.length > 0 ? (
                                    <div className="card-container">
                                        <EstimationCard
                                            products={activeEstimationsData}
                                            loading={activeEstimationsLoading}
                                            refetch={refetchPaymentPlans}
                                        />
                                    </div>
                                ) : (
                                    <EmptyState
                                        subTitle={
                                            'You do not have any active payment plans.'
                                        }
                                    />
                                )}
                                {activeEstimationsData.length > 1 && (
                                    <div className="pages">
                                        {paginationRequestActive()}
                                    </div>
                                )}
                            </TabPane>
                        )}
                        {!canUseNewOfferStatus && (
                            <>
                                <TabPane
                                    tab={
                                        <>
                                            {approvedEstimationsLoading ? (
                                                <Skeleton.Button
                                                    style={{
                                                        width: '100px',
                                                        height: '22px',
                                                    }}
                                                    active
                                                />
                                            ) : (
                                                `Approved ${
                                                    currentTab === '2' &&
                                                    !approvedEstimationsLoading
                                                        ? `(${countApproved})`
                                                        : ''
                                                }`
                                            )}{' '}
                                        </>
                                    }
                                    key="2"
                                >
                                    {toastError &&
                                        errorType ===
                                            'Approved Payment Plans' && (
                                            <Toast
                                                messageType="error"
                                                message={errorMessage}
                                            />
                                        )}
                                    {approvedEstimationsLoading ||
                                    approvedEstimationsData?.length > 0 ? (
                                        <div className="card-container">
                                            <EstimationCard
                                                products={
                                                    approvedEstimationsData
                                                }
                                                loading={
                                                    approvedEstimationsLoading
                                                }
                                                refetch={refetchPaymentPlans}
                                            />
                                        </div>
                                    ) : (
                                        <EmptyState
                                            subTitle={
                                                'You do not have any approved payment plans.'
                                            }
                                        />
                                    )}
                                    {approvedEstimationsData.length > 1 && (
                                        <div className="pages">
                                            {paginationRequestApproved()}
                                        </div>
                                    )}
                                </TabPane>
                                <TabPane
                                    tab={
                                        <>
                                            {ongoingEstimationsLoading ? (
                                                <Skeleton.Button
                                                    style={{
                                                        width: '100px',
                                                        height: '22px',
                                                    }}
                                                    active
                                                />
                                            ) : (
                                                `Awaiting Approval ${
                                                    currentTab === '3' &&
                                                    !ongoingEstimationsLoading
                                                        ? `(${countOngoing})`
                                                        : ''
                                                }`
                                            )}
                                        </>
                                    }
                                    key="3"
                                >
                                    {toastError &&
                                        errorType ===
                                            'Awaiting Approval Payment Plans' && (
                                            <Toast
                                                messageType="error"
                                                message={errorMessage}
                                            />
                                        )}
                                    {ongoingEstimationsLoading ||
                                    ongoingEstimationsData?.length > 0 ? (
                                        <div className="card-container">
                                            <EstimationCard
                                                products={
                                                    ongoingEstimationsData
                                                }
                                                loading={
                                                    ongoingEstimationsLoading
                                                }
                                                refetch={refetchPaymentPlans}
                                            />
                                        </div>
                                    ) : (
                                        <EmptyState
                                            subTitle={
                                                'You do not have any payment plans awaiting approval.'
                                            }
                                        />
                                    )}
                                    {ongoingEstimationsData.length > 1 && (
                                        <div className="pages">
                                            {paginationRequestOngoing()}
                                        </div>
                                    )}
                                </TabPane>
                                <TabPane
                                    tab={
                                        <>
                                            {inProgressEstimationsLoading ? (
                                                <Skeleton.Button
                                                    style={{
                                                        width: '100px',
                                                        height: '22px',
                                                    }}
                                                    active
                                                />
                                            ) : (
                                                `In Progress ${
                                                    currentTab === '4' &&
                                                    !inProgressEstimationsLoading
                                                        ? `(${countInProgress})`
                                                        : ''
                                                }`
                                            )}
                                        </>
                                    }
                                    key="4"
                                >
                                    {toastError &&
                                        errorType ===
                                            'In Progress Payment Plans' && (
                                            <Toast
                                                messageType="error"
                                                message={errorMessage}
                                            />
                                        )}
                                    {inProgressEstimationsLoading ||
                                    inProgressEstimationsData?.length > 0 ? (
                                        <div className="card-container">
                                            <EstimationCard
                                                products={
                                                    inProgressEstimationsData
                                                }
                                                loading={
                                                    inProgressEstimationsLoading
                                                }
                                                refetch={refetchPaymentPlans}
                                            />
                                        </div>
                                    ) : (
                                        <EmptyState
                                            subTitle={
                                                'You do not have any in progress payment plans.'
                                            }
                                        />
                                    )}
                                    {inProgressEstimationsData.length > 1 && (
                                        <div className="pages">
                                            {paginationRequestInProgress()}
                                        </div>
                                    )}
                                </TabPane>
                                <TabPane
                                    tab={
                                        <>
                                            {cancelledEstimationsLoading ? (
                                                <Skeleton.Button
                                                    style={{
                                                        width: '100px',
                                                        height: '22px',
                                                    }}
                                                    active
                                                />
                                            ) : (
                                                `Declined ${
                                                    currentTab === '5' &&
                                                    !cancelledEstimationsLoading
                                                        ? `(${countCancelled})`
                                                        : ''
                                                }`
                                            )}
                                        </>
                                    }
                                    key="5"
                                >
                                    {toastError &&
                                        errorType ===
                                            'Declined Payment Plans' && (
                                            <Toast
                                                messageType="error"
                                                message={errorMessage}
                                            />
                                        )}
                                    {cancelledEstimationsLoading ||
                                    cancelledEstimationsData?.length > 0 ? (
                                        <div className="card-container">
                                            <EstimationCard
                                                products={
                                                    cancelledEstimationsData
                                                }
                                                loading={
                                                    cancelledEstimationsLoading
                                                }
                                                refetch={refetchPaymentPlans}
                                            />
                                        </div>
                                    ) : (
                                        <EmptyState
                                            subTitle={
                                                'You do not have any declined payment plans.'
                                            }
                                        />
                                    )}
                                    {cancelledEstimationsData.length > 1 && (
                                        <div className="pages">
                                            {paginationRequestCancelled()}
                                        </div>
                                    )}
                                </TabPane>
                            </>
                        )}
                    </Tabs>
                )}
            </div>
            <div className="HelpCenterWrapper">
                <FloatingBox
                    floatIcon={QuestionMarkIcon}
                    floatBgColor="#011A3C"
                    floatBoxClass="floatBox"
                    renderComponent={props => <HelpCenter {...props} />}
                />
            </div>
        </>
    )
}

export default Estimations

/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { Layout, Menu } from 'antd'
import { Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'
import {
    isAuthenticated,
    decodeToken,
    getToken,
    decodeUserInfo,
    decodeUserNextStage,
    logsOutUser,
} from '../../utils/auth'
import './authenticatedroute.scss'

import AuthenticatedNavBar from '../AuthenticatedNavBar'
import FloatingBox from '../FloatingBox'
import { navRoutes, adminNavRoutes } from './navRoutes'
import logo2 from '../../assets/images/logo2.svg'
import FloatIcon from '../../assets/images/float-icon.svg'

import { onboardingStage, stageNumber } from '../../pages/Login/stages'
import { getProfileInformationApi } from '../../api/profile'
import { checkConsumerPages } from '../../utils/checkConsumerPages'
import { encrypt } from 'src/utils/cryptography'
import {
    encrptUserPermissions,
    permissionsControl,
} from 'src/utils/permissionsControl'
import { useDispatch } from 'react-redux'
import { resetAdminPaymentPlanFilters } from 'src/redux/reducers/admin/payment-plan/listing'
import { resetAdminProductsFilters } from 'src/redux/reducers/admin/products/listing'
import { resetAdminDTCReviewFilters } from 'src/redux/reducers/admin/dtc-review/listing'
import { resetAdminCreditSystemFilters } from 'src/redux/reducers/admin/credit-system/listing'
import { resetAdminUsersFilters } from 'src/redux/reducers/admin/users/listing'
import { resetAdminSettingsFilters } from 'src/redux/reducers/admin/settings/listing'
import { resetAdminProfilesFilters } from 'src/redux/reducers/admin/profiles/listing'
import { resetProviderProductsFilters } from 'src/redux/reducers/provider/products/listing'
import { resetProviderPaymentPlanFilters } from 'src/redux/reducers/provider/payment-plan/listing'
import { resetProviderCustomersFilters } from 'src/redux/reducers/provider/customers/listing'
import { checkRepWorkspacePages } from 'src/utils/checkClusterRepPages'

/**
 * Renders the component if the user is authenticated
 *
 * @param {Component} Component
 *
 * @returns {JSX}
 */
const renderComponent = (Component, onboardingStageHandler) => props => {
    const userInfo = decodeUserInfo()
    return (
        <Component
            onboardingStageHandler={onboardingStageHandler}
            {...props}
            userInfo={userInfo}
        />
    )
}

/**
 * This Component returns a new
 * route based on authenticated status
 *
 * @returns {JSX}
 */
const AuthenticatedRoute = props => {
    const { Header, Sider, Content } = Layout

    const history = useHistory()
    const dispatch = useDispatch()

    const [showOnboardingBox, setShowOnboardingBox] = useState(false)
    const [userOnboardingStage, setUserOnboardingStage] = useState('')
    const [permittedRoutes, setPermittedRoutes] = useState([])
    const [getProfilePermission, setGetProfilePermission] = useState(false)

    const { component: Component, path, ...rest } = props

    const checkisAuthenticated = isAuthenticated()
    const token = getToken()
    const user = decodeToken(token)
    const userInfo = decodeUserInfo()
    const getStage = decodeUserNextStage()
    const isSSEConsumer = JSON.parse(localStorage.getItem('isSSEConsumer'))
    const isClusterRep = JSON.parse(localStorage.getItem('isClusterRep'))

    const canViewPaymentPlansList = permissionsControl([
        'can_view_estimation_list',
    ])

    const canViewProductLibrary =
        permissionsControl(['can_view_package_list']) ||
        permissionsControl(['can_view_component_list'])

    const canViewCustomersList = permissionsControl(['can_view_consumer_list'])
    const isConsumerOrRepWorkspace =
        checkConsumerPages(path) || checkRepWorkspacePages(path)
    const canViewUsersList = permissionsControl(['can_view_user_list'])

    useEffect(() => {
        const accessibleRoutes = navRoutes(
            canViewProductLibrary,
            canViewPaymentPlansList,
            canViewCustomersList,
            canViewUsersList,
        )?.filter(
            route =>
                route?.permissionRole?.includes(
                    userInfo?.isAdminWorkspaceUser === 'true'
                        ? 'admin_workspace'
                        : 'provider_workspace',
                ) === true,
        )
        setPermittedRoutes(
            userInfo.isDeveloperUser === 'true'
                ? adminNavRoutes
                : accessibleRoutes,
        )
    }, [
        userInfo?.isAdminWorkspaceUser,
        userInfo.isDeveloperUser,
        canViewPaymentPlansList,
        canViewProductLibrary,
        canViewCustomersList,
        canViewUsersList,
    ])

    useEffect(() => {
        const currentUrlIsPaymentPlan =
            history?.location?.pathname.includes('/app/payment-plans')
        if (
            userInfo?.isAdminWorkspaceUser === 'true' &&
            currentUrlIsPaymentPlan
        ) {
            history.push('/app/403-page')
        }
    }, [history, userInfo.isAdminWorkspaceUser])

    useEffect(() => {
        const currentUrlIsOverview =
            history?.location?.pathname.includes('/app/overview')
        if (userInfo?.isAdminWorkspaceUser === 'true' && currentUrlIsOverview) {
            history.push('/app/403-page')
        }
    }, [history, userInfo.isAdminWorkspaceUser])

    useEffect(() => {
        if (getStage && !checkConsumerPages(path)) {
            setUserOnboardingStage(getStage)
        }

        // eslint-disable-next-line
    }, [])

    const isAdminWorkspaceUser = userInfo?.isAdminWorkspaceUser === 'true'

    useEffect(() => {
        // const currentUrl = history?.location?.pathname

        if (userOnboardingStage === 'ongoing') {
            refetch()
        } else if (
            process.env.REACT_APP_FLAG_ENFORCE_PERMISSION === 'true' &&
            !isAdminWorkspaceUser &&
            !checkConsumerPages(path) &&
            !checkRepWorkspacePages(path)
        ) {
            setGetProfilePermission(true)
            refetch()
        }
        // eslint-disable-next-line
    }, [userOnboardingStage])

    const { refetch } = useQuery(
        'getProfile',
        () => getProfileInformationApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                if (getProfilePermission) {
                    createPermissionStream(data?.data?.data?.id)
                    encrptUserPermissions(data)
                } else {
                    const encodedNextStage = encrypt(
                        data?.data?.data?.next_stage === null ||
                            data?.data?.data?.is_system_admin
                            ? 'completed'
                            : 'ongoing',
                    )
                    localStorage.setItem('sunfiUserStage', encodedNextStage)
                    if (
                        data?.data?.data?.next_stage === null ||
                        data?.data?.data?.is_system_admin
                    ) {
                        history.push(
                            data?.data?.data?.is_system_admin
                                ? 'admin/overview'
                                : '/app/products',
                        )
                    } else {
                        const hasOnboardingStarted =
                            data?.data?.data?.last_completed_stage ===
                            onboardingStage['COMPLETED_SIGN_UP']
                                ? 'notstarted'
                                : 'started'

                        history.push({
                            pathname: `/onboarding`,
                            state: {
                                onboardingState: hasOnboardingStarted,
                                nextOnboardingStage:
                                    stageNumber[data?.data?.data?.next_stage],
                            },
                        })
                    }
                }
            },
            onError: () => {
                logsOutUser()
            },
        },
    )

    const updateOnboardingStage = (value = false) => {
        setShowOnboardingBox(value)
    }

    const removeQueryParams = url => {
        switch (url) {
            case '/admin/plans':
                dispatch(resetAdminPaymentPlanFilters())
                break
            case '/app/payment-plans':
                dispatch(resetProviderPaymentPlanFilters())
                break
            case '/app/customers':
                dispatch(resetProviderCustomersFilters())
                break
            case '/admin/products':
                dispatch(resetAdminProductsFilters())
                break
            case '/admin/alternative-recommendations':
                dispatch(resetAdminDTCReviewFilters())
                break
            case '/admin/credit':
                dispatch(resetAdminCreditSystemFilters())
                break
            case '/admin/users/listings':
                dispatch(resetAdminUsersFilters())
                break
            case '/app/users/listing':
                dispatch(resetAdminUsersFilters())
                break
            case '/admin/settings':
                dispatch(resetAdminSettingsFilters())
                break
            case '/admin/consumer-profiles':
                dispatch(resetAdminProfilesFilters())
                break
            case '/app/products':
                dispatch(resetProviderProductsFilters())
                break
            default:
        }
    }

    //change this after integrating repayment schedule endpoint
    const exceptionUrls = [
        '/consumer/repayment-schedule',
        '/consumer/update-application',
        '/consumer/workspace',
    ]

    const exceptionRepUrls = ['/rep/workspace']

    if (!exceptionUrls?.includes(path) && !exceptionRepUrls.includes(path)) {
        if (
            !checkisAuthenticated ||
            (isSSEConsumer !== true && isClusterRep !== true && !getStage)
        ) {
            const exceptionUrls = ['/app/403-page', '/app/not-found']
            const nextPage = window.location.pathname + window?.location?.search
            return (
                <Redirect
                    to={
                        exceptionUrls.includes(nextPage)
                            ? '/login'
                            : `/login?next=${nextPage}`
                    }
                />
            )
        }
    }

    if (exceptionUrls?.includes(path)) {
        if (!checkisAuthenticated) {
            return <Redirect to="/consumer/login" />
        }
    }

    if (exceptionRepUrls?.includes(path)) {
        if (!checkisAuthenticated) {
            return <Redirect to="/rep/login" />
        }
    }

    const currentUrl = props?.path

    const checkSelection = () => {
        if (userOnboardingStage === 'ongoing') {
            return ''
        } else {
            const getKey = (
                userInfo.isDeveloperUser === 'true'
                    ? adminNavRoutes
                    : navRoutes(
                          canViewProductLibrary,
                          canViewPaymentPlansList,
                          canViewCustomersList,
                          canViewUsersList,
                      )
            ).filter(route => route.routeList.includes(currentUrl))
            return getKey[0]?.key?.toString()
        }
    }

    function createPermissionStream(user_id) {
        const permissionSource = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_URL}/user-permission-watch/stream/${user_id}/`,
        )

        permissionSource.onmessage = event => {
            if (event.data === 'READY') {
                refetch()
            }
        }
    }
    return (
        <Layout
            className={
                isConsumerOrRepWorkspace
                    ? 'ConsumerLayoutWrapper'
                    : 'LayoutWrapper'
            }
        >
            <Sider
                trigger={null}
                collapsible
                collapsed={false}
                className="VerticalSider"
                style={{
                    display: isConsumerOrRepWorkspace ? 'none' : 'block',
                }}
            >
                <div
                    className="LogoBox"
                    style={{
                        display: isConsumerOrRepWorkspace ? 'block' : 'none',
                    }}
                >
                    <img src={logo2} alt="logo" />
                </div>
                <Menu
                    theme="light"
                    defaultSelectedKeys={checkSelection()}
                    selectedKeys={checkSelection()}
                    mode="inline"
                    className="MenuItemBox"
                >
                    <>
                        <div className="MenuItemBox_Logo">
                            <img
                                src={
                                    'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Blue.png'
                                }
                                alt="SunFi"
                            />
                        </div>
                        {permittedRoutes.map(navRoute => {
                            return (
                                <Menu.Item
                                    key={navRoute?.key}
                                    icon={
                                        <div style={{ width: '30px' }}>
                                            <img
                                                src={
                                                    parseInt(
                                                        checkSelection(),
                                                        10,
                                                    ) === navRoute?.key
                                                        ? navRoute?.onSelectIcon
                                                        : navRoute?.icon
                                                }
                                                alt="icon"
                                            />
                                        </div>
                                    }
                                    className="MenuItem"
                                    disabled={
                                        navRoute?.key === 1 ||
                                        navRoute?.key === 2 ||
                                        navRoute?.key === 3 ||
                                        navRoute?.key === 4 ||
                                        navRoute?.key === 5 ||
                                        navRoute?.key === 6 ||
                                        navRoute?.key === 7 ||
                                        navRoute?.key === 8 ||
                                        navRoute?.key === 9 ||
                                        navRoute?.key === 10 ||
                                        navRoute?.key === 11 ||
                                        navRoute?.key === 12 ||
                                        navRoute?.key === 13
                                            ? false
                                            : true
                                    }
                                    onClick={
                                        userOnboardingStage !== 'ongoing'
                                            ? () => {
                                                  removeQueryParams(
                                                      navRoute.url,
                                                  )
                                                  history.push(navRoute.url)
                                              }
                                            : () => {}
                                    }
                                >
                                    <span className="MenuTitle">
                                        {navRoute?.title}
                                    </span>
                                </Menu.Item>
                            )
                        })}
                    </>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className={`${
                        props.hideNavOnMobile ? 'hideNav' : 'HeaderBox'
                    } ${isConsumerOrRepWorkspace ? 'HeaderBoxV2' : ''}`}
                    style={{
                        backgroundColor: isConsumerOrRepWorkspace
                            ? '#fff'
                            : props.bgColor
                            ? props.bgColor
                            : '',
                    }}
                >
                    <AuthenticatedNavBar userInfo={userInfo} path={path} />
                </Header>
                <Content
                    className="ContentBox"
                    style={{
                        backgroundColor: isConsumerOrRepWorkspace
                            ? '#fff'
                            : props.bgColor
                            ? props.bgColor
                            : '',
                    }}
                >
                    {currentUrl.includes('products') ||
                    currentUrl.includes('estimations') ||
                    currentUrl.includes('payment-plans') ||
                    currentUrl.includes('admin') ||
                    currentUrl.includes('profile') ||
                    currentUrl.includes('not-found') ||
                    currentUrl.includes('overview') ||
                    currentUrl.includes('requests') ||
                    currentUrl.includes('/consumer/repayment-schedule') ||
                    currentUrl.includes('/consumer/update-application') ||
                    currentUrl.includes('/consumer/workspace') ||
                    currentUrl.includes('/rep/workspace') ||
                    currentUrl.includes('/app/customers') ||
                    currentUrl.includes('user') ||
                    currentUrl.includes('group') ? (
                        ''
                    ) : (
                        <>
                            <FloatingBox
                                type="onboarding"
                                floatIcon={FloatIcon}
                                showOnboardingBox={showOnboardingBox}
                                userInfo={userInfo}
                            />
                        </>
                    )}

                    <div user={user}>
                        <Route
                            {...rest}
                            render={renderComponent(
                                Component,
                                updateOnboardingStage,
                            )}
                        />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default AuthenticatedRoute

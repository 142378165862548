import { uploadToS3 } from 'src/api/utils/file-upload'

import { useMutation } from 'react-query'

export const useUploadToS3 = (options = {}) => {
    const { onSuccess, onError, ...others } = options
    const {
        isLoading: uploadToS3Loading,
        mutate: uploadToS3Mutate,
        error: uploadToS3Error,
    } = useMutation({
        mutationFn: ({ category, file }) => uploadToS3(category, file),
        onSuccess: data => {
            const s3Url = data?.url + data?.fields?.key

            if (onSuccess) {
                onSuccess(s3Url)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
        ...others,
    })

    return {
        uploadToS3Loading,
        uploadToS3Error,
        uploadToS3Mutate,
    }
}

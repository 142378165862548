import 'src/pages/Admin/Providers/Active/adminprovidersactive.scss'
import { Menu } from 'antd'
import { useHistory } from 'react-router-dom'

const ListingsMenu = () => {
    const history = useHistory()

    return (
        <Menu>
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_PROFILE_GROUPS ===
                'true' && (
                <Menu.Item
                    onClick={() =>
                        history.push('/admin/consumer-profiles-groups')
                    }
                    data-testid="manage-profile-groups"
                >
                    <span className="AdminProvidersActiveDropdownLinks">
                        Manage profile groups
                    </span>
                </Menu.Item>
            )}
            <Menu.Item
                onClick={() =>
                    history.push(
                        '/admin/consumer-profiles/add-consumer-profile',
                    )
                }
                data-testid="create-consumer-profile"
            >
                <span className="AdminProvidersActiveDropdownLinks">
                    Create a new consumer profile
                </span>
            </Menu.Item>
        </Menu>
    )
}

export default ListingsMenu

import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import s from '../profilesdetails.module.scss'
import styles_pageform from 'src/pages/Admin/Profiles/NewProfile/components/PageForm/pageform.module.scss'
import BackNav from 'src/components/BackNav'
import close from 'src/assets/images/close-icon.svg'
import ButtonComponent from 'src/components/Button'
import { ReactComponent as AddButtonIcon } from 'src/assets/images/plus-icon-filled.svg'
import NairaSymbol from 'src/components/NairaSymbol'
import EnergyNeeds from 'src/components/EnergyNeeds'
import { InlineLoader } from 'src/components/Loader'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { SelectField, MultipleSelectField } from 'src/components/InputFields'
import { useGetClusterVariables } from '../../NewProfile/hooks/useGetClusterVariable'
import AllComponents from '../../components/all-components'
import {
    formatPaymentTypesOnChange,
    formatPaymentTypesOptionsForInput,
    paymentTypeOptions,
} from '../../AddPackage/components/AddPackageDrawer/data'

const EditPackageDrawer = ({
    visible,
    toggleEditPackageDrawer,
    singlePackageEnergyNeeds,
    setSinglePackageEnergyNeeds,
    singlePackageEnergyInfo,
    handleSavePackage,
    savePackageLoading,
    handleRemovePackage,
    singleCluster,
    setSingleCluster,
    profileName,
    paymentPlanTypes,
    setPaymentPlanTypes,
    paymentPlanTypesError,
    setPaymentPlanTypesError,
}) => {
    const { isMobile } = useMediaQueries()
    const {
        l2OCreditVariablesFetching,
        l2OCreditVariableData,
        subCreditVariablesFetching,
        subCreditVariableData,
        formatClusterData,
    } = useGetClusterVariables()

    const handlePaymentTypeChange = values => {
        setPaymentPlanTypes(formatPaymentTypesOnChange(values))
        setPaymentPlanTypesError('')
    }

    return (
        <>
            <Drawer
                visible={visible}
                closable={false}
                width="450px"
                onClose={toggleEditPackageDrawer}
                className={'AdminProfilesDetails_EditDrawer'}
            >
                <div className={s.editPackage_closeBtns}>
                    <BackNav
                        title="Go back"
                        onClick={() => toggleEditPackageDrawer()}
                        marginLeft="6px"
                        v2
                    />
                    <img
                        src={close}
                        alt="close"
                        role={'button'}
                        onClick={() => toggleEditPackageDrawer()}
                    />
                </div>
                <div className={s.editPackage_titleAndBtn}>
                    <h2>Edit Energy Solution</h2>
                    {isMobile ? (
                        <AddButtonIcon
                            role={'button'}
                            aria-label="save package"
                            onClick={() => handleSavePackage()}
                        />
                    ) : (
                        <ButtonComponent
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            fontSize={'14px'}
                            handleClick={() => handleSavePackage()}
                        >
                            {savePackageLoading ? (
                                <InlineLoader />
                            ) : (
                                'Save package'
                            )}
                        </ButtonComponent>
                    )}
                </div>
                <div className={s.editPackage_card}>
                    <h3>{singlePackageEnergyInfo?.name}</h3>
                    <div className={s.editPackage_cardStats}>
                        <div>
                            <h4>CAPACITY</h4>
                            <p>
                                {
                                    singlePackageEnergyInfo?.energy_capacity
                                        ?.value
                                }
                                {
                                    singlePackageEnergyInfo?.energy_capacity
                                        ?.unit_of_measurement
                                }
                            </p>
                        </div>
                        <div>
                            <h4>AMOUNT</h4>
                            <p>
                                <NairaSymbol />{' '}
                                {singlePackageEnergyInfo?.amount}
                            </p>
                        </div>
                    </div>
                    <AllComponents solutionId={singlePackageEnergyInfo.id} />
                    <EnergyNeeds
                        section={'CONSUMER PROFILE'}
                        energyNeeds={singlePackageEnergyNeeds}
                        setEnergyNeeds={setSinglePackageEnergyNeeds}
                        modalTitle={'Edit Package Energy Needs'}
                        modalDescription={
                            'Edit the energy needs that fit this package'
                        }
                        modalSummaryTitle={'See energy needs summary'}
                        modalBtnText={'Continue'}
                        cardBorderWithNoShadow
                        profileName={profileName}
                        canPullEnergyNeeds={
                            process.env
                                .REACT_APP_FLAG_SHOW_PULL_ENERGY_NEEDS ===
                            'true'
                        }
                    />

                    {(singlePackageEnergyInfo?.lease_to_own_supported ||
                        singlePackageEnergyInfo?.subscription_supported) && (
                        <section
                            className={styles_pageform.FormSection}
                            style={{ marginTop: 24, width: '100%' }}
                        >
                            <h4>Apply cluster variable to this package</h4>
                            <div
                                className={
                                    styles_pageform.FormSectionContentRow
                                }
                            >
                                {singlePackageEnergyInfo?.lease_to_own_supported && (
                                    <div>
                                        <SelectField
                                            selectWidth="100%"
                                            initialOption="Lease to own"
                                            floatingLabel={
                                                singleCluster?.lease_to_own_cluster_code &&
                                                'Lease to own'
                                            }
                                            optionStyleNoCheckbox={{
                                                color: '#233862',
                                            }}
                                            name="lease_to_own"
                                            contentField="name"
                                            dropdownPositionRelative
                                            withCheckBox
                                            currentSelected={
                                                singleCluster?.lease_to_own_cluster_code
                                            }
                                            value={
                                                singleCluster?.lease_to_own_cluster_code
                                            }
                                            values={formatClusterData(
                                                l2OCreditVariableData?.data
                                                    ?.data,
                                            )}
                                            handleChange={(_, value) => {
                                                setSingleCluster(prev => ({
                                                    ...prev,
                                                    lease_to_own_cluster_code:
                                                        value,
                                                }))
                                            }}
                                            disabled={
                                                l2OCreditVariablesFetching
                                            }
                                        />
                                        <p
                                            className={
                                                styles_pageform.OptionalLabel
                                            }
                                        >
                                            Optional
                                        </p>
                                    </div>
                                )}
                                {singlePackageEnergyInfo?.subscription_supported && (
                                    <div>
                                        <SelectField
                                            selectWidth="100%"
                                            initialOption="Subscription"
                                            floatingLabel={
                                                singleCluster?.subscription_cluster_code &&
                                                'Subscription'
                                            }
                                            optionStyleNoCheckbox={{
                                                color: '#233862',
                                            }}
                                            name="subscription"
                                            contentField="name"
                                            dropdownPositionRelative
                                            withCheckBox
                                            currentSelected={
                                                singleCluster?.subscription_cluster_code
                                            }
                                            value={
                                                singleCluster?.subscription_cluster_code
                                            }
                                            values={formatClusterData(
                                                subCreditVariableData?.data
                                                    ?.data,
                                            )}
                                            handleChange={(_, value) => {
                                                setSingleCluster(prev => ({
                                                    ...prev,
                                                    subscription_cluster_code:
                                                        value,
                                                }))
                                            }}
                                            disabled={
                                                subCreditVariablesFetching
                                            }
                                        />
                                        <p
                                            className={
                                                styles_pageform.OptionalLabel
                                            }
                                        >
                                            Optional
                                        </p>
                                    </div>
                                )}
                            </div>
                        </section>
                    )}

                    {process.env
                        .REACT_APP_FLAG_SHOW_SELECT_PAYMENT_PLAN_TYPES_ADMIN && (
                        <section
                            className={styles_pageform.FormSection}
                            style={{ marginTop: 24, width: '100%' }}
                        >
                            <h4>Select payment type for this package</h4>
                            <MultipleSelectField
                                selectWidth="100%"
                                title={'Payment type'}
                                optionStyleNoCheckbox={{ color: '#233862' }}
                                name="payment_type"
                                contentField="label"
                                selectStyle={{
                                    opacity: !paymentPlanTypes?.length
                                        ? 0.7
                                        : 1,
                                }}
                                dropdownPositionRelative
                                currentSelected={formatPaymentTypesOptionsForInput(
                                    paymentPlanTypes || [],
                                )}
                                value={formatPaymentTypesOptionsForInput(
                                    paymentPlanTypes || [],
                                )}
                                values={paymentTypeOptions}
                                handleMultipleSelectChange={
                                    handlePaymentTypeChange
                                }
                                errorMessage={paymentPlanTypesError}
                                topArrow={paymentPlanTypes?.length > 1}
                            />
                        </section>
                    )}
                    <ButtonComponent
                        btnWidth={'137px'}
                        btnHeight={'40px'}
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        fontSize={'14px'}
                        handleClick={() => {
                            handleRemovePackage()
                        }}
                    >
                        Remove package
                    </ButtonComponent>
                </div>
            </Drawer>
        </>
    )
}

EditPackageDrawer.propTypes = {
    visible: PropTypes.bool,
    toggleEditPackageDrawer: PropTypes.func,
    setSinglePackageEnergyNeeds: PropTypes.func,
    singlePackageEnergyNeeds: PropTypes.array,
    singlePackageEnergyInfo: PropTypes.obj,
    handleSavePackage: PropTypes.func,
    savePackageLoading: PropTypes.bool,
    handleRemovePackage: PropTypes.func,
    singleCluster: PropTypes.obj,
    setSingleCluster: PropTypes.func,
    profileName: PropTypes.string,
    setPaymentPlanTypes: PropTypes.func,
    paymentPlanTypes: PropTypes.array,
    setPaymentPlanTypesError: PropTypes.func,
    paymentPlanTypesError: PropTypes.array,
}

export default EditPackageDrawer

export const consumerProfileEvents = type => {
    const events = {
        viewListing: {
            action: `View ${type} Listing`,
            category: 'Consumer Profile',
            label: `View ${type} Listing`,
        },
        viewDetail: {
            action: `View ${type} Details`,
            category: 'Consumer Profile',
            label: `View ${type} Details`,
        },
        viewProfileCount: {
            action: `Viewed ${type}`,
            category: 'Consumer Profile',
            label: `Viewed ${type}`,
        },
        viewPackageDetails: {
            action: `View ${type}`,
            category: 'Consumer Profile',
            label: `View ${type}`,
        },
        getSystem: {
            action: `Wants ${type}`,
            category: 'Consumer Profile',
            label: `Wants ${type}`,
        },
        selectBuildSytem: {
            action: `Selected BCS on ${type}`,
            category: 'Consumer Profile',
            label: `Selected BCS on ${type}`,
        },
        useFilter: {
            action: `Use Filter ${type}`,
            category: 'Consumer Profile',
            label: `Use Filter - ${type}`,
        },
        useSort: {
            action: `Use Sort ${type}`,
            category: 'Consumer Profile',
            label: `Use Sort - ${type}`,
        },
        shareProfile: {
            action: `Share ${type}`,
            category: 'Consumer Profile',
            label: `Share ${type}`,
        },
        viewedSharedProfile: {
            action: `View shared ${type}`,
            category: 'Consumer Profile',
            label: `View shared ${type}`,
        },
    }
    return events
}

import axios from '../../config/axios'

export const getAllEstimationsApi = async (
    pageNumber,
    searchText = '',
    startDate = '',
    endDate = '',
    status = '',
    canUseNewOfferStatus = false,
    startAmount = '',
    endAmount = '',
    startTenure = '',
    endTenure = '',
) => {
    const request = await axios.get(
        `/estimations?page=${pageNumber}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&${
            canUseNewOfferStatus
                ? `application_status=${status}`
                : `statuses=${status}`
        }&start_amount=${startAmount}&end_amount=${endAmount}&start_tenure=${startTenure}&end_tenure=${endTenure}`,
    )
    return request
}

export const getActiveEstimationsApi = async (pageNumber, searchText = '') => {
    const request = await axios.get(
        `/estimations?page=${pageNumber}&name=${searchText}&application_status=${'ACTIVE'}`,
    )
    return request
}
export const getApprovedEstimationsApi = async (
    pageNumber,
    searchText = '',
) => {
    const request = await axios.get(
        `/estimations?page=${pageNumber}&name=${searchText}&statuses=${'approved'}`,
    )
    return request
}
export const getOngoingEstimationsApi = async (pageNumber, searchText = '') => {
    const request = await axios.get(
        `/estimations?page=${pageNumber}&name=${searchText}&statuses=${'awaiting_approval'}`,
    )
    return request
}
export const getCancelledEstimationsApi = async (
    pageNumber,
    searchText = '',
) => {
    const request = await axios.get(
        `/estimations?page=${pageNumber}&name=${searchText}&statuses=${'declined,declined_and_re_estimate'}`,
    )
    return request
}
export const getInProgressEstimationsApi = async (
    pageNumber,
    searchText = '',
) => {
    const request = await axios.get(
        `/estimations?page=${pageNumber}&name=${searchText}&statuses=${'in_progress'}`,
    )
    return request
}

export const validateExistingAwaitingApprovalPaymentPlansApi =
    async consumer_id => {
        const request = await axios.post(
            '/estimations/mini/validate-existing-awaiting-approval',
            consumer_id,
        )
        return request
    }

export const simulatePaymentPlanApi = async inputs => {
    const request = await axios.post(`/products/packages/compare`, inputs)
    return request
}

import 'src/components/GettingStarted/gettingstarted.scss'
import PropTypes from 'prop-types'
import { useState } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { SelectField, TextArea } from 'src/components/InputFields'
import { disputeReasonsOptions } from '../data'
import { disputePackageSchema } from 'src/utils/validationSchema'
import { useMutation } from 'react-query'
import { disputePackageApi } from 'src/api/shoppingExperience/account-overview'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'

const DisputePackageModal = ({
    showModal,
    onCancel,
    onClick,
    estimation_id,
}) => {
    const [inputs, setInputs] = useState({
        category: '',
        reason: '',
    })
    const [errors, setErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [showToast, setShowToast] = useState(false)

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
        clearError(name)
    }

    const handleInputChange = event => {
        const { value } = event.target
        const { name } = event.target
        setInputs(prev => ({ ...prev, [name]: value }))
        clearError(name);
    }

    const clearError = (name) => {
        if (errors[name]) {
          setErrors((prev) => ({ ...prev, [name]: '' }));
        }
      };

    const handleDisputePackage = () => {
        disputePackageSchema
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors('')
                submitDisputeMutation.mutate()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const submitDisputeMutation = useMutation({
        mutationKey: ['dispute_package'],
        mutationFn: () => disputePackageApi(estimation_id, inputs),
        onSuccess: () => {
            onClick()
        },
        onError: err => {
            setShowToast(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    const handleModalClose = () => {
        setInputs({ category: '', reason: '' });
        setErrors({});
        onCancel();
      };

    return (
        <SecondaryModal
            v2
            showModal={showModal}
            modalWidth={475}
            title={'Reason for disputing package'}
            onCancel={handleModalClose}
            content={
                <div className={'GettingStartedWrapper'}>
                    {showToast && (
                        <Toast
                            messageType="error-secondary"
                            message={errorMessage}
                            closable={true}
                        />
                    )}
                    <div
                        className={'GettingStartedHeadingAndText'}
                        style={{ marginBottom: 8 }}
                    >
                        <h3>Select a category for your reason</h3>
                        <p>
                            Please tell us why you want to dispute your package
                        </p>

                        <SelectField
                            values={disputeReasonsOptions.map(option => ({
                                value: option,
                                label: option,
                            }))}
                            selectWidth="338px"
                            name="category"
                            initialOption="Select a reason"
                            handleChange={handleSelectChange}
                            marginBottom="20px"
                            dropdownPositionRelative
                            errorMessage={errors['category']}
                            floatingLabel={
                                inputs.category === '' ? '' : 'Select a reason'
                            }
                            withCheckBox
                            selectedValue={inputs.category}
                            currentSelected={inputs.category}
                        />
                    </div>
                    <div
                        className={'GettingStartedHeadingAndText'}
                        style={{ marginBottom: 8 }}
                    >
                        <h3>Add a more explanatory note</h3>
                        <p>
                            Please tell us why you want to dispute your package
                        </p>
                        <TextArea
                            title={'Leave a more explanatory note'}
                            height="140px"
                            resize
                            name="reason"
                            inputValue={inputs.reason}
                            value={inputs.reason}
                            rows={10}
                            handleChange={handleInputChange}
                            errorMessage={errors['reason']}
                        />
                    </div>
                </div>
            }
            showFooter
            footerLeftContent={null}
            primaryBtnText={'Confirm'}
            handlePrimaryBtnClick={() => handleDisputePackage()}
            primaryBtnLoading={submitDisputeMutation.isLoading}
        />
    )
}

DisputePackageModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    onClick: PropTypes.func,
    estimation_id: PropTypes.string,
}

export default DisputePackageModal

import { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isMobile } from 'src/utils/mediaQueries'
import PropTypes from 'prop-types'
import circleLeftBlue from 'src/assets/images/circle-left-pri-blue.svg'
import styles from './header.module.scss'
import { ConsumerButton } from 'src/components/Button'
import { ReactComponent as SaveIcon } from 'src/assets/images/saveIcon.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import Stepper from '../Stepper'
import ActionModal from 'src/components/ActionModalV2'
import { useScrollPosition } from 'src/utils/Hooks/useScrollPosition'
import { useAppContext } from 'src/appContext/AppContext'
import {
    CREATE_BUSINESS_ACCOUNT,
    CREATE_RESIDENTIAL_ACCOUNT,
} from 'src/routes/pathnames'
import {
    SET_MONTHLY_REVENUE_ERROR,
    SET_BVN_ERROR,
} from 'src/appContext/appReducer'
import { PersonalInfoContext } from '../../PersonalInformation/context'
import { SSEResidentialIdentityInfoContext } from '../../IdentityInformation/context'
import { decodeUserInfo } from 'src/utils/auth'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { ReactComponent as NextIcon } from 'src/assets/images/circle-right-white.svg'
import useLogOut from 'src/utils/Hooks/useLogOut'

const Header = ({
    userType,
    handleSaveAction = undefined,
    handleSubmitAction = undefined,
    submitLoading,
    step,
}) => {
    const {
        Wrapper,
        BackButtonWrapper,
        BackButtonSpan,
        SaveButtonWrapper,
        StepperWrapper,
        adminNav,
        NextIconSpan,
    } = styles
    const [buttonState, setButtonState] = useState('')
    const history = useHistory()
    const { id } = useParams()
    const { logOut } = useLogOut('/')

    const pageTitle = 'KYC | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'

    const isFinancialInfoPage =
        history.location.pathname === CREATE_BUSINESS_ACCOUNT.FINANCIAL_INFO ||
        history.location.pathname === CREATE_RESIDENTIAL_ACCOUNT.FINANCIAL_INFO

    // context
    const personalInfoValues = useContext(PersonalInfoContext)

    const {
        persistInfo,
        persistResidentialInfoLoading,
        errorPersonalInfo,
        modalType,
        setModalType,
        refetchInfo,
    } = personalInfoValues

    const identityInfoValue = useContext(SSEResidentialIdentityInfoContext)
    const {
        persistResidentialIdentityInfo,
        persistResidentialIdentityInfoLoading,
        errorResidentialIdentityInfo,
        modalRIDType,
        setModalRIDType,
        sseBasicInfoRefetch,
        kycResidentialIdentityCompareLoading,
        verificationFailed,
        inputs,
    } = identityInfoValue

    const { currentPosition } = useScrollPosition()
    const BASIC_INFORMATION_RESIDENTIAL = location.pathname.includes(
        'residential-information',
    )

    const BASIC_INFORMATION_BUSINESS = location.pathname.includes(
        'business-information',
    )
    const IDENTITY_INFORMATION_BUSINESS = location.pathname.includes(
        'business-verification',
    )

    const IDENTITY_INFORMATION_RESIDENTIAL = location.pathname.includes(
        'identity-information',
    )

    // modal states
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [actionModalType, setActionModalType] = useState('warning')
    const [buttonClicked, setButtonClicked] = useState('')
    const {
        handleSubmitKyc,
        setSuccessActions,
        submitBusinessInfo,
        submitBusinessContactInfo,
        handleSubmitBusinessFinancialInfo,
        errorAlert,
        state,
        isAuditReportUploading,
        dispatch,
        finanicalInfoFormik,
        isCustomerApproved,
    } = useAppContext()
    const { values: financialInfoValues } = finanicalInfoFormik
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    useEffect(() => {
        setSuccessActions({
            setActionModalOpen,
            setActionModalType,
        })
    }, [setSuccessActions])

    const handleSave = () => {
        setActionModalOpen(true)
        setActionModalType('warning')
    }

    const handleSubmit = () => {
        if (isFinancialInfoPage) {
            if (!state.monthly_revenue && userType === 'business') {
                dispatch({
                    type: SET_MONTHLY_REVENUE_ERROR,
                    payload: 'Monthly revenue is required',
                })
            }

            if (!state.bvn && userType === 'residential') {
                dispatch({
                    type: SET_BVN_ERROR,
                    payload: 'BVN is required',
                })
                errorAlert('BVN is required')
                return
            }

            if (!state.isBvnVerified && userType === 'residential') {
                dispatch({
                    type: SET_BVN_ERROR,
                    payload: 'BVN is not verified',
                })
                errorAlert('BVN is not verified')
                return
            }

            if (!financialInfoValues.paymentMethod) {
                return errorAlert('Payment method is required')
            }
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSubmitFinancialInfo'].action,
                eventTrackers['sseSubmitFinancialInfo'].label,
                eventTrackers['sseSubmitFinancialInfo'].category,
            )

            handleSubmitBusinessFinancialInfo(userType)
        }

        if (BASIC_INFORMATION_RESIDENTIAL) {
            setButtonState('Submit')
            persistInfo('Submit')
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSubmitBasicInfo'].action,
                eventTrackers['sseSubmitBasicInfo'].category,
                eventTrackers['sseSubmitBasicInfo'].label,
            )
        } else if (IDENTITY_INFORMATION_RESIDENTIAL) {
            if (!inputs.identityVerified) {
                if (!verificationFailed) {
                    errorAlert('Verify ID')
                } else {
                    setButtonState('Save')
                    persistResidentialIdentityInfo('Save')
                }
            } else {
                setButtonState('Compare')
                persistResidentialIdentityInfo('Compare')
            }
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                'SSE_SUBMIT_BASIC_IDENTITY_INFORMATION',
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'SSE_SUBMIT_KYC',
            )
        } else if (BASIC_INFORMATION_BUSINESS) {
            setButtonClicked('Submit')
            handleSubmitKyc('Submit')
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                'SSE_SUBMIT_BASIC_BUSINESS_INFORMATION',
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'SSE_SUBMIT_KYC',
            )
        }
    }
    const showActionEmailContent = () => {
        if (BASIC_INFORMATION_RESIDENTIAL) {
            setButtonState('Save')
            persistInfo('Save')
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSaveBasicInfo'].action,
                eventTrackers['sseSaveBasicInfo'].category,
                eventTrackers['sseSaveBasicInfo'].label,
            )
        } else if (IDENTITY_INFORMATION_RESIDENTIAL) {
            setButtonState('Save')
            persistResidentialIdentityInfo('Save')
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSaveIdentityInfo'].action,
                eventTrackers['sseSaveIdentityInfo'].category,
                eventTrackers['sseSaveIdentityInfo'].label,
            )
        } else if (
            BASIC_INFORMATION_BUSINESS ||
            IDENTITY_INFORMATION_BUSINESS
        ) {
            setButtonClicked('Save')
            handleSubmitKyc('Save')
            if (BASIC_INFORMATION_BUSINESS) {
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'SSE_SAVE_BASIC_BUSINESS_INFORMATION',
                    'SMART_SHOPPING_EXPERIENCE_KYC',
                    'SSE_SAVE_KYC',
                )
            } else {
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'SSE_SAVE_BUSINESS_IDENTITY_INFORMATION',
                    'SMART_SHOPPING_EXPERIENCE_KYC',
                    'SSE_SAVE_KYC',
                )
            }
        }
    }

    const closeSubmitModal = () => {
        setActionModalOpen(false)
        if (modalType === 'countdown-timer' && BASIC_INFORMATION_RESIDENTIAL) {
            refetchInfo()
            setModalType('warning')
        }
        if (
            modalRIDType === 'countdown-timer' &&
            IDENTITY_INFORMATION_RESIDENTIAL
        ) {
            sseBasicInfoRefetch()
            setModalRIDType('warning')
        }
    }

    const saveLoading = BASIC_INFORMATION_RESIDENTIAL
        ? persistResidentialInfoLoading && buttonState === 'Save'
        : BASIC_INFORMATION_BUSINESS
        ? buttonClicked === 'Save' &&
          (submitBusinessInfo.isLoading ||
              submitBusinessContactInfo.isLoading ||
              isAuditReportUploading)
        : IDENTITY_INFORMATION_RESIDENTIAL
        ? persistResidentialIdentityInfoLoading && buttonState === 'Save'
        : false

    const submissionLoading = BASIC_INFORMATION_RESIDENTIAL
        ? persistResidentialInfoLoading && buttonState === 'Submit'
        : BASIC_INFORMATION_BUSINESS
        ? buttonClicked === 'Submit' &&
          (submitBusinessInfo.isLoading || submitBusinessContactInfo.isLoading)
        : IDENTITY_INFORMATION_BUSINESS
        ? submitLoading
        : IDENTITY_INFORMATION_RESIDENTIAL
        ? kycResidentialIdentityCompareLoading
        : isFinancialInfoPage
        ? isAuditReportUploading
        : false

    const toastError = () => {
        if (errorPersonalInfo && BASIC_INFORMATION_RESIDENTIAL) {
            return (errorPersonalInfo?.length > 0 ||
                errorPersonalInfo[0]?.non_field_errors?.length > 0) &&
                buttonState === 'Save'
                ? true
                : false
        }
        if (errorResidentialIdentityInfo && IDENTITY_INFORMATION_RESIDENTIAL) {
            return (errorResidentialIdentityInfo?.length > 0 ||
                errorResidentialIdentityInfo?.message?.length > 0 ||
                errorResidentialIdentityInfo[0]?.non_field_errors?.length >
                    0) &&
                buttonState === 'Save'
                ? true
                : false
        }
    }

    const toastMessage = () => {
        if (errorPersonalInfo && BASIC_INFORMATION_RESIDENTIAL) {
            return errorPersonalInfo[0]?.non_field_errors?.length > 0
                ? errorPersonalInfo[0]?.non_field_errors
                : errorPersonalInfo?.message?.length > 0
                ? [errorPersonalInfo?.message]
                : ''
        }
        if (errorResidentialIdentityInfo && IDENTITY_INFORMATION_RESIDENTIAL) {
            return errorResidentialIdentityInfo[0]?.non_field_errors?.length > 0
                ? errorResidentialIdentityInfo[0]?.non_field_errors
                : errorResidentialIdentityInfo?.message?.length > 0
                ? [errorResidentialIdentityInfo?.message]
                : ''
        }
    }

    const adminTitileMap = {
        'basic-info': 'Basic Information',
        1: 'Business Information',
        'identity-information': 'Identity Information',
        2: 'Business Verification',
        'financial-info': 'Financial Information',
    }

    const goToSSEHome = () => {
        setActionModalOpen(false)
        logOut()
    }

    return (
        <>
            {isAdminUser ? (
                <header className={adminNav}>
                    <div>
                        <h4>Customer&apos;s {adminTitileMap[step]}</h4>
                        <p>Consumer submitted data</p>
                    </div>
                    <ConsumerButton
                        btnBgColor={'#004AAD'}
                        btnWidth={'117px'}
                        btnHeight={'50px'}
                        btnTextColor={'#FFFFFF'}
                        handleClick={() =>
                            history.push(`/admin/plans/${id}/kyc`)
                        }
                    >
                        Go Back
                    </ConsumerButton>
                </header>
            ) : (
                <header className={Wrapper}>
                    {/* Modal start */}
                    <ActionModal
                        title={
                            modalType === 'countdown-timer' ||
                            modalRIDType === 'countdown-timer' ||
                            actionModalType === 'countdown-timer'
                                ? 'Redirecting to home page'
                                : 'Save your Progress'
                        }
                        actionModalOpen={actionModalOpen}
                        actionType={
                            BASIC_INFORMATION_RESIDENTIAL
                                ? modalType
                                : IDENTITY_INFORMATION_RESIDENTIAL
                                ? modalRIDType
                                : actionModalType
                        }
                        headerText={
                            (BASIC_INFORMATION_RESIDENTIAL
                                ? modalType
                                : IDENTITY_INFORMATION_RESIDENTIAL
                                ? modalRIDType
                                : actionModalType) === 'warning'
                                ? 'Save your progress? Please confirm'
                                : 'Progress Saved, check your mail'
                        }
                        subTitle={
                            (BASIC_INFORMATION_RESIDENTIAL
                                ? modalType
                                : IDENTITY_INFORMATION_RESIDENTIAL
                                ? modalRIDType
                                : actionModalType) === 'warning'
                                ? 'You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue'
                                : `Progress saved! We've emailed you instructions to resume when you have the required information.`
                        }
                        primaryBtnLoading={saveLoading}
                        primaryBtnText={
                            (BASIC_INFORMATION_RESIDENTIAL
                                ? modalType
                                : IDENTITY_INFORMATION_RESIDENTIAL
                                ? modalRIDType
                                : actionModalType) === 'warning'
                                ? 'Confirm'
                                : 'Proceed to LogOut'
                        }
                        handlePrimaryBtnClick={() => {
                            if (
                                BASIC_INFORMATION_RESIDENTIAL &&
                                modalType === 'warning'
                            ) {
                                showActionEmailContent()
                            } else if (
                                IDENTITY_INFORMATION_RESIDENTIAL &&
                                modalRIDType === 'warning'
                            ) {
                                showActionEmailContent()
                            } else if (
                                !BASIC_INFORMATION_RESIDENTIAL &&
                                !IDENTITY_INFORMATION_RESIDENTIAL &&
                                actionModalType === 'warning'
                            ) {
                                showActionEmailContent()
                            } else if (isFinancialInfoPage) {
                                handleSubmitBusinessFinancialInfo(userType)
                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    eventTrackers['sseSaveFinancialInfo']
                                        .action,
                                    eventTrackers['sseSaveFinancialInfo'].label,
                                    eventTrackers['sseSaveFinancialInfo']
                                        .category,
                                )
                            } else {
                                goToSSEHome()
                            }
                        }}
                        v2
                        onCancel={() => {
                            closeSubmitModal()
                        }}
                        toastError={toastError()}
                        errorMessage={toastMessage()}
                        countdownTimerSeconds={10}
                        onTimeEnd={() => goToSSEHome()}
                        footerLeftContent={
                            modalType === 'countdown-timer' ||
                            modalRIDType === 'countdown-timer' ||
                            actionModalType === 'countdown-timer'
                                ? 'button'
                                : 'chat'
                        }
                        secondaryBtnText="Continue Application"
                        handleSecondaryBtnClick={() => {
                            if (
                                modalType === 'countdown-timer' ||
                                modalRIDType === 'countdown-timer' ||
                                actionModalType === 'countdown-timer'
                            ) {
                                closeSubmitModal()
                            }
                        }}
                    />
                    {/* Modal end */}

                    <div className={BackButtonWrapper}>
                        <div onClick={() => history.goBack()}>
                            <img
                                src={circleLeftBlue}
                                alt="circle-left-blue"
                                role={'presentation'}
                            />
                            <span className={BackButtonSpan}>Go back</span>
                        </div>
                    </div>
                    <div className={SaveButtonWrapper}>
                        <div
                            onClick={() => {
                                if (handleSaveAction !== undefined) {
                                    handleSaveAction()
                                } else handleSave()
                            }}
                        >
                            {!isCustomerApproved && (
                                <CustomToolTip
                                    text="Save and continue later"
                                    showTooltip="true"
                                    toolTipPopupWidth="170px"
                                    toolTipMarginLeft="-67px"
                                    arrowPositionCenter
                                >
                                    <SaveIcon />
                                </CustomToolTip>
                            )}
                        </div>
                        {isCustomerApproved ? (
                            <ConsumerButton
                                btnWidth="117px"
                                btnBgColor="#004aad"
                                btnHeight="50px"
                                btnTextColor="#ffffff"
                                loading={submissionLoading}
                                hover
                                handleClick={() => {
                                    history.push('/consumer/workspace')
                                }}
                            >
                                Back to profile
                            </ConsumerButton>
                        ) : (
                            <ConsumerButton
                                btnWidth="117px"
                                btnBgColor="#004aad"
                                btnHeight="50px"
                                btnTextColor="#ffffff"
                                loading={submissionLoading}
                                handleClick={() => {
                                    if (handleSubmitAction !== undefined) {
                                        handleSubmitAction()
                                    } else handleSubmit()
                                }}
                            >
                                {isFinancialInfoPage ? (
                                    'Submit'
                                ) : (
                                    <span className={NextIconSpan}>
                                        {' '}
                                        Next{' '}
                                        {!submissionLoading && <NextIcon />}
                                    </span>
                                )}
                            </ConsumerButton>
                        )}
                    </div>
                    <div
                        style={{
                            display:
                                isMobile && currentPosition > 20 ? 'none' : '',
                        }}
                        className={StepperWrapper}
                    >
                        <Stepper userType={userType} />
                    </div>
                </header>
            )}
        </>
    )
}
Header.propTypes = {
    userType: PropTypes.string.isRequired,
    handleSaveAction: PropTypes.func,
    handleSubmitAction: PropTypes.func,
    submitLoading: PropTypes.bool,
    step: PropTypes.number || PropTypes.string,
}

export default Header

import { appTracking } from 'src/utils/appTracker'
import { consumerKYCEvents } from 'src/utils/mp-event-tracker/consumer-kyc'

const pageUrlName = window.location.pathname

export const handleAppTrackingOnSignup = event => {
    appTracking(
        pageUrlName,
        '',
        '',
        consumerKYCEvents[event].action,
        consumerKYCEvents[event].label,
        consumerKYCEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}

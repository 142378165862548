/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const checkValue = tag => {
    if (!tag || tag === undefined || tag?.length === 0) {
        return false
    } else {
        return true
    }
}

const PageMeta = props => {
    let domain = window.location.origin
    let location = useLocation()
    let currentUrl = domain + location.pathname
    let pageType = checkValue(props.pageType) ? props.pageType : 'website' // This can be [website, article]
    let quote = checkValue(props.quote) ? props.quote : ''
    let title = checkValue(props.title)
        ? props.title
        : 'SunFi | Simplifying and Scaling Clean Energy'
    let image = checkValue(props.image)
        ? props.image
        : 'https://sunfiwebassets.blob.core.windows.net/brand/card.png'
    let imageDescription = checkValue(props.imageDescription)
        ? props.imageDescription
        : 'SunFi, Simplifying and Scaling Clean Energy'
    let description = checkValue(props.description)
        ? props.description
        : 'SunFi builds energy financial technology that optimizes and matches the right clean energy solution to meet your needs. From payment plans to installation support and after-care service, we’ve got you covered. Get a SunFi offer now.'
    let hastag = checkValue(props.hastag) ? props.hastag : '#sunfi'
    let keywords = checkValue(props.keywords)
        ? props.keywords
        : 'SunFi, Solar Financing, Renewable Energy, reliable solar, Purchase solar, Inverters, Energy Savings'
    let facebookAppId = null // TODO: Facebook numberic ID
    let googleSiteVerificationId = process.env.REACT_APP_GOOGLE_VERIFICATION_ID
    const facebookDomainVerificationId =
        process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION_ID
    /**
     * Post: should have the following
     *  - created_at
     * 	- updated_at
     * 	- tags
     * 	- category
     */
    let post = checkValue(props.post) ? props.post : {}
    let preloadImages = props.preloadImages

    return (
        <Helmet defer={false}>
            <title>{title}</title>
            <meta charset="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="csrf_token" content="" />
            <meta property="type" content={pageType} />
            <meta property="author" content={domain} />
            <meta property="url" content={currentUrl} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, viewport-fit=cover, shrink-to-fit=no"
            />

            <meta property="_token" content="" />
            <meta
                property="robots"
                content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
            />
            <meta property="googlebot" content="" />
            <meta property="mobile-web-app-capable" content="yes" />
            <meta property="apple-mobile-web-app-title" content={title} />
            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="image" content={image} />
            <meta name="theme-color" content="#000000" />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={domain + `/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={domain + `/favicon-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={domain + `/favicon-16x16.png`}
            />
            <link rel="manifest" href={domain + `/site.webmanifest`} />
            <link
                rel="mask-icon"
                href={domain + `/safari-pinned-tab.svg`}
                color="#ffc222"
            />
            {preloadImages?.length > 0 &&
                preloadImages?.map((img, i) => (
                    <link key={i} rel="preload" href={img} as="image" />
                ))}
            <meta name="msapplication-TileColor" content="#ffc222" />
            <link rel="manifest" href={domain + `/manifest.json`} />

            {/* Open Graph data */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content={pageType} />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hastag} />
            <meta property="og:image" content={image} />
            <meta property="og:image:type" content={image} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta property="og:image:alt" content={imageDescription} />
            <meta property="og:image:type" content="image/png" />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="SunFi" />
            <meta property="og:description" content={description} />
            <meta property="fb:admins" content={facebookAppId} />

            {/* Open Graph data for educational-content/project */}
            <meta property="article:published_time" content={post.created_at} />
            <meta property="article:modified_time" content={post.updated_at} />
            <meta property="article:section" content={post.category} />
            <meta property="article:tag" content={post.tags} />
            <meta property="og:updated_time" content={post.created_at} />

            {/* Google data */}
            <meta
                name="google-site-verification"
                content={googleSiteVerificationId}
            />
            {/* Facebook tag */}
            <meta
                name="facebook-domain-verification"
                content={facebookDomainVerificationId}
            />
            <meta itemProp="name" content={title} />
            <meta itemProp="description" content={description} />
            <meta itemProp="image" content={image} />

            {/* Twitter data */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:site" content="@sunfi" />
            <meta name="twitter:creator" content="@sunfi" />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:image:src" content={image} />
        </Helmet>
    )
}

export default PageMeta

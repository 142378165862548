export function recommendationsErrorFormatter(error) {
    let formattedStr = ''

    const key = Object.keys(error)[0]
    if (!key) return formattedStr

    if (key !== 'appliances') {
        formattedStr = `${key}: ${error[key]} \n`
    } else {
        Object.keys(error[key]).forEach(appliance => {
            formattedStr += `appliance-${appliance}: ${error[key][appliance]} \n`
        })
    }
    return formattedStr
}

export function displaySummaryBody(toggledText, setToggledText) {
    const cb = document.querySelector('#consumer-summary')
    cb.className === 'SSEEnergySummaryBodyVisible'
        ? ((cb.className = 'SSEEnergySummaryBody'),
          (document.getElementById('arrow').className =
              'GYOEnergySummaryArrowRotateHidden'))
        : ((cb.className = 'SSEEnergySummaryBodyVisible'),
          (document.getElementById('arrow').className =
              'GYOEnergySummaryArrowRotateVisible'))
    setToggledText(toggledText === 'Close' ? 'See/add more' : 'Close')
}

export const handleSelectChange = (
    name,
    value,
    errors,
    setInputs,
    setSearch,
) => {
    setSearch(false)
    setInputs(prev => ({ ...prev, [name]: value }))
    if (errors[name]) {
        delete errors[name]
    }
}

export const handleInputChange = (event, setInputs, setErrors) => {
    const { name, value } = event.target

    if (setErrors) {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
    }

    setInputs(preState => ({
        ...preState,
        [name]: value,
    }))
}

export const handleSearch = (
    name,
    value,
    setInputs,
    setSearch,
    setSearchValue,
) => {
    if (value !== '') {
        setInputs(prev => ({ ...prev, [name]: value }))
    } else {
        setSearch(true)
        setSearchValue(value)
    }
}

import axios from 'src/config/axios'

export const getProfileApi = async id => {
    const request = await axios.get(`/admin/energy-profiles/${id}`)
    return request
}

export const getProfilePackagesApi = async (id, page) => {
    const request = await axios.get(`/admin/energy-profiles/${id}/packages?page=${page}`)
    return request
}

export const updateProfileApi = async (id, payload) => {
    const request = await axios.patch(`/admin/energy-profiles/${id}`, payload)
    return request
}

export const updateProfilePackageApi = async (
    profileId,
    packageId,
    payload,
) => {
    const request = await axios.patch(
        `/admin/energy-profiles/${profileId}/packages/${packageId}`,
        payload,
    )
    return request
}

export const deleteProfileApi = async id => {
    const request = await axios.delete(`/admin/energy-profiles/${id}`)
    return request
}

export const duplicateProfileApi = async id => {
    const request = await axios.post(`/admin/energy-profiles/${id}/duplicate`)
    return request
}

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import blueArrow from 'src/assets/images/right-blue-arrow.svg'
import { LEARN_ROUTES } from 'src/routes/pathnames'
import styles from './newupdatenotification.module.scss'

const NewUpdateNotification = () => {
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()

    const toggleShowModal = () => {
        setShowModal(prev => !prev)
    }

    const handleNavigateToArticle = () => {
        history.push(LEARN_ROUTES.learnContentThirteen)
    }

    return (
        <>
            <div className={styles.Content} onClick={toggleShowModal}>
                <span>New update 🎉</span>
                <p className={styles.DesktopTimer}>
                    Finding what you need is easier now
                </p>
                <img src={blueArrow} alt="icon" />
            </div>
            <SecondaryModal
                showModal={showModal}
                v2
                showFooter
                title="New Update 🎉"
                footerLeftContent="chat"
                primaryBtnText="Read Article"
                toggleShowModal={toggleShowModal}
                onCancel={toggleShowModal}
                handlePrimaryBtnClick={handleNavigateToArticle}
                content={
                    <div className={styles.ModalContent}>
                        <img
                            src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/introducing-the-new-consumer-smart-shopping-experience-V2.png"
                            alt="content"
                        />
                        <h2>Finding what you need is easier now</h2>
                        <p>
                            We listened and learned from our users&apos;
                            experiences, and today, we&apos;re thrilled to
                            unveil the next evolution of our platform
                            <br />
                            <span>
                                A simpler way to shop for your solar systems.
                            </span>
                        </p>
                    </div>
                }
            />
        </>
    )
}

export default NewUpdateNotification

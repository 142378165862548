export const eventTrackers = {
    distributor: {
        action: 'PARTNER_AS_A_DISTRIBUTOR_CLICK',
        category: 'CTA_BUTTONS',
        label: 'PARTNER_AS_A_DISTRIBUTOR_CTA',
    },
    installer: {
        action: 'PARTNER_AS_AN_INSTALLER_CLICK',
        category: 'CTA_BUTTONS',
        label: 'PARTNER_AS_AN_INSTALLER_CTA',
    },
    manufacturer: {
        action: 'PARTNER_AS_A_MANUFACTURER_CLICK',
        category: 'CTA_BUTTONS',
        label: 'PARTNER_AS_A_MANUFACTURER_CTA',
    },
    offer: {
        action: 'GET_YOUR_OFFER_CLICK',
        category: 'CTA_BUTTONS',
        label: 'GET_YOUR_OFFER_CTA',
    },
    home: {
        action: 'GET_YOUR_OFFER_CLICK',
        category: 'CTA_BUTTONS',
        label: 'GET_YOUR_OFFER_CTA',
    },
    partnerSignup: {
        action: 'BECOME_A_PARTNER_CLICK',
        category: 'CTA_BUTTONS',
        label: 'BECOME_A_PARTNER_CTA',
    },
    partnerLogin: {
        action: 'SIGN_IN_AS_A_PARTNER_CLICK',
        category: 'CTA_BUTTONS',
        label: 'SIGN_IN_AS_A_PARTNER_CTA',
    },
    addComponent: {
        action: 'ADD_COMPONENT_VALUES',
        category: 'ADD_COMPONENT',
        label: 'ADD_COMPONENT_CTA',
    },
    addComponentAttributes: {
        action: 'ADD_COMPONENT_ATTRIBUTES',
        category: 'ADD_COMPONENT',
        label: 'ADD_COMPONENT_CTA',
    },
    createComponent: {
        action: 'CREATE_COMPONENT_SUCCESSFULLY',
        category: 'ADD_COMPONENT',
        label: 'ADD_COMPONENT_CTA',
    },
    editComponent: {
        action: 'EDIT_COMPONENT_CLICK',
        category: 'EDIT_COMPONENT',
        label: 'EDIT_COMPONENT_CTA',
    },
    deleteComponent: {
        action: 'DELETE_COMPONENT_CLICK',
        category: 'DELETE_COMPONENT',
        label: 'DELETE_COMPONENT_CTA',
    },
    addPackageName: {
        action: 'ADD_PACKAGE_NAME',
        category: 'ADD_PACKAGE',
        label: 'ADD_PACKAGE_NAME',
    },
    addPackageDescription: {
        action: 'ADD_PACKAGE_DESCRIPTION',
        category: 'ADD_PACKAGE',
        label: 'ADD_PACKAGE_DESCRIPTION',
    },
    selectComponent: {
        action: 'SELECT_COMPONENT',
        category: 'ADD_PACKAGE',
        label: 'SELECT_COMPONENT',
    },
    addPackageCapex: {
        action: 'ADD_PACKAGE_CAPEX',
        category: 'ADD_PACKAGE',
        label: 'ADD_PACKAGE_CAPEX',
    },
    addPackageProtectiveDevice: {
        action: 'ADD_PACKAGE_PROTECTIVE_DEVICE',
        category: 'ADD_PACKAGE',
        label: 'ADD_PACKAGE_PROTECTIVE_DEVICE',
    },
    addPackage: {
        action: 'PACKAGE_CREATED',
        category: 'ADD_PACKAGE',
        label: 'ADD_PACKAGE_CTA',
    },
    addGlobalPackage: {
        action: 'GLOBAL_PACKAGE_CREATED',
        category: 'ADD_GLOBAL_PACKAGE',
        label: 'ADD_GLOBAL_PACKAGE_CTA',
    },
    editPackage: {
        action: 'EDIT_PACKAGE_CLICK',
        category: 'EDIT_PACKAGE',
        label: 'EDIT_PACKAGE_CTA',
    },
    deletePackage: {
        action: 'DELETE_PACKAGE_CLICK',
        category: 'DELETE_PACKAGE',
        label: 'DELETE_PACKAGE_CTA',
    },
    duplicatePackage: {
        action: 'DUPLICATE_PACKAGE_CLICK',
        category: 'DUPLICATE_PACKAGE',
        label: 'DUPLICATE_PACKAGE_CTA',
    },
    getYourOffer: {
        action: 'GET_YOUR_OFFER_CLICK',
        category: 'CTA_BUTTONS',
        label: 'GET_YOUR_OFFER_CTA',
    },
    updateApplication: {
        action: 'UPDATE_APPLICATION_CLICK',
        category: 'CTA_BUTTONS',
        label: 'UPDATE_APPLICATION_CTA',
    },
    repaymentSchedule: {
        action: 'REPAYMENT_SCHEDULE_CLICK',
        category: 'CTA_BUTTONS',
        label: 'REPAYMENT_SCHEDULE_CTA',
    },
    updateApplicationPhoneLogin: {
        action: 'UPDATE_APPLICATION_PHONE_LOGIN_CLICK',
        category: 'CTA_BUTTONS',
        label: 'UPDATE_APPLICATION_PHONE_LOGIN_CTA',
    },
    updateApplicationEmailLogin: {
        action: 'UPDATE_APPLICATION_EMAIL_LOGIN_CLICK',
        category: 'CTA_BUTTONS',
        label: 'UPDATE_APPLICATION_EMAIL_LOGIN_CTA',
    },
    repaymentSchedulePhoneLogin: {
        action: 'REPAYMENT_SCHEDULE_PHONE_LOGIN_CLICK',
        category: 'CTA_BUTTONS',
        label: 'REPAYMENT_SCHEDULE_PHONE_LOGIN_CTA',
    },
    repaymentScheduleEmailLogin: {
        action: 'REPAYMENT_SCHEDULE_EMAIL_LOGIN_CLICK',
        category: 'CTA_BUTTONS',
        label: 'REPAYMENT_SCHEDULE_EMAIL_LOGIN_CTA',
    },
    consumerPersonalInformation: {
        action: 'UPDATE_CONSUMER_PERSONAL_INFORMATION_CLICK',
        category: 'CTA_BUTTONS',
        label: 'UPDATE_CONSUMER_PERSONAL_INFORMATION_CTA',
    },
    consumerEnergyInformation: {
        action: 'UPDATE_CONSUMER_ENERGY_INFORMATION_CLICK',
        category: 'CTA_BUTTONS',
        label: 'UPDATE_CONSUMER_ENERGY_INFORMATION_CTA',
    },
    consumerCreditInformation: {
        action: 'UPDATE_CONSUMER_CREDIT_INFORMATION_CLICK',
        category: 'CTA_BUTTONS',
        label: 'UPDATE_CONSUMER_CREDIT_INFORMATION_CTA',
    },
    viewConsumerSolution: {
        action: 'VIEW_CONSUMER_SOLUTION_CLICK',
        category: 'CTA_BUTTONS',
        label: 'VIEW_CONSUMER__SOLUTION_CTA',
    },
    consumerSelectedSolution: {
        action: 'CONSUMER_SELECTED_SOLUTION_CLICK',
        category: 'CTA_BUTTONS',
        label: 'CONSUMER_SELECTED_SOLUTION_CTA',
    },
    submittedConsumerPII: {
        action: 'SUBMIT_CONSUMER_PII_CLICK',
        category: 'CTA_BUTTONS',
        label: 'SUBMIT_CONSUMER_PII_CTA',
    },
    submittedConsumerPIIThirdParty: {
        action: 'SUBMIT_CONSUMER_PII_AS_A_THIRD_PARTY_CLICK',
        category: 'CTA_BUTTONS',
        label: 'SUBMIT_CONSUMER_PII_AS_A_THIRD_PARTY_CTA',
    },
    consumerPIIFlowExistingConsumer: {
        action: 'CONSUMER_PII_EXISTING_CONSUMER_CLICK',
        category: 'EXISTING_CONSUMER',
        label: 'CONSUMER_PII_EXISITING_CONSUMER',
    },
    consumerPIIFlowSalaryDropOff: {
        action: 'CONSUMER_PII_SALARY_DROP_OFF_CLICK',
        category: 'DROP_OFF',
        label: 'CONSUMER_PII_SALARY_DROP_OFF',
    },
    consumerPIIFlowEmploymentStatusDropOff: {
        action: 'CONSUMER_PII_EMPLOYMENT_STATUS_DROP_OFF_CLICK',
        category: 'DROP_OFF',
        label: 'CONSUMER_PII_EMPLOYMENT_STATUS_DROP_OFF',
    },
    consumerPIIFlowMonoEntry: {
        action: 'CONSUMER_PII_MONO_ENTRY_CLICK',
        category: 'CTA_BUTTONS',
        label: 'CONSUMER_PII_MONO_ENTRY_CTA',
    },
    consumerPIIFLowUploadEntry: {
        action: 'CONSUMER_PII_UPLOAD_SOFT_COPY_ENTRY_CLICK',
        category: 'CTA_BUTTONS',
        label: 'CONSUMER_PII_UPLOAD_SOFT_COPY_ENTRY_CTA',
    },
    createPaymentPlan: {
        action: 'PAYMENT_PLAN_CREATED_SUCCESSFULLY',
        category: 'CREATE_PAYMENT_PLAN',
        label: 'PAYMENT_PLAN_STEP_THREE',
    },
    createPaymentPlanStepOnePartOne: {
        action: 'PAYMENT_PLAN_STEP_ONE_PART_ONE_INFO_SAVED_SUCCESSFULLY',
        category: 'CREATE_PAYMENT_PLAN',
        label: 'PAYMENT_PLAN_STEP_ONE_PART_ONE',
    },
    restimatedPaymentPlanStepOnePartOne: {
        action: 'RESTIMATED_PAYMENT_PLAN_STEP_ONE_PART_ONE_INFO_SAVED_SUCCESSFULLY',
        category: 'RESTIMATE_PAYMENT_PLAN',
        label: 'RESTIMATED_PAYMENT_PLAN_STEP_ONE_PART_ONE',
    },
    createPaymentPlanStepOnePartTwo: {
        action: 'PAYMENT_PLAN_STEP_ONE_PART_TWO_INFO_SAVED_SUCCESSFULLY',
        category: 'CREATE_PAYMENT_PLAN',
        label: 'PAYMENT_PLAN_STEP_ONE_PART_TWO',
    },
    restimatedPaymentPlanStepOnePartTwo: {
        action: 'RESTIMATED_PAYMENT_PLAN_STEP_ONE_PART_TWO_INFO_SAVED_SUCCESSFULLY',
        category: 'RESTIMATE_PAYMENT_PLAN',
        label: 'RESTIMATED_PAYMENT_PLAN_STEP_ONE_PART_TWO',
    },
    createPaymentPlanStepTwo: {
        action: 'PAYMENT_PLAN_STEP_TWO_INFO_SAVED_SUCCESSFULLY',
        category: 'CREATE_PAYMENT_PLAN',
        label: 'PAYMENT_PLAN_STEP_TWO',
    },
    restimatedPaymentPlanStepTwo: {
        action: 'RESTIMATED_PAYMENT_PLAN_STEP_TWO_INFO_SAVED_SUCCESSFULLY',
        category: 'RESTIMATE_PAYMENT_PLAN',
        label: 'RESTIMATED_PAYMENT_PLAN_STEP_TWO',
    },
    viewPaymentPlan: {
        action: 'VIEW_PAYMENT_PLAN_CLICK',
        category: 'PAYMENT_PLAN',
        label: 'VIEW_PAYMENT_PLAN_CTA',
    },
    updatePaymentPlan: {
        action: 'VIEW_PAYMENT_PLAN_CLICK',
        category: 'PAYMENT_PLAN',
        label: 'VIEW_PAYMENT_PLAN_CTA',
    },
    onboardingPersonalInformation: {
        action: 'PARTNERS_PERSONAL_INFORMATION_SUBMISSION',
        category: 'PARTNERS_ONBOARDING',
        label: 'PARTNERS_PERSONAL_INFORMATION',
    },
    onboardingWorkExperience: {
        action: 'PARTNERS_WORK_EXPERIENCE_SUBMISSION',
        category: 'PARTNERS_ONBOARDING',
        label: 'PARTNERS_WORK_EXPERIENCE',
    },
    onboardingIdentityVerification: {
        action: 'PARTNERS_IDENTITY_VERIFICATION_SUBMISSION',
        category: 'PARTNERS_ONBOARDING',
        label: 'PARTNERS_IDENTITY_VERIFICATION',
    },
    onboardingNubanAccountDetails: {
        action: 'PARTNERS_NUBAN_ACCOUNT_DETAILS_SUBMISSION',
        category: 'PARTNERS_ONBOARDING',
        label: 'PARTNERS_NUBAN_ACCOUNT_DETAILS',
    },
    onboardingCompanyInformation: {
        action: 'PARTNERS_COMPANY_INFORMATION_SUBMISSION',
        category: 'PARTNERS_ONBOARDING',
        label: 'PARTNERS_COMPANY_INFORMATION',
    },
    onboardingCompanyContact: {
        action: 'PARTNERS_COMPANY_CONTACT_SUBMISSION',
        category: 'PARTNERS_ONBOARDING',
        label: 'PARTNERS_COMPANY_CONTACT',
    },
    sseLandingPage: {
        action: 'View consumer page',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'View consumer page',
    },
    sseApplianceModal: {
        action: 'SSE_APPLIANCE_MODAL',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'SSE_APPLIANCE_MODAL',
    },
    sseGetSolarRecommendations: {
        action: 'SSE_GET_SOLAR_RECOMMENDATIONS',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'SSE_GET_SOLAR_RECOMMENDATIONS',
    },
    sseViewSolarRecommendations: {
        action: 'SSE_VIEW_SOLAR_RECOMMENDATIONS',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'SSE_VIEW_SOLAR_RECOMMENDATIONS',
    },
    seeAllPromoButton: {
        action: 'SEE_ALL_PROMO_BUTTON',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'SEE_ALL_PROMO_BUTTON',
    },
    clickedGetStartedButton: {
        action: 'CLICKED_GET_STARTED_BUTTON',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'CLICKED_GET_STARTED_BUTTON',
    },
    clickedPartnerButton: {
        action: 'CLICKED_PARTNER_BUTTON',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'CLICKED_PARTNER_BUTTON',
    },
    clickedForSolarSavingsCalculations: {
        action: 'CLICKED_FOR_SOLAR_SAVINGS_CALCULATIONS',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'CLICKED_FOR_SOLAR_SAVINGS_CALCULATIONS',
    },
    sendDetailedSavingCalculations: {
        action: 'SEND_DETAILED_SAVING_CALCULATIONS',
        category: 'SMART_SHOPPING_EXPERIENCE_LANDING_PAGE',
        label: 'SEND_DETAILED_SAVING_CALCULATIONS',
    },
    sseViewSolarSystem: {
        action: 'SSE_VIEW_SOLAR_SOLAR_SYSTEM',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_VIEW_SOLAR_SOLAR_SYSTEM',
    },
    sseGetSolarSystem: {
        action: 'SSE_GET_SOLAR_SOLAR_SYSTEM',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_GET_SOLAR_SOLAR_SYSTEM',
    },
    sseSaveBasicInfo: {
        action: 'SSE_SAVE_BASIC_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSaveBasicInfoSuccessfully: {
        action: 'SSE_SAVE_BASIC_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSubmitBasicInfo: {
        action: 'SSE_SUBMIT_BASIC_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    ssePhoneVerificationSuccessful: {
        action: 'SSE_PHONE_VERIFICATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    ssePhoneVerificationUnsuccessful: {
        action: 'SSE_PHONE_VERIFICATION_UNSUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseEmailVerificationSuccessful: {
        action: 'SSE_EMAIL_VERIFICATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseEmailVerificationUnsuccessful: {
        action: 'SSE_EMAIL_VERIFICATION_UNSUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitBasicInfoSuccessfully: {
        action: 'SSE_SUBMIT_BASIC_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSaveBusinessInfo: {
        action: 'SSE_SAVE_BUSINESS_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSaveBusinessInfoSuccessfully: {
        action: 'SSE_SAVE_BUSINESS_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSubmitBusinessInfo: {
        action: 'SSE_SUBMIT_BUSINESS_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitBusinessInfoSuccessfully: {
        action: 'SSE_SUBMIT_BUSINESS_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSaveIdentityInfo: {
        action: 'SSE_SAVE_IDENTITY_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSaveIdentityInfoSuccessfully: {
        action: 'SSE_SAVE_IDENTITY_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSubmitIdentityInfo: {
        action: 'SSE_SUBMIT_IDENTITY_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitIdentityInfoSuccessfully: {
        action: 'SSE_SUBMIT_IDENTITY_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSaveBusinessIdentityInfo: {
        action: 'SSE_SAVE_BUSINESS_IDENTITY_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSaveBusinessIdentityInfoSuccessfully: {
        action: 'SSE_SAVE_BUSINESS_IDENTITY_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSubmitBusinessIdentityInfo: {
        action: 'SSE_SUBMIT_BUSINESS_IDENTITY_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitBusinessIdentityInfoSuccessfully: {
        action: 'SSE_SUBMIT_BUSINESS_IDENTITY_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitIdentityImage: {
        action: 'SSE_SUBMIT_IDENTITY_IMAGE',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitIdentityImageSuccessfully: {
        action: 'SSE_SUBMIT_IDENTITY_IMAGE_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitIdentityImageUnsuccessful: {
        action: 'SSE_SUBMIT_IDENTITY_IMAGE_UNSUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSaveFinancialInfo: {
        action: 'SSE_SAVE_FINANCIAL_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSaveFinancialInfoSuccessfully: {
        action: 'SSE_SAVE_FINANCIAL_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_KYC',
    },
    sseSubmitFinancialInfo: {
        action: 'SSE_SUBMIT_FINANCIAL_INFORMATION',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSubmitFinancialInfoSuccessfully: {
        action: 'SSE_SUBMIT_FINANCIAL_INFORMATION_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_KYC',
    },
    sseSaveCompletedKyc: {
        action: 'SAVE_COMPLETED_KYC',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SAVE_COMPLETED_KYC',
    },
    sseSaveCompletedKycSuccessfully: {
        action: 'SAVE_COMPLETED_KYC_SUCCESSFUL',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC_SUCCESSFUL',
        label: 'SSE_SAVE_COMPLETED_KYC',
    },
    sseSubmitCompletedKyc: {
        action: 'SUBMIT_COMPLETED_KYC',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'SSE_SUBMIT_COMPLETED_KYC',
    },
    sseSubmitCompletedKycSuccessfully: {
        action: 'SUBMIT_COMPLETED_KYC',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC_SUCCESSFUL',
        label: 'SSE_SUBMIT_COMPLETED_KYC',
    },
    creditDecisionSuccess: {
        action: 'Receives credit decision',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Receives credit decision',
    },
    creditDecisionisYesL2O: {
        action: 'Credit decision is yes - L2O',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Credit decision is yes - L2O',
    },
    creditDecisionisYesSub: {
        action: 'Credit decision is yes - Subsp.',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Credit decision is yes - Subsp.',
    },
    creditDecisionisYesWithSuggestion: {
        action: 'Credit decision is yes w. sugg. - L2O',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Credit decision is yes w. sugg. - L2O',
    },
    creditDecisionisMaybe: {
        action: 'Credit decision is maybe - L2O',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Credit decision is maybe - L2O',
    },
    creditDecisionIsNoL20: {
        action: 'Credit decision is no - L2O',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Credit decision is no - L2O',
    },
    creditDecisionIsNoSub: {
        action: 'Credit decision is no - Subsp.',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Credit decision is no - Subsp.',
    },
    creditDecisionMaybeOptions: {
        action: 'Credit decision is maybe w. options',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Credit decision is maybe w. options',
    },
    creditDecisionMaybeRentalOption: {
        action: 'CREDIT_DECISION_IS_MAYBE_SWITCH_TO_RENTAL_OPTION_SELECTED',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_MAYBE',
    },
    creditDecisionMaybeRentalSimulationSuccessFul: {
        action: 'CREDIT_DECISION_IS_MAYBE_SWITCH_TO_RENTAL_PAYMENT_PLAN_SIMULATION_SUCCESS',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_MAYBE',
    },
    creditDecisionMaybeRentalSwitch: {
        action: 'CREDIT_DECISION_IS_MAYBE_SWITCH_TO_RENTAL_PAYMENT_PLAN_SELECTED',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_MAYBE',
    },
    creditDecisionMaybeRentalSwitchSuccessFul: {
        action: 'CREDIT_DECISION_IS_MAYBE_SWITCH_TO_RENTAL_PAYMENT_PLAN_SUCCESS',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_MAYBE',
    },
    sseShowPaymentPlans: {
        action: 'VIEW_ALL_PAYMENT_PLANS',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseSelectPaymentPlan: {
        action: 'SELECT_PAYMENT_PLAN',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseUnselectPaymentPlan: {
        action: 'UNSELECT_PAYMENT_PLAN',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseShowPaymentPlanDetails: {
        action: 'PAYMENT_PLAN_DETAILS',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseConfirmSelectedPaymentPlan: {
        action: 'PAYMENT_PLAN_SELECTED',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseConfirmSelectedPaymentPlanWithNewUpfrontDeposit: {
        action: 'PAYMENT_PLAN_WITH_NEW_UPFRONT_DEPOSIT_SELECTED',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseSelectedPaymentPlanSuccessFul: {
        action: 'PAYMENT_PLAN_SELECTED_SUCCESSFULLY',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseShowSubscriptionDetails: {
        action: 'SUBSCRIPTION_PLAN_DETAILS',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    ssePayUpfrontDeposit: {
        action: 'PAY_UPFRONT_DEPOSIT',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseIncreaseUpfrontDeposit: {
        action: 'INCREASE_PAYMENT_PLAN_UPFRONT_DEPOSIT',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES_WITH_SUGGESTION',
    },
    sseSubmitIncreasedUpfrontDeposit: {
        action: 'SUBMIT_INCREASDE_PAYMENT_PLAN_UPFRONT_DEPOSIT',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES_WITH_SUGGESTION',
    },
    sseSimulateIncreasedUpfrontDeposit: {
        action: 'SIMULATE_INCREASDE_PAYMENT_PLAN_UPFRONT_DEPOSIT',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES_WITH_SUGGESTION',
    },
    sseTenureSetup: {
        action: 'SHOW_TENURE_SETUP',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseSelectedTenure: {
        action: 'SELECTED_TENURE',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    sseSubmitSelectedTenureSuccesful: {
        action: 'SELECTED_TENURE_SUBMITTED_SUCCESFULLY',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    showRepaymentType: {
        action: 'SHOW_REPAYMENT_METHOD_TYPE',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    showCardDebitSetup: {
        action: 'SHOW_REPAYMENT_CARD_DEBIT_SETUP',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    showDirectDebitSetup: {
        action: 'SHOW_REPAYMENT_DIRECT_DEBIT_SETUP',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    showMandate: {
        action: 'SHOW_DIRECT_DEBIT_MANDATE',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    showNonMandate: {
        action: 'SHOW_DIRECT_DEBIT_NON_MANDATE',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
    printMandate: {
        action: 'PRINT_DIRECT_DEBIT_MANDATE',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'CREDIT_DECISION_IS_YES',
    },
}

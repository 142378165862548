import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { getPaymentPlans } from 'src/api/admin/payment-plans/listings'
import { format } from 'date-fns'
import { getOutrightSaleOfferStatusKeyByTitle } from '../data'
import { errorHandler } from 'src/utils/errorHandler'
import {
    campaignTypePayloadMap,
    providerTypePayloadMap,
    statementTypePayloadMap,
    statusPayloadMapping,
    onboardingTypePayloadMap,
} from '../data'
import { updateAdminOutrightSaleFilters } from 'src/redux/reducers/admin/outright-sale/listing'
import { convertQueryParamsToObject } from 'src/utils/formatting'

export const useGetOutrightSales = ({
    filterState,
    canUseNewOfferStatus,
    showUpdatedSSEFlow,
    setData,
    setTotalPages,
    setToastError,
    setErrorMessage,
}) => {
    const dispatch = useDispatch()

    const queryKey = [
        'fetch-payment-plans',
        filterState?.surveyType,
        filterState?.currentPage,
        filterState?.searchText,
        filterState?.status,
        filterState?.filterInputs?.start_date,
        filterState?.filterInputs?.end_date,
        filterState?.filterInputs?.onboarding_type,
    ]

    const { refetch: outrightSalesRefetch, isFetching: outrightSaleFetching } =
        useQuery(
            queryKey,
            () =>
                getPaymentPlans(
                    filterState?.surveyType,
                    filterState?.currentPage,
                    filterState?.searchText,
                    canUseNewOfferStatus
                        ? filterState?.status
                              ?.map(item =>
                                  getOutrightSaleOfferStatusKeyByTitle(
                                      item?.value,
                                  ),
                              )
                              ?.join(',') || ''
                        : filterState?.status
                              ?.map(item => statusPayloadMapping[item?.value])
                              ?.join(',') || '',
                    canUseNewOfferStatus,
                    (filterState?.filterInputs?.start_date !== null &&
                        format(
                            new Date(filterState?.filterInputs?.start_date),
                            'yyyy-MM-dd',
                        )) ||
                        '',
                    (filterState?.filterInputs?.end_date !== null &&
                        format(
                            new Date(filterState?.filterInputs?.end_date),
                            'yyyy-MM-dd',
                        )) ||
                        '',
                    filterState?.filterInputs?.payment_plan_type || '',
                    filterState?.filterInputs?.provider_type
                        ?.map(item => providerTypePayloadMap[item])
                        ?.join(',') || '',
                    filterState?.filterInputs?.campaign_type !== ''
                        ? campaignTypePayloadMap[
                              filterState?.filterInputs?.campaign_type
                          ]
                        : '',
                    filterState?.filterInputs?.statement_type?.length > 0
                        ? filterState?.filterInputs?.statement_type
                              ?.map(item => statementTypePayloadMap[item])
                              ?.join(',')
                        : '',
                    showUpdatedSSEFlow,
                    filterState?.filterInputs?.onboarding_type?.length > 0
                        ? filterState?.filterInputs?.onboarding_type
                              ?.map(item => onboardingTypePayloadMap[item])
                              ?.join(',')
                        : '',
                ),
            {
                enabled: true,
                refetchOnWindowFocus: false,
                retry: false,
                onSuccess: res => {
                    const queries = res?.config?.url
                        .split('?')[1]
                        ?.replaceAll('=', ':')
                        ?.split('&')
                    dispatch(
                        updateAdminOutrightSaleFilters(
                            convertQueryParamsToObject([...queries]),
                        ),
                    )
                    const data = res?.data?.data
                    setData && setData(data)
                    setTotalPages && setTotalPages(res?.data?.total_pages)
                },
                onError: error => {
                    setToastError && setToastError(true)
                    setErrorMessage &&
                        setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    return { outrightSaleFetching, outrightSalesRefetch }
}

export const formatAllData = (data = []) => {
    let formattedData = []
    data.forEach(item =>
        formattedData.push({
            id: item?.provider?.id,
            provider_name: item?.provider?.display_name,
            global_access: item?.global_access,
            packages: item?.packages_count,
            components: item?.components_count,
            admin: item?.provider?.user?.is_system_admin,
        }),
    )

    return formattedData
}

export const formatGlobalContributorsData = (data = []) => {
    let formattedData = []
    data.forEach(item => {
        formattedData.push({
            id: item?.provider?.id,
            provider_name: item?.provider?.display_name,
            global_packages: item?.packages_count,
            global_components: item?.components_count,
            admin: item?.provider?.user?.is_system_admin,
        })
    })

    return formattedData
}

import './uploadmodal.scss'
import SecondaryModal from '../Modal/SecondaryModal'
import PropTypes from 'prop-types'
import { message, Upload } from 'antd'
import { ReactComponent as UploadIcon } from 'src/assets/images/upload.svg'
import { ReactComponent as SuccessUploadIcon } from 'src/assets/images/successUpload.svg'
import { useEffect, useState } from 'react'
import { isMobile } from 'src/utils/mediaQueries'

const UploadModal = ({
    showModal,
    onCancel,
    title,
    primaryBtnText,
    successUpload,
    setSuccessUpload,
    acceptableFileTypes,
    setFileToState,
    handlePrimaryBtnClick,
    isLoading,
}) => {
    const [showAdditionalLoadingCopy, setShowAdditionalLoadingCopy] =
        useState(false)
    const [uploading, setUploading] = useState(false)
    const [width, setWidth] = useState(40)
    const [fileValid, setFileValid] = useState(true)
    const { Dragger } = Upload

    const props = {
        name: 'file',
        onChange({ fileList }) {
            setUploading(true)
            const isLt2M = fileList[0].size / 1000 / 1000 <= 10

            if (!Object.keys(acceptableFileTypes)?.includes(fileList[0].type)) {
                message.error(
                    `${
                        fileList[0].name
                    } is not one of the supported file types (${Object.values(
                        acceptableFileTypes,
                    )?.join(',')})`,
                )
                setFileValid(false)
                setUploading(false)
                return false
            } else if (!isLt2M) {
                message.error('File should be less than 10mb')
                setFileValid(false)
                setUploading(false)
                return false
            } else {
                setFileValid(true)
                setSuccessUpload(fileList)
                setUploading(false)

                return true
            }
        },
        onDrop() {
            setUploading(false)
        },
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    const handleSubmit = () => {
        if (successUpload?.length > 0) {
            setFileToState(successUpload)
            onCancel()
        }
    }

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setShowAdditionalLoadingCopy(true)
            }, 5000)
        } else {
            setShowAdditionalLoadingCopy(false)
        }
    }, [isLoading])

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={475}
            title={title}
            showFooter
            primaryBtnText={primaryBtnText}
            primaryBtnWidth={isMobile ? '127.15px' : ''}
            primaryBtnHeight={isMobile ? '44.5px' : ''}
            handlePrimaryBtnClick={handlePrimaryBtnClick || handleSubmit}
            v2
            primaryBtnDisabled={!fileValid || successUpload?.length === 0}
            content={
                <div className={'UploadModalWrapper'}>
                    <Dragger
                        className={'UploadModalDragger'}
                        fileList={successUpload}
                        maxCount={1}
                        showUploadList={true}
                        customRequest={() => {}}
                        {...props}
                    >
                        {uploading ? (
                            <div className="SlideContainer">
                                <p
                                    className="Slider"
                                    style={{
                                        width: `${width}%`,
                                    }}
                                ></p>
                            </div>
                        ) : successUpload?.length > 0 ? (
                            <div className={'UploadModalContent'}>
                                <SuccessUploadIcon />
                                <p>{successUpload?.[0]?.name}</p>
                                
                                {showAdditionalLoadingCopy && (
                                    <p className="UMC__AdditionalLoadingCopy">
                                        Your upload is taking a bit longer than
                                        <br />
                                        usual, we are working on it
                                    </p>
                                )}
                            </div>
                        ) : (
                            <div className={'UploadModalContent'}>
                                <UploadIcon />
                                <p>
                                    Upload file. Drag and drop or{' '}
                                    <span>browse</span>
                                </p>
                                        <p className='fileSize'>The maximum accepted file size is 10mb</p>
                                        <p className='fileFormat'>Supported format : {
                                            Object.values(acceptableFileTypes).join(', ') || ''}</p>

                            </div>
                        )}
                    </Dragger>
                </div>
            }
        />
    )
}

UploadModal.propTypes = {
    showModal: PropTypes.bool,
    isLoading: PropTypes.bool,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    primaryBtnText: PropTypes.string || PropTypes.node,
    successUpload: PropTypes.array,
    setSuccessUpload: PropTypes.func,
    acceptableFileTypes: PropTypes.object,
    setFileToState: PropTypes.func,
    handlePrimaryBtnClick: PropTypes.func,
}

export default UploadModal
